@import "./../../variables";

$arrowColorNormal: transparent;
$arrowColorSelected: $thirdColor;
$thisDefaultColor: #333333;



.leftMenu {
  position: fixed;
  left: -409px;
  top: 0;
  z-index: 1060;
  display: flex;
  height: 100%;
  transition: all .3s ease-in-out;

  &.opened {
    left: 0px;
  }


  .left-trigger-menu-support {
    flex-grow: 0.5;
    flex: auto;
    max-width: 77px;
    height: 78px;
    background: transparent;

    .left-menu-trigger {
      border-color: transparent;
      background-color: transparent;
      cursor: pointer;
      display: block;
      height: 40px;

      margin: 0px;
      margin-top: 20px;

      .leftMenuTrigger {
        width: 32px;
        height: 32px;
        display: block;
        margin-left: 10px;

        .cls-1 {
          fill:#e6e6e6;
          //stroke:#333;
          stroke-miterlimit:10;
        }
      }

      &:focus,
      &:active {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }

  .rcs-custom-scroll {
    .rcs-inner-handle {
      background-color: $thirdColor;
      border-radius: 0px;
      width: 6px;
      right: -4px;
      position: relative;
    }
  }

  .leftMenuSupport {
    width: 409px;
    background-color: #f7f7f7;
    min-height: 600px;
    overflow: hidden;
    color: #000;

    .header {
      height: 78px;
      background-color: $firstColor;
      color: $iconGray;
      min-width: 409px;

      h1 {
        color: $iconGray;
        text-transform: capitalize;
        line-height: 60px;
        font-size: 16px;
        font-weight: bold;
        padding-top: 44px;
        margin-bottom: 0;
      }
      .columns-2 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        div {
          margin-top: 29px;

          &.logo {
            margin-top: auto;
            width: auto;
            position: relative;
          }
          button {
            color: white;
            transition: all .5s ease-in-out .2s;
            border: none;
            font-weight: 600;
            font-size: 14px;
          }
        }
      }
      div {
        &.logo {
          width: 100%;
          position: absolute;
          height: 78px;
          top: 0;
          display: flex;
          align-items: center;
          align-content: center;
          text-align: center;
        }
      }
      img {
        position: relative;
        //left: -30px;
        //top: 4px;
        &.logo {
          max-height: 78px;
          max-width: 143px;
          margin: auto;
          //top: 10px;
        }
      }

      .casinoLogo {
        height: 70px;
        position: relative;
        top: 4px;

        img {
          max-height: 50px;
        }

        .cls-1
        {
          fill:#0d0d0d;
        }
        .cls-2
        {
          fill:#941b20;
        }
        .cls-3
        {fill:#fccd00;}
        .cls-4
        {fill:#ffef00;}
      }

      .close-left-menu {
        float: left;
        position: relative;
        top: 0px;
        left: 14px;
        z-index: 2;

        button {
          border: 0;
          background: transparent;
          color: #ffffff;
          padding-top: 26px;
          padding-bottom: 22px;
          cursor: pointer;

          .close-left-menu-icon {
            height: 30px;
            width: 30px;

            .cls-1 {
              fill:#f2f2f2;
            }
          }

          &:focus,
          &:active {
            outline: none !important;
            box-shadow: none !important;
          }
        }
      }
    }

    .body {
      min-width: 409px;
      box-shadow: inset 1px 6px 10px rgba(0, 0, 0, 0.2);
      padding-bottom: 60px;

      .section {
        display: block;
        width: 100%;
        border-bottom: 2px solid $iconGray;
        padding-bottom: 30px;
        padding-top: 30px;
        &:last-child {
          border-bottom: 0px;
        }
        &:first-child {
          padding-top: 10px;
        }

        ul.menuSection {
          list-style: none;
          margin: 0 !important;
          padding: 0 !important;
          display: block;
          width: 100%;

          li {
            display: block;

            a {
              padding: 0;
              margin: 0;
              color: $thisDefaultColor;
              transition: all .1s ease-in;
              line-height: 52px;

              &.tconditions{
                &:hover{
                  cursor: pointer;
                }
              }

              span {
                margin: 0;
                padding: 0;
                line-height: 52px;
                font-weight: bold;
                font-size: 14px;
              }

              .icon {
                width: 4rem;
                border-left: 6px solid transparent;
                min-width: 4rem;
                transition: all .2s ease-in;
                text-align: center;
                color: $thisDefaultColor;

                svg {
                  width: 32px;
                  height: 32px;
                }

                .menu-info-icon,
                .menu-warning-icon,
                .menu-danger-icon {
                  width: 24px;
                  height: 24px;
                  margin-right: 10px;

                  .cls-2{
                    fill: $thisDefaultColor;
                  }
                }

                .menu-warning-icon {
                  .cls-1 {
                    fill: $warningColor;
                  }
                }

                .menu-info-icon {
                  .cls-1 {
                    fill: $infoColor;
                  }
                }

                .menu-danger-icon {
                  .cls-1 {
                    fill: $dangerColor;
                  }
                }

                .menu-hot-icon {
                  width: 56px;

                  .cls-1{fill:none;stroke:#ed1c24;stroke-miterlimit:10;}
                  .cls-2,
                  .cls-3{fill:#ed1c24;}
                  .cls-3{font-size:14px;font-family:Montserrat-Medium, Montserrat;font-weight:500;letter-spacing:0.02em;}
                }
              }

              .ptext {
                text-align: left;
                text-transform: capitalize;
              }

              .arrow {
                margin: 0;
                text-align: center;

                .menuArrow {
                  fill: $arrowColorNormal;
                  transition: all .2s ease-in;
                }
                .profile-menu-arrow {
                  height: 14px;
                }
              }

              &:hover,
              &.selected {
                text-decoration: none;
                color: $thirdColor;
                font-weight: normal;
                //background-color: $white;

                .icon {
                  border-left: 6px solid $thirdColor;
                  svg {
                    //animation: wobble 1s;
                  }
                }
                .arrow {
                  .menuArrow {
                    fill: $arrowColorSelected;
                  }
                }
              }
            }

          }

          &.notStandard {
            a {
              line-height: 34px;

              span {
                line-height: 34px;
                text-transform: none;
              }
            }
          }
        }
      }
    }
  }
}
