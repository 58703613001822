.bets-lobby {
  background: #fff;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .lobby-component-wrapper {
    display: inline-block;
    width: 100%;

    .component_not_used {
      @for $x from 2 through 10 {
        &:nth-child(#{$x}) {
          opacity: 0;
          transform: translate(0, 100px);
          will-change: opacity, transform;
          animation: staggered 300ms ease forwards;
          animation-delay: 100ms * ($x - 1);
        }
      }
    }

    @keyframes staggered {
      to {
        opacity: 1;
        transform: translate(0, 0)
      }
    }
  }

  .free-spin-ticker {
    position: absolute;
    left: 5px;
    right: 5px;
    top: 14px;
    background: linear-gradient(to right, #ff4e00, #ce0031 81.7%, #80001f);
    border-radius: 3px;
    color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, .5);
    border: 1px solid #FFC6C6;
    font-weight: bold;
    text-align: center;
    font-size: 10px;
    padding: 1px 3px;
    z-index: 1;
    pointer-events: none;

    @media screen and (min-width:1528px) {
      padding: 3px 3px;
      border-radius: 6px;
      font-size: 20px;
      top: 24px;
    }
  }
}

.app-footer-menu-spacer {
  .bets-lobby {
    padding-bottom: 81px;
  }
}