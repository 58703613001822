$transitionDuration: 300ms;

.portrait-player-gallery {
  height: 89px;
  width: 100%;
  position: relative;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  background-image: url('../assets/player-gallery-bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  .header-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .header {
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 55px 0 0;

      .tmr {
        display: flex;
        align-items: center;
        color: #BDBDBD;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;

        img {
          display: block;
          margin-left: 4px;
          margin-right: 5px;
          width: 21px;
          height: 21px;
          opacity: .6;
        }
      }

      .title {
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        margin-right: 0;
        padding-left: 20px;
        white-space: nowrap;
        text-align: center;
        flex: 1 1 auto;

        .text-transition {
          white-space: nowrap;

          .text-transition_inner>div {
            text-align: right;
            width: 100%;
          }
        }

        .muted {
          color: #BDBDBD;
        }
      }

      @media screen and (min-width: 600px) {
        .title {
          margin-right: 97px;
          padding-left: 0;

          .text-transition {
            .text-transition_inner>div {
              text-align: center;
            }
          }
        }
      }

    }

    .hr {
      height: 2px;
      width: 100%;
      background: linear-gradient(to right,
          #14248a00,
          rgba(28, 200, 232, .3) 40%,
          #1ef1ffcc 50%,
          rgba(31, 197, 230, .3) 60%,
          #232a8f00);
    }
  }

  .gallery-wrapper {
    width: 100%;
    height: 90px;
    overflow: hidden;
    padding: 26px 12px 0 16px;
    max-width: 320px;
    margin-right: 55px;
    position: relative;
    user-select: none;

    .empty {
      width: 100%;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 10px;
      color: #AAAFE7;
      font-weight: bold;
      padding-left: 55px;
    }

    .gallery {
      display: grid;

      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      grid-column-gap: 10px;

      // align-items: center;
      // justify-content: space-between;
      position: relative;
      width: 292px;

      .container {
        position: absolute;
        width: 121px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .container.before {
        left: -127px;
      }

      .container.after {
        right: -127px;
      }
    }

    .gallery.float {
      position: relative;
      top: -57px;
    }

    .item {
      pointer-events: none;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform-origin: center;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: linear-gradient(to bottom,
            #E494FF,
            #6B22A7);

        img {
          margin: 2px;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          display: inline-block;
        }

        &.off {
          background: transparent;
        }
      }

      .pos {
        width: 32px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -6px;

        img {
          width: 32px;
          height: 11px;
          object-fit: contain;
        }

        .no {
          position: absolute;

          color: #BFBFBF;
          font-size: 8px;
          font-weight: bold;
          padding-bottom: 2px;
          white-space: nowrap;
        }
      }

      .score {
        margin-top: 3px;
        color: #00FFFF;
        font-size: 11px;
        font-weight: bold;
        line-height: 11px;
        white-space: nowrap;
      }
    }

    .item.current {
      .score {
        opacity: 0;
      }
    }

    .item.big {

      transform: translate(0, -3px) scale(1.2);

      .avatar {
        background: linear-gradient(to bottom,
            #FFEE00,
            #FF9E00 45%,
            #C85D00 100%);

      }

      .score {
        margin-top: 1px;
        color: #fff;
        text-shadow: 0 1px 4px rgba(0, 255, 255, .75);
      }

      .pos {
        position: relative;

        .award-icon {
          position: absolute;
          bottom: 0;
          right: -8px;

          img {
            width: auto;
            height: 16px;
            display: block;
            object-fit: contain;
          }
        }
      }
    }

    .item.bigger {
      transform: translate(0, -3px) scale(1.2);


      .pos {
        opacity: 0;
      }

      .score {
        opacity: 0;
        margin-top: 1px;
        color: #fff;
        text-shadow: 0 1px 4px rgba(0, 255, 255, .75);
      }
    }

    .item.float {
      top: 26px;
      position: absolute;
    }

    .item.float.pos-0 {
      margin-left: 0;
    }

    .item.float.pos-1 {
      margin-left: calc(30.4% - 20px - 14px);
    }

    .item.float.pos-2 {
      margin-left: calc(50% - 20px - 14px);
    }

    .item.float.pos-3 {
      margin-left: calc(70% - 20px - 14px);
    }

    .item.float.pos-4 {
      margin-left: calc(100% - 70px);
    }
  }

  .menu {
    position: absolute;
    right: 12px;
    bottom: 8px;
    width: 31px;
    height: 31px;
    border-radius: 50%;
    border: 2px solid #5D097A;
    background: #6c1ea8;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      display: block;

    }
  }

  .deposit {
    position: absolute;
    right: 12px;
    bottom: 8px;
    cursor: pointer;
    border-radius: 3px;
    height: 32px;
    display: flex;
    align-items: center;
    padding: 0 24px;
    background: linear-gradient(to right, #EC5E00, #981D91);
    border: 1px solid #FFC7DB;
    color: #fff;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    font-family: OpenSans, "Open Sans";
  }

  .menu.close {
    bottom: unset;
    top: 8px;

    border: 2px solid rgba(256, 256, 256, 0.5);
    background: rgba(0, 0, 0, 0.5);
  }
}