@import "./../../../../variables";

.notLogged {
    padding-top: 160px;

    h1 {
        color: $thirdColor;
        font-size: 24px;
        font-weight: 600;
    }
}