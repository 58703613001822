.winter-fun-modal-season-start {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 16px);
    min-width: calc(100% - 16px);
  }

  @media (min-width:400px) {
    & .MuiDialog-paperFullWidth {
      min-width: 400px;
      max-width: 400px;
    }
  }

  .wf-dialog-content {
    background-color: #fff;
    background: url(./assets/modal-bg@3x.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    padding: 8px 35px;
  }

  .wf-header-icon {
    width: 90px;
    height: 90px;
    display: block;
    margin: 0 auto 8px;
  }

  .wf-title {
    font-size: 20px;
    color: #131727;
    font-weight: bold;
    text-align: center;
    margin-bottom: 7px;
  }

  .wf-subtitle {
    font-size: 13px;
    color: #909092;
    font-weight: bold;
    text-align: center;
    margin-bottom: 15px;
  }

  .wf-timeline {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wf-calendar {
      background: url(./assets/calendar-bg.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% 100%;
      width: 84px;
      height: 124px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .wf-day {
        font-size: 40px;
        color: #3D4045;
        font-weight: bolder;
        text-align: center;
        margin-top: 10px;
        line-height: 40px;
      }

      .wf-month {
        font-size: 22px;
        line-height: 22px;
        color: #4B5067;
        font-weight: 600;
        text-align: center;
        text-transform: lowercase;
      }
    }

    .wf-time-range {
      margin: 0 30px;

      .wf-text {
        font-size: 20px;
        color: #131727;
        font-weight: 600;
        text-align: center;
        white-space: nowrap;
      }

      .wf-icon {
        width: 44px;
        height: 39px;
        margin: 0 auto;

        img {
          width: 44px;
          height: auto;
          display: block;
        }
      }
    }
  }

  .wf-rules {
    margin: 0 10px;

    .wf-rule {
      background-color: rgba(255, 255, 255, .6);
      border-radius: 7px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 3px 10px;
      margin-bottom: 22px;

      div.wf-icon {
        height: 12px;
        width: 12px;
        background-image: url(./assets/rule-bullet.svg);
        background-size: contain;
        background-repeat: no-repeat;

        margin-top: 4px;
        min-width: 12px;
      }

      div.wf-content {


        min-width: 0;
        flex: 1 1 auto;

        .wf-header {
          color: #131727;
          font-size: 20px;
          font-weight: bold;
          text-align: left;
        }

        .wf-text {
          color: #4B5067;
          font-size: 13px;
          font-weight: 600;
          text-align: left;
        }

      }
    }
  }

  .wf-button-wrapper {
    display: flex;
    justify-content: center;

    .wf-button {
      background: linear-gradient(to right, #0A44E4, #006FFF);
      color: #fff;
      min-width: 260px;

      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding: 13px;
      border-radius: 10px;
      margin-bottom: 26px;
    }
  }
}