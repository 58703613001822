.FloatingButton {
  position: relative;
  width: 8.5rem;
  margin-top: .5rem;
  margin-left: -6rem;
  transition: all .2s ease-in-out;
  cursor: pointer;
  &.hide {
    margin-left: 10rem;
  }
  &.small {
    //margin-left: 0;
  }
  &:hover {
    margin-left: -6rem;
  }
  .FloatingButtonWrapper {
    background: white;
    border-radius: .75rem;
    box-shadow: 0 4px 8px  rgba(1,1,1,.25);
    .notification-dot {
      background: red;
      border-radius: .5rem;
      border: .125rem solid;
      color: white;
      position: absolute;
      top: -.35rem;
      left: -.25rem;
      width: auto;
      height: 1rem;
      text-align: center;
      font-size: .6rem;
      align-content: center;
      align-self: center;
      display: grid;
      padding: 0 .25rem;
    }
    .FloatingButtonInner {
      display: grid;
      grid-template-columns: 2rem 1fr;
      grid-column-gap: .5rem;
      padding: .25rem .5rem;
      .icon {
        svg {
          width: 100%;
        }
      }
      .title {
        text-align: left;
        font-size: .75rem;
        align-self: center;
        align-content: center;
        font-weight: 600;
        color: #333;
      }
    }
  }
}