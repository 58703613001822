@import "../../../variables";

.safe-charge {
  height: 675px;
  iframe {
    border: none;
  }
}
.secure-transaction {
  margin: -1.5rem 0 0 1.5rem;
  svg {
    margin-top: -0.25rem;
  }
}
.depositLimit {
  font-weight: bold;
  font-size: 14px;
  color: $warningColor;
  margin-top: .5rem;
}
.loading {
  text-align: center;
  padding: 2rem;
  color: black;
}

.Taxes{
  display: flex;
  width: 100%;
  flex: 1;
  justify-content: space-between;
  padding: 0px 25px 0px 25px;
  
  p{
    font-size: 13px;
    font-weight: bold;
    margin: 0;
    margin-bottom: -5px;
  }
  span{
    font-size: 11px;
    font-weight: normal;
    margin: 0;
  }

  .left{
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
  }
  .right{
    justify-content: flex-end;
    align-items: flex-end;
    text-align: right;
  }
}
.bonus-button-label {
  display: block;
  color: purple;
  text-transform: uppercase;
  font-size: 0.5rem;
}