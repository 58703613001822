.registerV2 {
  .step-2 {
    .text-separator {
      margin: 1rem auto 2rem auto;
      width: 75%;

      text-align: center;
      text-transform: uppercase;
      font-size: .75rem;
      color: #6B7892;
      position: relative;
      border-bottom: .5px solid;
      height: .45rem;
      span {
        background: #FFFFFF;
        padding: 0 .5rem;
        font-weight: normal;
        font-size: 0.7rem;
      }
    }
  }
}