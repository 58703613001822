.PopupMarketingDialog {
  z-index: 1304 !important;

  font-family: "Open Sans", OpenSans;

  * {
    box-sizing: border-box;
  }

  & .MuiPaper-rounded {
    border-radius: 10px;
    background-color: #E1E6E8;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 20px);
    max-width: 360px;
    margin: 10px;
  }


  .dialogContent {
    padding: 0px !important;

    .dialogBody {
      position: relative;

      .button {
        user-select: none;
        cursor: pointer;
        height: 48px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        flex: 1 1 100%;
      }

      .flex-full {
        flex: 1 1 100%;
      }

      .button:first-child {
        margin-right: 6px;
      }

      .button:last-child {
        margin-left: 6px;
      }

      .pbd-top {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 16px;
        justify-content: flex-end;
        border-radius: 7px;
        overflow: hidden;
        min-height: 519px;
        width: 100%;

        img {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: center;
        }

        &>div {
          position: relative;
        }

        .pbd-header {
          position: absolute;
          top: 16px;
          right: 16px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;

          .close {
            cursor: pointer;
            width: 40px;
            height: 40px;
            background: rgba(255, 255, 255, 0.30);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #ccc;
            border-radius: 50%;
          }
        }

        .pbd-footer {
          padding: 0 24px 24px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;

          .button.cta {
            background: linear-gradient(to right, #FE1C6B, #F8961D);
            color: #fff;
            padding: 0px 24px;
            white-space: nowrap;
          }

          .button.tc {
            background: linear-gradient(to right, #0C0B0B, #1F1F1F);
            border: 1px solid #565151;
            color: #BDBDBD;
          }

          .button.tc.hide-cls {
            background: linear-gradient(to right, #0048D7, #113D70);
            border: 1px solid #565151;
            color: #BDBDBD;
          }
        }

        .pbd-rules {
          padding: 0px 24px 36px;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
      }

      .disabled {
        pointer-events: none;
        background-color: #E8E8EB !important;
        color: #A2AAB1 !important;
      }

    }
  }

  .pdc-row {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: flex-start;

    div.txt {
      text-align: left;
      min-width: 0;
      flex: 1 1 auto;
    }

    .rich-text {
      color: #fff;

      p {
        margin: 0;
      }

      ol {
        list-style: decimal;
        padding-inline-start: 2em;
      }

      ul {
        list-style: disc;
        padding-inline-start: 2em;
      }

      li {
        display: list-item;
        line-height: normal;
      }

      em {
        font-style: italic;
      }

      strong {
        font-weight: bold;
      }

      sup {
        vertical-align: super;
        font-size: smaller;
      }

      sub {
        vertical-align: sub;
        font-size: smaller;
      }

      h1 {
        font-size: 2em;
        font-weight: bold;
      }

      h2 {
        font-size: 1.5em;
        font-weight: bold;
      }

      h3 {
        font-size: 1.17em;
        font-weight: bold;
      }

      h4 {
        font-size: 1em;
        font-weight: bold;
      }

      h5 {
        font-size: 0.83em;
        font-weight: bold;
      }

      h6 {
        font-size: 0.67em;
        font-weight: bold;
      }

      .ql-align-center {
        text-align: center;
      }

      .ql-align-right {
        text-align: right;
      }

      .ql-align-left {
        text-align: left;
      }

      .ql-align-justify {
        text-align: justify;
      }

      .ql-indent-1 {
        text-indent: 1em;
      }

      .ql-indent-2 {
        text-indent: 2em;
      }

      .ql-indent-3 {
        text-indent: 3em;
      }

      .ql-indent-4 {
        text-indent: 4em;
      }

      .ql-indent-5 {
        text-indent: 5em;
      }
    }

  }

  .pbd-marketing {
    margin: 0px 24px;
    border-radius: 8px;
    background: #161616;
    padding: 12px 16px;
    width: calc(100% - 48px);

    .MuiIconButton-label {
      color: #A2AAB1;
    }

    .MuiFormHelperText-root {
      color: #A2AAB1;
      text-align: left;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 150%;
      margin-left: 31px;
      margin-bottom: 8px;
    }

    .MuiIconButton-root {
      padding: 0 9px;
    }

    .Mui-checked .MuiIconButton-label {
      color: #00AE26;
    }

    .notifications {
      .MuiFormControlLabel-label {
        color: #47B96A;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        text-align: left;
      }
    }

    .field.more {
      color: #FFF;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.12px;
      text-decoration-line: underline;
      text-align: left;
      cursor: pointer;
      margin-left: 30px;
      margin-bottom: 8px;
    }

    .more-fields {
      margin-left: 30px;

      .MuiFormControlLabel-label {
        text-align: left;
        color: #A2AAB1;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%;
      }
    }
  }
}