$reward-primary-color: #9b36aa;
$reward-primary-color-light: #ff00ff;
$loader-width: 19%;
$reward-text-color: #7f7f80;
$reward-link-color: #017048;
$green: #017048;

.input-group {
  input {
    &:focus {
      & ~ {
        .reward-coins {
          &:not(.input-active) {
            -webkit-animation-name: rewardIcon;
            animation-name: rewardIcon;
            -webkit-animation-duration: .4s;
            animation-duration: .4s;
            -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
            .reward {
              //opacity: 1 !important;
            }
          }
        }
      }

    }
  }
}
.reward-coins {
  position: absolute;
  right: -30px;
  top: 0px;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  .reward {
    width: 19px;
    height: 25px;
    padding: 2px 1px 2px 1px;
    border: 1px solid $reward-primary-color;
    border-style: dashed;
    border-radius: 4px;
    .icon {
      width: 15px;
      position: relative;
      .IconSVG {

        .fillMe {
          max-width: calc(100% - 0.25rem);
          max-height: calc(100% - 0.25rem);
          fill: $reward-primary-color;
        }
      }
    }
  }
}

@keyframes rocketPulseHole {
  0% {
    -webkit-transform: scale3d(1,1,1);
    transform: scale3d(1,1,1);
    border: none;
  }

  50% {
    -webkit-transform: scale3d(3,3,3);
    transform: scale3d(3,3,3);
  }

  70% {
    -webkit-transform: scale3d(3,3,3);
    transform: scale3d(3,3,3);
  }
  80% {
    opacity: 1;

  }
  99% {
    -webkit-transform: scale3d(1.8,1.8,1.8);
    transform: scale3d(1.8,1.8,1.8);
  }
  100% {
    border: none;
    opacity: 0;
    -webkit-transform: scale3d(1.8,1.8,1.8);
    transform: scale3d(1.8,1.8,1.8);
  }
}

@keyframes rewardIcon {
  0% {
    -webkit-transform: rotate(0deg) scale3d(1,1,1);
    -moz-transform: rotate(0deg) scale3d(1,1,1);
    -ms-transform: rotate(0deg) scale3d(1,1,1);
    -o-transform: rotate(0deg) scale3d(1,1,1);
    transform: rotate(0deg) scale3d(1,1,1);

  }
  40% {
      -webkit-transform: rotate(-20deg) scale3d(1.4,1.4,1.4);
      -moz-transform: rotate(-20deg) scale3d(1.4,1.4,1.4);
      -ms-transform: rotate(-20deg) scale3d(1.4,1.4,1.4);
      -o-transform: rotate(-20deg) scale3d(1.4,1.4,1.4);
      transform: rotate(-20deg) scale3d(1.4,1.4,1.4);
  }
  50% {
    -webkit-transform:  scale3d(1.5,1.5,1.5);
    -moz-transform:scale3d(1.5,1.5,1.5);
    -ms-transform:scale3d(1.5,1.5,1.5);
    -o-transform:scale3d(1.5,1.5,1.5);
    transform:scale3d(1.5,1.5,1.5);
  }
  85% {
    -webkit-transform: rotate(5deg);
    -moz-transform: rotate(5deg);
    -ms-transform: rotate(5deg);
    -o-transform: rotate(5deg);
    transform: rotate(5deg);
  }
  93% {
    -webkit-transform: rotate(-2deg);
    -moz-transform: rotate(-2deg);
    -ms-transform: rotate(-2deg);
    -o-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  99% {
    -webkit-transform: rotate(0deg) scale3d(1,1,1);
    -moz-transform: rotate(0deg) scale3d(1,1,1);
    -ms-transform: rotate(0deg) scale3d(1,1,1);
    -o-transform: rotate(0deg) scale3d(1,1,1);
    transform: rotate(0deg) scale3d(1,1,1);
  }
  100% {

  }
}

.rocketPulseHole {
  -webkit-animation-name: rocketPulseHole;
  animation-name: rocketPulseHole;
}

.mover {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

#reward {
  position: absolute;
  top: 0;
  width: 25rem;
  left: 100rem;
  z-index: 3;
  #reward-box {
    width: 20rem;
    height: 19rem;
    margin: 0 auto;
    margin-bottom: 1rem;
    position: relative;
    img {
      max-width: 100%;
    }
    #rewardboxinfo {
      position: absolute;
      bottom: 3rem;
      left: 1rem;
    }
  }
  #reward-target {
    height: 2.35rem;
    width: 1.8rem;
    margin-left: 1.6rem;
    float: left;
    z-index: 200;
    position: relative;
    &.active {
      -webkit-animation-name: targetReward;
      animation-name: targetReward;
      -webkit-animation-duration: 1.5s;
      animation-duration: 1.5s;
      -webkit-animation-delay: 2s;
      animation-delay: 2s;
      -webkit-animation-fill-mode: both;
      animation-fill-mode: both;
    }
    .icon {
      path,
      circle,
      polygon {
        fill: $reward-primary-color;
      }
    }
  }
  #reward-value {
    font-weight: bold;
    font-size: 2rem;
    line-height: 1rem;
    color: $reward-primary-color;
    .big  {
      font-size: 3.875rem;
      position: relative;
    }
  }
  #reward-progress {
    width: 100%;
    height: 0.45rem;
    background-color: $reward-primary-color;
    margin-top: 0.5rem;
    position: relative;
    overflow: hidden;

    .loader {
      width: 20%;
      height: 0.45rem;
      background-color: $reward-primary-color-light;
      position: absolute;
      top: 0;
      left: calc(-20% - 100px);
      -webkit-box-shadow:-24px -8px 33px 23px $reward-primary-color-light;
      -moz-box-shadow: -24px -8px 33px 23px $reward-primary-color-light;
      box-shadow: -24px -8px 33px 23px $reward-primary-color-light;

      &.active {
        -webkit-animation-name: loadingReward;
        animation-name: loadingReward;
        -webkit-animation-duration: 1.5s;
        animation-duration: 1.5s;
        -webkit-animation-delay: 1.5s;
        animation-delay: 1.5s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
      }
    }
  }
  #reward-text {
    color: $reward-text-color;
    font-weight: 500;

    .buttons { // TODO: 388 x 134 si aliniere
      margin-left: -12rem;
      margin-right: -12rem;
      .col-xs-6 {
        width: 50%;
        float: left;
        padding: 1rem;
        p {
          font-size: 0.875rem;
          font-weight: 600;
        }
      }

      .btn {
        background-color: $green;
        width: 100%;
        border: 2px solid $green;
        &.big {
          padding: 1rem;

          -webkit-transition: all 0.2s ease-in;
          -moz-transition: all 0.2s ease-in;
          -ms-transition: all 0.2s ease-in;
          -o-transition: all 0.2s ease-in;
          transition: all 0.2s ease-in;
          &:hover {
            box-shadow: 3px 3px 5px 1px rgba(0,0,0,0.20);
            -webkit-transform: scale3d(1.05,1.05,1.05);
            -moz-transform: scale3d(1.05,1.05,1.05);
            -ms-transform: scale3d(1.05,1.05,1.05);
            -o-transform: scale3d(1.05,1.05,1.05);
            transform: scale3d(1.05,1.05,1.05);
          }
          div {
            &:nth-child(1) {
              font-size: 1.5rem;
              font-weight: 600;
            }
            &:nth-child(2) {
              font-size: 1rem;
              font-weight: 600;
            }
            color: #ffffff;
          }
          &.outer {
            background-color: white;

            div {
              color: $green;
            }
          }
        }
      }
    }

    .title {
      text-transform: uppercase;
      color: $reward-primary-color;
      font-weight: 400;
      line-height: 1.5rem;
      padding-bottom: 2.5rem;
      font-size: 0.875rem;
      font-style: normal;
    }
    p {
      font-size: 1.125rem;
      font-style: italic;
      text-align: left;
      padding: 0.9rem;
      font-weight: 400;
    }
    a {
      color: $reward-link-color;
      &.terms-cond {
        &:hover{
          cursor: pointer;
          text-decoration: underline;
        }
        font-size: 0.8rem;
        font-weight: 600;
      }
      &:hover {
        color: darken($reward-link-color, 10%);
      }
    }
  }
}

.no-pointer-events {
  pointer-events: none;
}

@keyframes loadingReward {
  1% {
    width: $loader-width;
    left: -$loader-width;
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    left: 100%;
    width: $loader-width;
    opacity: 0;
  }
}
@keyframes targetReward {
  0% {
    -webkit-transform: scale3d(1,1,1);
       -moz-transform: scale3d(1,1,1);
        -ms-transform: scale3d(1,1,1);
         -o-transform: scale3d(1,1,1);
            transform: scale3d(1,1,1);
  }
  40% {
    -webkit-transform: scale3d(1.5,1.5,1.5);
       -moz-transform: scale3d(1.5,1.5,1.5);
        -ms-transform: scale3d(1.5,1.5,1.5);
         -o-transform: scale3d(1.5,1.5,1.5);
            transform: scale3d(1.5,1.5,1.5);
  }
  85% {
    -webkit-transform: scale3d(0.8,0.8,0.8);
       -moz-transform: scale3d(0.8,0.8,0.8);
        -ms-transform: scale3d(0.8,0.8,0.8);
         -o-transform: scale3d(0.8,0.8,0.8);
            transform: scale3d(0.8,0.8,0.8);
  }
  93% {
    -webkit-transform: scale3d(1.1,1.1,1.1);
       -moz-transform: scale3d(1.1,1.1,1.1);
        -ms-transform: scale3d(1.1,1.1,1.1);
         -o-transform: scale3d(1.1,1.1,1.1);
            transform: scale3d(1.1,1.1,1.1);
  }
  100% {
    -webkit-transform: scale3d(1,1,1);
       -moz-transform: scale3d(1,1,1);
        -ms-transform: scale3d(1,1,1);
         -o-transform: scale3d(1,1,1);
            transform: scale3d(1,1,1);
  }
}

.congrats {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  &.password {
    position: relative;
    width: 5rem;
    height: 4.0616rem;
    margin: 0 auto;
    -webkit-transform: translateY(-.8rem);
    -moz-transform: translateY(-.8rem);
    -ms-transform: translateY(-.8rem);
    -o-transform: translateY(-.8rem);
    transform: translateY(-.8rem);
    .circles {
      width: 5rem;
      position: relative;
      height: 5rem;
      .circle {
        position: absolute;
        width: 5rem;
        height: 5rem;
        top: auto;
        left: auto;
        margin-top: -1rem;
        -webkit-animation: waterPulsSmall 4s ;
        -o-animation: waterPulsSmall 4s ;
        animation: waterPulsSmall 4s ;
      }
      .IconSVG {
        position: relative;
        margin: 0 auto;
        left: 25%;
        top: 10%;
        &.active {
          path {
            &.fillMe {
              -webkit-animation: fillSVG 3s ease-in;
              -o-animation: fillSVG 3s ease-in;
              animation: fillSVG 3s ease-in;
              -webkit-animation-fill-mode: forwards;
              -moz-animation-fill-mode: forwards;
              -o-animation-fill-mode: forwards;
              animation-fill-mode: forwards;
            }
          }
        }
      }
    }
  }
  .circles {
    max-width: 100%;
    width: 15rem;
    height: 15rem;
    margin: 0 auto;
    margin-top: 3rem;
    .IconSVG {
      width: 50%;
      margin: 0 auto;
      margin-top: calc(100%  - 10rem);
      z-index: 1;
      position: relative;
      path {
        &.fillMe {
          fill: none;
          stroke: #017048;
          stroke-miterlimit: 10;
          stroke-width: 2px;
          stroke-dasharray: 800;
          stroke-dashoffset: 800;
          -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
        }
      }
      &.active {
        path {
          &.fillMe {
            -webkit-animation: fillSVG 3s ease-in-out 1s;
            -o-animation: fillSVG 3s ease-in-out 1s;
            animation: fillSVG 3s ease-in-out 1s;
          }
        }
      }
    }
    .circle {
      border-radius: 100%;
      -webkit-animation: waterPuls 6s infinite;
      -o-animation: waterPuls 6s infinite;
      animation: waterPuls 6s infinite;
      border: 1px solid #e7e7e7;
      width: 15rem;
      height: 15rem;
      position: absolute;
      &:nth-child(1) {
        -webkit-animation-delay: 4s;
        -moz-animation-delay: 4s;
        -o-animation-delay: 4s;
        animation-delay: 4s;
      }
      &:nth-child(2) {
        -webkit-animation-delay: 2s;
        -moz-animation-delay: 2s;
        -o-animation-delay: 2s;
        animation-delay: 2s;
      }
    }

  }
}
@keyframes waterPulsSmall {
  0% {
    opacity: 0;
    -webkit-transform: scale(0,0);
    -moz-transform:  scale(0,0);
    -ms-transform:  scale(0,0);
    -o-transform:  scale(0,0);
    transform:  scale(0,0);
    border-width: 3rem;
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(1,1);
    -moz-transform:  scale(1,1);
    -ms-transform:  scale(1,1);
    -o-transform:  scale(1,1);
    transform:  scale(1,1);
    border-width: 1rem;
  }
  100% {
    opacity: 0;
    border-width: 0;
    -webkit-transform: scale(1.5,1.5);
    -moz-transform: scale(1.5,1.5);
    -ms-transform: scale(1.5,1.5);
    -o-transform: scale(1.5,1.5);
    transform: scale(1.5,1.5);
  }
}
@keyframes waterPuls {
  0% {
    opacity: 0;
    -webkit-transform: scale(0,0);
    -moz-transform:  scale(0,0);
    -ms-transform:  scale(0,0);
    -o-transform:  scale(0,0);
    transform:  scale(0,0);
    border-width: 10rem;
  }
  30% {
    opacity: 1;
    -webkit-transform: scale(1,1);
    -moz-transform:  scale(1,1);
    -ms-transform:  scale(1,1);
    -o-transform:  scale(1,1);
    transform:  scale(1,1);
    border-width: 1rem;
  }
  100% {
    opacity: 0;
    border-width: 0;
    -webkit-transform: scale(1.5,1.5);
    -moz-transform: scale(1.5,1.5);
    -ms-transform: scale(1.5,1.5);
    -o-transform: scale(1.5,1.5);
    transform: scale(1.5,1.5);
  }
}

.btn-animation {
  -webkit-animation: btnPulse 2s;
  -o-animation: btnPulse 2s;
  animation: btnPulse 2s;
  -webkit-animation-delay: 6s;
  -moz-animation-delay: 6s;
  -o-animation-delay: 6s;
  animation-delay: 6s;
}

@keyframes btnPulse {
  0% {
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -o-transform: scale(1,1);
    transform: scale(1,1);
  }
  8% {
    -webkit-transform: scale(0.98,0.98);
    -moz-transform: scale(0.98,0.98);
    -ms-transform: scale(0.98,0.98);
    -o-transform: scale(0.98,0.98);
    transform: scale(0.98,0.98);
  }
  16% {
    -webkit-transform: scale(1.1,1.1);
    -moz-transform: scale(1.1,1.1);
    -ms-transform: scale(1.1,1.1);
    -o-transform: scale(1.1,1.1);
    transform: scale(1.1,1.1);
  }
  24% {
    -webkit-transform: scale(0.99,0.99);
    -moz-transform: scale(0.99,0.99);
    -ms-transform: scale(0.99,0.99);
    -o-transform: scale(0.99,0.99);
    transform: scale(0.99,0.99);
  }
  32% {
    -webkit-transform: scale(1.02,1.02);
    -moz-transform: scale(1.02,1.02);
    -ms-transform: scale(1.02,1.02);
    -o-transform: scale(1.02,1.02);
    transform: scale(1.02,1.02);
  }
  40% {
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -o-transform: scale(1,1);
    transform: scale(1,1);
  }
  60% {
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -o-transform: scale(1,1);
    transform: scale(1,1);
  }
  68% {
    -webkit-transform: scale(0.98,0.98);
    -moz-transform: scale(0.98,0.98);
    -ms-transform: scale(0.98,0.98);
    -o-transform: scale(0.98,0.98);
    transform: scale(0.98,0.98);
  }
  76% {
    -webkit-transform: scale(1.1,1.1);
    -moz-transform: scale(1.1,1.1);
    -ms-transform: scale(1.1,1.1);
    -o-transform: scale(1.1,1.1);
    transform: scale(1.1,1.1);
  }
  84% {
    -webkit-transform: scale(0.99,0.99);
    -moz-transform: scale(0.99,0.99);
    -ms-transform: scale(0.99,0.99);
    -o-transform: scale(0.99,0.99);
    transform: scale(0.99,0.99);
  }
  92% {
    -webkit-transform: scale(1.02,1.02);
    -moz-transform: scale(1.02,1.02);
    -ms-transform: scale(1.02,1.02);
    -o-transform: scale(1.02,1.02);
    transform: scale(1.02,1.02);
  }
  100% {
    -webkit-transform: scale(1,1);
    -moz-transform: scale(1,1);
    -ms-transform: scale(1,1);
    -o-transform: scale(1,1);
    transform: scale(1,1);
  }
}

@keyframes fillSVG {
  from {
    stroke-dashoffset: 800;
  }

  to {
    stroke-dashoffset: 0;
  }
}
