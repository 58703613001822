.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  height: auto;
  padding: 0px;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.modal-content {
  max-height: 100vh;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

/*.eldorado-dark-theme .App-main*/
/*{*/
/*  color: white;*/
/*}*/

.dontShow {
  display: none;
}

.topMargin {
  margin-top: 30px !important;
}