.wf-right-to-left {
  opacity: 0;
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-name: wf-fade-in-to-left;
}

.wf-left-to-right {
  opacity: 0;
  animation-duration: 200ms;
  animation-fill-mode: both;
  animation-name: wf-fade-in-to-right;
}

@keyframes wf-fade-in-to-right {
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes wf-fade-in-to-left {
  0% {
    opacity: 0;
    transform: translateX(20px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}