@import "./../../../variables";

.GeoCard {
    background: transparent;
    padding-bottom: 20px;

    .cardBody {
        background-color: #ffffff;
        color: $firstColor;
        border-top: 1px solid #e5e5e5;
        border-bottom: 2px solid #dfdfdf;
        padding-top: 20px;
        padding-bottom: 14px;
        text-align: center;

        .cardMessage {
            padding-top: 16px;
            font-size: 13px;
            padding-left: 40px;
            padding-right: 40px;
            font-weight: 600;
        }

        .cardButton {
            padding-top: 30px;
            padding-right: 40px;
            padding-left: 40px;

            button {
                width: 100%;
                border-color: $thirdColor;
                color: $thirdColor;
                position: relative;
                top: 0px;
                font-weight: 600;
                font-size: 18px;
                display: flex;
                padding-top: 10px;
                padding-bottom: 10px;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: $thirdColor;
                    color: #ffffff;
                }

                &:active {
                    top: 2px;
                    background-color: $thirdColor;
                    color: #ffffff;
                }

                span {
                    height: 46px;
                    justify-content: center;

                    &:first-child {
                        line-height: 46px;
                        text-align: right;
                        width: 70%;
                        padding-right: 5%;
                    }

                    &:last-child {
                        width: 30%;
                        text-align: right;
                    }
                }
            }
        }
    }

    .cardHead {
        border-bottom: 1px solid #dfdfdf;

        .header {
            margin: 0 8%;
            background-color: #e6e6e6;
            display: flex;
            text-align: left;
            padding-left: 10px;
            padding-bottom: 2px;
            padding-top: 2px;
            height: 28px;
            font-weight: 500;

            span {
                padding: 0 8px 0 0;
                margin: 0;
                height: 25px;
            }

            p {
                padding: 0 0 0 0;
                margin: 0;
                line-height: 25px;
                font-size: 13px;
            }
        }

        padding-top: 15px;
    }

    .exSign {
        height: 64px;

        .cls-1 {
            fill: #ff530d;
        }
        .cls-2{
            fill: $white;
        }
    }

    .locTarget {
        fill: $firstColor;
        height: 21px;
    }

    .selfLocationIcon {
        height: 46px;

        .cls-1{fill:#006837;}
        .cls-2{fill:#fff;}
        .cls-3{fill:#017048;}
        .cls-4{fill:#ed1c24;}
    }
}