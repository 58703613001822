.winter-time-main-view-dialog {
  .MuiPaper-root {
    border-radius: 10px;
    background-color: transparent;
    max-height: calc(100% - 12px);
    margin: 6px;

    .promo-winter-main-view {
      position: relative !important;
      width: 375px !important;
      border-radius: 10px;
      display: block;
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}