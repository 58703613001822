@import "./../../variables";

.DepositPage {
    opacity: 0;
    top: 40px;
    position: relative;

}

.actions-fixed {
    text-align: center;
    button {
        background-color: transparent;
        box-shadow: none;
        width: 100%;
        padding: 1rem ;
        border: none;
        .newTab {
            position: absolute;
            right: 3rem;
            height: 1.5rem;
        }
        &:disabled {
            background: transparent;
            filter: grayscale(1);
        }
    }
    .description {
        font-size: .725rem;
        padding: .5rem 2rem ;
        font-weight: normal;
    }
}