@import "../../../variables";

#cookieInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(1,1,1,.6);
  z-index: 9999999;
  &.hide-cookies {
    pointer-events: none;
  }
  .content {
    background: white;
    color: black;
    width: 100%;
    max-width: 70vw;
    max-height: 70vh;
    padding: 0 2rem 2rem 2rem;
    header {
      background: $thirdColor;
      color: white;
      padding: 1rem;
      margin: 0 -2rem;
      font-size: 1.5rem;
      position: relative;

        .btn-close {
          width: 35px;
          height: 35px;
          padding: 0px;
          margin: 0px;
          text-align: center;
          border: none;
          position: absolute;
          right: 5px;
          background: transparent;
          color: #fff;
      }
    }
    
    div.title {
      margin-top: 2rem;
      font-weight: bold;
      font-size: 1.2rem;
    }
    div,p {
      text-align: left;
    }
    .btn-success {
      background: $thirdColor;
      margin: 1rem;
    }
  }
}

@media screen and (max-height: 850px){
  .CookiesContent {
    overflow-y: scroll;
    max-height: 75%;
  }
}

@media screen and (max-height: 650px){
  .CookiesContent {
    overflow-y: scroll;
    max-height: 65%;
  }
}
