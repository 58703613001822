$btnColor: #ffc107;
$disabledBtnColor: #fde67f;

@import "../../../../../variables";

.self-exclusion {
  min-height: 950px;
  .title {
    font-size: 2.1rem;
    letter-spacing: .08rem;
    color: inherit;
    margin-bottom: 4rem;
  }
  .description {
    font-weight: 500;
    margin-bottom: 3rem;
    font-size: 1.5rem;
    line-height: 1.5rem;
    letter-spacing: -.03rem;
  }
  .subtitle {
    font-weight: 600;
    font-size: 1.5rem;
  }
  .choices {
    margin-bottom: 3rem;
  }
  .title,
  .subtitle,
  .description {
    padding-left: 1.5rem;
  }

  .checkbox-container {
    .input-centered {
      .input-group {
        display: grid;
        grid-template-columns: 4rem 1fr;
        text-align: left;
        .input-label {
          font-size: 1.5rem;
        }
        input{
          margin-left: 1.5rem;
          margin-top: 0.2rem;
          width: 1.6rem;
          margin-right: 0.5rem;
        }
        &:after {
          width: 1.5rem;
          height: 1.5rem;
          left: -1.8rem;
          top: .35rem;
        }
        &.checked {
          &:after {
            width: .8rem;
            height: 1.75rem;
            top: 0rem;
            left: -1.25rem;
          }
        }
      }
    }
  }

  .btn {
    font-weight: 600;
    font-size: 1.125rem;
    padding: 2rem;
    width: calc(100% - 3rem);
    margin: 4rem 1.5rem 0 1.5rem;
    -webkit-transition: ease-in .1s ;
    -moz-transition: ease-in .1s ;
    -ms-transition: ease-in .1s ;
    -o-transition: ease-in .1s ;
    transition: ease-in .1s ;
    &.disabled {
      pointer-events: none;
    }
    &:hover {
      //opacity: 0.8;
    }
    &.btn-danger {
      background-color: $redColor;
      border-color: $redColor;
      margin-bottom: -1rem;
    }
    &.btn-success {
      background-color: $buttonGreen;
      border-color: $buttonGreen;
      &.btn-outer {
        background-color: white;
        border-width: .2rem;
        color: $buttonGreen;
        font-weight: bold;
      }
    }
  }

  .TextContent{
    margin-top: 20px;
    p{
      margin: 10px 0px;
      font-size: 14px;
      font-weight: 500;
      text-align: left;

      &.EmailSent{
        span{
          font-weight: 700;
          color: #000;
        }
      }

      span{
        font-weight: 500;
        &.pinkBold{
          font-weight: 700;
          color: #f81057;
        }
      }
    }
  }

  .AlertContent{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 15px 0px;

    p{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin: 10px 0px;
      text-align: left;

      .closeIcon {
        background: #f81057;
        padding: 5px;
        border-radius: 100%;
        fill: #fff;
        width: 20px;
        height: 20px;
      }

      span{
        width: calc(100% - 40px);
        font-weight: 700;
        font-size: 20px;
        color: #f81057;
      }
    }
  }
  .nextButton {
    color: $buttonGreen;
    font-weight: bold;
    background-color: $buttonYellow;

    .fillMe{
        fill: $buttonGreen !important;
    }

    &:hover {
        background-color: lighten($buttonYellow, 5%);
    }

    .text {
        padding-left: 5px;
    }
    .animate {
        display: inline-block;
      animation: rotate-center 1s linear infinite both;
    }
  }

  .backButton{
    margin-top: 2rem;
    cursor: pointer;
  }
}
.self-exclusion-confirmation {
  position: relative;
  pointer-events: none;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;

  &.need-confirmation {
    pointer-events: all;
    p {
      font-size: 1.5rem;
      font-weight: bold;
      &:last-child {
        padding-bottom: 6rem;
      }
    }
  }
  .exWarning {
    max-width: 7rem;
    margin-top: 1.25rem;
    float: right;
    margin-right: 1rem;
    .IconSVG {
      margin: 0 auto;
      display: block;
    }
    .cls-1 {
      fill: $dangerColor;
    }
    .cls-2 {
      fill: white;
    }
  }
}

.self-exclusion-button-support {
  padding-top: 120px;
  position: relative;
  top: 50px;

  a {
    color: $thirdColor;
    font-weight: 500;
  }
}

.take-a-break-button-support{
  width: calc(100% - 3rem);
  margin: 2rem 1.5rem;
  margin-bottom: 4rem;
  a{
    padding: 2rem;
    width: 100%;
    display: block;
    box-sizing: border-box;
    -webkit-transition: ease-in .1s;
    -o-transition: ease-in .1s;
    transition: ease-in .1s;
    color: #212529;
    background-color: #ffc107;
    border-color: #ffc107;
    line-height: 1.5;
    border-radius: .25rem;
    font-weight: 600;
    font-size: 1.125rem;
    text-decoration: none;
    &:hover{
      color: #212529;
      background-color: #e0a800;
      border-color: #d39e00;
    }
  }
}
