.maintenance-page {
  min-height: 100vh;
  width: 100vw;
  background: #E6EEF1;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;

  * {
    box-sizing: border-box;
    text-align: left;
  }

  .mp-header {
    width: 100vw;
    height: 80px;
    background: #001D8A;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 13px;

    &>div {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #fff;
    }
  }

  .mp-body {
    margin: 10px 14px;
    background: #fff;
    border-radius: 18px;
    padding: 10px 0 30px;
    box-shadow: 0 3px 6px 0px rgba(0, 0, 0, .16);
    max-width: 444px;

    .mp-image {
      margin: 30px 0 10px;
      text-align: center;

      img {
        width: 100%;
        max-width: 190px;
        height: auto;
      }
    }

    .mp-title {
      color: #000000;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 0 24px 30px;
    }

    .mp-content {
      color: #151D26;
      font-weight: 600;
      font-size: 14px;
      margin: 0 24px 30px;

    }

    .mp-button {
      background: linear-gradient(to right, #295A91, #2174E0);
      color: #fff;
      padding: 20px 0;
      font-size: 15px;
      font-weight: bold;
      border-radius: 3px;
      text-align: center;
      margin: 0 24px 26px;
      cursor: pointer;
    }

    .mp-link {
      color: #737B83;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}