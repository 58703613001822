.component-games-collection {
  margin: 10px auto;
  max-width: 1528px;

  .before {
    display: none;
    z-index: 1;
  }

  .after {
    display: none;
    z-index: 1;
  }

  .cgl-super-header {
    margin: 0 10px;
    z-index: 2;
    position: relative;

    .category {
      color: #353540;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;
    padding-bottom: 10px;

    .cgl-grid {
      width: 100%;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 15px;

      padding-top: 40px;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 142px;
        width: 100%;
        margin-right: 12px;
        padding: 2px;
        border-radius: 10px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .2);
        overflow: visible;
        background-color: #fff;
        cursor: pointer;
        transition: transform .25s ease;

        &.empty {
          max-width: 142px;
        }

        .outer {
          width: 100%;
          padding-top: 120%;
          position: relative;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }
        }

        .cgl-wrapper {
          width: 100%;
          height: 100%;
          border-radius: 10px;
          background: linear-gradient(135deg, rgba(0, 0, 0, .1), rgba(255, 255, 255, 1) 70%);

          .cgl-name {
            color: #353540;
            font-size: clamp(11px, 1.1vw, 15px);
            font-weight: 600;
            width: 100%;
            white-space: normal;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 20%;
            padding: 0 5px;
            text-align: center;
          }

          .cgl-icon {
            display: flex;
            align-items: flex-end;
            height: 80%;

            img {
              width: 95%;
              height: auto;
              object-fit: contain;
              display: block;
              margin: 0 auto;
            }
          }
        }

        &:last-child {
          margin-right: 0px;
        }

        &:hover {
          position: relative;
          transform-origin: center;
          transform: translateZ(0) scale(1.05);
        }
      }
    }

    @media screen and (min-width: 1528px) {
      .cgl-grid {
        overflow: hidden;

        &::before,
        &::after {
          content: '\00a0';
          min-width: 25px;
          max-width: 25px;
        }

        .cgl-item {
          margin-right: 40px;

        }

        .cgl-item:last-child {
          margin-right: 0px;
        }
      }
    }
  }
}