.coinsStack  {
  .a,.ad,.am,.b,.bm,.bz,.dk,.dl,.ew,.gh,.hs,.jd,.ko,.lv,.nc,.oj,.pq,.qz,.ra,.sh,.tj {
    mix-blend-mode:multiply;
  }
  .a,.ab,.ad,.af,.ag,.ah,.ai,.aj,.ak,.am,.b,.bm,.bp,.bq,.br,.bs,.bt,.bu,.bv,.bw,.bx,.bz,.da,.db,.dc,.dd,.de,.df,.dg,.dh,.di,.dk,.dl,.em,.en,.eo,.ep,.eq,.er,.es,.et,.eu,.ew,.fx,.fy,.fz,.ga,.gb,.gc,.gd,.ge,.gf,.gh,.hi,.hj,.hk,.hl,.hm,.hn,.ho,.hp,.hq,.hs,.it,.iu,.iv,.iw,.ix,.iy,.iz,.ja,.jb,.jd,.ke,.kf,.kg,.kh,.ki,.kj,.kk,.kl,.km,.ko,.lo,.lp,.lq,.lr,.ls,.lt,.lv,.mv,.mw,.mx,.my,.mz,.na,.nc,.oc,.od,.oe,.of,.og,.oh,.oj,.pj,.pk,.pl,.pm,.pn,.po,.pq,.qq,.qr,.qs,.qt,.qu,.qv,.qz,.ra,.sa,.sb,.sc,.sd,.se,.sf,.sh,.th,.tj,.tm,.tn,.to {
    isolation:isolate;
  }
  .a {
    fill:url(#a);
  }
  .b {
    fill:url(#b);
  }
  .c {
    fill:#883000;
  }
  .d {
    fill:url(#c);
  }
  .e {
    fill:url(#d);
  }
  .f {
    fill:url(#e);
  }
  .g {
    fill:url(#f);
  }
  .h {
    fill:url(#g);
  }
  .i {
    fill:url(#h);
  }
  .j {
    fill:url(#i);
  }
  .k {
    fill:url(#j);
  }
  .l {
    fill:url(#k);
  }
  .m {
    fill:url(#l);
  }
  .n {
    fill:url(#m);
  }
  .o {
    fill:url(#n);
  }
  .p {
    fill:url(#o);
  }
  .q {
    fill:url(#p);
  }
  .r {
    fill:url(#q);
  }
  .s {
    fill:url(#r);
  }
  .t {
    fill:url(#s);
  }
  .u {
    fill:url(#t);
  }
  .v {
    fill:url(#u);
  }
  .w {
    fill:url(#v);
  }
  .x {
    fill:url(#w);
  }
  .y {
    fill:url(#x);
  }
  .z {
    fill:url(#y);
  }
  .aa {
    fill:url(#aa);
  }
  .ab {
    fill:#fce61b;
  }
  .ab,.af,.ag,.ah,.ai,.aj,.ak,.bp,.bq,.br,.bs,.bt,.bu,.bv,.bw,.bx,.da,.db,.dc,.dd,.de,.df,.dg,.dh,.di,.em,.en,.eo,.ep,.eq,.er,.es,.et,.eu,.fx,.fy,.fz,.ga,.gb,.gc,.gd,.ge,.gf,.hi,.hj,.hk,.hl,.hm,.hn,.ho,.hp,.hq,.it,.iu,.iv,.iw,.ix,.iy,.iz,.ja,.jb,.ke,.kf,.kg,.kh,.ki,.kj,.kk,.kl,.km,.lo,.lp,.lq,.lr,.ls,.lt,.mv,.mw,.mx,.my,.mz,.na,.oc,.od,.oe,.of,.og,.oh,.pj,.pk,.pl,.pm,.pn,.po,.qq,.qr,.qs,.qt,.qu,.qv,.sa,.sb,.sc,.sd,.se,.sf,.th,.tm,.tn,.to {
    mix-blend-mode:screen;
  }
  .ac {
    fill:url(#ab);
  }
  .ad,.bn {
    fill:#840;
  }
  .ae {
    fill:#fcec66;
  }
  .af {
    fill:url(#ac);
  }
  .ag {
    fill:url(#ad);
  }
  .ah {
    fill:url(#ae);
  }
  .ai {
    fill:url(#af);
  }
  .aj {
    fill:url(#ag);
  }
  .ak {
    fill:url(#ah);
  }
  .al,.by,.dj,.ev,.gg,.hr,.jc,.kn,.lu,.nb,.oi,.pp,.qw,.sg {
    opacity:0.5;
  }
  .al {
    fill:url(#ai);
  }
  .am {
    fill:url(#aj);
  }
  .an {
    fill:url(#ak);
  }
  .ao {
    fill:url(#al);
  }
  .ap {
    fill:url(#am);
  }
  .aq {
    fill:url(#an);
  }
  .ar {
    fill:url(#ao);
  }
  .as {
    fill:url(#ap);
  }
  .at {
    fill:url(#aq);
  }
  .au {
    fill:url(#ar);
  }
  .av {
    fill:url(#as);
  }
  .aw {
    fill:url(#at);
  }
  .ax {
    fill:url(#au);
  }
  .ay {
    fill:url(#av);
  }
  .az {
    fill:url(#aw);
  }
  .ba {
    fill:url(#ax);
  }
  .bb {
    fill:url(#ay);
  }
  .bc {
    fill:url(#az);
  }
  .bd {
    fill:url(#ba);
  }
  .be {
    fill:url(#bb);
  }
  .bf {
    fill:url(#bc);
  }
  .bg {
    fill:url(#bd);
  }
  .bh {
    fill:url(#be);
  }
  .bi {
    fill:url(#bf);
  }
  .bj {
    fill:url(#bg);
  }
  .bk {
    fill:url(#bi);
  }
  .bl {
    fill:url(#bj);
  }
  .bo {
    fill:url(#bk);
  }
  .bp {
    fill:url(#bl);
  }
  .bq {
    fill:url(#bm);
  }
  .br {
    fill:url(#bn);
  }
  .bs {
    fill:url(#bo);
  }
  .bt {
    fill:url(#bp);
  }
  .bu {
    fill:url(#bq);
  }
  .bv {
    fill:url(#br);
  }
  .bw {
    fill:url(#bs);
  }
  .bx {
    fill:url(#bt);
  }
  .by {
    fill:url(#bu);
  }
  .bz {
    fill:url(#bv);
  }
  .ca {
    fill:url(#bw);
  }
  .cb {
    fill:url(#bx);
  }
  .cc {
    fill:url(#by);
  }
  .cd {
    fill:url(#bz);
  }
  .ce {
    fill:url(#ca);
  }
  .cf {
    fill:url(#cb);
  }
  .cg {
    fill:url(#cc);
  }
  .ch {
    fill:url(#cd);
  }
  .ci {
    fill:url(#ce);
  }
  .cj {
    fill:url(#cf);
  }
  .ck {
    fill:url(#cg);
  }
  .cl {
    fill:url(#ch);
  }
  .cm {
    fill:url(#ci);
  }
  .cn {
    fill:url(#cj);
  }
  .co {
    fill:url(#ck);
  }
  .cp {
    fill:url(#cl);
  }
  .cq {
    fill:url(#cm);
  }
  .cr {
    fill:url(#cn);
  }
  .cs {
    fill:url(#co);
  }
  .ct {
    fill:url(#cp);
  }
  .cu {
    fill:url(#cq);
  }
  .cv {
    fill:url(#cr);
  }
  .cw {
    fill:url(#cs);
  }
  .cx {
    fill:url(#cu);
  }
  .cy {
    fill:url(#cv);
  }
  .cz {
    fill:url(#cw);
  }
  .da {
    fill:url(#cx);
  }
  .db {
    fill:url(#cy);
  }
  .dc {
    fill:url(#cz);
  }
  .dd {
    fill:url(#da);
  }
  .de {
    fill:url(#db);
  }
  .df {
    fill:url(#dc);
  }
  .dg {
    fill:url(#dd);
  }
  .dh {
    fill:url(#de);
  }
  .di {
    fill:url(#df);
  }
  .dj {
    fill:url(#dg);
  }
  .dk {
    fill:url(#dh);
  }
  .dl {
    fill:url(#di);
  }
  .dm {
    fill:url(#dj);
  }
  .dn {
    fill:url(#dk);
  }
  .do {
    fill:url(#dl);
  }
  .dp {
    fill:url(#dm);
  }
  .dq {
    fill:url(#dn);
  }
  .dr {
    fill:url(#do);
  }
  .ds {
    fill:url(#dp);
  }
  .dt {
    fill:url(#dq);
  }
  .du {
    fill:url(#dr);
  }
  .dv {
    fill:url(#ds);
  }
  .dw {
    fill:url(#dt);
  }
  .dx {
    fill:url(#du);
  }
  .dy {
    fill:url(#dv);
  }
  .dz {
    fill:url(#dw);
  }
  .ea {
    fill:url(#dx);
  }
  .eb {
    fill:url(#dy);
  }
  .ec {
    fill:url(#dz);
  }
  .ed {
    fill:url(#ea);
  }
  .ee {
    fill:url(#eb);
  }
  .ef {
    fill:url(#ec);
  }
  .eg {
    fill:url(#ed);
  }
  .eh {
    fill:url(#ee);
  }
  .ei {
    fill:url(#ef);
  }
  .ej {
    fill:url(#eh);
  }
  .ek {
    fill:url(#ei);
  }
  .el {
    fill:url(#ej);
  }
  .em {
    fill:url(#ek);
  }
  .en {
    fill:url(#el);
  }
  .eo {
    fill:url(#em);
  }
  .ep {
    fill:url(#en);
  }
  .eq {
    fill:url(#eo);
  }
  .er {
    fill:url(#ep);
  }
  .es {
    fill:url(#eq);
  }
  .et {
    fill:url(#er);
  }
  .eu {
    fill:url(#es);
  }
  .ev {
    fill:url(#et);
  }
  .ew {
    fill:url(#eu);
  }
  .ex {
    fill:url(#ev);
  }
  .ey {
    fill:url(#ew);
  }
  .ez {
    fill:url(#ex);
  }
  .fa {
    fill:url(#ey);
  }
  .fb {
    fill:url(#ez);
  }
  .fc {
    fill:url(#fa);
  }
  .fd {
    fill:url(#fb);
  }
  .fe {
    fill:url(#fc);
  }
  .ff {
    fill:url(#fd);
  }
  .fg {
    fill:url(#fe);
  }
  .fh {
    fill:url(#ff);
  }
  .fi {
    fill:url(#fg);
  }
  .fj {
    fill:url(#fh);
  }
  .fk {
    fill:url(#fi);
  }
  .fl {
    fill:url(#fj);
  }
  .fm {
    fill:url(#fk);
  }
  .fn {
    fill:url(#fl);
  }
  .fo {
    fill:url(#fm);
  }
  .fp {
    fill:url(#fn);
  }
  .fq {
    fill:url(#fo);
  }
  .fr {
    fill:url(#fp);
  }
  .fs {
    fill:url(#fq);
  }
  .ft {
    fill:url(#fr);
  }
  .fu {
    fill:url(#ft);
  }
  .fv {
    fill:url(#fu);
  }
  .fw {
    fill:url(#fv);
  }
  .fx {
    fill:url(#fw);
  }
  .fy {
    fill:url(#fx);
  }
  .fz {
    fill:url(#fy);
  }
  .ga {
    fill:url(#fz);
  }
  .gb {
    fill:url(#ga);
  }
  .gc {
    fill:url(#gb);
  }
  .gd {
    fill:url(#gc);
  }
  .ge {
    fill:url(#gd);
  }
  .gf {
    fill:url(#ge);
  }
  .gg {
    fill:url(#gf);
  }
  .gh {
    fill:url(#gg);
  }
  .gi {
    fill:url(#gh);
  }
  .gj {
    fill:url(#gi);
  }
  .gk {
    fill:url(#gj);
  }
  .gl {
    fill:url(#gk);
  }
  .gm {
    fill:url(#gl);
  }
  .gn {
    fill:url(#gm);
  }
  .go {
    fill:url(#gn);
  }
  .gp {
    fill:url(#go);
  }
  .gq {
    fill:url(#gp);
  }
  .gr {
    fill:url(#gq);
  }
  .gs {
    fill:url(#gr);
  }
  .gt {
    fill:url(#gs);
  }
  .gu {
    fill:url(#gt);
  }
  .gv {
    fill:url(#gu);
  }
  .gw {
    fill:url(#gv);
  }
  .gx {
    fill:url(#gw);
  }
  .gy {
    fill:url(#gx);
  }
  .gz {
    fill:url(#gy);
  }
  .ha {
    fill:url(#gz);
  }
  .hb {
    fill:url(#ha);
  }
  .hc {
    fill:url(#hb);
  }
  .hd {
    fill:url(#hc);
  }
  .he {
    fill:url(#hd);
  }
  .hf {
    fill:url(#hf);
  }
  .hg {
    fill:url(#hg);
  }
  .hh {
    fill:url(#hh);
  }
  .hi {
    fill:url(#hi);
  }
  .hj {
    fill:url(#hj);
  }
  .hk {
    fill:url(#hk);
  }
  .hl {
    fill:url(#hl);
  }
  .hm {
    fill:url(#hm);
  }
  .hn {
    fill:url(#hn);
  }
  .ho {
    fill:url(#ho);
  }
  .hp {
    fill:url(#hp);
  }
  .hq {
    fill:url(#hq);
  }
  .hr {
    fill:url(#hr);
  }
  .hs {
    fill:url(#hs);
  }
  .ht {
    fill:url(#ht);
  }
  .hu {
    fill:url(#hu);
  }
  .hv {
    fill:url(#hv);
  }
  .hw {
    fill:url(#hw);
  }
  .hx {
    fill:url(#hx);
  }
  .hy {
    fill:url(#hy);
  }
  .hz {
    fill:url(#hz);
  }
  .ia {
    fill:url(#ia);
  }
  .ib {
    fill:url(#ib);
  }
  .ic {
    fill:url(#ic);
  }
  .id {
    fill:url(#id);
  }
  .ie {
    fill:url(#ie);
  }
  .if {
    fill:url(#if);
  }
  .ig {
    fill:url(#ig);
  }
  .ih {
    fill:url(#ih);
  }
  .ii {
    fill:url(#ii);
  }
  .ij {
    fill:url(#ij);
  }
  .ik {
    fill:url(#ik);
  }
  .il {
    fill:url(#il);
  }
  .im {
    fill:url(#im);
  }
  .in {
    fill:url(#in);
  }
  .io {
    fill:url(#io);
  }
  .ip {
    fill:url(#ip);
  }
  .iq {
    fill:url(#ir);
  }
  .ir {
    fill:url(#is);
  }
  .is {
    fill:url(#it);
  }
  .it {
    fill:url(#iu);
  }
  .iu {
    fill:url(#iv);
  }
  .iv {
    fill:url(#iw);
  }
  .iw {
    fill:url(#ix);
  }
  .ix {
    fill:url(#iy);
  }
  .iy {
    fill:url(#iz);
  }
  .iz {
    fill:url(#ja);
  }
  .ja {
    fill:url(#jb);
  }
  .jb {
    fill:url(#jc);
  }
  .jc {
    fill:url(#jd);
  }
  .jd {
    fill:url(#je);
  }
  .je {
    fill:url(#jf);
  }
  .jf {
    fill:url(#jg);
  }
  .jg {
    fill:url(#jh);
  }
  .jh {
    fill:url(#ji);
  }
  .ji {
    fill:url(#jj);
  }
  .jj {
    fill:url(#jk);
  }
  .jk {
    fill:url(#jl);
  }
  .jl {
    fill:url(#jm);
  }
  .jm {
    fill:url(#jn);
  }
  .jn {
    fill:url(#jo);
  }
  .jo {
    fill:url(#jp);
  }
  .jp {
    fill:url(#jq);
  }
  .jq {
    fill:url(#jr);
  }
  .jr {
    fill:url(#js);
  }
  .js {
    fill:url(#jt);
  }
  .jt {
    fill:url(#ju);
  }
  .ju {
    fill:url(#jv);
  }
  .jv {
    fill:url(#jw);
  }
  .jw {
    fill:url(#jx);
  }
  .jx {
    fill:url(#jy);
  }
  .jy {
    fill:url(#jz);
  }
  .jz {
    fill:url(#ka);
  }
  .ka {
    fill:url(#kb);
  }
  .kb {
    fill:url(#kd);
  }
  .kc {
    fill:url(#ke);
  }
  .kd {
    fill:url(#kf);
  }
  .ke {
    fill:url(#kg);
  }
  .kf {
    fill:url(#kh);
  }
  .kg {
    fill:url(#ki);
  }
  .kh {
    fill:url(#kj);
  }
  .ki {
    fill:url(#kk);
  }
  .kj {
    fill:url(#kl);
  }
  .kk {
    fill:url(#km);
  }
  .kl {
    fill:url(#kn);
  }
  .km {
    fill:url(#ko);
  }
  .kn {
    fill:url(#kp);
  }
  .ko {
    fill:url(#kq);
  }
  .kp {
    fill:url(#kr);
  }
  .kq {
    fill:url(#ks);
  }
  .kr {
    fill:url(#kt);
  }
  .ks {
    fill:url(#ku);
  }
  .kt {
    fill:url(#kv);
  }
  .ku {
    fill:url(#kw);
  }
  .kv {
    fill:url(#kx);
  }
  .kw {
    fill:url(#ky);
  }
  .kx {
    fill:url(#kz);
  }
  .ky {
    fill:url(#la);
  }
  .kz {
    fill:url(#lb);
  }
  .la {
    fill:url(#lc);
  }
  .lb {
    fill:url(#ld);
  }
  .lc {
    fill:url(#le);
  }
  .ld {
    fill:url(#lf);
  }
  .le {
    fill:url(#lg);
  }
  .lf {
    fill:url(#lh);
  }
  .lg {
    fill:url(#li);
  }
  .lh {
    fill:url(#lj);
  }
  .li {
    fill:url(#lk);
  }
  .lj {
    fill:url(#ll);
  }
  .lk {
    fill:url(#lm);
  }
  .ll {
    fill:url(#ln);
  }
  .lm {
    fill:url(#lp);
  }
  .ln {
    fill:url(#lq);
  }
  .lo {
    fill:url(#lr);
  }
  .lp {
    fill:url(#ls);
  }
  .lq {
    fill:url(#lt);
  }
  .lr {
    fill:url(#lu);
  }
  .ls {
    fill:url(#lv);
  }
  .lt {
    fill:url(#lw);
  }
  .lu {
    fill:url(#lx);
  }
  .lv {
    fill:url(#ly);
  }
  .lw {
    fill:url(#lz);
  }
  .lx {
    fill:url(#ma);
  }
  .ly {
    fill:url(#mb);
  }
  .lz {
    fill:url(#mc);
  }
  .ma {
    fill:url(#md);
  }
  .mb {
    fill:url(#me);
  }
  .mc {
    fill:url(#mf);
  }
  .md {
    fill:url(#mg);
  }
  .me {
    fill:url(#mh);
  }
  .mf {
    fill:url(#mi);
  }
  .mg {
    fill:url(#mj);
  }
  .mh {
    fill:url(#mk);
  }
  .mi {
    fill:url(#ml);
  }
  .mj {
    fill:url(#mm);
  }
  .mk {
    fill:url(#mn);
  }
  .ml {
    fill:url(#mo);
  }
  .mm {
    fill:url(#mp);
  }
  .mn {
    fill:url(#mq);
  }
  .mo {
    fill:url(#mr);
  }
  .mp {
    fill:url(#ms);
  }
  .mq {
    fill:url(#mt);
  }
  .mr {
    fill:url(#mu);
  }
  .ms {
    fill:url(#mv);
  }
  .mt {
    fill:url(#mx);
  }
  .mu {
    fill:url(#my);
  }
  .mv {
    fill:url(#mz);
  }
  .mw {
    fill:url(#na);
  }
  .mx {
    fill:url(#nb);
  }
  .my {
    fill:url(#nc);
  }
  .mz {
    fill:url(#nd);
  }
  .na {
    fill:url(#ne);
  }
  .nb {
    fill:url(#nf);
  }
  .nc {
    fill:url(#ng);
  }
  .nd {
    fill:url(#nh);
  }
  .ne {
    fill:url(#ni);
  }
  .nf {
    fill:url(#nj);
  }
  .ng {
    fill:url(#nk);
  }
  .nh {
    fill:url(#nl);
  }
  .ni {
    fill:url(#nm);
  }
  .nj {
    fill:url(#nn);
  }
  .nk {
    fill:url(#no);
  }
  .nl {
    fill:url(#np);
  }
  .nm {
    fill:url(#nq);
  }
  .nn {
    fill:url(#nr);
  }
  .no {
    fill:url(#ns);
  }
  .np {
    fill:url(#nt);
  }
  .nq {
    fill:url(#nu);
  }
  .nr {
    fill:url(#nv);
  }
  .ns {
    fill:url(#nw);
  }
  .nt {
    fill:url(#nx);
  }
  .nu {
    fill:url(#ny);
  }
  .nv {
    fill:url(#nz);
  }
  .nw {
    fill:url(#oa);
  }
  .nx {
    fill:url(#ob);
  }
  .ny {
    fill:url(#oc);
  }
  .nz {
    fill:url(#od);
  }
  .oa {
    fill:url(#of);
  }
  .ob {
    fill:url(#og);
  }
  .oc {
    fill:url(#oh);
  }
  .od {
    fill:url(#oi);
  }
  .oe {
    fill:url(#oj);
  }
  .of {
    fill:url(#ok);
  }
  .og {
    fill:url(#ol);
  }
  .oh {
    fill:url(#om);
  }
  .oi {
    fill:url(#on);
  }
  .oj {
    fill:url(#oo);
  }
  .ok {
    fill:url(#op);
  }
  .ol {
    fill:url(#oq);
  }
  .om {
    fill:url(#or);
  }
  .on {
    fill:url(#os);
  }
  .oo {
    fill:url(#ot);
  }
  .op {
    fill:url(#ou);
  }
  .oq {
    fill:url(#ov);
  }
  .or {
    fill:url(#ow);
  }
  .os {
    fill:url(#ox);
  }
  .ot {
    fill:url(#oy);
  }
  .ou {
    fill:url(#oz);
  }
  .ov {
    fill:url(#pa);
  }
  .ow {
    fill:url(#pb);
  }
  .ox {
    fill:url(#pc);
  }
  .oy {
    fill:url(#pd);
  }
  .oz {
    fill:url(#pe);
  }
  .pa {
    fill:url(#pf);
  }
  .pb {
    fill:url(#pg);
  }
  .pc {
    fill:url(#ph);
  }
  .pd {
    fill:url(#pi);
  }
  .pe {
    fill:url(#pj);
  }
  .pf {
    fill:url(#pk);
  }
  .pg {
    fill:url(#pl);
  }
  .ph {
    fill:url(#pn);
  }
  .pi {
    fill:url(#po);
  }
  .pj {
    fill:url(#pp);
  }
  .pk {
    fill:url(#pq);
  }
  .pl {
    fill:url(#pr);
  }
  .pm {
    fill:url(#ps);
  }
  .pn {
    fill:url(#pt);
  }
  .po {
    fill:url(#pu);
  }
  .pp {
    fill:url(#pv);
  }
  .pq {
    fill:url(#pw);
  }
  .pr {
    fill:url(#px);
  }
  .ps {
    fill:url(#py);
  }
  .pt {
    fill:url(#pz);
  }
  .pu {
    fill:url(#qa);
  }
  .pv {
    fill:url(#qb);
  }
  .pw {
    fill:url(#qc);
  }
  .px {
    fill:url(#qd);
  }
  .py {
    fill:url(#qe);
  }
  .pz {
    fill:url(#qf);
  }
  .qa {
    fill:url(#qg);
  }
  .qb {
    fill:url(#qh);
  }
  .qc {
    fill:url(#qi);
  }
  .qd {
    fill:url(#qj);
  }
  .qe {
    fill:url(#qk);
  }
  .qf {
    fill:url(#ql);
  }
  .qg {
    fill:url(#qm);
  }
  .qh {
    fill:url(#qn);
  }
  .qi {
    fill:url(#qo);
  }
  .qj {
    fill:url(#qp);
  }
  .qk {
    fill:url(#qq);
  }
  .ql {
    fill:url(#qr);
  }
  .qm {
    fill:url(#qs);
  }
  .qn {
    fill:url(#qt);
  }
  .qo {
    fill:url(#qv);
  }
  .qp {
    fill:url(#qw);
  }
  .qq {
    fill:url(#qx);
  }
  .qr {
    fill:url(#qy);
  }
  .qs {
    fill:url(#qz);
  }
  .qt {
    fill:url(#ra);
  }
  .qu {
    fill:url(#rb);
  }
  .qv {
    fill:url(#rc);
  }
  .qw {
    fill:url(#rd);
  }
  .qx {
    fill:#853b00;
  }
  .qy {
    fill:url(#re);
  }
  .qz {
    fill:url(#rg);
  }
  .ra {
    opacity:0.4;
    fill:url(#rh);
  }
  .rb {
    fill:url(#ri);
  }
  .rc {
    fill:url(#rj);
  }
  .rd {
    fill:url(#rk);
  }
  .re {
    fill:url(#rl);
  }
  .rf {
    fill:url(#rm);
  }
  .rg {
    fill:url(#rn);
  }
  .rh {
    fill:url(#ro);
  }
  .ri {
    fill:url(#rp);
  }
  .rj {
    fill:url(#rq);
  }
  .rk {
    fill:url(#rr);
  }
  .rl {
    fill:url(#rs);
  }
  .rm {
    fill:url(#rt);
  }
  .rn {
    fill:url(#ru);
  }
  .ro {
    fill:url(#rv);
  }
  .rp {
    fill:url(#rw);
  }
  .rq {
    fill:url(#rx);
  }
  .rr {
    fill:url(#ry);
  }
  .rs {
    fill:url(#rz);
  }
  .rt {
    fill:url(#sa);
  }
  .ru {
    fill:url(#sb);
  }
  .rv {
    fill:url(#sc);
  }
  .rw {
    fill:url(#sd);
  }
  .rx {
    fill:url(#se);
  }
  .ry {
    fill:url(#sg);
  }
  .rz {
    fill:url(#sh);
  }
  .sa {
    fill:url(#si);
  }
  .sb {
    fill:url(#sj);
  }
  .sc {
    fill:url(#sk);
  }
  .sd {
    fill:url(#sl);
  }
  .se {
    fill:url(#sm);
  }
  .sf {
    fill:url(#sn);
  }
  .sg {
    fill:url(#so);
  }
  .sh {
    fill:url(#sp);
  }
  .si {
    fill:url(#sq);
  }
  .sj {
    fill:url(#sr);
  }
  .sk {
    fill:url(#ss);
  }
  .sl {
    fill:url(#st);
  }
  .sm {
    fill:url(#su);
  }
  .sn {
    fill:url(#sv);
  }
  .so {
    fill:url(#sw);
  }
  .sp {
    fill:url(#sx);
  }
  .sq {
    fill:url(#sy);
  }
  .sr {
    fill:url(#sz);
  }
  .ss {
    fill:url(#ta);
  }
  .st {
    fill:url(#tb);
  }
  .su {
    fill:url(#tc);
  }
  .sv {
    fill:url(#td);
  }
  .sw {
    fill:url(#te);
  }
  .sx {
    fill:url(#tf);
  }
  .sy {
    fill:url(#tg);
  }
  .sz {
    fill:url(#th);
  }
  .ta {
    fill:url(#ti);
  }
  .tb {
    fill:url(#tj);
  }
  .tc {
    fill:url(#tk);
  }
  .td {
    fill:url(#tl);
  }
  .te {
    fill:url(#tm);
  }
  .tf {
    fill:url(#tn);
  }
  .tg {
    fill:url(#tp);
  }
  .th {
    fill:#dfcb18;
  }
  .ti {
    fill:url(#tq);
  }
  .tj {
    fill:#6b3500;
  }
  .tk {
    fill:#dfd05a;
  }
  .tl {
    fill:#fff07a;
  }
  .tm {
    fill:url(#tr);
  }
  .tn {
    fill:url(#ts);
  }
  .to {
    fill:url(#tt);
  }
  .tp {
    fill:url(#tv);
  }
}
.winnerFunRegisterBadge {
  .a {
    stroke:#c0c0c0;
    stroke-width:2px;
    fill:url(#winnerFunRegisterGradinet);
  }
}
.winCoins {
  .a{opacity:0.28;fill:url(#wincoins-a);}.b{fill:url(#wincoins-b);}.c{fill:url(#wincoins-c);}.d{fill:#853b00;}.e{fill:url(#wincoins-d);}
}