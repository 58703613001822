@import url('https://fonts.googleapis.com/css2?family=Paytone+One&display=swap');

.momentum-backdrop-svg {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
  display: none;

  &.show {
    display: block;
  }
}

.momentum-won-panel-svg {
  position: fixed;
  padding: 0;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    aspect-ratio: 800/550;
    width: 170%;
    min-width: 170%;
    max-width: 550px;
    height: auto;
  }

  @media (orientation: landscape) {
    top: calc(100dvh / 2);
    left: calc(100dvw / 2);
    margin-top: -175px;
    margin-left: -253px;
    width: 506px;
    height: 350px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 8px;
    background: url("./won-dialog-svg-assets/bg-fx-l.png") no-repeat left top, radial-gradient(75.83% 93.72% at 48.83% 52.01%, #4B1575 0%, rgba(8, 19, 66, 0.61) 48.76%, rgba(0, 0, 0, 0.73) 100%), #2C054C;
    background-size: cover;

    box-shadow: 18.289px 0px 18.289px 0px rgba(0, 0, 0, 0.75);

    svg {
      aspect-ratio: 800/550;
      width: auto;
      height: 350px;
    }
  }

  @media (orientation: portrait) {
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    max-width: 550px;
    margin: 0 auto;
    height: fit-content;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0px 0px 8px 8px;
    background: url("./won-dialog-svg-assets/bg-fx.png") no-repeat left top, radial-gradient(67.09% 97.38% at 48.83% 63.29%, #4B1575 0%, rgba(8, 19, 66, 0.61) 48.76%, rgba(0, 0, 0, 0.73) 100%), #2C054C;
    background-size: cover;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75);
  }

  &.desktop {
    margin-top: -300px;
    margin-left: -350px;
    width: 700px;
    height: 600px;

    svg {
      aspect-ratio: 800/550;
      width: auto;
      height: 600px;
    }
  }

  .you-won {
    text {
      text-anchor: middle;
      transform: translate(118px, -4px);

      tspan {
        font-family: "Paytone One", sans-serif !important;
      }
    }
  }

  .claim-text {
    text {
      font-size: 20px;
      font-family: "Open Sans", OpenSans !important;
      text-anchor: middle;
      transform: translate(50px, 1px);
    }
  }

  .prize-text {
    text {

      font-family: "Open Sans", OpenSans !important;
      text-anchor: middle;
      transform: translate(90px, 0px);
    }
  }

  .anim {

    /* amount */
    #ejzKihZF9pk35_ts {
      text {
        font-family: "Open Sans", OpenSans !important;
        text-anchor: middle;
        transform: translate(85px, 2px);
      }
    }

    /* you won */
    #ejzKihZF9pk45_ts {
      text {
        font-family: "Paytone One", sans-serif !important;
        text-anchor: middle;
        transform: translate(372px, 241px);
      }
    }

    /* cta text */
    #ejzKihZF9pk30_ts {
      z-index: 1;
      cursor: pointer;

      text {
        font-size: 20px;
        font-family: "Open Sans", OpenSans !important;
        text-anchor: middle;
        transform: translate(372px, 463px);
      }
    }

    #ejzKihZF9pk43_ts {
      pointer-events: none;
    }

    #MY-AMOUNT {
      text {
        text-anchor: end;
        transform: translate(240px, 334px);
      }
    }

    #ejzKihZF9pk5 {
      animation: ejzKihZF9pk5_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk5_c_o {
      0% {
        opacity: 0.25
      }

      5% {
        opacity: 0.25
      }

      7% {
        opacity: 1
      }

      11.5% {
        opacity: 1
      }

      12.5% {
        opacity: 0.18
      }

      13.5% {
        opacity: 1
      }

      20% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      22.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      42.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      62.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      82.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 1
      }
    }

    #ejzKihZF9pk10 {
      animation: ejzKihZF9pk10_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk10_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      36% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      37% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      39% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      56% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      57% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      59% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      76% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      77% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      79% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      96% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      97% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      99% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk11 {
      animation: ejzKihZF9pk11_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk11_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk12 {
      animation: ejzKihZF9pk12_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk12_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      24.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      25.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      44.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      45.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      64.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      65.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      84.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      85.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk13 {
      animation: ejzKihZF9pk13_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk13_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      34.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      35.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      37.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      54.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      55.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      57.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      74.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      75.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      77.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      94.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      95.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      97.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk14 {
      animation: ejzKihZF9pk14_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk14_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      32.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      33.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      35.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      52.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      53.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      55.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      72.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      73.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      75.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      92.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      93.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      95.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk15 {
      animation: ejzKihZF9pk15_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk15_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      37% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      38% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      57% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      58% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      77% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      78% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      97% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      98% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk16 {
      animation: ejzKihZF9pk16_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk16_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      31.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      51.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      71.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      91.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk17 {
      animation: ejzKihZF9pk17_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk17_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      23% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      24% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      43% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      44% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      63% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      64% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      83% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      84% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk18 {
      animation: ejzKihZF9pk18_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk18_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      34% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      35% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      37% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      54% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      55% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      57% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      74% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      75% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      77% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      94% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      95% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      97% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk19 {
      animation: ejzKihZF9pk19_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk19_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      21.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      22.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      24.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      41.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      42.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      44.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      61.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      62.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      64.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      81.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      82.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      84.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk20 {
      animation: ejzKihZF9pk20_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk20_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      21% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      23% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      41% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      43% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      61% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      63% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      81% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      83% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk21 {
      animation: ejzKihZF9pk21_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk21_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk22 {
      animation: ejzKihZF9pk22_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk22_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      34.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      35.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      37.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      54.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      55.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      57.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      74.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      75.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      77.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      94.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      95.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      97.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk23 {
      animation: ejzKihZF9pk23_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk23_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      20.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      21.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      23.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      41.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      43.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      61.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      63.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      81.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      83.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk24 {
      animation: ejzKihZF9pk24_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk24_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      32.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      33.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      35.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      52.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      53.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      55.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      72.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      73.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      75.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      92.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      93.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      95.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk25 {
      animation: ejzKihZF9pk25_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk25_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      31% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      33% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      51% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      53% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      71% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      73% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      91% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      93% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk26 {
      animation: ejzKihZF9pk26_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk26_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      24.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      25.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      44.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      45.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      64.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      65.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      84.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      85.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk27 {
      animation: ejzKihZF9pk27_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk27_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      31.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      32.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      34.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      51.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      52.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      54.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      71.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      72.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      74.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      91.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      92.5% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      94.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk28 {
      animation: ejzKihZF9pk28_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk28_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      23% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      24% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      43% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      44% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      63% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      64% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      83% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      84% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk29 {
      animation: ejzKihZF9pk29_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk29_c_o {
      0% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      31% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      32% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      34% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      51% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      52% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      54% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      71% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      72% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      74% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      91% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      92% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      94% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk30_ts {
      animation: ejzKihZF9pk30_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk30_ts__ts {
      0% {
        transform: translate(369.999497px, 456.085541px) scale(0, 0)
      }

      5% {
        transform: translate(369.999497px, 456.085541px) scale(0, 0);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      14.8% {
        transform: translate(369.999497px, 456.085541px) scale(0, 0);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      15.3% {
        transform: translate(369.999497px, 456.085541px) scale(0.090662, 1.37251);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      16% {
        transform: translate(369.999497px, 456.085541px) scale(1.080808, 0.791938);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      16.65% {
        transform: translate(369.999497px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      17.9% {
        transform: translate(369.999497px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      19.25% {
        transform: translate(369.999497px, 456.085541px) scale(1, 1)
      }

      20% {
        transform: translate(369.999497px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(369.999497px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(369.999497px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(369.999497px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(369.999497px, 456.085541px) scale(1, 1)
      }
    }

    #ejzKihZF9pk32_ts {
      animation: ejzKihZF9pk32_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk32_ts__ts {
      0% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1)
      }

      20% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      34% {
        transform: translate(369.999496px, 456.085541px) scale(1.193649, 1.545558);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      54% {
        transform: translate(369.999496px, 456.085541px) scale(1.193649, 1.545558);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      74% {
        transform: translate(369.999496px, 456.085541px) scale(1.193649, 1.545558);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        transform: translate(369.999496px, 456.085541px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      94% {
        transform: translate(369.999496px, 456.085541px) scale(1.193649, 1.545558);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(369.999496px, 456.085541px) scale(1.193649, 1.545558)
      }
    }

    #ejzKihZF9pk32 {
      animation: ejzKihZF9pk32_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk32_c_o {
      0% {
        opacity: 1
      }

      20% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      34% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      54% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      74% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      94% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk35_ts {
      animation: ejzKihZF9pk35_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk35_ts__ts {
      0% {
        transform: translate(370px, 246px) scale(0, 0)
      }

      11% {
        transform: translate(370px, 246px) scale(0, 0);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      13% {
        transform: translate(370px, 246px) scale(0, 0);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      13.5% {
        transform: translate(370px, 246px) scale(0.961128, 0.027335);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      15% {
        transform: translate(370px, 246px) scale(0.950844, 1.206081);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      16% {
        transform: translate(370px, 246px) scale(1, 1)
      }

      20% {
        transform: translate(370px, 246px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(370px, 246px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(370px, 246px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(370px, 246px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(370px, 246px) scale(1, 1)
      }
    }

    #ejzKihZF9pk42_ts {
      animation: ejzKihZF9pk42_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk42_ts__ts {
      0% {
        transform: translate(369.471368px, 323.820359px) scale(0, 0)
      }

      14% {
        transform: translate(369.471368px, 323.820359px) scale(0, 0)
      }

      15% {
        transform: translate(369.471368px, 323.820359px) scale(2.499431, 1)
      }

      16.5% {
        transform: translate(369.471368px, 323.820359px) scale(1.051669, 0.420764)
      }

      20% {
        transform: translate(369.471368px, 323.820359px) scale(1.051669, 0.420764);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(369.471368px, 323.820359px) scale(1.051669, 0.420764);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(369.471368px, 323.820359px) scale(1.051669, 0.420764);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(369.471368px, 323.820359px) scale(1.051669, 0.420764);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(369.471368px, 323.820359px) scale(1.051669, 0.420764)
      }
    }

    #ejzKihZF9pk42 {
      animation: ejzKihZF9pk42_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk42_c_o {
      0% {
        opacity: 0.05
      }

      14% {
        opacity: 0.05
      }

      15% {
        opacity: 0.54
      }

      16.5% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk43_ts {
      animation: ejzKihZF9pk43_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk43_ts__ts {
      0% {
        transform: translate(367.333316px, 316.000138px) scale(0, 0)
      }

      14% {
        transform: translate(367.333316px, 316.000138px) scale(0, 0)
      }

      15% {
        transform: translate(367.333316px, 316.000138px) scale(4.814359, 3.150792)
      }

      17.5% {
        transform: translate(367.333316px, 316.000138px) scale(5.98863, 3.592383)
      }

      20% {
        transform: translate(367.333316px, 316.000138px) scale(5.98863, 3.592383);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(367.333316px, 316.000138px) scale(5.98863, 3.592383);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(367.333316px, 316.000138px) scale(5.98863, 3.592383);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(367.333316px, 316.000138px) scale(5.98863, 3.592383);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(367.333316px, 316.000138px) scale(5.98863, 3.592383)
      }
    }

    #ejzKihZF9pk43 {
      animation: ejzKihZF9pk43_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk43_c_o {
      0% {
        opacity: 0.27
      }

      14% {
        opacity: 0.27
      }

      15% {
        opacity: 0.56
      }

      17.5% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        opacity: 0.17;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      35% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        opacity: 0.17;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      55% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        opacity: 0.17;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      75% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87.5% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        opacity: 0.17;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      95% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk44_ts {
      animation: ejzKihZF9pk44_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk44_ts__ts {
      0% {
        transform: translate(367.333316px, 228.67975px) scale(0, 0)
      }

      12.5% {
        transform: translate(367.333316px, 228.67975px) scale(0, 0)
      }

      14% {
        transform: translate(367.333316px, 228.67975px) scale(2.544961, 0.853474)
      }

      15% {
        transform: translate(367.333316px, 228.67975px) scale(3.313783, 1.306123)
      }

      20% {
        transform: translate(367.333316px, 228.67975px) scale(3.313783, 1.306123);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(367.333316px, 228.67975px) scale(3.313783, 1.306123);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(367.333316px, 228.67975px) scale(3.313783, 1.306123);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(367.333316px, 228.67975px) scale(3.313783, 1.306123);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(367.333316px, 228.67975px) scale(3.313783, 1.306123)
      }
    }

    #ejzKihZF9pk44 {
      animation: ejzKihZF9pk44_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk44_c_o {
      0% {
        opacity: 0
      }

      12.5% {
        opacity: 0
      }

      14% {
        opacity: 0.18
      }

      15% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk45_to {
      animation: ejzKihZF9pk45_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk45_to__to {
      0% {
        transform: translate(363.238007px, 216.489418px)
      }

      14.5% {
        transform: translate(363.238007px, 216.489418px)
      }

      15% {
        transform: translate(363.238007px, 222.489418px)
      }

      15.5% {
        transform: translate(363.238007px, 216.489418px)
      }

      20% {
        transform: translate(363.238007px, 216.489418px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(363.238007px, 216.489418px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(363.238007px, 216.489418px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(363.238007px, 216.489418px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(363.238007px, 216.489418px)
      }
    }

    #ejzKihZF9pk45_ts {
      animation: ejzKihZF9pk45_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk45_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      12.3% {
        transform: scale(0, 0);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      12.8% {
        transform: scale(0.090662, 1.37251);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      13.5% {
        transform: scale(1.080808, 0.791938);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      14.15% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      15.4% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      16.3% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk54_to {
      animation: ejzKihZF9pk54_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk54_to__to {
      0% {
        offset-distance: 0%
      }

      13% {
        offset-distance: 0%
      }

      15% {
        offset-distance: 73.266763%
      }

      16% {
        offset-distance: 90.452561%
      }

      17.5% {
        offset-distance: 100%
      }

      20% {
        offset-distance: 100%;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        offset-distance: 100%;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        offset-distance: 100%;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        offset-distance: 100%;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        offset-distance: 100%
      }
    }

    #ejzKihZF9pk54_tr {
      animation: ejzKihZF9pk54_tr__tr 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk54_tr__tr {
      0% {
        transform: rotate(76.516229deg)
      }

      13% {
        transform: rotate(76.516229deg)
      }

      15% {
        transform: rotate(-6.128297deg)
      }

      16% {
        transform: rotate(78.182561deg)
      }

      17.5% {
        transform: rotate(0deg)
      }

      20% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: rotate(0deg)
      }
    }

    #ejzKihZF9pk54_ts {
      animation: ejzKihZF9pk54_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk54_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      13% {
        transform: scale(0, 0)
      }

      13.5% {
        transform: scale(0.7, 0.7)
      }

      17.5% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk64_to {
      animation: ejzKihZF9pk64_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk64_to__to {
      0% {
        offset-distance: 0%
      }

      13% {
        offset-distance: 0%
      }

      15% {
        offset-distance: 65.85207%
      }

      16.5% {
        offset-distance: 86.925973%
      }

      18% {
        offset-distance: 100%
      }

      20% {
        offset-distance: 100%
      }

      40% {
        offset-distance: 100%
      }

      60% {
        offset-distance: 100%
      }

      80% {
        offset-distance: 100%
      }

      100% {
        offset-distance: 100%
      }
    }

    #ejzKihZF9pk64_tr {
      animation: ejzKihZF9pk64_tr__tr 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk64_tr__tr {
      0% {
        transform: rotate(-62.373337deg)
      }

      13% {
        transform: rotate(-62.373337deg)
      }

      15% {
        transform: rotate(4.762795deg)
      }

      16.5% {
        transform: rotate(-55.575278deg)
      }

      18% {
        transform: rotate(0deg)
      }

      20% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: rotate(0deg);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: rotate(0deg)
      }
    }

    #ejzKihZF9pk64_ts {
      animation: ejzKihZF9pk64_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk64_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      13% {
        transform: scale(0, 0)
      }

      13.5% {
        transform: scale(0.7, 0.7)
      }

      18% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk74_to {
      animation: ejzKihZF9pk74_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk74_to__to {
      0% {
        transform: translate(370.693504px, 281.025215px)
      }

      11.5% {
        transform: translate(370.693504px, 281.025215px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      13% {
        transform: translate(370.693504px, 98.025215px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      13.5% {
        transform: translate(370.693504px, 98.025215px)
      }

      20% {
        transform: translate(370.693504px, 98.025215px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(370.693504px, 98.025215px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(370.693504px, 98.025215px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(370.693504px, 98.025215px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(370.693504px, 98.025215px)
      }
    }

    #ejzKihZF9pk74_ts {
      animation: ejzKihZF9pk74_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk74_ts__ts {
      0% {
        transform: scale(2.040922, 2.040913)
      }

      11.5% {
        transform: scale(2.040922, 2.040913);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      12% {
        transform: scale(2.331484, 2.331471);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      13% {
        transform: scale(1.129943, 1.129943);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      13.5% {
        transform: scale(1.280389, 1.280385)
      }

      20% {
        transform: scale(1.280389, 1.280385);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1.280389, 1.280385);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1.280389, 1.280385);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1.280389, 1.280385);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1.280389, 1.280385)
      }
    }

    #ejzKihZF9pk75_ts {
      animation: ejzKihZF9pk75_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk75_ts__ts {
      0% {
        transform: translate(214.693504px, 78.980949px) scale(0, 0)
      }

      5% {
        transform: translate(214.693504px, 78.980949px) scale(0, 0)
      }

      5.5% {
        transform: translate(214.693504px, 78.980949px) scale(0.791759, 0.329884)
      }

      5.85% {
        transform: translate(214.693504px, 78.980949px) scale(1.407595, 1)
      }

      6.4% {
        transform: translate(214.693504px, 78.980949px) scale(1, 1)
      }

      16.1% {
        transform: translate(214.693504px, 78.980949px) scale(1, 1)
      }

      20% {
        transform: translate(214.693504px, 78.980949px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(214.693504px, 78.980949px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(214.693504px, 78.980949px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(214.693504px, 78.980949px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(214.693504px, 78.980949px) scale(1, 1)
      }
    }

    #ejzKihZF9pk77_ts {
      animation: ejzKihZF9pk77_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk77_ts__ts {
      0% {
        transform: translate(215.852394px, 56.452301px) scale(0, 0)
      }

      5% {
        transform: translate(215.852394px, 56.452301px) scale(0, 0)
      }

      6.1% {
        transform: translate(215.852394px, 56.452301px) scale(0, 0)
      }

      6.9% {
        transform: translate(215.852394px, 56.452301px) scale(1.2, 1.2)
      }

      7.2% {
        transform: translate(215.852394px, 56.452301px) scale(1, 1)
      }

      16.1% {
        transform: translate(215.852394px, 56.452301px) scale(1, 1)
      }

      20% {
        transform: translate(215.852394px, 56.452301px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(215.852394px, 56.452301px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(215.852394px, 56.452301px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(215.852394px, 56.452301px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(215.852394px, 56.452301px) scale(1, 1)
      }
    }

    #ejzKihZF9pk84_ts {
      animation: ejzKihZF9pk84_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk84_ts__ts {
      0% {
        transform: translate(213.739502px, 89.266453px) scale(0, 0)
      }

      5% {
        transform: translate(213.739502px, 89.266453px) scale(0, 0)
      }

      6.6% {
        transform: translate(213.739502px, 89.266453px) scale(0, 0)
      }

      7.2% {
        transform: translate(213.739502px, 89.266453px) scale(1.2, 1.2)
      }

      7.7% {
        transform: translate(213.739502px, 89.266453px) scale(1, 1)
      }

      16.1% {
        transform: translate(213.739502px, 89.266453px) scale(1, 1)
      }

      20% {
        transform: translate(213.739502px, 89.266453px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(213.739502px, 89.266453px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(213.739502px, 89.266453px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(213.739502px, 89.266453px) scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(213.739502px, 89.266453px) scale(1, 1)
      }
    }

    #ejzKihZF9pk91_to {
      animation: ejzKihZF9pk91_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk91_to__to {
      0% {
        transform: translate(178.071999px, 120.475014px)
      }

      20% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      25% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26% {
        transform: translate(178.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      45% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46% {
        transform: translate(178.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      65% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66% {
        transform: translate(178.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      85% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86% {
        transform: translate(178.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(178.071999px, 120.475014px)
      }
    }

    #ejzKihZF9pk91_ts {
      animation: ejzKihZF9pk91_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk91_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      7% {
        transform: scale(0, 0)
      }

      7.85% {
        transform: scale(1.2, 1.2)
      }

      8.45% {
        transform: scale(1, 1)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk93_to {
      animation: ejzKihZF9pk93_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk93_to__to {
      0% {
        transform: translate(196.071999px, 120.475014px)
      }

      20% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27% {
        transform: translate(196.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47% {
        transform: translate(196.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67% {
        transform: translate(196.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87% {
        transform: translate(196.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(196.071999px, 120.475014px)
      }
    }

    #ejzKihZF9pk93_ts {
      animation: ejzKihZF9pk93_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk93_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      7.5% {
        transform: scale(0, 0)
      }

      8.45% {
        transform: scale(1.2, 1.2)
      }

      9.25% {
        transform: scale(1, 1)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk95_to {
      animation: ejzKihZF9pk95_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk95_to__to {
      0% {
        transform: translate(213.999504px, 120.501442px)
      }

      20% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        transform: translate(213.999504px, 117.377382px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        transform: translate(213.999504px, 117.377382px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        transform: translate(213.999504px, 117.377382px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        transform: translate(213.999504px, 117.377382px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(213.999504px, 120.501442px)
      }
    }

    #ejzKihZF9pk95_ts {
      animation: ejzKihZF9pk95_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk95_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      8% {
        transform: scale(0, 0)
      }

      9.15% {
        transform: scale(1.2, 1.2)
      }

      9.95% {
        transform: scale(1, 1)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk97_to {
      animation: ejzKihZF9pk97_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk97_to__to {
      0% {
        transform: translate(231.927498px, 120.475014px)
      }

      20% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        transform: translate(231.927498px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        transform: translate(231.927498px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        transform: translate(231.927498px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        transform: translate(231.927498px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(231.927498px, 120.475014px)
      }
    }

    #ejzKihZF9pk97_ts {
      animation: ejzKihZF9pk97_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk97_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      8.5% {
        transform: scale(0, 0)
      }

      9.7% {
        transform: scale(1.2, 1.2)
      }

      10.55% {
        transform: scale(1, 1)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk99_to {
      animation: ejzKihZF9pk99_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk99_to__to {
      0% {
        transform: translate(250.473343px, 120.475014px)
      }

      20% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        transform: translate(250.473343px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      31% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        transform: translate(250.473343px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      51% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        transform: translate(250.473343px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      71% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        transform: translate(250.473343px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      91% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(250.473343px, 120.475014px)
      }
    }

    #ejzKihZF9pk99_ts {
      animation: ejzKihZF9pk99_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk99_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      9% {
        transform: scale(0, 0)
      }

      10.3% {
        transform: scale(1.2, 1.2)
      }

      10.85% {
        transform: scale(1, 1)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk101_to {
      animation: ejzKihZF9pk101_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk101_to__to {
      0% {
        transform: translate(172.268524px, 94.958542px)
      }

      5% {
        transform: translate(172.268524px, 94.958542px)
      }

      7.2% {
        transform: translate(172.268524px, 94.958542px)
      }

      8.05% {
        transform: translate(153.268524px, 78.958542px)
      }

      8.65% {
        transform: translate(158.268524px, 82.958542px)
      }

      16.1% {
        transform: translate(158.268524px, 82.958542px)
      }

      20% {
        transform: translate(158.268524px, 82.958542px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(158.268524px, 82.958542px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(158.268524px, 82.958542px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(158.268524px, 82.958542px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(158.268524px, 82.958542px)
      }
    }

    #ejzKihZF9pk101_ts {
      animation: ejzKihZF9pk101_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk101_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      7.2% {
        transform: scale(0, 0)
      }

      8.05% {
        transform: scale(1.2, 1.2)
      }

      8.65% {
        transform: scale(1, 1)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk112_to {
      animation: ejzKihZF9pk112_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk112_to__to {
      0% {
        transform: translate(178.071999px, 120.475014px)
      }

      20% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      25% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26% {
        transform: translate(178.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      45% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46% {
        transform: translate(178.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      65% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66% {
        transform: translate(178.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      85% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86% {
        transform: translate(178.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87% {
        transform: translate(178.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(178.071999px, 120.475014px)
      }
    }

    #ejzKihZF9pk112_ts {
      animation: ejzKihZF9pk112_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk112_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      7% {
        transform: scale(0, 0)
      }

      9.5% {
        transform: scale(1.873852, 1.87385)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk112 {
      animation: ejzKihZF9pk112_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk112_c_o {
      0% {
        opacity: 1
      }

      7% {
        opacity: 1
      }

      8.5% {
        opacity: 1
      }

      9.5% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      25% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      45% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      65% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      85% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk114_to {
      animation: ejzKihZF9pk114_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk114_to__to {
      0% {
        transform: translate(196.071999px, 120.475014px)
      }

      20% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27% {
        transform: translate(196.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47% {
        transform: translate(196.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67% {
        transform: translate(196.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87% {
        transform: translate(196.071999px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        transform: translate(196.071999px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(196.071999px, 120.475014px)
      }
    }

    #ejzKihZF9pk114_ts {
      animation: ejzKihZF9pk114_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk114_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      7.5% {
        transform: scale(0, 0)
      }

      10% {
        transform: scale(1.873852, 1.87385)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk114 {
      animation: ejzKihZF9pk114_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk114_c_o {
      0% {
        opacity: 1
      }

      7.5% {
        opacity: 1
      }

      9% {
        opacity: 1
      }

      10% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      26% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      46% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      66% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      86% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk116_to {
      animation: ejzKihZF9pk116_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk116_to__to {
      0% {
        transform: translate(213.999504px, 120.501442px)
      }

      20% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        transform: translate(213.999504px, 117.377382px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        transform: translate(213.999504px, 117.377382px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        transform: translate(213.999504px, 117.377382px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        transform: translate(213.999504px, 117.377382px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        transform: translate(213.999504px, 120.501442px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(213.999504px, 120.501442px)
      }
    }

    #ejzKihZF9pk116_ts {
      animation: ejzKihZF9pk116_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk116_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      8% {
        transform: scale(0, 0)
      }

      10.5% {
        transform: scale(1.873852, 1.87385)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk116 {
      animation: ejzKihZF9pk116_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk116_c_o {
      0% {
        opacity: 1
      }

      8% {
        opacity: 1
      }

      9.5% {
        opacity: 1
      }

      10.5% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      27% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      47% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      67% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      87% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk118_to {
      animation: ejzKihZF9pk118_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk118_to__to {
      0% {
        transform: translate(231.927498px, 120.475014px)
      }

      20% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        transform: translate(231.927498px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        transform: translate(231.927498px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        transform: translate(231.927498px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        transform: translate(231.927498px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        transform: translate(231.927498px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(231.927498px, 120.475014px)
      }
    }

    #ejzKihZF9pk118_ts {
      animation: ejzKihZF9pk118_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk118_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      8.5% {
        transform: scale(0, 0)
      }

      11% {
        transform: scale(1.873852, 1.87385)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk118 {
      animation: ejzKihZF9pk118_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk118_c_o {
      0% {
        opacity: 1
      }

      8.5% {
        opacity: 1
      }

      10% {
        opacity: 1
      }

      11% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      28% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      31% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      48% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      51% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      68% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      71% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      88% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      91% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk120_to {
      animation: ejzKihZF9pk120_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk120_to__to {
      0% {
        transform: translate(250.473343px, 120.475014px)
      }

      20% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        transform: translate(250.473343px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      31% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        transform: translate(250.473343px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      51% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        transform: translate(250.473343px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      71% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        transform: translate(250.473343px, 117.350954px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      91% {
        transform: translate(250.473343px, 120.475014px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(250.473343px, 120.475014px)
      }
    }

    #ejzKihZF9pk120_ts {
      animation: ejzKihZF9pk120_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk120_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      9% {
        transform: scale(0, 0)
      }

      11.5% {
        transform: scale(1.873852, 1.87385)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }

    #ejzKihZF9pk120 {
      animation: ejzKihZF9pk120_c_o 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk120_c_o {
      0% {
        opacity: 1
      }

      9% {
        opacity: 1
      }

      10.5% {
        opacity: 1
      }

      11.5% {
        opacity: 0
      }

      20% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      29% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      30% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      32% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      49% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      50% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      52% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      69% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      70% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      72% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      89% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      90% {
        opacity: 1;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      92% {
        opacity: 0;
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        opacity: 0
      }
    }

    #ejzKihZF9pk122_to {
      animation: ejzKihZF9pk122_to__to 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk122_to__to {
      0% {
        transform: translate(261.077072px, 59.556232px)
      }

      5% {
        transform: translate(261.077072px, 59.556232px)
      }

      6.9% {
        transform: translate(261.077072px, 59.556232px)
      }

      7.5% {
        transform: translate(276.077072px, 46.556232px)
      }

      8.05% {
        transform: translate(271.077072px, 51.556232px)
      }

      16.1% {
        transform: translate(271.077072px, 51.556232px)
      }

      20% {
        transform: translate(271.077072px, 51.556232px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: translate(271.077072px, 51.556232px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: translate(271.077072px, 51.556232px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: translate(271.077072px, 51.556232px);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: translate(271.077072px, 51.556232px)
      }
    }

    #ejzKihZF9pk122_ts {
      animation: ejzKihZF9pk122_ts__ts 20000ms linear 1 normal forwards
    }

    @keyframes ejzKihZF9pk122_ts__ts {
      0% {
        transform: scale(0, 0)
      }

      5% {
        transform: scale(0, 0)
      }

      6.9% {
        transform: scale(0, 0)
      }

      7.5% {
        transform: scale(1.2, 1.2)
      }

      8.05% {
        transform: scale(1, 1)
      }

      16.1% {
        transform: scale(1, 1)
      }

      20% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      40% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      60% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      80% {
        transform: scale(1, 1);
        animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
      }

      100% {
        transform: scale(1, 1)
      }
    }
  }
}