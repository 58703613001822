#register {
  min-height: 600px;
}
.registerWizard {
    opacity: 0;
    .container {
      max-width: 100%;
      margin: 0 auto;
      width: 870px;
      position: relative;
      background-color: white;
      padding-left: 2rem;
      padding-right: 2rem;
      padding-bottom: 4rem;
      max-height: 100%;
    }

    .top {
        padding-top: 1.1rem;
        padding-bottom: 5rem;
        position: relative;
        z-index: 2;
        .title {
            font-weight: 600;
            font-size: 1.125rem;
            text-transform: uppercase;
            margin: 0 auto;
            color: #131313;
        }
    }
    .close {
      /*font-size: 4rem;*/
      font-weight: 100;
      float: right;
      margin-top: -.7rem;
      position: relative;
      z-index: 2;
      &.registerClose{
        path{
          fill: #000 !important;
        }
      }
    }
    .backButton {
        border: none;
        background: transparent;
        -webkit-appearance: none;
        cursor: pointer;
        padding: 1rem;
        line-height: 1;
        text-shadow: 0 1px 0 #017048;
        margin: -1rem auto -1rem -1rem;
        float: left;
        z-index: 99;
        path {
          fill: #cccccc;
        }
    }
    .breadcrumb {
      background-color: #ffffff;
      position: relative;
      margin: 0 auto;
      padding-top: 2.2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: -3rem;
      padding-bottom: 7rem;
      .line {
        height: 3px;
        background-color: #cccccc;
        position: absolute;
        width: calc(80% - 100px);
        top: 3.7rem;
        left: calc(10% + 50px);
        overflow: hidden;
        .line-content {
          height: 3px;
          position: absolute;
          background-color: lighten(#017048,12%);
          overflow: hidden;
          &:after {
            position: absolute;
            content: " ";
            height: 3px;
            box-shadow: 0rem 0rem 9rem 6rem #017048;
            width: 0px;
            right: 0px;
          }
        }
      }
      &.step-4 {

        .breadcrumbStep {

          .name {
            opacity: 0;
            margin-top: -100px;
            height: 0px;
            display: none;
          }
        }

      }
      .breadcrumbStep {
        width: 25%;
        min-width: 50px;
        text-align: center;
        cursor: not-allowed;
        pointer-events: none;
        position: absolute;
        min-height: 100px;
        &:nth-child(2) {
          left: 2%;
        }
        &:nth-child(3) {
          left: 25%;
        }
        &:nth-child(4) {
          left: 50%;
        }
        &:last-child {
          left: 73%;
          z-index: 2;
          .icon {
            max-width: 40px;
          }
        }
        .name {
          font-size: 0.875rem;
          margin-top: -0.4rem;
          padding-top: 0px;
          font-weight: 500;
          color: rgb(179,179,179);
        }
      .icon {
        max-width: 50px;
        margin: 0 auto;
        margin-bottom: 15px;
        height: 50px;
        position: relative;
        span {
          svg {
            background-color: white;
            position: relative;
            z-index: 1;
            path,
            circle,
            polygon {
              fill: rgb(204,204,204);
              -webkit-transition: all 0.5s ease-in-out 0.5s;
              -moz-transition: all 0.5s ease-in-out 0.5s;
              -ms-transition: all 0.5s ease-in-out 0.5s;
              -o-transition: all 0.5s ease-in-out 0.5s;
              transition: all 0.5s ease-in-out 0.5s;
            }
          }
          &.replace-icon {
            position: absolute;
            -webkit-transition: all 0.5s ease-in-out 0.5s;
            -moz-transition: all 0.5s ease-in-out 0.5s;
            -ms-transition: all 0.5s ease-in-out 0.5s;
            -o-transition: all 0.5s ease-in-out 0.5s;
            transition: all 0.5s ease-in-out 0.5s;
            top: 0px;
            width: 100%;
            left: 0px;
            opacity: 0;
          }
        }


      }

      &.active {

        .icon {
          .replace-icon {
            svg {
              path,
              circle,
              polygon {
                fill: #68a188;
              }
            }
          }

        }
        &.focus {
          .icon {
            .replace-icon {
              svg {
                path,
                circle,
                polygon {
                  fill: #017048;
                }
              }
            }
          }
        }
        .name {
          color: rgb(128,128,128);
        }
      }
      &.withLink {
        cursor: pointer;
        pointer-events: all;
      }
    }
    }
    .IconSVG {

       &.login-check {
          display: none;
       }

      .st75 {
        -webkit-transition: all 0.2s ease-in-out 0.2s;
        -moz-transition: all 0.2s ease-in-out 0.2s;
        -ms-transition: all 0.2s ease-in-out 0.2s;
        -o-transition: all 0.2s ease-in-out 0.2s;
        transition: all 0.2s ease-in-out 0.2s;
      }
    }
    .form {
      position: relative;
      max-width: 50%;
      width: 400px;
      margin-top: 1.2rem;
      .input-group {
        margin-bottom: 1.3rem;
      }
      @media screen and (min-width: 1440px){
          margin: 0 auto;
      }
      .ButtonNext {
        padding: 3rem 1rem;
        width: 100%;

        .btn {
          width: 100%;
          -webkit-transition: all 0.2s ease-in-out 0.2s;
          -moz-transition: all 0.2s ease-in-out 0.2s;
          -ms-transition: all 0.2s ease-in-out 0.2s;
          -o-transition: all 0.2s ease-in-out 0.2s;
          transition: all 0.2s ease-in-out 0.2s;
          .text {
            font-size: 1.125rem;
            font-weight: 600;
          }
          &:disabled {
            box-shadow: none;
            pointer-events: none;
          }
        }
      }
    }
  .left {
    float: left;
  }

  .UsernameField {
    .messages {
      -webkit-transition: all .2s ease-in;
      -moz-transition: all .2s ease-in;
      -ms-transition: all .2s ease-in;
      -o-transition: all .2s ease-in;
      transition: all .2s ease-in;
      height: auto;
      padding-top: 0rem;
      margin-top: -1.2rem;
      font-size: 0.875rem;
      font-weight: 500rem;
    }
    .input-centered {
        .input-group {
          &.error {
            .input-bar {
              &:after {
                width: 100%;
                left: 0%;
                background-color: red;
              }
            }
          }
            input {
              font-weight: 500;
              font-size: 1.25rem;
              &:valid
                ~ {
                  label {
                    color: gray;
                  }
              }
              &:focus {
                ~ {
                  label {
                    color: #0071bc;
                  }
                  .input-bar {
                    background-color: transparent;
                    &:after {
                      width: 100%;
                      left: 0%;
                    }
                  }
                }
              }
            }
          .input-bar {
            &:after {
              content: " ";
              position: absolute;
              top: 0px;
              height: 2px;
              background-color: #0071bc;
              width: 0%;
              left: 50%;
              -webkit-transition: all 0.2s ease-in;
              -moz-transition: all 0.2s ease-in;
              -ms-transition: all 0.2s ease-in;
              -o-transition: all 0.2s ease-in;
              transition: all 0.2s ease-in;
            }
          }
        }
    }
  }

  .hide {
    display: none;
  }

  #verification {
    h1 {
      font-size: 1.25rem;
      font-weight: 400;
      color: rgb(51,51,51);
    }

    p {
      font-weight: 400;
      font-size: 0.875rem;
      color: rgb(128,128,128);

    }
    .sms-container {
      .messages {
        &.error-type {
          height: auto !important;
        }
        p {
          &.error {
            color: var(--red);
            font-weight: 500;
            text-align: center;
            width: 200%;
            margin-left: -100%;
          }
        }
      }
      &:not(.active) {
        .messages {
          p {
            &.error {
              margin-top: 1rem;
            }
          }
        }
      }
      &.active {
        .messages {

          p {
            &.error {
              width: auto;
              margin-left: auto;
            }
          }
        }
      }


    }
  }
  .background-image {
    position: fixed;
    top: 0px;
    left: 0px;
    pointer-events: none;
    z-index: 1;
    &.right {
      left: auto;
      right: 0px;
    }
  }

}

.popover-register {
  box-shadow: 0px 0px 1px 0px var(--gray);
  z-index: 99999;
  position: absolute;
  .popover-inner {
    position: relative;
    z-index: 2;
    padding: 10px 0px 10px 10px;
    .popover-header {
      color: #000;
      background: white;
      border: none ;
      font-size: 0.7rem;
      padding: 0px;
      font-weight: bold;
    }
    .popover-body {
      font-size: 0.7rem;
      padding: 0px;
    }
  }
  &.bs-popover-left {
    position: relative;
    z-index: 0;
    .arrow {
      -webkit-transform: rotateX(30deg) !important;
      -moz-transform: rotateX(30deg) !important;
      -ms-transform: rotateX(30deg) !important;
      -o-transform: rotateX(30deg) !important;
      transform: rotateX(30deg) !important;
      &:before {
        border-left-color: rgba(0,0,0,.9);
      }
    }
  }
}

 .App {
   .MuiInput-underline {
     &:after {
       left: 0;
       right: 0;
       bottom: 0;
       content: "";
       position: absolute;
       transform: scaleX(0);
       transition: transform 200ms ease-in-out 0ms;
       border-bottom: 2px solid #303f9f;
       pointer-events: none;
     }

   }
 }
