@import "./../../../../../variables";

.NewPassword {
    .step-support {
        .step-footer {
            position: relative;

            .buttonsSupport {
                position: relative;
                top: 0;
                transition: .2s ease-in all;

                &.hide {
                    //animation: slideOutDown 1s;
                    top: 160px;
                }
            }

            .pm-loader {
                position: absolute;
                top: 60px;
                transition: .5s ease-in all;

                &.dontShow {
                    top: -50px;
                }

                div {
                    div {
                        background-color: $fourthColor;
                    }
                }
            }
        }
    }
}