.VivaScreen{
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-grow: 1;
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
    //height: 100vh;
    box-sizing: border-box;
    background-color: #ffffff;

    > .Loading {
        display: flex;
        margin: calc(50vh - 50px) auto;
        box-sizing: border-box;
        color: #ffb400;
        width: 40px !important;
        height: 40px !important;
    }

    .VivaScreenContainer{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;
        padding: 30px 0 0 0 ;
        box-sizing: border-box;

        .PageDescription{
            background-color: #fbfbfb;
            color: #b6b6b6;
            padding: 10px 15px;
            box-sizing: border-box;
            text-align: left;
            font-weight: 500;
            font-size: 12px;
            border-radius: 5px;
            margin: 0 10px;
        }

        .PageForm{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            padding: 0 25px;
            box-sizing: border-box;
            width: 100%;
            
            .FormTitle{
                font-weight: 500;
                text-align: left;
                margin: 15px 0px;
                font-size: 14px;
            }

            .PlayerDataInput{
                width: 100%;
                margin-bottom: 20px;
                input{
                    color: #7881a6;
                    font-weight: 600;
                    font-size: 20px;
                }
                button{
                    margin-right: -12px;
                    svg{
                        fill: #0026a2;
                        width: 20px;
                    }
                }
            }

            .SingleRow{
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                .PlayerDataInput{
                    width: 55%;

                    &:last-of-type{
                        width: 35%;
                    }
                }
            }
        }

        .DepositAmount{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            padding: 0 30px;
            box-sizing: border-box;
            margin-left: 15px;

            .PlayerDataInput{
                width: 45%;
                margin-bottom: 20px;
                input{
                    color: #313131;
                    font-weight: 600;
                    font-size: 20px;
                }
                button{
                    margin-right: -12px;
                    svg{
                        fill: #0026a2;
                        width: 20px;
                    }
                }
            }

            .BonusAmount{
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: flex-start;
                height: 50px;
                margin-left: 30px;
                width: 45%;

                .BonusTitle{
                    color: rgba(0, 0, 0, 0.54);
                    padding: 0;
                    font-size: 1rem;
                    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
                    font-weight: 400;
                    line-height: 1;
                    letter-spacing: 0.00938em;
                    transform: translate(0, 1.5px) scale(0.75);
                    transform-origin: top left;
                    transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
                    top: 0;
                    left: 0;
                    position: absolute;
                    display: block;
                }

                .BonusValue{
                    color: #5311d6;
                    font-size: 13px;
                    font-weight: 600;
                }
            }
        }

        .DepositButton{
            display: flex;
            width: 100%;
            padding: 0 25px;
            box-sizing: border-box;
            margin: 10px;
            gap: 10px;

            button {
                display: block;
                width: 100%;
                height: 60px;
                border-radius: 30px;
            }
            .Loading {
                color: #ffb400;
                width: 17px !important;
                height: 17px !important;
                margin: 0px;
            }
        }

        .ReturnMessage{
            font-size: 12px;
            width: calc(100% - 30px);
            color: #856404;
            background-color: #fff3cd;
            border-color: #ffeeba;
            box-sizing: border-box;
            position: relative;
            padding: .75rem 1.25rem;
            margin: 0px 15px;
            border-radius: .25rem;

            h2{
                font-size: 14px;
                font-weight: 600;
                margin-bottom: 5px;
            }

            p{
                font-size: 12px;
                font-weight: 500;
            }
        }

        .label3ds{
            padding: 12px;
        }

        .switch3ds{
            .MuiSwitch-thumb{
                background-color: #90caf9;
            }

            .MuiSwitch-track {
                background-color: #90caf9;
            }
        }

        #tdsMmethodTgtFrame{
            width: 100% !important;
            height: 400px !important;
            visibility: visible !important;
            border: 2px solid blue;
            border-radius: 5px;
        }

        .hidden{
            display: none;
        }

    }
    .actions-fixed {
        display: flex;
        flex-direction: column;
        gap: 10px;
        img {
            pointer-events: all;
            cursor: pointer;
        }
        .buttons-grouped {
            align-items: center;
            padding: 0 25px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            &> div {
                width: 100%;
                background: #FFF;
                font-size: 1rem;
                font-weight: 600;
                line-height: 1.5;
                outline: none;
                cursor: pointer;
                border-radius: 4px;
                transition: all .2s ease-in-out;
                border: 1px solid #000;
                height: 51px;
                img {
                    max-width: none;
                    max-height: 48px;
                }
                &:hover {
                    opacity: .8;
                }
            }
        }

    }
}

#validation3ds {
    display: none;
    position: absolute;
    z-index: 9999999999;
    top: calc(50% - 1.5rem);
    left: 50%;
    width: 100%;
    height: 100%;
    background: white;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    opacity: 0;
    -webkit-transition: opacity .2s ease-in;
    -o-transition: opacity .2s ease-in;
    transition: opacity .2s ease-in;
    margin-left: -50%;
    margin-top: -50%;
    overflow: auto;
    iframe {
        overflow: auto;
    }
    &.show {
        display: block;
        opacity: 1;
    }

}

