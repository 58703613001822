.dialog-modal {
  .MuiDialog-container {
    .MuiPaper-rounded {
      border-radius: .5rem;
      .dialog-content {
        text-align: center;
        font-weight: bold;
        font-size: .8rem;
        padding: 2rem 1rem !important;
        .dialog-icon {
          max-width: 5rem;
          margin: 0 auto;
          svg {
            max-width: 5rem;
          }
        }
        .dialog-title {
          font-weight: bold;
          padding: 1rem 0 2rem 0;
        }

      }
      .dialog-actions {
        display: grid !important;
        grid-template-columns: 1fr 1fr;
        margin: 1rem .5rem;
        button {
          border: .15rem solid;
          background: linear-gradient(90deg, #295A91,#2174E0);
          color: #fff;
          border-radius: .5rem;
          padding: .5rem 1.5rem;
          &.error {
            color: #FF196E;
            span {
              color: inherit;
            }
          }
          &.ghost {
            background: transparent;
          }
        }
      }
    }
  }
}
