/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : Aug 8, 2018, 5:15:58 PM
    Author     : daniel
*/


.PasswordField {
    padding-left: 20px;
    padding-right: 20px;
}

.PasswordField .space-top {
    width: 100%;
    min-height: 40px;
    display: block;
}

.PasswordField .space-bottom {
    min-height: 140px;
    max-height: 140px;
    height: 140px;
    width: 100%;
    display: block;
    overflow: hidden;
}

.PasswordField .input-centered .input-group .IconSVG
{
    position: absolute;
    right: 0;
}

.PasswordField .input-centered .input-group .showPassword
{
    position: absolute;
    right: 0;
    display: block;
    height: 100%;
    width: 34px;
    background: transparent;
    border: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

.PasswordField .input-centered .input-group .showPassword .IconSVG
{
    top: 0;
}

.PasswordField .input-centered .input-group input
{
    padding-right: 30px;
}

.PasswordField .messages
{
    padding-top: 10px;
    height: 80px;
}

.login_register_link,
.login_register_link:hover,
.login_register_link:focus,
.login_register_link:active
{
    text-decoration: none;
}