@import "../../ResponsibleGaming";

$greenColor: #007048;
$redColor: #d6346b;
$darkRedColor: #c21301;
$blueColor: #0171bc;
.change-limit {
  .hide {
    display: none !important;
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -ms-transition: none !important;
    -o-transition: none !important;
    transition: none !important;
  }
  .edit-btn {
    display: inline-block;
    border: 2px solid $greenColor;
    color: $greenColor;
    font-weight: 600;
    padding: .25rem 4rem;
    cursor: pointer;
    -webkit-transition: all 0.2s ease-in;
    -moz-transition: all 0.2s ease-in;
    -ms-transition: all 0.2s ease-in;
    -o-transition: all 0.2s ease-in;
    transition: all 0.2s ease-in;
    &:hover {
      color: white;
      background: $greenColor;
    }
    &.velocity-animating {
      opacity: 0 !important;
    }
  }
  .edit-widget {
    width: calc(100% + 8rem);
    min-width: 300px;
    padding: .5rem;
    position: relative;
    text-align: center;
    background-color: lighten($colorType,5);
    margin-left: -3rem;
    margin-right: -2.9rem;
    //border-top: 1px solid;
    -webkit-box-shadow: inset -1rem 0 6px darken($grayColor,20);
    -moz-box-shadow: inset -1rem 0 6px darken($grayColor,20);
    box-shadow: inset -1rem 0 6px darken($grayColor,20);
    padding-top: 9rem;
    .content {
      max-width: 50%;
      width: 26rem;
      position: relative;
      margin: 0 auto;
      .info {
        position: absolute;
        width: 100%;
        top: -4.125rem;
        font-size: 1.5rem;
        font-weight: 600;
        letter-spacing: .125rem;
        color: $grayColor;
        .big {
          display: inline-block;
          font-size: 2rem;
        }
      }
    }
    input {
      width: 100%;
      text-align: center;
      font-size: 2.5rem;
      font-weight: 600;
      border-bottom: 2px solid $blueColor !important;
      line-height: 1;
      background-color: transparent;
    }
    .increase,
    .decrease {
      width: 2.5rem;
      height: 2.5rem;
      border: 2px solid;
      position: absolute;
      top: .25rem;
      -webkit-border-radius: .4rem;
      -moz-border-radius: .4rem;
      border-radius: .4rem;
      background-color: white;
      &:after {
        content: " ";
        border-left: .75rem solid transparent;
        border-right: .75rem solid transparent;
        //border-top: 1rem solid $greenColor;
        position: absolute;
        //left: 1rem;
      }
    }
    .increase {
      right: .25rem;
      border-color: $greenColor;
      cursor: pointer;
      &:after {
        border-bottom: 1rem solid $greenColor;
        border-top: 0 ;
        top: .5rem;
        right: .3rem;
      }
      &:hover {
        opacity: .9;
      }
    }
    .decrease {
      cursor: pointer;
      left: .3rem;
      border-color: $redColor;
      &:after {
        top: .75rem;
        border-top: 1rem solid $redColor;
        left: .35rem;
      }
      &:hover {
        opacity: .8;
      }
    }
    .buttons {
      padding: 2.5rem 0 0;
      .btn {
        cursor: pointer;
        width: 100%;
        padding: .5rem;
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 1.7;
        color: #ffffff;
        margin-bottom: 1rem;
        -webkit-transition: all .2s ease-in;
        -moz-transition: all .2s ease-in;
        -ms-transition: all .2s ease-in;
        -o-transition: all .2s ease-in;
        transition: all .2s ease-in;
        border-radius: 5px;
        &.success{
          background-color: $greenColor;
          border: 3px solid $greenColor;
          margin-bottom: 1.5rem;
          &:hover {
            background-color: transparent;
            color: $greenColor;
          }
        }
        &.outer {
          background-color: white;
          border: 3px solid;

          &.success {
            border-color: $greenColor;
            color: $greenColor;
          }
          &.error {
            border-color: $darkRedColor;
            color: $darkRedColor;
            &:hover {
              color: white;
              background-color: $darkRedColor;
            }
          }
        }
      }
    }

  }
}