@import "./../../variables";

$arrowColorNormal: $iconGray;
$arrowColorSelected: $thirdColor;
$thisDefaultColor: #333333;

@keyframes openRightMenu {
  0% {
    width: 0px;
    display: none;
  }
  1% {
    display: block;
    width: 0px;
  }
  100% {
    display: block;
    width: 408px;
  }
}

@keyframes closeRightMenu {
  0% {
    display: block;
    width: 408px;
  }
  99% {
    width: 0px;
    display: block;
  }
  100% {
    display: none;
    width: 0px;
  }
}

.rightMenu {
  position: fixed;
  right: 0;
  z-index: 1061;
  height: 100%;

  .rcs-custom-scroll {
    .rcs-inner-handle {
      background-color: $thirdColor;
      border-radius: 0px;
      width: 6px;
      right: -4px;
      position: relative;
    }
  }

  .rightMenuSupport {
    width: 0px;
    //overflow-x: hidden;
    //overflow-y: auto;
    //transition: all .3s ease-in;
    animation: closeRightMenu .3s ease-in-out;
    height: 100%;

    &.opened {
      animation: openRightMenu .3s ease-in-out;
      width: 408px;
    }

    .rightMenuContent {
      width: 408px;
      background-color: #f7f7f7;
      min-height: 100%;
      overflow: hidden;
      color: #000;
      background-color: #f7f7f7;

      .rmSections {
        width: 408px;
      }

      .black {
        background-color: $firstColor;
        min-height: 178px;

        .nameRow {
          border-bottom: 1px solid #333333;
          padding: 0px 20px;
          box-sizing: border-box;

          .name {
            display: flex;
            flex-direction: row;
            box-sizing: border-box;
            height: auto;
            margin-top: 30px;
            text-align: left;
            color: #787878;
            font-weight: bold;
            font-size: 16px;

            p {
              padding-left: 20px;
              padding-right: 0;
              padding-bottom: 0;
              padding-top: 0;
            }
          }

          .button {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            height: auto;
            margin: 15px 0px 30px 0px;

            &:last-of-type{
              button{
                border-color: $buttonYellow;
                background-color: transparent;
                color: $buttonYellow;

                &:hover {
                  border-color: lighten($buttonYellow, 5%);
                  color: lighten($buttonYellow, 8%);
                  background-color: transparent;
                }
              }
            }

            button {
              font-weight: bold;
              width: 100%;
              border-color: $buttonYellow;
              background-color: $buttonYellow;

              &:hover {
                border-color: lighten($buttonYellow, 5%);
                background-color: lighten($buttonYellow, 8%);
              }

              &:focus,
              &:active {
                outline: none !important;
                box-shadow: none !important;
              }
            }

            .btn-success {
              background-color: #017048;
              border-color: #017048;
              font-weight: bold;
            }
          }
        }

        .walletCard {
          padding-left: 20px;
          padding-right: 20px;
          padding-top: 17px;
          padding-bottom: 14px;

          .IconSVG {
            fill: $iconGray;
          }

          .name {
            color: $iconGray;
            font-weight: bold;
            text-align: left;
          }

          .amount {
            color: $white;
            font-weight: bold;
            text-align: right;
            margin: 0;
            padding-left: 0;

            small{
              font-weight: inherit;
              font-size: 65%;
              color: gray;
            }
          }

          .walletRow {
            .IconSVG {
              height: 28px;
            }

            .name,
            .amount {
              font-size: 16px;
            }
          }

          .realMoney,
          .bonusMoney {
            .IconSVG {
              height: 20px;
            }

            .name {
              color: $grayColor;
            }

            .name,
            .amount {
              font-size: 12px;
            }
          }

          .bonusMoney {
            .IconSVG {
              .cls-1,
              .cls-2{fill:$iconGray;}
              .cls-2{stroke: $firstColor;stroke-miterlimit:10;stroke-width:1.03px;}.cls-3{fill:$firstColor;}
            }
          }

          .cardButtons {
            text-align: right;
            padding-top: 20px;

            button {
              background: transparent;
              border: 1px solid white;
              border-radius: 5px;
              font-size: 11px;
              text-transform: uppercase;
              padding: 4px 12px 4px 20px;
              color: white;
              font-weight: bold;

              .profile-menu-arrow {
                height: 11px;
                margin-left: 10px;
                fill: white;
                position: relative;
                top: -2px;
              }

              &:hover {
                background-color: rgba(255,255,255,0.1);
                color: $white;
                //.profile-menu-arrow {
                //  fill: $white;
                //}
              }

              &:focus,
              &:active {
                outline: none !important;
                box-shadow: none !important;
              }
            }
          }
        }
      }

      .white {
        background-color: #f7f7f7;

        .rmCard {
          border-bottom: 2px solid $iconGray;

          &:last-child {
            border-bottom: 0;
          }

          .eta {
            color: #bcbcbc;
            line-height: 24px;

            .IconSVG {
              height: 24px;
              fill: $thirdColor;
            }

            b {
              color: $black;
            }
          }
          
          .logoutEntry {
            .ptext {
              color: #c20002;
            }

            .icon {
              .logout-icon {
                .cls-1{fill:#c20002;}
              }
            }
          }
        }

        ul.menuSection {
          list-style: none;
          margin: 0 !important;
          padding-left: 0 !important;
          padding-right: 0 !important;
          padding-top: 20px;
          padding-bottom: 20px;
          display: block;
          width: 100%;

          li {
            display: block;

            a {
              padding: 0;
              margin: 0;
              color: $thisDefaultColor;
              transition: all .1s ease-in;
              line-height: 52px;

              span {
                margin: 0;
                padding: 0;
                line-height: 52px;
                font-weight: bold;
                font-size: 14px;
              }

              .icon {
                width: 4rem;
                border-left: 6px solid transparent;
                min-width: 4rem;
                transition: all .2s ease-in;
                text-align: center;
                color: $thisDefaultColor;

                svg {
                  width: 32px;
                  height: 32px;

                  &.withdraw-alert-icon {
                    width: 28px;
                  }
                }

                .menu-info-icon,
                .menu-warning-icon,
                .menu-danger-icon {
                  width: 24px;
                  height: 24px;
                  margin-right: 10px;

                  .cls-2{
                    fill: $white;
                  }
                }
                &.orange {
                  .menu-warning-icon {
                    .cls-1 {
                      fill: $dangerColor;
                    }
                  }
                }
                .menu-warning-icon {
                  .cls-1 {
                    fill: $warningColor;
                  }
                }

                .menu-info-icon {
                  .cls-1 {
                    fill: $infoColor;
                  }
                }

                .menu-danger-icon {
                  .cls-1 {
                    fill: $dangerColor;
                  }
                }

                .pin-address {
                  .cls-1{fill:#cccccc;}
                  .cls-2{fill:$white;}
                }

                .menu-hot-icon {
                  width: 56px;

                  .cls-1{fill:none;stroke:#ed1c24;stroke-miterlimit:10;}
                  .cls-2,
                  .cls-3{fill:#ed1c24;}
                  .cls-3{font-size:14px;font-family:Montserrat-Medium, Montserrat;font-weight:500;letter-spacing:0.02em;}
                }
              }

              .ptext {
                text-align: left;
                text-transform: capitalize;

                .subn {
                  display: block;
                  width: 100%;

                  &.notifications {
                    color: #999999;
                    font-weight: normal;
                    line-height: 14px;
                    font-size: 11px;
                    padding-left: 10px;
                    position: relative;
                    top: -5px;
                  }
                }
              }

              .arrow {
                margin: 0;
                text-align: center;

                .menuArrow {
                  fill: $arrowColorNormal;
                  transition: all .2s ease-in;
                }
                .profile-menu-arrow {
                  height: 14px;
                }
              }

              &:hover,
              &.selected {
                text-decoration: none;
                color: $thirdColor;
                font-weight: normal;
                background-color: $white;

                .icon {
                  border-left: 6px solid $thirdColor !important;
                  svg {
                    //animation: wobble 1s;
                  }
                }
                .arrow {
                  .menuArrow {
                    fill: $arrowColorSelected;
                  }
                }
              }
            }

          }

          &.notStandard {
            a {
              line-height: 34px;

              span {
                line-height: 34px;
                text-transform: none;
              }
            }
          }
        }

        .logoutSupport {
          .btn-outline-success {
            border-color: $thirdColor;
            font-weight: bold;
            color: $thirdColor;
            padding-left: 100px;
            padding-right: 100px;

            &:hover {
              background-color: $thirdColor;
              color: $white;
            }

            &:focus,
            &:active {
              outline: none !important;
              box-shadow: none !important;
            }
          }
        }

        @media screen and (min-width: $hideMenuMaxWidth + 1) {
          .show-custom-menu {
            display: none;
          }
        }
        //.bonusCard {
        //  height: 182px;
        //  display: block;
        //  background-position: left -60px;
        //  background-repeat: no-repeat;
        //
        //  .bonus-row {
        //    width: 100%;
        //    display: block;
        //
        //    &:first-child {
        //      padding-bottom: 5px;
        //    }
        //  }
        //
        //  .bonus-title,
        //  .bonus-amount {
        //    padding: 10px 20px;
        //
        //    .row {
        //      .col-10 {
        //        padding-left: 0;
        //      }
        //    }
        //  }
        //
        //  .bonus-amount {
        //    color: #9b36aa;
        //    font-weight: bold;
        //    .row {
        //      .col-10 {
        //        line-height: 38px;
        //        font-size: 20px;
        //      }
        //    }
        //  }
        //
        //  .bonus-title {
        //    font-weight: bold;
        //    color: $firstColor;
        //    .row {
        //      .col-10 {
        //        line-height: 38px;
        //        font-size: 16px;
        //      }
        //    }
        //  }
        //
        //  .bonus-timer {
        //    padding-top: 20px;
        //
        //    .bonusEtr {
        //      background-color: $white;
        //      border: 1px solid #f2f2f2;
        //      border-radius: 18px;
        //      margin-left: 11px;
        //      height: 34px;
        //      line-height: 34px;
        //      text-align: left;
        //      padding-left: 16px;
        //      width: 234px;
        //
        //      span.icon {
        //        line-height: 28px;
        //        height: 28px;
        //        display: inline-block;
        //      }
        //
        //      span.text {
        //        line-height: 34px;
        //        height: 34px;
        //        font-weight: bold;
        //        padding-left: 14px;
        //        display: inline-block;
        //        text-align: left;
        //        width: 180px;
        //      }
        //    }
        //
        //    .bonus-deposit {
        //      font-weight: bold;
        //      border-color: $buttonYellow;
        //      background-color: $buttonYellow;
        //
        //      &:hover {
        //        border-color: lighten($buttonYellow, 5%);
        //        background-color: lighten($buttonYellow, 8%);
        //      }
        //    }
        //  }
        //
        //  .calendarSymbol {
        //    height: 28px;
        //    position: relative;
        //    top: -2px;
        //
        //    .cls-1{fill:#fff;stroke:#999;}
        //    .cls-1,
        //    .cls-4,
        //    .cls-5{stroke-miterlimit:10;}
        //    .cls-2{fill:#db6e56;}
        //    .cls-3,
        //    .cls-5{fill:#ccc;}
        //    .cls-4{fill:#c1272d;stroke:#c1272d;opacity:0.38;}
        //    .cls-5{stroke:#ccc;}
        //    .days {
        //      transform: translateY(20%);
        //      font-size: 14px;
        //      font-weight: bold;
        //    }
        //  }
        //
        //  .promoGift {
        //    .cls-1{
        //      fill: $firstColor;
        //    }
        //  }
        //
        //  .bonus-symbol {
        //    width: 30px;
        //
        //    .cls-1,
        //    .cls-2{fill: #9b36aa;}
        //    .cls-2{stroke: $white;stroke-miterlimit:10;stroke-width:1.03px;}.cls-3{fill:$white;}
        //  }
        //}

        .menuCardTwo {
          padding-bottom: 60px;
        }
      }
    }
  }
}

.withdraw-alert-icon {
  .cls-1 {
    fill: $orangeColor;
  }
  .cls-2{
    fill: $white;
  }
}