.Okto {
    padding: 2rem;

      .buttons {
        &.small-buttons {
          button {
            width: 100%;
            margin: 0;
          }
          margin-bottom: 2rem;
        }
      }
      .Taxes {
        p {
          font-weight: bold;
        }
      }
      .field {
        width: 100%;
        text-align: left;
        padding-top: 0;
        &:nth-child(1) {
          margin-top: 2rem;
        }
        &:last-child {
          margin-bottom: 2rem;
        }
      }
      .checkbox-title {
        margin-top: .75rem;
        color: #009245;
        font-size: .75em;
      }
      .checkbox-subtitle {
        font-weight: normal;
        font-size: .675rem;
      }
  .IconSVG {
    width: 75px;
    fill: green;
    margin-bottom: 20px;
  }
  .title {
    color: green;
    font-size: 1.5rem;
  }
  .subtitle {
    font-size: .85rem;
    margin-bottom: 20px;
    margin-top: 20px;
    font-weight: bold;
  }
  .description {
    font-size: .85rem;
    font-weight: normal;
  }
  .code {
    font-size: 3rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  a {
    font-size: .85rem;
  }
  .user-details {
    .value {
      font-size: 0.825rem;
      font-weight: normal;
      text-align: left;
    }
    .right {
      .value {
        text-align: right;
      }
    }
  }
  .nearest-locations {
    a {

      text-align: left;
      margin-top: 2rem;
      margin-bottom: 1rem;
      font-size: .9rem;
      font-weight: normal;
      display: grid;
      grid-template-columns: 1fr 50px;

      .IconSVG {
        width: 100%;
        fill: green;
      }
    }
  }
  .result {
      text-align: center;
  }
}
.accepted-values {
  font-size: .86rem;
  color: rgba(0, 0, 0, 0.54);
  text-align: left;
}
.DepositAmount{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 30px;
  box-sizing: border-box;
  margin-left: 15px;

  .PlayerDataInput{
    width: 45%;
    margin-bottom: 20px;
    input{
      color: #a1a1a1;
      font-weight: 600;
      font-size: 20px;
    }
    button{
      margin-right: -12px;
      svg{
        fill: #0026a2;
        width: 20px;
      }
    }
  }

  .BonusAmount{
    position: relative;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    height: 50px;
    margin-left: 30px;
    width: 45%;

    .BonusTitle{
      color: rgba(0, 0, 0, 0.54);
      padding: 0;
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1;
      letter-spacing: 0.00938em;
      transform: translate(0, 1.5px) scale(0.75);
      transform-origin: top left;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      top: 0;
      left: 0;
      position: absolute;
      display: block;
    }

    .BonusValue{
      color: #5311d6;
      font-size: 13px;
      font-weight: 600;
    }
  }
  .actions {
    margin-top: 20px;
  }



}