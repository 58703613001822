.momentum-game-component-portrait {
  height: 90px;
  width: 100%;
  font-family: "Open Sans", OpenSans;
  font-weight: normal;
  text-align: left;
  box-sizing: border-box;


  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
  position: relative;
  overflow: hidden;

  background: radial-gradient(58.03% 111.67% at 50.13% 50%, #541782 0%, rgba(8, 19, 66, 0.46) 48.76%, rgba(0, 0, 0, 0.00) 100%), #2C054C;

  * {
    box-sizing: border-box;
  }

  &.d-none {
    display: none !important;
  }

  .mom-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0;
    position: relative;
    overflow: hidden;
    max-width: 490px;

    @media (min-width: 390px) {
      gap: 4px;
    }
  }

  .info-button {
    top: 8px;
    left: 8px;
    position: absolute;
    cursor: pointer;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.10);
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 16px;
      height: 16px;
      display: block;
    }
  }

  .left-side {
    flex: 0 0 95px;
    width: 95px;
    height: 100%;
    display: flex;
    padding: 8px 12px 8px 23px;
    gap: 4px;

    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
    position: relative;

    .logo-wrapper {
      display: flex;
      padding-top: 10px;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      gap: 8px;
    }

    .logo {
      width: 71px;
      height: auto;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }

    .timer {
      height: 14px;
      display: block;
      color: #FFF;
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      line-height: 14px;

      .muted {
        color: rgba(255, 255, 255, 0.35);
      }

      .expired {
        color: #ED003F;
      }
    }
  }

  .center {
    width: 175px;
    height: 90px;
    display: flex;
    padding: 10px 6px 2px 0px;
    margin-left: 6px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    overflow: visible;
    position: relative;

    .value {
      display: flex;
      min-height: 47px;
      height: 47px;
      padding: 0 32px 5px 32px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 26.5px;
      border-top: 1px solid #1E0A44;
      gap: 5px;

      background: linear-gradient(0deg, rgba(31, 5, 51, 0.00) 0%, #041132 100%);


      .stars {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 0;
        margin-top: -8px;

        .star {
          width: 21px;
          height: 20px;
          margin: 0 0 0 -6px;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .star:first-child {
          margin-left: 0;
        }
      }

      .text {
        color: rgba(255, 255, 255, 0.90);
        text-shadow: 0px 3.629px 10.614px rgba(255, 255, 255, 0.20);
        font-size: 26px;
        font-style: italic;
        font-weight: 600;
        line-height: 90%;
        letter-spacing: 0.52px;
        white-space: nowrap;
        width: 85px;
        text-align: center;
        overflow: visible;

        display: flex;
        align-items: flex-end;
        justify-content: center;
        gap: 6px;

        .smaller {
          font-size: 22px;
          text-transform: lowercase;
        }
      }
    }

    .progress-wrapper {
      width: 169px;
      height: 24px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .progress {
        width: 159px;
        height: 14px;
        border-radius: 50px;
        border: 1px solid #6916A8;
        padding: 2px;

        background: #2C024D;

        box-shadow: 0px 4px 4.3px 0px rgba(0, 0, 0, 0.56) inset;
        position: relative;

        .progress-bar {
          height: 100%;
          border-radius: 50px;
          background: linear-gradient(92deg, rgba(255, 255, 255, 0.00) 92.4%, #FFF 96.41%, rgba(255, 255, 255, 0.00) 100.42%), linear-gradient(198deg, #FFCC3F 27.22%, #FD692A 93.1%);
          box-shadow: 0.481px 8px 0px 0px rgba(242, 213, 88, 0.44) inset;
          position: relative;
          transition: width 200ms ease-in-out;

          .end-stuff {
            position: absolute;
            top: 5px;
            right: -12px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              position: absolute;
            }

            img.se {
              width: 24px;
              height: auto;
              display: block;
            }
          }
        }

        @keyframes my-bg-animation-portrait {
          100% {
            background-position: 1590px 0;
          }
        }

        .progress-bar-shine {
          height: 6px;
          border-radius: 50px;
          position: absolute;
          top: 3px;
          left: 0;
          overflow: hidden;
          background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 1149px, rgba(255, 255, 255, .5) 1159px, rgba(255, 255, 255, 0) 1169px, transparent);
          animation: my-bg-animation-portrait 10s infinite linear;
          background-repeat: repeat;
          background-position: 0 0;
          background-size: 1590px 6px;
        }
      }

      .end-star {
        position: absolute;
        top: -8px;
        right: -4px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }

        &.op img {
          opacity: 0;
        }
      }

      .end-star.orig {
        top: 3px;
        right: 2px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .progress-animation {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 175px;
    height: 90px;
    z-index: 5;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .right-side {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
    padding: 8px 12px 8px 0;

    @media (min-width: 390px) {
      padding: 8px 12px;
    }

    .close-button {
      cursor: pointer;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.10);

      svg {
        color: #fff;
        width: 16px;
        height: 16px;
        display: block;
      }
    }

    .deposit-button {
      cursor: pointer;
      width: 100%;
      height: 32px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: #ED003F;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    display: block;
    opacity: 0;
    align-items: center;
    justify-content: center;
    background: rgba(42, 2, 75, 0.85);
    transition: opacity 300ms ease-in-out;
    z-index: 1;
    overflow: hidden;
  }

  .overlay-text {
    position: absolute;
    bottom: -24px;
    left: 0;
    right: 0;

    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    padding-bottom: 4px;
    transition: bottom 300ms ease-in-out;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(42, 2, 75, 0), rgba(13, 14, 54, .5) 100%);
  }

  .overlay-expired-text {
    position: absolute;
    bottom: -61px;
    left: 0;
    right: 0;

    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    padding-bottom: 4px;
    transition: bottom 300ms ease-in-out;
    z-index: 3;
    background: linear-gradient(to bottom, rgba(42, 2, 75, 0), rgba(13, 14, 54, .5) 100%);

    .title {
      color: rgba(255, 255, 255, 0.90);
      text-align: center;
      text-shadow: 0px 3.629px 10.614px rgba(255, 255, 255, 0.20);
      font-size: 26px;
      font-style: italic;
      font-weight: 600;
      line-height: 90%;
      letter-spacing: 0.52px;
      margin-bottom: 12px;
    }

    .subtitle {
      color: #FFF;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      margin-bottom: 3px;
    }
  }

  .overlay-warning {
    position: absolute;
    bottom: -85px;
    left: 0;
    right: 0;

    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    padding-bottom: 4px;
    transition: bottom 300ms ease-in-out;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .title {
      color: #FFCD00;

      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      line-height: 14px;
      margin-bottom: 40px;
    }

    .subtitle {
      cursor: pointer;
      border-radius: 17.5px;
      background: rgba(255, 255, 255, 0.10);
      padding: 2px 4px 2px 8px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 2px;
      color: #D2CFDB;
      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      line-height: 12px;
      margin-bottom: 3px;
      pointer-events: all;

      .info-button {
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: static;

        img {
          width: 12px;
          height: 12px;
          display: block;
        }

      }
    }
  }

  &.show-overlay .overlay,
  &.show-expired .overlay {
    opacity: 1;
  }

  &.show-overlay .overlay-text,
  &.show-expired .overlay-expired-text,
  &.show-warning .overlay-warning {
    bottom: 0;
  }

  &.show-expired {
    .center .value {
      z-index: 0;
    }

    .value .text {
      opacity: 0;
    }

    .timer {
      z-index: 4;
      position: relative;
      top: -25px;
    }

    .left-side .logo {
      opacity: 0;
    }
  }

  &.show-warning {
    .stars {
      opacity: 0;
    }

    .progress-wrapper {
      opacity: 0;
    }
  }

  .info-button,
  .close-button,

  .center .value {
    z-index: 2;
  }

  .close-button {
    z-index: 2;
    position: relative;
  }

}