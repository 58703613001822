.wheel-result-dialog {
  font-family: "Open Sans";
  position: relative;

  * {
    box-sizing: border-box;
  }

  .result-dialog-body {
    padding-top: calc(10px + var(--notch-top-offset, 0px));

    width: 100%;
    height: 100%;
    background: url("https://t5a4q7k3.stackpathcdn.com/dialog-bg-1635769840636.png");
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;

    .timer {
      text-align: center;
      padding-bottom: 5px;

      .txt {
        font-size: 14px;
        color: #FFFFFF;
        font-weight: normal;
        white-space: normal;
        line-height: 14px;
      }

      .counter {
        font-size: 16px;
        color: #FFFFFF;
        font-weight: bold;
        white-space: normal;
      }
    }

    .close-header {
      display: flex;
      justify-content: flex-end;
      padding: 0 10px;
      cursor: pointer;
    }

    .results-dialog-logo {
      display: flex;
      justify-content: center;

      img {
        width: 67px;
        height: auto;
        display: block;
      }
    }

    .result-dialog-title {
      margin-top: 6px;
      display: flex;
      justify-content: center;
      color: #F1F1F1;
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
    }

    .wrapper-info {
      margin-top: 20px;
      background: url('../assets/results-bg.svg');
      background-repeat: no-repeat;
      background-size: 100 auto;
      background-position: center 50px;
      padding-bottom: 50px;

      &.wrapper-info.mon {
        background-image: url('../assets/results-bg-mon.svg');
      }
    }

    .outer-wrapper {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
      animation: wrapper-info-keys 6s infinite;
      border-radius: 50%;
      margin: 0 auto;
      width: 290px;
      height: 290px;
      overflow: visible;
      padding-top: 55px;
      position: relative;
      top: -25px;
    }

    @keyframes wrapper-info-keys {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      }

      40% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }

      60% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.4);
      }
    }

    .center-piece {
      /*background: linear-gradient(to right, rgba(6, 12, 145, 0) 0, rgba(6, 12, 145, 1) 34.9%, rgba(6, 12, 145, 1) 68%, rgba(6, 12, 145, 0) 100%);*/
      background: linear-gradient(to right, rgba(6, 12, 145, 0) 0, rgba(6, 12, 145, .7) 34.9%, rgba(6, 12, 145, .7) 68%, rgba(6, 12, 145, 0) 100%);
      position: relative;
      z-index: 1;

      .top-piece {
        width: 100%;
        height: 1px;
        background: linear-gradient(to right, rgba(6, 12, 145, 0) 0, #FFFFFF 34.9%, #FFFFFF 68%, rgba(6, 12, 145, 0) 100%);
      }

      .product {
        .product-title {
          margin-top: 9px;
          display: flex;
          justify-content: center;
          color: #FFF;
          font-size: 15px;
          font-weight: bold;
          text-transform: uppercase;
        }

        .product-subtitle {
          margin-top: 6px;
          display: flex;
          justify-content: center;
          color: #fff;
          font-size: 30px;
          font-weight: bold;
          text-transform: uppercase;
        }
      }

      .bottom-piece {
        margin-top: 10px;
        width: 100%;
        height: 3px;
        background: linear-gradient(to right, rgba(6, 12, 145, 0) 0, #FFFFFF 34.9%, #FFFFFF 68%, rgba(6, 12, 145, 0) 100%);
      }
    }

    .value-piece {
      //margin-top: 30px;
      display: flex;
      justify-content: center;
      align-items: flex-end;

      color: #fff;
      font-size: 48px;
      font-weight: 800;
      line-height: 48px;

      &>span {
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        line-height: normal;
        display: inline-block;
        margin-left: 6px;

        &.hide {
          display: none;
        }
      }
    }

    .value-name {
      text-transform: uppercase;
      color: #ffffff;
      font-size: 10px;
      font-weight: 600;
      text-align: center;
    }
  }

  .multiplier-piece {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .naming {
      margin-top: 7px;
      background: linear-gradient(to right, rgba(74, 81, 254, 0) 0%, #4a51fe 34.9%, #4a51fe 68%, rgba(74, 81, 254, 0) 100%);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .lock-icon {
        color: #5B69E3;
      }

      .txt {
        color: #A6A8CE;
        font-size: 25px;
        font-weight: bold;
        min-width: 190px;
        text-align: center;
      }
    }
  }

  .total-piece {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .with-keys-text {
      color: #FFF;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
      max-width: 210px;
      margin: 5px auto 0;
    }

    .value {
      position: relative;
      color: #FFF;
      font-size: 80px;
      line-height: 80px;
      font-weight: 800;
      overflow: visible;

      &.strike-through {
        color: #A6A8CE;
      }

      &.strike-through::after {
        pointer-events: none;
        position: absolute;
        content: '';
        left: 0;
        top: 48%;
        right: 0;
        border-top: 7px solid #D40000;
        transform: skewY(340deg);
      }
    }

    .value-name {
      text-transform: uppercase;
      color: #A6A8CE;
      font-size: 10px;
      font-weight: 600;
      text-align: center;
    }
  }

  .wrapper-info.mon {
    .value-piece {
      color: #A6A8CE;
    }

    .value-name {
      color: #A6A8CE;
    }

    .multiplier-piece {
      .naming {
        .lock-icon {
          opacity: 0;
        }

        .txt {
          color: #FFFFFF;
        }
      }

      .value-name {
        color: #fff;
      }
    }
  }

  .buttons-column {
    position: absolute;
    bottom: 30px;
    left: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .regular {
      background: linear-gradient(to right, #1A33D9, #1664FF);
      font-size: 14px;
      color: #FFFFFF;
      font-weight: bold;
      font-family: 'Open Sans', sans-serif;
      box-shadow: 0 -3px 6px rgba(0, 0, 0, .16);
      height: 53px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 9px;
      margin-top: 11px;
      cursor: pointer;
    }

    .highlighted {
      background: linear-gradient(to right, rgba(230, 111, 31, .4), rgba(248, 21, 120, .4));
      box-shadow: 0 -3px 6px rgba(0, 0, 0, .16);
      height: 68px;
      width: 100%;
      border-radius: 12px;
      padding: 4px;
      cursor: pointer;

      .middle {
        background: linear-gradient(to right, rgba(230, 111, 31, .74), rgba(255, 62, 146, .75));
        padding: 4px;
        border-radius: 12px;
        height: 100%;

        .inner {
          background: linear-gradient(to right, #E66F1F, #FF167B);
          border-radius: 12px;
          border: 1px solid #fff;
          height: 100%;

          display: flex;
          align-items: center;
          justify-content: center;

          .txt {
            font-size: 14px;
            color: #FFFFFF;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
          }
        }
      }
    }


  }

  .multiplier-info-text {
    color: rgba(255, 255, 255, .5);
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    white-space: normal;
    margin-top: -55px;

    .question {
      background: #fff;
      border-radius: 50%;
      width: 16px;
      height: 16px;
      font-size: 10px;
      line-height: 0;
      color: #14259B;
      font-weight: 600;
      border: 1px solid rgba(255, 255, 255, .45);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      animation: pulse_anim_keys 3s infinite;
    }

    @keyframes pulse_anim_keys {
      0% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
      }

      50% {
        box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
      }

      100% {
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
      }
    }

    .sep {
      color: #E6E7F3;
      font-size: 12px;
      font-weight: normal;
      padding: 0 4px;
    }
  }
}