.tm-sport-ranking-info {
  font-family: 'Open Sans', OpenSans;

  * {
    box-sizing: border-box;
  }

  .tm-columns {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .tm-text-right {
    text-align: right;
  }

  .tm-text-left {
    text-align: left;
  }

  .tm-rows {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .tm-rows.tm-left {
    align-items: flex-start;
  }

  .tm-rows.tm-right {
    align-items: flex-end;
  }

  .tm-rows {
    .name {

      font-size: 11px;
      font-weight: 600;
      color: #586682;
    }

    .value {
      font-size: 15px;
      font-weight: bold;
      color: #162A57;
    }
  }

  .tm-column {
    flex: 1 1 auto;
    margin: 0 5px;
  }

  .tm-nicknames {
    margin: 0 7px;

    .tm-nickname {
      font-size: 11px;
      font-weight: normal;
      color: #213348;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;
    }
  }

  .tm-prize {
    font-size: 14px;
    font-weight: bold;
    color: #FFFFFF;
    white-space: nowrap;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;

    .tm-regular-color {
      color: #98A0AD;
    }

    &.tm-first-place {
      color: #91DC1B;
    }

    img.tm-score-up {
      display: inline-block;
      width: 9px;
      height: 10px;
    }
  }

  .tm-points {
    font-size: 11px;
    font-weight: bold;
    color: #98A0AD;
    white-space: nowrap;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;

    &.tm-color-up {
      color: #FFB011;
    }

    &.tm-color-down {
      color: #FD632F;
    }
  }

  .tm-informations {
    background: #213348;
    border-radius: 7px;
    margin: 2px 7px;
    padding: 0 7px;
    height: 64px;
    overflow: hidden;
  }

  .tm-separator {
    height: 52px;
    min-width: 12px;
    max-width: 12px;
    position: relative;
    display: flex;
    justify-content: center;

    .tm-line-863468712318723 {
      min-width: 2px;
      max-width: 2px;
      height: 52px;
      background: rgba(210, 219, 231, .18);
    }

    img {
      display: block;
      position: absolute;
      top: 13px;
      width: 12px;
      height: 12px;
    }
  }

  .tm-position2 {
    background-color: #152333;
    border-radius: 8px;
    width: 40px;
    height: 17px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: bold;
    color: #98A0AD;
    line-height: 0;
    padding-bottom: 1px;
    position: relative;

    &.tm-first-place {
      color: #91DC1B;
    }

    img.tm-position-up {
      position: absolute;
      display: block;
      width: 9px;
      height: 10px;
      bottom: -1px;
      right: -1px;
    }
  }

  .tm-nom {
    margin: 0
  }

  .tm-max-width-55 {
    max-width: 55%;
    margin: 0 3px 0 0;
  }

  .tm-max-width-45 {
    max-width: 45%;
    margin: 0 0 0 3px;
  }

  .tm-centered {
    display: flex;
    justify-content: center;
  }

  .tm-left-wrapper-outer-nicknames {
    height: 15px;
    overflow: hidden;
  }

  .tm-left-wrapper-nicknames {
    &.with-animation {
      animation-name: animPosText;
      animation-timing-function: linear;
      animation-duration: 8800ms;
      animation-iteration-count: infinite;
    }
  }

  @keyframes animPosText {
    0% {
      transform: translate(0, 0);
      animation-timing-function: linear;
    }

    45% {
      transform: translate(0, 0);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    49.5% {
      transform: translate(0, -15px);
      animation-timing-function: linear;
    }

    94.5% {
      transform: translate(0, -15px);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    100% {
      transform: translate(0, 0);
      animation-timing-function: linear;
    }
  }

  .tm-left-wrapper-outer {
    height: 64px;
    overflow: hidden;
  }

  .tm-left-wrapper {
    &.with-animation {
      animation-name: animPos;
      animation-timing-function: linear;
      animation-duration: 8800ms;
      animation-iteration-count: infinite;
    }

    &>.tm-columns {
      margin-top: 9px;
      margin-bottom: 10px;
    }
  }

  @keyframes animPos {
    0% {
      transform: translate(0, 0);
      animation-timing-function: linear;
    }

    45% {
      transform: translate(0, 0);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    49.5% {
      transform: translate(0, -54px);
      animation-timing-function: linear;
    }

    94.5% {
      transform: translate(0, -54px);
      animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    100% {
      transform: translate(0, 0);
      animation-timing-function: linear;
    }
  }

  .tm-avatar-wrapper {
    position: relative;
    overflow: visible;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #FFEE00, #FF9E00 40%, #C85D00);
    border-radius: 50%;
    margin-bottom: 5px;

    &.tm-alternative {
      background: linear-gradient(to bottom, #E494FF, #6B22A7);
    }

    &.tm-ml {
      margin-left: 10px;
    }

    &.tm-mr {
      margin-right: 5px;
    }

    img {
      display: block;
      width: 36px;
      height: 36px;
    }

    .tm-position {
      background-color: #152333;
      border-radius: 8px;
      position: absolute;
      bottom: -5px;
      width: 40px;
      height: 17px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 11px;
      font-weight: bold;
      color: #98A0AD;
      line-height: 0;
      padding-bottom: 1px;

      &.tm-first-place {
        color: #FFB011;
      }
    }

    .tm-award-img {
      position: absolute;
      bottom: -5px;
      display: block;
      width: 17px;
      height: 20px;
      margin-left: 37px;
    }
  }
}