.match-details-dialog {
  top: 57px !important;

  &>div.MuiBackdrop-root {
    display: none;
  }

  &.n-page.prematch {
    background-color: unset;
  }

  .MuiPaper-root {
    background-color: #eeedf2;
    border-radius: 0;
  }

  &.min-height {
    .MuiPaper-root.MuiDialog-paper {
      min-height: calc(100vh - 64px);
      width: 1024px;
    }
  }
}

@media (min-width:1281px) {
  .match-details-dialog {
    top: 0 !important;

    &>div.MuiBackdrop-root {
      display: flex;
    }
  }
}



.match-details-top-header {
  .back-header {
    height: 52px;
    position: absolute;
    z-index: 1;
    top: 2px;
    left: 2px;

    .controls>span {
      height: 27px;
      padding: 0 5px;
      background-color: transparent;

      svg {
        top: 6px;

        path {
          fill: #fff;
        }
      }
    }

    @media (hover) {
      .controls>span:hover {
        background-color: #fff;

        svg {
          path {
            fill: #000;
          }
        }
      }
    }

    .input {
      display: none;
    }
  }
}