.registerV2 {
  .step-4 {
    .field {
      text-align: center;
    }
    .input-group {
      width: 2rem;
      height: 2.5rem;
      position: relative;
      display: inline-block;
      margin-right: .5rem;
      input {
        max-width: 100%;
        display: block;
        height: 2.5rem;
        font-size: 2rem;
        padding: 0;
        margin: 0 ;
        border: 1px solid #717171;
        border-radius: .25rem;
        text-align: center;
      }
    }

    .change {
      font-size: .75rem;
      padding-top: 1rem;
    }
    .timer {
      text-align: center;
      font-weight: normal;
      font-size: .9rem;
      .underlined {
        text-decoration: underline;
      }
      .error {
        color: #FF196E;
        text-decoration: none;
      }
    }
  }
  .loading-screen {
    position: absolute;
    width: 100%;
    top: 0;
    align-content: center;
    display: flex;
    padding-top: 50%;
    > div {
      margin: 0 auto;
      width: 100%;
    }
  }
}