.header {
  position: fixed;
  width: 100%;
  top: 0;
  height: 40px;
  z-index: 3000;
  transition: top 0.4s;
  background-color: var(--ns-header-bg);

  .nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
  }

  .items {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;

    .item {
      height: 40px;
      padding: 13px 7.5px;
      border-bottom: 0;
      color: var(--ns-header-color);
      line-height: 1;
      font-size: 0.9375em;
      border-bottom: 4px solid transparent;
      cursor: pointer;
      text-decoration: none;
      margin: 0 10px;

      .group {
        height: 100%;
        display: inline-block;
        padding: 0 .5em;
      }
    }

    .item:not(.no-border).active {
      border-bottom: 4px solid var(--ns-header-active-color);
      color: var(--ns-header-active-color);
    }

    .item:not(.no-border):hover {
      border-bottom: 4px solid var(--ns-header-active-color);
      color: var(--ns-header-active-color);
    }

    .item.no-border {
      padding: 7px 0 0;
      margin: 0;
    }

    .item.no-border.active {
      background: #0d54a5;
    }

    .list {
      display: none;
      position: absolute;
      //left: 0;
      top: 100%;
      min-width: 15em;
      width: 12em;
      padding: 0.25em 0;
      background: #0d54a5;
      color: #ebebeb;
      line-height: 40px;

      .list-item {
        padding: 0.25em 1.5em;
        cursor: pointer;
        opacity: 0.75;
        text-align: left;
        border-bottom: 1px solid rgba(235, 235, 235, 0.1);
      }

      .list-item.active {
        color: #ffb200;
      }
    }

    .item.active .list {
      display: block;
    }
  }

  .options {
    height: 100%;
    float: right;
    padding-right: 10px;

    .item {
      display: block;
      position: relative;
      height: 40px;
      float: left;
      font-size: 0.875em;
      text-decoration: none;
      line-height: 40px;
      color: #ebebeb;

      .group {
        height: 100%;
        display: inline-block;
        padding: 0 1em;

        svg {
          position: relative;
          top: 6px;
        }
      }
    }

    .item.active {
      background: #0d54a5;
    }

    .item.toggle {
      cursor: pointer;
    }

    .betslip {
      color: #2979ff;
      background-color: rgba(0, 0, 0, 0.75);
      border-left: 0;
      text-transform: uppercase;
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      .group>span {
        padding: 0 0.4em;
        background-color: #ffb300;
        color: #111;
        border-radius: 0.25em;
        font-size: 0.875em;
        font-weight: 700;
      }
    }

    .betslip.changed {
      animation: color-change 1s 1;
    }

    @keyframes color-change {
      0% {
        background-color: #ffb300;
      }

      20% {
        background-color: rgba(0, 0, 0, 0.75);
      }

      40% {
        background-color: #ffb300;
      }

      60% {
        background-color: rgba(0, 0, 0, 0.75);
      }

      80% {
        background-color: #ffb300;
      }

      100% {
        background-color: rgba(0, 0, 0, 0.75);
      }
    }

    .list {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      min-width: 15em;
      width: 12em;
      padding: 0.25em 0;
      background: #0d54a5;
      color: #ebebeb;
      line-height: 40px;

      .list-item {
        padding: 0.25em 1.5em;
        cursor: pointer;
        opacity: 0.75;
        text-align: right;
        border-bottom: 1px solid rgba(235, 235, 235, 0.1);
      }

      .list-item.active {
        color: #ffb200;
      }
    }

    .item.active .list {
      display: block;
    }

    @media (min-width: 992px) {
      .item {
        font-size: 0.75em;
      }

      .list-item {
        padding: 0 1em;
      }
    }
  }

  .visible-mobile {
    display: block !important;
  }

  .visible-desktop {
    display: none !important;
  }

  @media (min-width: 992px) {
    .visible-mobile {
      display: none !important;
    }

    .visible-desktop {
      display: block !important;
    }
  }
}