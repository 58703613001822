@import '../../../../variables.scss';

.NotFoundScreen{
    display: flex;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
    flex-direction: column;
    height: 100%;
	flex-basis: 100%;
	width: 100%;
    background-color: #ffffff;
    padding: 80px 15px;
    box-sizing: border-box;
    color: $black;
    
    h3{
        margin: 0;
    }
    
    h1{
        margin: 10px 0px;
        font-size: 70px;
    }
    
    p{
        margin: 0;
        font-weight: 500;
        font-size: 14px;
        text-transform: uppercase;
    }
    
    a{
        margin: 20px 0px;
        width: fit-content;
        border: none;
        padding: 10px 30px;
        text-transform: uppercase;
        color: #FFF;
        font-size: 14px;
        font-weight: 600;
        border-radius: 10px;
        background: rgba(223,83,26,1);
        background: -moz-linear-gradient(left,  rgba(223,83,26,1) 0%, rgba(151,15,183,1) 100%);
        background: -webkit-linear-gradient(left,  rgba(223,83,26,1) 0%,rgba(151,15,183,1) 100%);
        background: linear-gradient(to right,  rgba(223,83,26,1) 0%,rgba(151,15,183,1) 100%);
        text-decoration: none;
    }
}