.jackpot-header {
    position: relative;
    height: 346px;
    padding-top: 30px;
    padding-bottom: 16px;

    .jackpot-header-content {
        display: block;
        background-position-x: 100%;
        background-size: 100% auto;
        background-repeat: no-repeat;
        margin: 0 auto 1em auto;
        position: relative;
        padding: 1em 0 .5em 0;
        z-index: 1;

        .wrapper {
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 300px;
            text-align: center;

            .jackpot-img {
                padding-top: 60px;

                >img {
                    width: 120px;
                }
            }

            .jackpot-name {
                color: #fff;
                font-weight: bold;
                font-size: 41px;
            }

            .jackpot-counter {
                padding-left: 30px;
                padding-top: 10px;
                font-size: 38px;

                .currency {
                    position: relative;
                    color: #F6A700;
                    font-size: 22px;
                    top: 10px;
                    padding-left: 3px;
                }
            }
        }
    }
}

.jackpot-header-lobby {
    position: relative;
    width: 100%;
    cursor: pointer;

    .jackpot-header-content {
        display: block;
        background-position-x: 100%;
        background-size: 100% auto;
        background-repeat: no-repeat;
        position: relative;
        padding: 0 0 15px 0;
        z-index: 1;

        .wrapper {
            background-position: 50% 50%;
            background-size: contain;
            background-repeat: no-repeat;
            width: 100%;
            height: 250px;
            text-align: center;
            margin: 0 auto;

            .jackpot-img {
                padding-top: 40px;

                >img {
                    width: 120px;
                }
            }

            .jackpot-name {
                color: #fff;
                font-weight: bold;
                font-size: 38px;
                padding-bottom: 2px;
            }

            .jackpot-counter {
                padding-left: 30px;
                padding-top: 0px;
                font-size: 30px;
                position: relative;

                .currency {
                    position: relative;
                    color: #F6A700;
                    font-size: 22px;
                    top: 8px;
                    padding-left: 3px;
                }
            }
        }
    }
}

@media (max-width:1430px) {
    .jackpot-header-lobby {
        .jackpot-header-content {
            padding: 0 0 5px 0;
        }
    }
}