@import "../../../variables.scss";

.App{
    .titleBar{
        background: $titleBarBGColor;
        color: $titleBarFontColor;
        border-bottom: 1px solid #ffb400;
        
        .returnButton{
            flex: 1;
            position: absolute;
            left: 15px;
            padding: 12px;

            .ArrowBackIcon{
                width: 25px;
                height: auto;
                fill: #fff;
            }
        }

        .closeButton{
            flex: 1;
            position: absolute;
            right: 15px;
            padding: 12px;

            .CloseMenuIcon{
                width: 10px;
                height: auto;
                fill: #fff;
            }
        }
        
        .title{
            flex: 11;
            align-content: center;
            align-items: center;
            justify-content: center;
            font-size: 20px;
            
            .subTitle{
                font-size: 16px;
                font-weight: 500;
            }
        }

        .DinamicImageContainer {
            display: initial;
            height: 56px;
            img {
                max-height: 56px;
            }
        }
    }

}
.App{
    .TopMenu-toolbarMargin-5 {
        min-height: 56px;
    }
    &.ios {
        .TopMenu-toolbarMargin-5 {
            //min-height: 4rem;
        }
    }
}
