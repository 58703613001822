$bets-bg-image: url(../../assets/bets/bg-live.png);
$bets-q-mark-bg:#133AA2;
$bets-q-mark-brd: #06D2BD;

.bets-live-regular-wrapper {
  position: relative;

  .bets-live-regular {
    max-width: 400px;
    height: 91px;
    margin: 0 auto;
    background: $bets-bg-image;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, .4);
    background-color: #1b1e2c;
    border-radius: 22px 22px 8px 8px;

    &.inBetslip {
      background-color: transparent;
      background-image: $bets-bg-image, linear-gradient(to bottom, rgba(172, 173, 174, 0) 0%, #acadae 100%);
    }

    * {
      box-sizing: border-box;
    }

    .hh-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      .header-tag-wrapper {
        width: 108px;
        position: relative;
        text-align: center;
        line-height: 22px;
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        font-style: italic;
        height: 20px;
        margin-right: 10px;
      }

      .title {
        height: 21px;
        min-width: 0;
        flex: 1 1 auto;
        margin: 0px 9px 0 0px;
        padding-top: 6px;

        display: flex;
        align-items: center;
        justify-content: space-between;

        & div {
          min-width: 0;
          flex: 1 1 auto;
          color: #fff;
          font-size: 11px;
          font-weight: 600;
          padding-left: 11px;
          text-align: left;
          text-transform: uppercase;
        }

        .q-mark {
          min-width: 21px;
          max-width: 21px;
          width: 21px;
          height: 21px;
          border-radius: 50%;
          background-color: $bets-q-mark-bg;
          border: 1px solid $bets-q-mark-brd;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          padding-left: 1px;
          margin-right: 17px;
          cursor: pointer;
        }
      }
    }

    .hh-prizes {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 6px;

      .hh-timer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 80px;
        min-width: 80px;
        max-width: 80px;

        .hh-hour,
        .hh-min,
        .hh-sec {
          .hh-top {
            font-size: 18px;
            font-weight: 600;
            color: #fff;
            text-align: center;
            line-height: 18px;
          }

          .hh-bottom {
            font-size: 8px;
            font-weight: normal;
            color: #FFFFFF;
            text-align: center;
            opacity: .5;
          }
        }

        .hh-min {
          margin: 0 5px;
        }

        .disabled {
          opacity: 0.4;
        }
      }

      .hh-prize-after,
      .hh-prize-before {
        height: 29px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #C0C0C0;
        font-size: 13px;
        font-weight: bold;
        min-width: 64px;
        background-color: rgba(71, 37, 154, 0.48);
        border-radius: 31px;
      }

      .hh-prize-after {
        color: #FFD759;
      }

      .hh-prize-current {
        margin: 0 29px 0 25px;
        height: 51px;
        min-width: 148px;
        background-color: rgba(80, 18, 135, 0.48);
        border: 1px solid rgba(255, 199, 219, .48);
        border-radius: 31px;

        .hh-flx {
          display: flex;
          align-items: center;
          justify-content: center;
          padding-top: 4px;

          .hh-img {
            svg {
              width: 25px;
              height: auto;
              position: relative;
              left: -4px;
              vertical-align: middle;
            }

            img {
              width: 25px;
              height: auto;
              position: relative;
              left: -4px;
            }
          }

          .hh-txt {
            color: #FFC400;
            font-size: 22px;
            font-weight: bold;
          }
        }

        .hh-bet {
          color: #dcc9c9;
          text-align: center;
          font-size: 12px;
          font-weight: normal;
          line-height: 12px;
        }
      }

      .hh-special-prize-front.hidden,
      .hh-prize-before.hidden,
      .hh-prize-after.hidden {
        opacity: 0;
      }

      .hh-special-prize-front {
        width: 70px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        overflow: visible;

        .hh-sp-img {
          display: block;
          max-width: 40px;
          height: auto;
          position: absolute;
          margin-left: auto;
          margin-right: auto;
          left: 0;
          right: 0;
          top: 0px;
        }

        .hh-sp-title-front {
          color: #F4C850;
          font-size: 11px;
          font-weight: bold;
          text-align: center;
        }
      }
    }
  }


  .hh-warning {
    max-width: 375px;
    margin: 0 auto;
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    min-height: 27px;
    flex-wrap: nowrap;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);

    .hh-ft {
      color: #ff0000;
      font-size: 13px;
      font-weight: bold;
      white-space: nowrap;
      text-align: center;
      line-height: 27px;
    }
  }

  &.warning {
    .bets-live-regular {
      filter: grayscale(1);
    }

    .hh-warning {
      display: block;
    }
  }
}