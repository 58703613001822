@import "./../../../../../variables";

.documentUploadImage {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;

  &.icon {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .imgSupport {

    .doc-image-icon {
      width: 66px;
      height: 66px;
      margin-right: 17px;

      .cls-1 {
        fill: $iconGray;
      }
    }

    .file-preview {
      width: 130px;
      height: 130px;
      background-position: center;
      background-size: cover;
    }
  }

  .restSupport {
    width: 100%;
    padding-left: 20px;

    p {
      margin: 0;

      &.file-name {
        font-size: 14px;
        font-weight: bold;
        color: $almostBlack;
        line-height: 16px;
      }

      &.file-size {
        font-size: 13px;
        color: $grayColor;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      &.file-status {
        text-align: right;
        font-size: 12px;
        color: $thirdColor;
        font-weight: normal;
        padding-top: 10px;
      }
    }

    .imgLoader {

      .progress {
        height: 10px;
        border-radius: 5px;
        background-color: #cef1e8;

        .bg-success {
          background-color: $thirdColor !important;
        }
      }
    }

    .removeUploadFileSupport {
      position: relative;
      float: right;

      button {
        border: none;
        background: transparent;
        height: 24px;
        width: 24px;
        position: relative;
        top: 0;
        transition: all 0.1s ease-in;

        &:focus,
        &:active {
          outline: none !important;
          box-shadow: none !important;
        }

        &:active {
          top: 2px;
        }
      }
    }
  }
}
