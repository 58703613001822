.btRegisterActions {
    line-height: 50px !important;
    padding-left: 5px;
    padding-right: 5px;
}

.wf-menu-button-root {
    height: 42px;
    width: 50px;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 25px;
    display: flex;
    align-items: center;
    margin: 0 0 0 .25rem;
    justify-content: center;
}

.wf-menu-button-season {
    color: #fff;
    margin-left: 3px;
    width: 38px;
}

.wf-menu-button-image {
    display: block;
    position: relative;
    top: 0px;
}

.menu-tickets-icon-badge {
    top: -3px;
    left: 7px;
}