.eldorado-dark-theme {
  .lobby-root {
    background: #0A1520;
    color: #fff;
  }

  .component-happy_hours .cgl-header .category,
  .component-jackpot-collection .cgl-header .category,
  .component-providers .cgl-header .category,
  .component-egt_jackpot .cgl-header .category,
  .component-content-slider .cgl-header .category,
  .component-simple_promotion .cgl-header .category,
  .component-games-list .cgl-super-header .category {
    color: #fff;
  }

  .component-happy_hours .cgl-header .title,
  .component-jackpot-collection .cgl-header .title,
  .component-providers .cgl-header .title,
  .component-egt_jackpot .cgl-header .title,
  .component-content-slider .cgl-header .title,
  .component-simple_promotion .cgl-header .title,
  .component-games-list .cgl-header .title {
    color: #fff;
  }

  .component-happy_hours .cgl-header .link,
  .component-jackpot-collection .cgl-header .link,
  .component-providers .cgl-header .link,
  .component-egt_jackpot .cgl-header .link,
  .component-content-slider .cgl-header .link,
  .component-simple_promotion .cgl-header .link,
  .component-games-list .cgl-header .link {
    color: #8D92A0;
  }

  .component-providers .cgl-list .cgl-grid .cgl-item .outer .inner .provider-name {
    color: #8D92A0;
  }

  .component-providers .cgl-footer {
    color: #8D92A0;
  }

  .component-nav-bar .nav-items .nav-item {
    color: #fff;
  }

  .component-nav-bar {
    margin: 0;
    padding: 10px 10px 0;
    background-color: #142232;

    .nav-items::after {
      background: transparent;
    }
  }

  .component-providers .cgl-list .cgl-grid .cgl-item .outer .inner .image-wrapper {
    background: linear-gradient(to bottom, #213348, #142232);
  }

  .component-providers .cgl-list .cgl-grid .cgl-item .outer .inner .image-wrapper .image-holder {
    background: linear-gradient(135deg, #0A1520, #142232, #213348);
  }

  .component-games-collection .cgl-list .cgl-grid .cgl-item {
    background: linear-gradient(to bottom, #213348, #142232);
  }

  .component-games-collection .cgl-list .cgl-grid .cgl-item .cgl-wrapper {
    background: linear-gradient(135deg, #0A1520, #142232, #213348);
  }

  .component-games-collection .cgl-list .cgl-grid .cgl-item .cgl-wrapper .cgl-name {
    color: #fff;
  }

  .component-games-collection {
    background: #142232 !important;
    padding-top: 10px;
  }

  .component-games-collection .cgl-header .category,
  .component-games-collection .cgl-header .title {
    color: #fff;
  }

  .component-games-collection .cgl-header .link {
    color: #8D92A0;
  }

  .lobby-special-component-favorites .lscf-header {
    color: #fff;
  }

  .lobby-special-component-search .cgl-list .cgl-grid .cgl-wrapper .gtxt {
    color: #fff;
  }

  .lobby-special-component-search .search-in {
    background: #0A1520;

    border-bottom: 0px;
  }

  .component-providers {
    background: linear-gradient(150deg, #213348 10%, #142232 40%);
  }

  .component-breadcrumbs {
    .breadcrumbs-list {
      background: linear-gradient(to right, #0A1520, #083B6E 2%, #0A1520);

      li.cb-item {
        background-color: #041F3A;
      }

      li.cb-item.first-element {
        color: #7A8896;
        background: #041F3A;

        svg {
          fill: #fff;
        }
      }

      li.cb-item.first-element:after {
        border-left-color: #041F3A;
      }

      li.cb-item.regular-element {
        color: #fff;
        background-color: #062C53;
      }

      li.cb-item.regular-element:before {
        border-left-color: #083B6E;
      }

      li.cb-item.regular-element:after {
        border-left-color: #062C53;
      }
    }
  }

  .component-content-slider,
  .component-games-list {
    position: relative;

    .before {
      left: 0;
      top: 0;
      bottom: 0;
      width: 30px;
      z-index: 1;
      position: absolute;
      background: linear-gradient(to left, rgba(10, 21, 32, 0), rgba(10, 21, 32, .9));
      display: block;
    }

    .after {
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      z-index: 1;
      position: absolute;
      background: linear-gradient(to left, rgba(10, 21, 32, .9), rgba(10, 21, 32, 0));
      display: block;
    }

    &.hide-margins {

      .before,
      .after {
        display: none;
      }
    }
  }

  .component-games-collection {
    position: relative;

    .before {
      left: 0;
      top: 0;
      bottom: 0;
      width: 30px;
      z-index: 1;
      position: absolute;
      background: linear-gradient(to left, rgba(20, 34, 50, 0), rgba(20, 34, 50, .9));
      display: block;
    }

    .after {
      right: 0;
      top: 0;
      bottom: 0;
      width: 50px;
      z-index: 1;
      position: absolute;
      background: linear-gradient(to left, rgba(20, 34, 50, .9), rgba(20, 34, 50, 0), );
      display: block;
    }

    &.hide-margins {

      .before,
      .after {
        display: none;
      }
    }
  }

  @media screen and (min-width:1528px) {

    .component-content-slider,
    .component-games-collection,
    .component-games-list {

      .before,
      .after {
        display: none;
      }
    }
  }

  .component {
    .cgl-header {
      .link {
        border-color: #142232
      }
    }
  }

  .lobby-link-arrow {
    .lobby-link-arrow-1 {
      fill: rgba(141, 141, 161, 1);
    }

    .lobby-link-arrow-2 {
      fill: rgba(141, 141, 161, .7);
    }
  }

  .component-games-list-promoted {
    .gcl-inner-wrapper {
      background-color: #142232;
    }

    .gcl-footer {
      .description {
        color: #8D92A0;
      }
    }

    .lobby-link-arrow {
      .lobby-link-arrow-1 {
        fill: rgba(255, 255, 255, 1);
      }

      .lobby-link-arrow-2 {
        fill: rgba(255, 255, 255, .34);
      }
    }
  }


  .lobby-special-component-search {
    .search-in {
      border-bottom: 1px solid transparent;

      .queryInput {
        background: #041F2F;
        color: #fff;
      }
    }
  }


  .lobby-search-popup .MuiPaper-root {
    background-color: #0A1520;

    .lobby-special-component-search {
      .search-in {
        border-bottom: 1px solid #000;
      }
    }

    .lobby-special-component-search {
      .cancel-text.popup {
        color: #fff;
      }
    }
  }

  .component-breadcrumbs.component {
    .cb-footer {
      color: #E7E7EF
    }
  }

  .component-jackpot-collection .cgl-list .cgl-grid .cgl-item.soon {
    background-color: #202F40;
  }
}