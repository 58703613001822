.bets-ticket-selector {
  background: transparent;
  height: 41px;

  >div {
    height: 41px;
    background: #586682;
    border-top-left-radius: 23px;
    border-top-right-radius: 23px;

    .button-wrapper {
      display: flex;
      align-items: flex-end;
      justify-content: center;
      flex-wrap: nowrap;
      height: 41px;

      .ticket-wrapper {
        width: 45px;
        height: 30px;
        background: #717D95;
        border-top-left-radius: 9px;
        border-top-right-radius: 9px;
        margin: 0 1px;
        cursor: pointer;
        will-change: height;

        .ticket-no {
          background: #717D95;
          color: #CCCCCC;
          font-size: 14px;
          font-weight: normal;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          border-top-left-radius: 9px;
          border-top-right-radius: 9px;
        }

        &.active {
          background: #EEF0F1;
          height: 37px;


          .ticket-no {
            background: #EEF0F1;
            color: #17214D;

          }
        }

        &.ticket-live {
          background: rgba(255, 25, 110, 1);



          .ticket-no {
            border-top-left-radius: 30px;

          }
        }

        &.ticket-prematch {
          background: rgba(31, 132, 230, 1);



          .ticket-no {
            border-top-left-radius: 30px;

          }
        }
      }

      .ticket-wrapper.animate {
        animation: shake-vertical 1.5s ease-in-out 1 both;
      }

      @keyframes shake-vertical {

        0%,
        100% {
          transform: translateY(0);
        }

        10%,
        30%,
        50%,
        70% {
          transform: translateY(4px);
        }

        20%,
        40%,
        60% {
          transform: translateY(0px);
        }

        80% {
          transform: translateY(2px);
        }

        90% {
          transform: translateY(0px);
        }
      }

    }
  }
}