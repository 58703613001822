/** =================== MONTSERRAT =================== **/

/** Montserrat Thin **/
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-Thin.eot");
    src: url("/fonts/montserrat/Montserrat-Thin.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-Thin.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-Thin.woff") format("woff");
}

/** Montserrat Thin-Italic  **/
@font-face {
    font-family: "Montserrat";
    font-weight: 100;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-ThinItalic.eot");
    src: url("/fonts/montserrat/Montserrat-ThinItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-ThinItalic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-ThinItalic.woff") format("woff");
}

/** Montserrat ExtraLight **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-ExtraLight.eot");
    src: url("/fonts/montserrat/Montserrat-ExtraLight.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-ExtraLight.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-ExtraLight.woff") format("woff");
}

/** Montserrat ExtraLight-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 200;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-ExtraLightItalic.eot");
    src: url("/fonts/montserrat/Montserrat-ExtraLightItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-ExtraLightItalic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Light **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-Light.eot");
    src: url("/fonts/montserrat/Montserrat-Light.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-Light.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-Light.woff") format("woff");
}

/** Montserrat Light-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 300;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-LightItalic.eot");
    src: url("/fonts/montserrat/Montserrat-LightItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-LightItalic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-LightItalic.woff") format("woff");
}

/** Montserrat Regular **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-Regular.eot");
    src: url("/fonts/montserrat/Montserrat-Regular.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-Regular.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 400;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-Italic.eot");
    src: url("/fonts/montserrat/Montserrat-Italic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-Italic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-Medium.eot");
    src: url("/fonts/montserrat/Montserrat-Medium.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-Medium.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 500;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-MediumItalic.eot");
    src: url("/fonts/montserrat/Montserrat-MediumItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-MediumItalic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-MediumItalic.woff") format("woff");
}

/** Montserrat SemiBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-SemiBold.eot");
    src: url("/fonts/montserrat/Montserrat-SemiBold.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-SemiBold.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-SemiBold.woff") format("woff");
}

/** Montserrat SemiBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 600;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-SemiBoldItalic.eot");
    src: url("/fonts/montserrat/Montserrat-SemiBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-SemiBoldItalic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Bold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-Bold.eot");
    src: url("/fonts/montserrat/Montserrat-Bold.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-Bold.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-Bold.woff") format("woff");
}

/** Montserrat Bold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 700;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-BoldItalic.eot");
    src: url("/fonts/montserrat/Montserrat-BoldItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-BoldItalic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-BoldItalic.woff") format("woff");
}

/** Montserrat ExtraBold **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-ExtraBold.eot");
    src: url("/fonts/montserrat/Montserrat-ExtraBold.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-ExtraBold.woff") format("woff");
}

/** Montserrat ExtraBold-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 800;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-ExtraBoldItalic.eot");
    src: url("/fonts/montserrat/Montserrat-ExtraBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-ExtraBoldItalic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Black **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: normal;
    src: url("/fonts/montserrat/Montserrat-Black.eot");
    src: url("/fonts/montserrat/Montserrat-Black.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-Black.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-Black.woff") format("woff");
}

/** Montserrat Black-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: italic;
    src: url("/fonts/montserrat/Montserrat-BlackItalic.eot");
    src: url("/fonts/montserrat/Montserrat-BlackItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/Montserrat-BlackItalic.woff2") format("woff2"),
         url("/fonts/montserrat/Montserrat-BlackItalic.woff") format("woff");
}

/** =================== MONTSERRAT ALTERNATES =================== **/

/** Montserrat Alternates Thin **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 100;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-Thin.eot");
    src: url("/fonts/montserrat/MontserratAlternates-Thin.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-Thin.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-Thin.woff") format("woff");
}

/** Montserrat Alternates Thin-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 100;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-ThinItalic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-ThinItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-ThinItalic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-ThinItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraLight **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 200;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-ExtraLight.eot");
    src: url("/fonts/montserrat/MontserratAlternates-ExtraLight.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-ExtraLight.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-ExtraLight.woff") format("woff");
}

/** Montserrat Alternates ExtraLight-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 200;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-ExtraLightItalic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-ExtraLightItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-ExtraLightItalic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-ExtraLightItalic.woff") format("woff");
}

/** Montserrat Alternates Light **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 300;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-Light.eot");
    src: url("/fonts/montserrat/MontserratAlternates-Light.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-Light.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-Light.woff") format("woff");
}

/** Montserrat Alternates Light-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 300;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-LightItalic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-LightItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-LightItalic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-LightItalic.woff") format("woff");
}

/** Montserrat Alternates Regular **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 400;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-Regular.eot");
    src: url("/fonts/montserrat/MontserratAlternates-Regular.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-Regular.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-Regular.woff") format("woff");
}

/** Montserrat Alternates Regular-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 400;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-Italic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-Italic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-Italic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-Italic.woff") format("woff");
}

/** Montserrat Alternates Medium **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-Medium.eot");
    src: url("/fonts/montserrat/MontserratAlternates-Medium.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-Medium.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-Medium.woff") format("woff");
}

/** Montserrat Alternates Medium-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 500;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-MediumItalic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-MediumItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-MediumItalic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-MediumItalic.woff") format("woff");
}

/** Montserrat Alternates SemiBold **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 600;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-SemiBold.eot");
    src: url("/fonts/montserrat/MontserratAlternates-SemiBold.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-SemiBold.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-SemiBold.woff") format("woff");
}

/** Montserrat Alternates SemiBold-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 600;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-SemiBoldItalic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-SemiBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-SemiBoldItalic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-SemiBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Bold **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 700;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-Bold.eot");
    src: url("/fonts/montserrat/MontserratAlternates-Bold.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-Bold.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-Bold.woff") format("woff");
}

/** Montserrat Alternates Bold-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 700;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-BoldItalic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-BoldItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-BoldItalic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-BoldItalic.woff") format("woff");
}

/** Montserrat Alternates ExtraBold **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 800;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-ExtraBold.eot");
    src: url("/fonts/montserrat/MontserratAlternates-ExtraBold.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-ExtraBold.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-ExtraBold.woff") format("woff");
}

/** Montserrat Alternates ExtraBold-Italic **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 800;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-ExtraBoldItalic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-ExtraBoldItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-ExtraBoldItalic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-ExtraBoldItalic.woff") format("woff");
}

/** Montserrat Alternates Black **/
@font-face {
    font-family: "Montserrat Alternates";
    font-weight: 900;
    font-style: normal;
    src: url("/fonts/montserrat/MontserratAlternates-Black.eot");
    src: url("/fonts/montserrat/MontserratAlternates-Black.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-Black.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-Black.woff") format("woff");
}

/** Montserrat Alternates Black-Italic **/
@font-face {
    font-family: "Montserrat";
    font-weight: 900;
    font-style: italic;
    src: url("/fonts/montserrat/MontserratAlternates-BlackItalic.eot");
    src: url("/fonts/montserrat/MontserratAlternates-BlackItalic.eot?#iefix") format('embedded-opentype'),
         url("/fonts/montserrat/MontserratAlternates-BlackItalic.woff2") format("woff2"),
         url("/fonts/montserrat/MontserratAlternates-BlackItalic.woff") format("woff");
}