@import './../../../../variables.scss';

#accountPage {
    .playerDetails {
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 60px;

        h1 {
            color: $black;
            font-weight: bold;
            font-size: 24px;
            text-transform: capitalize;
            margin: 0;
            padding: 0;
            padding-bottom: 10px;
            padding-left: 10px;
        }
    }

    .editFields {
        padding-left: 3rem;
        padding-right: 3rem;
        padding-bottom: 6rem;

        .icon {
            padding-top: 5px;
        }

        .IDcardIcon {
            height: 34px;

            .cls-1 {
                fill: $iconGray;
                fill-rule:evenodd;
            }
        }

        .PasswordIcon {
            padding-right: 12px;
        }

        .PasswordIcon,
        .MobilePhoneIcon,
        .PinAddressIcon,
        .FlagLangIcon {
            height: 40px;

            .cls-1 {
                fill: $iconGray;
            }
        }

        .MobilePhoneIcon {
            padding-right: 15px;
        }

        .PinAddressIcon {
            padding-right: 12px;
        }

        .fieldsSupport {
            padding-left: 10px;
            padding-right: 10px;

            .row {
                border-bottom: 1px solid $iconGray;
                padding-bottom: 30px;
                padding-top: 30px;

                .fieldText {
                    text-align: left;

                    .field-label {
                        display: block;
                        color: $thirdColor;
                        font-size: 14px;
                        font-weight: bold;

                        &.red {
                            color: $redColor;

                            &:hover {
                                text-decoration: underline;
                                cursor: pointer;
                            }
                        }

                        .id-card-tooltip {
                            position: relative;
                            svg {
                                animation: wobble 1s;
                            }
                        }
                    }

                    .field-content {
                        display: block;
                        color: $black;
                        font-size: 13px;

                        &.password-circles {
                            font-size: 17px;
                        }
                    }
                }

                .button-support {
                    padding-top: 10px;

                    .edit-button {
                        color: $grayColor;
                        font-size: 13px;
                    }
                }
            }
        }
    }
}
