.winter-time-rules {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 20px);
    margin: 10px;
  }

  .dialogTitle {
    padding-top: 32px;
    padding-bottom: 0;

    .dh-logo {
      width: 65px;
      height: auto;
      display: block;
      margin: 0 auto;
    }

    .dh-text {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #558707;
      padding: 10px 0 0;
    }

    .muted {
      padding-top: 0;
      color: #909092;
      font-size: 13px;
      font-weight: normal;
    }
  }

  .dialogContent {
    font-size: 12px;
    color: #17214D;
    font-weight: bold;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 30px;

    .dg-text {
      color: #003D6A;
      font-size: 15px;
      font-weight: normal;
      text-align: center;
      margin: 45px 10px 60px;
    }

    .db-link {
      color: #939393;
      font-size: 11px;
      text-decoration: underline;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
      margin-top: 30px;
      margin-bottom: 0;
      display: block;
    }

    .dialogOK {
      width: 100%;
      padding: 13px 0;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 0;
      background: linear-gradient(90deg, #295A91 0%, #2174E0 100%);
      height: 45px;
      margin-left: 5px;
      text-transform: uppercase;
    }
  }
}