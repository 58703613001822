.component-starting_soon {
  margin: 10px auto;
  padding-bottom: 5px;
  max-width: 1528px;

  .cgl-super-header {
    margin: 0 10px;

    .category {
      color: #353540;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;
    margin-top: 10px;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 10px;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 320px;
        max-width: 370px;
        width: 100%;
        margin-right: 10px;
        cursor: pointer;
        border-radius: 7px;
        background-color: #fff;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
        border-top: 1px solid #f0f0f0;

        &.empty {
          max-width: 186px;
        }

        &>div {

          min-height: 147px;
        }


        .myBetElement {
          min-width: 70px;
          max-width: 200px;

          &>div {
            width: auto;
          }
        }


        .moreBets {
          padding-bottom: 4px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      @media screen and (min-width: 992px) {
        .cgl-item {
          min-width: 370px;
        }
      }
    }
  }
}