.jackpot-subheader {
  width: 100%;
  margin: 0 0 .5em;
  transform: translateZ(0);

  .all {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0 1em;

    .wrapper {
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      width: 330px;
      height: 135px;
      text-align: center;
      margin: 1em 18px .5em;
      padding: .5em 0;

      @media (min-width: 450px) {
        margin-bottom: 10px;
      }

      .title {
        color: #FF9F03;
        margin: .5em .1em 0 .1em;
        font-weight: 300;
        font-size: 20px;
        background: radial-gradient(#B402FF99, #B600FA00);
      }

      .counter {
        color: #fff;
        padding-top: 10px;
        font-weight: 600;
        font-size: 35px;
        display: flex;
        align-items: center;
        justify-content: center;

        .currency {
          margin-left: 10px;
        }
      }
    }

    :first-child {
      margin-left: 0;
    }

    :last-child {
      margin-right: 0;
    }
  }
}

.jackpot-subheader-lobby {
  width: 100%;

  .all {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 0;

    .wrapper {
      background-position: 50% 50%;
      background-size: 100% 100%;
      background-repeat: no-repeat;
      flex: 0 1 150px;
      text-align: center;
      margin: 0 9px;
      padding: .5em 0;
      cursor: pointer;

      .title {
        color: #FF9F03;
        margin: .5em .1em 0 .1em;
        font-weight: 300;
        font-size: 11px;
        background-size: 100% 20px;
        background-repeat: no-repeat;
      }

      .counter {
        color: #fff;
        padding-top: 4px;
        font-weight: 600;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        .odometer.odometer-theme-simplejackpot .odometer-formatting-mark {
          width: 4px;
        }

        .odometer.odometer-theme-simplejackpot .odometer-formatting-mark.odometer-radix-mark {
          width: 4px;
        }

        .currency {
          margin-left: 5px;
        }
      }
    }

    :first-child {
      margin-left: 0;
    }

    :last-child {
      margin-right: 0;
    }
  }
}