$imageWidth: 100%;
$imageHeight: auto;
$imageFeaturedHeight: auto;
.LayoutMessages p {
    //display: none;
}
.PromotionsScreenContent{
    color:#000;
    max-width: 1280px;
    margin: 0 auto;
    > div {
        display: grid;
        grid-gap: 1rem;
    }
    .otherPromotions {
        display: grid;
        grid-template-columns: repeat(12,1fr);
        grid-gap: 1rem;
    }
    .promotion-card {
        position: relative;
        background: white;
        border-radius: .5rem;
        overflow: hidden;
        &:nth-child(5n) {
            grid-column: 1 / 5;
        }
        &:nth-child(5n+1) {
            grid-column: 1 / 5;
        }
        &:nth-child(5n+2) {
            grid-column: 5 / 9;
        }
        &:nth-child(5n+3) {
            grid-column: 9 / 13;
        }
        &:nth-child(5n+4) {
            grid-column: 1 / 7;
        }
        &:nth-child(5n+5) {
            grid-column: 7 / 13;
        }
        box-shadow: 0 0 3px 0 rgba(0,0,0,.3);
        .top {
            position: relative;
            .DinamicImageContainer {
                width: $imageWidth;
                height: $imageHeight;
                min-height: 13rem;
                position: relative;
                display: block;
                margin-bottom: -.5rem;
                &.Loading {
                    border-bottom: 1px solid rgba(0,0,0,.2);
                    img {
                        display: none;
                    }
                }
            }
        }
        &:hover {
            .top {
                cursor: pointer;
            }
        }

        .CountdownScreen {
            margin-top: -3.2rem;
            position: relative;
            z-index: 1;
            background: rgba(255,255,255,.2);
            padding: .5rem;
            height: 2.2rem;
            .CountdownTimer {
                float: right;
                margin-top: 0.125rem;
            }
        }

        .short-description {
            display: none;
        }
        &.big {
            .content {
                .top {
                    width: 60%;
                    float: left;
                }
            }
            .short-description {
                display: block;
            }
            .bottom {
                width: 40%;
                float: right;
                height: auto;
                & > * {
                    padding: 2rem;
                }
                .short-description {
                    padding: 0 2rem;
                    text-align: left;
                    font-weight: bold;
                    font-size: 1rem;
                    color: #666666;
                }
                h2 {
                    font-size: 2rem;
                    padding-top: .5rem;
                    line-height: 1.25;
                    height: auto;
                    padding-bottom: 0.2rem;
                }
                .get-bonus {
                    width: calc(35% - 6rem);
                    bottom: -0.5rem;
                    right: 3rem;
                    position: absolute;

                }
            }
            &:hover {
                .bottom {
                    & > .label {
                        margin-top: auto;
                    }
                }
            }
        }
        .content {
            width: 100%;
        }
        .bottom {
            height: 8rem;
            overflow: hidden;
            & > * {
                padding: 1.5rem;
            }
            h2 {
                text-align: left;
                font-size: 1.2rem;
                padding-top: 0;
                font-weight: 600;
                height: 6rem;
                color: #333333;
                &:hover {
                    cursor: pointer;
                }
            }
            div {
                &.label {
                    text-align: left;
                    font-weight: 700;
                    font-size: .875rem;
                    padding-bottom: 0;
                    color: #9fa2a9;
                    height: 3rem;
                    transition: all .25s ease-in-out ;
                }
                &.get-bonus {
                    background: linear-gradient(90deg,#fe1c6b,#f8961d);
                    color: white;
                    width: calc(100% - 4rem);
                    margin: 2rem;
                    box-sizing: border-box;
                    font-weight: 600;
                    font-size: 1.1rem;
                    padding: 0.5rem;
                    border-radius: .25rem;
                    &:hover {
                        box-shadow: 0 0 4px 0 rgba(0,0,0,.4);
                        cursor: pointer;
                    }
                }
            }
        }
        &:hover {
            .bottom {
                & > .label{
                    margin-top: -8rem;
                }
            }
        }
    }

    .promotion-detail {
        background: white;
        overflow: hidden;
        .top {
            .label {
                position: absolute;
                top: .5rem;
                left: .5rem;
                z-index: 2;
                padding: .25rem .5rem;
                font-size: .875rem;
                color: white;
                background: rgba(0,0,0,.5);
                border-radius: .375rem;
            }
            .DinamicImageContainer {
                width: 100%;
                position: relative;
                display: block;
                img {
                    width: 100%;
                }
                &.Loading {
                    border-bottom: 1px solid rgba(0,0,0,.2);
                    height: calc(100vw * 0.3722);
                    img {
                        display: none;

                    }
                }
            }
        }

        .CountdownScreen {
            margin-top: -3.2rem;
            position: relative;
            z-index: 1;
            background: rgba(255,255,255,.2);
            padding: .5rem;
            height: 2.2rem;
            .CountdownTimer {
                float: right;
                margin-top: 0.125rem;
            }
        }

        .bottom {

            & > * {
                padding: 1rem;
            }
            h2 {
                text-align: left;
                font-size: 2rem;
            }
            .description {
                text-align: left;
                padding: 0 1rem 2rem 1rem;
                p {
                    font-weight: normal;
                    padding: .5rem 0;
                }
                ul {
                    list-style: initial;
                    padding-left: 1rem;
                    li {
                        font-weight: normal;
                    }
                }
                em {
                    font-weight: normal;
                    font-style: italic;
                }
            }
            .get-bonus {
                display: block;
                position: fixed;
                bottom: 0;
                background: linear-gradient(90deg,#fe1c6b,#f8961d);
                width: 100%;
                padding-left: 0;
                color: white;
                left: 0;
            }
        }
    }
}

.wrapper.dummy {
    background: #fff;
    width: 100%;
    border-radius: .5rem;
    height: 25rem;
    margin-bottom: 2rem;
    overflow: hidden;
}

.wrapper-cell {
    max-width: 100%;
    position: relative;
    height: 100%;
}

// Animation
@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}

.animated-background {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #F6F6F6;
    background: linear-gradient(to right, #F6F6F6 8%, #F0F0F0 18%, #F6F6F6 33%);
    background-size: 800px 104px;
    height: 96px;
    position: relative;
}

// Page Elements
.wrapper.dummy .image {
    height: 10rem;
    width: 100%;
    @extend .animated-background;
}
.wrapper.dummy {
    position: relative;
    &.small {
        width: calc((100% - 3rem) / 3 );
        margin: .5rem;
        float: left;
        max-height: 17rem;
        .text-line {
            width: calc(100% - 2rem);
            height: 1.2rem;
            margin-bottom: 0;
        }

    }
    &.medium {
        display: none;

    }
}
.wrapper.dummy.big .image {
    height: 30rem;
    width: 60%;
    float: left;
}
.wrapper.dummy.big .text-line {
    width: 35%;
    float: right;
}
.medium .image {
    height: 20rem;
}
.wrapper.dummy .text-line {
    height: 2rem;
    width: 100%;
    //  background: #F6F6F6;
    margin: 1.5rem 1rem;
    padding-bottom: 2rem;
    box-sizing: content-box;
    @extend .animated-background;
}

