.play-now-wrapper {
  margin-top: -.5rem;
  .play-now {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border: none;
    padding: .5rem 2rem;
    color: #fff !important;
    font-weight: 600;
    border-radius: 10px;
    background: #df531a;
    background: -webkit-linear-gradient(left,#df531a,#970fb7);
    background: -webkit-gradient(linear,left top,right top,from(#df531a),to(#970fb7));
    background: -o-linear-gradient(left,#df531a 0,#970fb7 100%);
    background: linear-gradient(
                    90deg,#df531a 0,#970fb7);
    text-decoration: none !important;
    transform: translateX(-10px);
    cursor: pointer;
  }
}