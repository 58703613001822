.back-header {
  position: relative;
  background-color: var(--ns-search-bg);
  border-bottom: 1px solid var(--ns-search-border-bottom-color);
  padding: 0 10px;

  .input {
    position: relative;
    color: var(--ns-search-input-color);
    height: 52px;
    padding-top: 6px;
    padding-left: 50px;
    overflow: hidden;

    .textbox {
      width: 100%;
      height: 38px;
      margin: 0;
      padding: 5px 45px 5px 10px;
      float: left;
      color: var(--ns-search-input-border-color);
      outline: 0;
      font-size: 0.8em;
      transition: right 0.4s linear;
      z-index: 1;
      border-radius: 0;
      background: #fff;
      border: 0;
      border-radius: 2px;
    }

    .textbox::placeholder {
      color: rgba(0, 0, 0, .5);
    }

    .button {
      display: block;
      position: absolute;
      right: 0;
      top: 10px;
      max-width: 3em;
      height: 32px;
      margin: 0;
      padding: 0 10px;
      background-color: transparent;
      border: 0px;
      outline: 0;
      color: #2A46AF;
      font-size: .8em;
      cursor: pointer;
      z-index: 2;
      transition: background 0.5s;

      svg {
        width: .8em;
        height: .8em;
        margin-top: 4px;
      }
    }
  }

  .controls {
    position: absolute;
    top: 0;
    margin-top: 10px;

    >span {
      display: block;
      max-width: 3em;
      height: 32px;
      margin: 0;
      padding: 0 10px;
      background-color: #fff;
      border: 1px solid #ddd;
      outline: 0;
      color: var(--ns-search-button-color);
      font-size: .8em;
      z-index: 1000;
      transition: background 0.5s;
      cursor: pointer;
      border-radius: 4px;

      >svg {
        fill: #000;
        width: .6em;
        height: .6em;
      }
    }

    >span:hover>svg {
      fill: #000;
    }

    >span>svg {
      position: relative;
      top: 8px;
      left: 3px;
    }

    >span>svg.clear-icon {
      left: 0;
    }

    >span.active>svg {
      fill: var(--ns-action-color);
    }
  }


  @media screen and (min-width: 992px) {
    .input {
      float: none;
      width: auto;
    }
  }

  @media screen and (min-width: 1440px) {
    .input {
      float: right;
      width: 175px;

      .textbox {
        width: 175px;
        position: absolute;
        right: 0
      }
    }

    .controls {
      left: 10px;
    }
  }

}

@media screen and (min-width: 1440px) {
  .back-header {
    height: 52px;
  }
}