.CodeCountdown
{
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 14px;
    color: #0071bc;
}

.CodeCountdown .ccAlertIcon
{
    height: 80px;
    float: left;
    padding-right: 15px;
    padding-top: 10px;
}

.CodeCountdown p
{
    font-size: 14px;
    line-height: 20px;
    text-align: left;
}