.component-nav-bar {
  margin: 10px 10px;
  overflow-x: auto;

  .nav-items {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
    padding-bottom: 10px;

    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      content: "";

      height: 3px;
      border-radius: 3px;
      background: #EEF0F1
    }

    .nav-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 58px;
      margin-right: 9px;
      position: relative;
      cursor: pointer;

      &.nav-brd {
        display: flex;
        align-items: center;
        overflow: visible;
        border: 0px !important;

        div {
          width: 3px;
          height: 38px;
          background-color: #EEF0F1;
          border-radius: 3px;
          position: relative;
          top: 6px;
        }
      }

      .nav-item-icon {
        height: 41px;
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: auto;
          height: 41px;
          object-fit: contain;
        }

        .tag-new {
          border-radius: 6px;
          background-color: #fff;
          border: 1px solid #FF005F;
          color: #FF005F;
          font-size: 9px;
          font-weight: bold;
          text-align: center;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          text-transform: uppercase;
          padding: 1px 5px 0;

          position: absolute;
          bottom: 0;
          right: -5px;
        }
      }

      color: #001D8A;

      .nav-item-label {
        font-size: 12px;
        text-align: center;
        font-weight: 600;
      }

      &.active {
        .nav-item-label {
          color: #FD6E00;
        }

        &::after {
          position: absolute;
          bottom: -10px;
          left: 0;
          right: 0;
          content: "";

          height: 3px;
          border-radius: 3px;
          background: #FD6E00;
          z-index: 1;
        }
      }
    }

    .nav-item:last-child {
      padding-right: 10px;
    }


  }

  @media screen and (min-width: 1528px) {
    .nav-items {
      justify-content: center;

      .nav-item {
        margin-right: 20px;
      }

      .nav-item:last-child {
        margin-right: 0px;
      }
    }
  }
}