.n-page {
  height: 100%;
  line-height: normal;
  overflow: hidden;
  color: var(--ns-body-color);
  background-color: var(--ns-body-bg);

  &.prematch {
    color: var(--ns-body-prematch-color);
    background-color: var(--ns-body-prematch-bg);
  }

  @import "header";

  img,
  svg {
    vertical-align: baseline;
  }

  .content {
    height: 100%;
    padding: 0;
    padding-top: 58px;
    padding-bottom: 0;
    color: var(--ns-content-color);
    background-color: var(--ns-content-bg);
    height: 100vh;

    .frame {
      position: relative;
      height: 100%;

      .content-wrap {
        height: 100%;
        padding-left: 50px;
        overflow: auto;
        scrollbar-color: var(--ns-scrollbar-thumb-bg) var(--ns-scrollbar-track-bg);
        scrollbar-width: thin;

        &::-webkit-scrollbar {
          width: 5px
        }

        &::-webkit-scrollbar-track {
          background: var(--ns-scrollbar-track-bg);
          border-radius: 0px
        }

        &::-webkit-scrollbar-thumb {
          background: var(--ns-scrollbar-thumb-bg);
          border-radius: 3px
        }

        .elements {
          padding: 7px;
        }
      }

      @media (min-width: 848px) {
        .content-wrap.has-sidebar {
          margin-right: 412px;
        }
      }

      @media (min-width: 992px) {
        .content-wrap.has-left-nav {
          padding-left: 214px;
        }

        .content-wrap.has-sidebar {
          margin-right: 412px;
        }
      }

      @media (min-width: 1920px) {
        .content-wrap.has-sidebar {
          margin-right: 412px;
        }
      }
    }

    @media screen and (max-width: 848px) {
      .frame.prematch .content-wrap {
        padding-left: 0;
        padding-bottom: 40px;
      }

      .frame.prematch .left-nav.expanded {
        padding-bottom: 40px;
      }
    }
  }

  @media (max-width: 847px) {
    .content {
      height: calc(100vh - 40px);
    }
  }

  .footer {
    position: relative;
    display: block;

    .frame {
      padding: 0 22px;
      border: 1px solid var(--ns-footer-border-color);
      border-top-color: #434243;
      border-bottom: 0;
      background-color: var(--ns-footer-bg-color);
      background-image: var(--ns-footer-bg-image);
      height: 35px;
      overflow: hidden;

      .menu-items {
        height: 100%;
        display: block;
        margin: 0;
        padding: 0;
        float: left;
        list-style-type: none;
        border-right: 1px solid var(--ns-footer-menu-items-border-right);

        .menu-item {
          height: 100%;
          display: block;
          float: left;
          border-left: 1px solid var(--ns-footer-menu-item-border-left);

          .menu-link {
            display: block;
            padding: 0 22px;
            border-left: 1px solid var(--ns-footer-menu-link-border-left);
            font-size: 0.75em;
            text-decoration: none;
            color: var(--ns-footer-menu-link-color);
            cursor: pointer;
            line-height: 35px;
          }

          .menu-link:hover {
            color: var(--ns-footer-menu-link-active);
          }
        }
      }
    }
  }

  .footer.fixed {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 35px;
    padding: 0;
  }

  @media (min-width: 768px) {
    .footer.fixed {
      display: block;
    }

    .content {
      //padding-bottom: 35px;
    }
  }

  .page-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-start;

    .filters {
      width: 100%;
    }

    .verify-ticket {
      width: 412px;
      min-width: 412px;
    }
  }

  .elementsTopPadding {
    padding: 7px 7px 0;
  }

  .elementsNoYPadding {
    padding: 7px 7px 0;
    height: calc(100% - 237px);
    overflow: hidden;
    overflow-y: auto;
    margin-top: -9px;

    scrollbar-color: var(--ns-scrollbar-thumb-bg) var(--ns-scrollbar-track-bg);
    scrollbar-width: thin;

    &::-webkit-scrollbar {
      width: 5px
    }

    &::-webkit-scrollbar-track {
      background: var(--ns-scrollbar-track-bg);
      border-radius: 0px
    }

    &::-webkit-scrollbar-thumb {
      background: var(--ns-scrollbar-thumb-bg);
      border-radius: 3px
    }
  }

  @media (min-width: 848px) {
    .elementsNoYPadding {
      height: calc(100% - 197px);
    }
  }

  @media (min-width:1281px) {
    .elementsNoYPadding {
      height: calc(100% - 150px);
    }
  }
}

.device-ios {
  .elementsNoYPadding {
    height: calc(100% - 257px);
  }

  @media (min-width: 848px) {
    .elementsNoYPadding {
      height: calc(100% - 217px);
    }
  }

  @media (min-width:1281px) {
    .elementsNoYPadding {
      height: calc(100% - 170px);
    }
  }
}