#Identity {
  width: 90%;
  margin: 0 auto;
  padding-top: 5rem;
  label {
    font-weight: 500;
  }
  .ColumnMessage {
    p {
      line-height: 1;
    }
  }
  .SuccessMessage{
    background-color: #d4edda;
    padding: 15px;
    border: 1px solid #c3e6cb;
    border-radius: 5px;
    margin: 0 1.5rem .5rem;
    box-sizing: border-box;

    h2{
        margin: 0;
        font-size: 15px;
        font-weight: 500;
        color: #155724;
    }
  }
}