.bets-component-winnerfunboost-progress {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;

  .bcwp-title {
    color: #586682;
    font-size: 9px;
    font-weight: 600;
    white-space: nowrap;
  }

  .bcwp-end-title {
    background: #F0F8FB;
    border: 1px solid #D2DBE7;
    border-radius: 9px;
    height: 16px;
    display: flex;
    align-items: center;
    padding: 0 7px 1px;
    color: #586682;
    font-size: 8px;
    font-weight: bold;
    white-space: nowrap;
    position: relative;
  }

  .bcwp-center {
    flex: 1 1 100%;
    position: relative;
    margin-left: 5px;
    height: 21px;

    .bcwp-bg {
      position: absolute;
      left: 0;
      right: 0;
      top: 8px;
      background: #D2DBE7;
      border-radius: 7px 0 0 7px;
      height: 5px;
    }

    .bcwp-bg-progress {
      position: absolute;
      left: 0;
      top: 8px;
      background: linear-gradient(to right, #7C7B79, #1E1E1E);
      border-radius: 7px 0 0 7px;
      height: 5px;
    }

    .bcwp-info {
      position: absolute;
      display: flex;
      align-items: center;

      .bcwp-logo {
        background: url("./assets/icon-off.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center;
        width: 18px;
        height: 21px;
        z-index: 1;
        position: relative;
        top: 1px;
        left: -2px;
      }

      .bcwp-text {
        height: 16px;
        padding-bottom: 2px;
        padding-left: 8px;
        margin-left: -15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        border: 1px solid #D2DBE7;
        background: #F0F8FB;
        border-radius: 9px;

        color: #586682;
        font-size: 10px;
        font-weight: bold;
      }
    }
  }



  &.bcwp-active-boost {
    .bcwp-center {
      .bcwp-bg-progress {
        background: linear-gradient(to right, #FFB011, #FD6130);
      }

      .bcwp-info {
        .bcwp-logo {
          background-image: url("./assets/icon-on.png");
        }

        .bcwp-text {
          border: 1px solid #FD6130;
          color: #FF7600;
        }
      }
    }

    &.bcwp-max-boost {
      .bcwp-end-title {
        background-color: #FD6130;
        border: 1px solid #FD6130;
        color: #fff;
      }

    }
  }

  &.bcwp-max-boost {
    .bcwp-end-title {
      background-color: #586682;
      border: 1px solid #586682;
      color: #fff;
    }

    .bcwp-center {
      .bcwp-info {
        .bcwp-text {
          border-right: 0;
          border-top-right-radius: 0px;
          border-bottom-right-radius: 0px;
        }
      }
    }
  }
}