
.content-step {
  padding: 4rem 2rem 2rem 2rem;
  background: white;

  .payment-method-reference-list {
    width: 100%;
    box-sizing: content-box;
    position: relative;
    li {
      background: linear-gradient(90deg,#EEF1FF 0%,#F0F3FF 25%,#F0F3FF 75%,#F0F3FF 100%);
      border: 1px solid #134CFF4D;
      font-size: 14px;
      padding: 1rem 2rem;
      font-weight: bold;
      width: 100%;
      margin-bottom: 1rem;
      box-sizing: border-box;
      cursor: pointer;
      text-align: left;
      &.selected {
        background: linear-gradient(90deg,#EEF1FF 0%,#EBEEFD 25%,#E4E8F9 75%,#D4DAED 100%);
        border: 2px solid #92A6FF;
        box-shadow: 0 3px 6px 0 #00000029;
      }
      div {
        small {
          font-size: .675rem;
          color: #a3afd5;
          display: block;
        }
      }

    }
  }

}

.stepSection,.content-step {
  .nextButtonOnline {

    background: rgba(223,85,25,1);
    background: -moz-linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
    background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(223,85,25,1)), color-stop(100%, rgba(157,20,188,1)));
    background: -webkit-linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
    background: -o-linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
    background: -ms-linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
    background: linear-gradient(60deg, rgba(223,85,25,1) 0%, rgba(157,20,188,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#df5519', endColorstr='#9d14bc', GradientType=1 );
    margin-bottom: 1rem;
    height: 50px;
    position: relative;
    span {
      color: #FFF;
    }
    .Loading {
      color: #fff;
      width: 20px !important;
      height: 20px !important;
    }
    &:disabled {
      background: #E4E8F9;
      color: #92A6FF;
      cursor: not-allowed;
    }
  }
  .nextButton {
    margin-bottom: 1rem;
    background: none;
    border: 1px solid #134CFF4D;
    height: 50px;
    position: relative;
    &:disabled {
      background: #E4E8F9;
      color: #92A6FF;
      cursor: not-allowed;
    }
    img {
      max-height: 50px;
    }
  }
  .w-100 {
    width: 100% !important;
  }
  .PlayerDataInput {
    width: 100%;
    margin-top: 1rem;
    .error {
      font-size: small;
      color: red;
      text-align: left;
    }
  }
  .buttons {
    margin-top: 3rem;
  }
}