.raffle-personal-details {
  padding-bottom: 10px;

  .link {
    line-height: 14px;
    display: block;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .raffle-checkbox-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 3rem);
    margin: 0 auto;

    .raffle-checkbox {
      &>span {
        padding-top: 0;
      }

      .MuiSvgIcon-root {
        color: #f50057 !important;
      }
    }

    .raffle-text {
      flex: 1 1 100%;
      text-align: left;

      .first-line {
        font-size: 13px;
        color: #009245;
        font-weight: bold;
        white-space: normal;
      }

      .second-line {
        font-size: 11px;
        color: #000;
        font-weight: normal;
        white-space: normal;
      }
    }
  }

  .raffle-field.first {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 3rem);
    margin: 0 auto;

    .phone {
      flex: 1 1 100%;
    }

    .edit-icon {
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        position: relative;
        left: 5px;
        top: 3px;
        width: 24px;
        height: auto;
      }

      &.grey {
        filter: grayscale(1);
        opacity: .6;
      }
    }

  }

  .closed-details {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 3rem);
    margin: 0 auto;

    .first {
      flex: 1 1 100%;

      .phone {
        font-size: 17px;
        color: #7881A6;
        text-align: left;
        font-weight: bold;
        white-space: normal;
      }

      .name {
        font-size: 11px;
        color: #7881A6;
        text-align: left;
        font-weight: bold;
        white-space: normal;
      }
    }
  }
}