.CollectionScreen {
    display: flex;
    align-items: center;
    justify-content: stretch;
    flex-grow: 1;
    background-color: #fff;
    flex-direction: column;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;

    >.Loading {
        margin: 20px;
        color: #ffb400;
        width: 20px !important;
        height: 20px !important;
    }


    .CollectionContainer {
        display: flex;
        flex-direction: column;
        margin: 50px auto;
        width: 100%;
        max-width: 1280px;

        .CollectionHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0px 15px;

            .CollectionTitle {
                text-align: left;
                color: #606060;
                font-weight: 700;
                font-size: 20px;

                span {
                    color: #888;
                    font-weight: 500;
                    font-size: 15px;
                }
            }
        }

        .ErrorContainer {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 30px;

            .ErrorTitle {
                font-size: 25px;
                margin-top: 20px;
                margin-bottom: 30px;
                font-weight: bold;
            }

            .ErrorContent {
                font-weight: 500;
                font-size: 16px;
                margin-bottom: 100px;
                text-align: left;
            }

            .BackButton {
                background-color: #007048;
                font-size: 1rem;
                color: white;
                border: 2px solid #007048;
                font-weight: 600;
                transition: all .2s ease-in;
                padding: .7rem 1.4rem;

                &:hover {
                    transform: scale(1.03);
                }
            }
        }

        .GamesContainer {
            display: grid;
            padding: 15px;
            grid-template-columns: repeat(auto-fit, minmax(calc(14rem - 20px * 1.5), 1fr));
            grid-gap: calc(20px / 1.5);

            .GameItem {
                &.FirstGame {
                    grid-column: span 2 / auto;
                    grid-row: span 2 / auto;
                }

                &.JackpotWidget {
                    grid-column: span 3 / last-col;
                    grid-row: 3 / 3;
                    grid-column-end: -1;

                    .ticker-players {
                        .top {
                            height: 61px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .CollectionScreen {
        .CollectionContainer {
            .GamesContainer {
                grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
            }
        }
    }
}