.ColumnMessage
{
    text-align: left;
}

.ColumnMessage .error
{
    color: #ff0000;
}

.ColumnMessage p
{
    margin: 0;
}