.news-popup {

  & .MuiPaper-root {
    background-color: #EDF0F0;
  }

  &.h100vh {
    height: 100vh;
  }

  &.minHeight {
    & .MuiPaper-root.MuiDialog-paper {
      min-height: calc(100vh - 64px);
      width: 1024px
    }
  }


  .news-full-page {

    .title-bar {
      position: sticky;
      top: 0;

      background: linear-gradient(90deg, #14259B 0%, #070D29 100%);
      color: #fff;
      font-family: OpenSans;
      min-height: 56px;

      .back {
        padding: 24px 23px;
        fill: #fff;
      }

      .backSvg {
        height: 17px;
        width: auto;
      }

      .title {
        font-size: 13px;
        font-family: OpenSans;
      }
    }

    .returnButton {
      flex: 1;
      position: absolute;
      left: 15px;
      padding: 12px;

      .ArrowBackIcon {
        width: 25px;
        height: auto;
        fill: #fff;
      }
    }


    .news-content {
      text-align: left;

      .news-image {
        margin-bottom: 8px;

        img {
          display: block;
          width: 100%;
        }
      }

      .news-flex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 0 10px 15px;
      }

      .news-tag {
        display: inline-block;
        border-radius: 5px;
        padding: 3px 8px;
        color: #17214D;
        background-color: #EEF0F1;
        font-size: 8px;
        font-weight: bold;
        text-transform: uppercase;
        //margin: 0 10px 8px;
      }

      .news-title {
        margin: 0 10px 8px;
        color: #22262D;
        font-size: 16px;
        font-weight: 600;
      }

      .news-text>div p {
        margin: 0 10px 13px;
        color: #22262D;
        font-size: 14px;
        font-weight: normal;
        overflow: hidden;
      }

      .news-date {
        //margin: 0 10px 20px;
        color: #22262D;
        font-size: 12px;
        font-weight: normal;
      }

      .news-button-wrapper {
        width: 100%;
        text-align: center;

        .see-more-button {
          margin: 5px auto 0px;
        }
      }
    }
  }
}