.left-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  min-width: 50px;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
  background: var(--ns-left-nav-bg);
  font-size: 0.8125em;
  z-index: 90;
  transition: width 0.5s, height 0.2s;
  scrollbar-color: var(--ns-scrollbar-thumb-bg) var(--ns-scrollbar-track-bg);
  scrollbar-width: thin;

  &.prematch {
    top: -56px;
    height: 0;
    z-index: 95;
  }

  &::-webkit-scrollbar {
    width: 5px
  }

  &::-webkit-scrollbar-track {
    background: var(--ns-scrollbar-track-bg);
    border-radius: 0px
  }

  &::-webkit-scrollbar-thumb {
    background: var(--ns-scrollbar-thumb-bg);
    border-radius: 3px
  }

  .toggle {
    display: block;
    height: 48px;
    padding: 16px;
    border-bottom: 1px solid var(--ns-left-nav-navigation-item-border-bottom);
    color: var(--ns-action-color);
    text-align: center;
    cursor: pointer;
  }

  .navigation-group {
    display: block;
    height: 30px;
    margin: 16px 0 8px 0px;
    padding: 0 8px;
    line-height: 30px;
    border-bottom: 0;
    border-left: 2px solid transparent;
    font-family: var(--ns-font-font-family-alternative);
  }

  .navigation-group:nth-of-type(1) {
    margin-top: 0 !important;
  }

  .navigation-item {
    color: var(--ns-left-nav-navigation-item-color);
    position: relative;
    min-height: 48px;
    padding: 8px;
    border-left: 2px solid #811e1e;
    border-bottom: 1px solid var(--ns-left-nav-navigation-item-border-bottom);
    outline: none;
    transition: color 0.25s linear;

    cursor: pointer;

    .icon {
      width: 100%;
      display: block;
      position: absolute;

      svg {
        width: 32px;
        height: 32px;
        fill: var(--ns-left-nav-navigation-item-color);
        transition: fill 0.25s linear;
      }

      .flag,
      .digflag2 {
        transform-origin: 0 -3px;
        transform: translate(0px, -1px) scale(.8);
      }
    }

    .label {
      display: block;
      max-width: 65%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--ns-left-nav-label-color);
    }

    &.full {
      .label {
        max-width: 95%;
      }

      .match-count {
        display: none
      }
    }

    .icon+.label {
      padding-left: 35px;
    }

    .icon.special {
      border-radius: 2px;
      background: #fff;
      width: 16px;
      height: 16px;

      svg {
        height: 12px;
        margin-left: 2px;
      }
    }

    .match-count {
      position: absolute;
      top: 8px;
      right: 26px;
      color: var(--ns-action-color);
      padding-left: 5px;
    }

    .match-count.no-right {
      right: 10px;
    }

    &.full {
      .label {
        max-width: 95%;
      }

      .match-count {
        display: none
      }
    }

    .navigation-item-arrow {
      position: absolute;
      right: 0;
      top: 0;
      display: none;
      width: 30px;
      height: 30px;
      color: var(--ns-left-nav-label-color);
      font-size: 0.8em;
      font-weight: 400 !important;
      text-align: center;
      line-height: 30px;
      padding-top: 8px;

      svg {
        opacity: .8;
        width: .8em;
        height: .8em;
      }
    }

    &.favorite {
      .icon {
        svg {
          fill: #FDC138;
        }
      }

      .label,
      .match-count {
        color: #FDC138;
      }
    }

  }

  .navigation-item.category {
    border-bottom-color: transparent;
    background: var(--ns-left-nav-category-bg);
  }

  .navigation-item.category.active .label {
    color: var(--ns-action-color);
  }

  .navigation-item.tournament {
    border-bottom-color: transparent;
    background: var(--ns-left-nav-tournament-bg);
    padding-left: 35px;
  }

  .navigation-item.tournament.active .label {
    color: var(--ns-action-color);
  }

  .navigation-item.sport:hover {
    color: var(--ns-left-nav-navigation-item-active-color);

    svg {
      fill: var(--ns-left-nav-navigation-item-active-color);
    }
  }

  .navigation-item.sport.active {
    color: var(--ns-left-nav-navigation-item-active-color);
    background: var(--ns-left-nav-navigation-item-active-bg);

    svg {
      fill: var(--ns-left-nav-navigation-item-active-color);
    }
  }

  .new-website {
    position: relative;
    cursor: pointer;
    display: none;
    text-decoration: none;
    width: 214px;
    height: 118px;
    margin-bottom: 10px;
    display: none;

    &.live {
      margin-bottom: 0;
    }

    img {
      width: 214px;
    }

    .new-website-link {
      color: #fff;
      text-decoration: underline;
      position: absolute;
      white-space: nowrap;
      bottom: 10px;
      right: 5px;
      font-size: 13px;
      font-weight: 600;
    }
  }
}

.left-nav.expanded {
  width: 214px;
}

.left-nav.prematch.expanded {
  width: 100%;
  height: calc(100% + 19px);
}

@media screen and (min-width: 849px) {
  .left-nav.prematch {
    top: 0;
    height: 100%;
  }
}

@media screen and (max-width: 991px) {
  .left-nav:not(.expanded) {
    .navigation-item {

      .label,
      .match-count {
        display: none;
      }
    }

    .navigation-item.category,
    .navigation-item.tournament {
      display: none;
    }

    .navigation-group {
      display: none;
    }
  }
}

.left-nav.expanded {
  .navigation-item {
    min-height: 35px;
    padding: 8px 0 0;
    border-left: 0;

    .icon {
      width: 13px;
      margin-right: 10px;
      margin-left: 10px;
      top: 10px;

      svg {
        width: 13px;
        height: 13px;
      }

      .flag {
        transform: scale(.5);
      }
    }
  }

  .navigation-item.tournament {
    padding-left: 35px;
  }

  .navigation-item.active .navigation-item-arrow,
  .navigation-item:hover .navigation-item-arrow {
    display: block;
  }

  @media (hover: none) {
    .navigation-item .navigation-item-arrow {
      display: block;
    }
  }
}

@media (min-width: 992px) {
  .left-nav {
    width: 214px;
    min-width: 214px;
  }

  .left-nav {
    .navigation-item {
      min-height: 35px;
      padding: 8px 0 0;
      border-left: 0;

      .icon {
        width: 13px;
        margin-right: 10px;
        margin-left: 10px;
        top: 10px;

        svg {
          width: 13px;
          height: 13px;
        }

        .flag {
          transform: scale(.5);
        }
      }
    }

    .navigation-item.tournament {
      padding-left: 35px;
    }

    .navigation-item.active .navigation-item-arrow,
    .navigation-item:hover .navigation-item-arrow {
      display: block;
    }

    .new-website {
      display: block;
    }
  }
}

.navigation-group.navigation-group-1 {
  border-left-color: var(--ns-navigation-group-1);
}

.navigation-group.navigation-group-2 {
  border-left-color: var(--ns-navigation-group-2);
}

.navigation-group.navigation-group-3 {
  border-left-color: var(--ns-navigation-group-3);
}

.navigation-group.navigation-group-4 {
  border-left-color: var(--ns-navigation-group-4);
}

.left-nav {
  .table {
    display: none;
    font-size: 16px;

    .table-header {
      height: 34px;
      line-height: 24px;
      padding: 5px;
      padding-left: 10px;
      border-bottom: 1px solid var(--ns-left-nav-table-header-border-color);
      color: var(--ns-left-nav-table-header-color);
      overflow: hidden;
      text-transform: none;

      .table-title {
        font-weight: 300;
        font-size: 1em;
        text-align: left;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .table-row {
      background: var(--ns-left-nav-table-row-bg);
      border-bottom: 1px solid var(--ns-left-nav-table-row-border-color);
      cursor: pointer;

      .match-item {
        position: relative;
        display: block;
        border: 0;
        border-left: 1px solid var(--ns-left-nav-match-item-color);
        font-size: 0.75em;
        text-decoration: none;
        color: var(--ns-left-nav-match-item-color);

        &.active {
          background-color: var(--ns-left-nav-match-item-active-bg);
          border-color: var(--ns-left-nav-match-item-active-border-color);
        }
      }

      .match-item-time {
        padding-left: 30px;
      }

      .match-time {
        position: absolute;
        left: 5px;
        top: 5px;
        font-weight: 700;
        font-size: 11px;
        color: var(--ns-left-nav-match-time-color);
      }

      .match-favorite {
        display: none;
        position: absolute;
        left: 10px;
        bottom: 7px;
        color: #898989;
        cursor: pointer;
        line-height: 1;

        svg {
          width: auto;
          height: 16px;
          vertical-align: middle;
        }
      }

      .match-favorite.active {
        display: block;
        color: var(--ns-left-nav-match-time-color);
      }

      .match-title {
        width: 100%;
        padding: 5px 10px;
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--ns-left-nav-match-title-color);

        .match-team {
          position: relative;
          width: 100%;
          padding-right: 10%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      .match-result {
        padding: 5px 10px;
        padding-top: 0;
        line-height: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: var(--ns-left-nav-match-result-color);
        font-weight: 700;
      }

      .match-locked {
        position: absolute;
        right: 10px;
        bottom: 5px;
        color: #898989;
        line-height: 1;

        svg {
          width: auto;
          height: 14px;
          vertical-align: middle;
        }
      }

      @media screen and (min-width: 992px) {
        .match-item:hover .match-favorite {
          display: block;
        }
      }
    }
  }

  &.expanded .navigation {
    display: none;
  }

  &.expanded .table {
    display: block;
  }

  @media screen and (min-width: 992px) {
    .table {
      display: block;
    }

    .navigation {
      display: none;
    }
  }

  .h0 {
    height: 0;
  }

  .dnone {
    display: none;
  }
}

$sports-color: 1,
  2,
  3,
  4,
  5,
  6,
  7,
  9,
  10,
  11,
  12,
  13,
  16,
  17,
  19,
  20,
  21,
  22,
  23,
  26,
  29,
  31,
  32,
  37,
  34,
  43,
  44,
  46,
  61,
  109,
  110,
  111,
  117;

.left-nav {
  @each $colorNo in $sports-color {
    .sport-border-color-#{$colorNo} {
      border-left-color: var(--ns-sport-color-#{$colorNo});

      .match-item {
        border-left-color: var(--ns-sport-color-#{$colorNo}) !important;
      }

      .match-item.active {
        background-color: rgba(map-get($theme-colors, "sport-color-#{$colorNo}"), .25) !important;
        border-color: var(--ns-sport-color-#{$colorNo}) !important;
      }
    }
  }
}

.favorites-header-color {
  background-color: var(--ns-favorites-header-bg);
}

.favorites-border-color {
  border-left-color: var(--ns-favorites-header-bg);

  .match-item {
    border-left-color: var(--ns-favorites-header-bg) !important;
  }

  .match-item.active {
    background-color: rgba(map-get($theme-colors, "favorites-header-bg"), .25) !important;
    border-color: var(--ns-favorites-header-bg) !important;
  }
}


.left-nav.lvl {
  @media screen and (max-width: 991px) {
    display: none;

    &.expanded {
      display: block;
    }
  }
}

.content .frame {
  @media screen and (max-width: 991px) {
    .content-wrap.has-sidebar {
      padding-left: 0;
    }
  }
}