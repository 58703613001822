.wf-calendar {
  padding: 20px 14px 20px;

  background-color: #06084b;
  background-image: url("../assets/bg-winner-fun2@3x.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;

  .wf-mt-28 {
    margin-top: 28px;
  }

  .wf-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    .wf-title {
      color: #fff;
      text-align: left;
      font-size: 13px;
      font-weight: bold;
      line-height: 13px;
    }
  }

  .wf-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    .wf-tab {
      font-size: 10px;
      font-weight: bold;
      opacity: .5;
      text-align: left;
      color: #fff;
      margin-right: 20px;
      cursor: pointer;
    }

    .wf-tab.wf-active {
      opacity: 1;
    }
  }

  .wf-body {
    margin-bottom: 100px;
    border-radius: 7px;
    background-color: #fff;
    padding: 10px 10px 0;

    .wf-calendar-event {
      margin-bottom: 10px;


      .wf-calendar-header {
        background-image: url("../assets/img-bg-season-title@3x.png");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100% 100%;

        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        height: 50px;
        border-radius: 7px;
        padding: 0 20px;
        margin-bottom: 10px;

        .wf-title {
          font-size: 20px;
          font-weight: 600;
          text-align: left;
          color: #fff;
        }

        .wf-subtitle {
          font-size: 12px;
          font-weight: normal;
          text-align: left;
          color: #fff;
        }
      }

      .wf-content {
        display: flex;
        align-items: flex-start;
        justify-content: stretch;
        border-bottom: 1px solid #EEEEEE;
        margin-bottom: 20px;

        &:last-child {
          border-bottom: 0px;
        }

        .wf-icon {
          width: 61px;
          max-width: 61px;
          min-width: 61px;
          padding: 10px 0 0;

          img {
            display: block;
            width: 42px;
            height: auto;
            margin: 0 auto;
          }
        }

        .wf-info {
          width: 100%;

          .wf-summary {
            margin-right: 10px;

            .wf-title {
              font-size: 12px;
              font-weight: bold;
              text-align: left;
              color: #17214D;
            }

            .wf-subtitle {
              font-size: 10px;
              font-weight: normal;
              text-align: left;
              color: #979DAB;
            }

            .wf-days {
              background-image: url("../assets/calendar-bg.png");
              background-repeat: no-repeat;
              background-position: center center;
              background-size: 100% 100%;
              height: 41px;
              width: 31px;

              color: #3D4045;
              font-size: 9px;
              line-height: 9px;
              font-weight: bold;
              text-align: center;
              margin-right: 5px;

              .wf-mt {
                margin-top: 5px;
                font-size: 12px;
                line-height: 12px;
                margin-bottom: 1px;
              }
            }

            .wf-period {
              color: #17214D;
              font-size: 10px;
              line-height: 10px;
              font-weight: bold;

              & * {
                text-align: left;
              }
            }
          }

          .wf-soon {
            margin: 10px 0;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 0 10px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            background: linear-gradient(to right, #FF131B, #3846F7, #141E98, #141E98);
            font-size: 10px;
            font-weight: 600;
            color: #fff;
            position: relative;

            &:after {
              position: absolute;
              content: '';
              width: 0;
              height: 0;
              right: 0;
              top: 0;
              bottom: 0;
              border-top: 10px solid transparent;
              border-bottom: 10px solid transparent;
              border-right: 10px solid #fff;
            }
          }

          .wf-progress {
            margin: 10px 0;

            .MuiLinearProgress-colorPrimary {
              background-color: #D6DCE2;
              border-radius: 3px;
            }

            .MuiLinearProgress-barColorPrimary {
              border-radius: 3px;
              background: linear-gradient(to right, #F98D00, #FFCA1E);
            }

            &.wf-ended {
              .MuiLinearProgress-barColorPrimary {
                background: #99D2B0;
              }

            }
          }

          .wf-ranking {
            margin-bottom: 10px;

            .wf-label {
              font-size: 10px;
              font-weight: normal;
              text-align: left;
              color: #979DAB;
            }

            .wf-value {
              font-size: 18px;
              font-weight: 600;
              text-align: left;
              color: #17214D;
            }
          }

          .wf-title,
          .wf-description {
            font-weight: 600;
            font-size: 12px;
            line-height: 12px;
            color: #000;
            text-align: left;
          }

          .wf-description {
            font-weight: normal;
            margin-bottom: 30px;
          }

          .wf-description.no-m {
            margin-bottom: 0;
          }

          .wf-button {
            cursor: pointer;
            border-radius: 7px;
            background: linear-gradient(to right, #2A18C9, #4053FF);
            display: flex;
            align-items: center;
            padding: 0 16px;
            font-size: 11px;
            font-weight: 600;
            color: #fff;
            margin-top: 5px;
            text-transform: uppercase;
            height: 28px;
            margin-bottom: 30px;
          }
        }

        &:last-child {
          border-bottom: 0px;

          .wf-info {

            .wf-description {
              margin-bottom: 20px;
            }

            .wf-button {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
  }
}