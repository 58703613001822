.winner-loader {
  position: relative;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  display: flex;
  margin: 10px auto;

  .nl-arrange-center {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .nl-top-title {
      font-size: 7px;
      font-weight: bolder;
      text-transform: uppercase;
      color: #979DAB;
    }

    .nl-logo {
      img {
        width: 47px;
        height: 47px;
        vertical-align: middle;
      }
    }

    .nl-bottom-title {
      height: 8px;
      line-height: 8px;

      svg {
        width: 47px;
        vertical-align: middle;
      }
    }
  }

  .nl-circular-progress {
    position: absolute;
    width: 120px;
    height: 120px;
    top: 0;
    color: #EDF0F0;
  }

  &.nsoft-live {
    .nl-circular-progress {
      color: #222730;
    }

    .nl-top-title {
      color: #d7deec
    }

    .nl-bottom-title {
      svg {
        path {
          fill: #fff
        }
      }
    }
  }
}