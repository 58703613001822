.notifications-settings {
  .notification-row {
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    justify-content: space-between;

    .row-icon {
      svg {
        width: 50px;
        height: auto;
      }
    }

    .row-content {
      min-width: 0;
      flex: 1 1 auto;
      padding: 0 10px;

      .row-title {
        color: #151D26;
        font-size: 15px;
        font-weight: 600;
        text-align: left;
      }

      .row-text {
        color: #707880;
        font-size: 12px;
        font-weight: normal;
        text-align: left;
      }
    }
  }

  .row-sep {
    height: 2px;
    background-color: #ECEEF0;
    margin: 15px 0 10px 60px;
  }
}