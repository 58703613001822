.component-labels {
  margin: 10px auto;
  max-width: 1528px;

  .cgl-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 10px;

    .cgl-item {
      margin: 5px;
      min-width: 164px;
      max-width: 164px;
      height: 45px;
      border-radius: 5px;
      overflow: hidden;
      cursor: pointer;

      color: #FFFFFF;
      background-color: #ccc;
      font-size: calc(12px + (18 - 12) * ((100vw - 360px) / (1528 - 360)));
      font-weight: bold;
      text-align: center;

      display: flex;
      align-items: center;
      justify-content: flex-start;

      img {
        display: block;
        width: 26px;
        height: 26px;
        object-fit: contain;
        margin-left: 21px;
        margin-right: 14px;
      }

      &:last-child {
        margin-right: 0px;
      }

      &.empty {
        color: #000;
      }
    }
  }

  @media screen and (min-width:992px) {
    .cgl-list {
      flex-wrap: nowrap;

      .cgl-item {
        max-width: unset;
        width: 100%;
        height: 74px;

        img {
          width: 35px;
          height: 35px;
        }
      }
    }
  }

}

@media screen and (min-width:1528px) {
  .component-labels {
    margin: 20px auto;

    .cgl-list {
      padding-left: 40px;
      padding-right: 40px;
    }
  }
}