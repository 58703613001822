.momentum-start-panel {
  position: fixed;
  padding: 8px 0px 16px;
  box-sizing: border-box;

  padding-top: calc(8px + var(--notch-top-offset, 0px));

  * {
    box-sizing: border-box;
  }

  z-index: 1000;

  @media (orientation: landscape) {
    left: 0;
    top: 0;
    bottom: 0;
    height: 100dvh;
    width: fit-content;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0px 8px 8px 0px;
    background: radial-gradient(122.43% 209.61% at 49.87% 10.42%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 48.76%, rgba(0, 0, 0, 0.00) 100%), #2C054C;
    box-shadow: 20px 0px 20px 0px rgba(0, 0, 0, 0.75);
  }

  @media (orientation: portrait) {
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: fit-content;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0px 0px 8px 8px;
    background: radial-gradient(122.43% 209.61% at 49.87% 10.42%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 48.76%, rgba(0, 0, 0, 0.00) 100%), #2C054C;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75);
  }


  .logo {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 80px;

    &>img {
      height: 100%;
      width: auto;
      object-fit: contain;
      display: block;
    }
  }

  .msp-center {
    margin-top: 8px;
    padding: 0px 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(255, 255, 255, 0.00);
    background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 16%, rgba(255, 255, 255, 0.05) 48.71%, rgba(255, 255, 255, 0.00) 86.1%);
    box-shadow: 0px 10px 39.1px 0px #12052E, 0px -10px 39.1px 0px rgba(18, 5, 46, 0.21);
    gap: 8px;

    .stars,
    .amount {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 4px 0px;
      gap: 4px;
      width: 100%;
    }

    .sep {
      flex: 0 0 1px;
      width: 1px;
      height: 70px;
      opacity: 0.2;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 36.43%, #FFF 100%);
    }

    .title {
      opacity: .5;
      color: #FFF;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
    }

    .icon {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
        display: block;
        object-fit: contain;
      }
    }

    .text {
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: rgba(255, 255, 255, 0.90);

      text-align: center;
      -webkit-text-stroke-width: 1;
      -webkit-text-stroke-color: #FFF;
      font-size: 26px;
      font-style: italic;
      font-weight: 600;
      line-height: 90%;
      letter-spacing: 0.52px;
      max-width: 85px;
      overflow: visible;
      text-align: center;
      white-space: nowrap;

      .smaller {
        font-size: 22px;
        letter-spacing: 0.44px;
        text-transform: lowercase;
      }
    }
  }

  .close-wrapper {
    margin-top: 16px;
    width: 100%;
    display: flex;
    justify-content: center;

    .close {
      cursor: pointer;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.10);

      &>img {
        width: 24px;
        height: 24px;
        display: block;
        object-fit: contain;
      }
    }
  }

  @media (orientation: landscape) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .msp-center {
      flex-direction: column;
      padding: 16px;
      border: 1px solid rgba(255, 255, 255, 0.00);
      background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 16%, rgba(255, 255, 255, 0.05) 48.71%, rgba(255, 255, 255, 0.00) 86.1%);
      box-shadow: 0px 10px 39.1px 0px #12052E, 0px -10px 39.1px 0px rgba(18, 5, 46, 0.21);

      .sep {
        flex: 0 0 1px;
        width: 190px;
        height: 1px;
        opacity: 0.2;
        background: linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 50%, rgba(255, 255, 255, 0.00) 100%);
      }
    }

    .close-wrapper {
      .close {
        transform: rotate(-90deg);
      }
    }
  }

  &.desktop {
    top: 67px;
    bottom: 17px;
    height: calc(100dvh - 84px);
  }
}