@import "./../../../../../variables";

.stepNewPhoneNumber {
    .step-footer {
        position: relative;

        .buttonsSupport {
            position: relative;
            top: 0;
            transition: .2s ease-in all;

            &.hide {
                top: 160px;
            }
        }

        .pm-loader {
            position: absolute;
            top: 60px;
            transition: .2s ease-in all;

            &.dontShow {
                top: -50px;
            }

            div {
                div {
                    background-color: $fourthColor;
                }
            }
        }
    }
}