@import "./../../../../variables";

$colorType: #cce3f2;
$greenColor: #07ba8a;
$grayColor: #b3b3b3;


.transactionsHistory {
    color: $firstColor;
    width: 100%;

    .header-limits {
        width: calc(100% - 4rem);
        background-color: lighten($colorType, 5);
        margin: 0 auto;
        padding-top: 3rem;
        border-radius: 5px;

        .limit-item {
            display: inline-block;
            width: 23%;
            background-color: lighten($colorType, 10);
            padding: .5rem 0;
            margin: 0 .25rem .75rem;
            cursor: pointer;
            font-weight: 500;
            color: darken($grayColor, 30);
            position: relative;
            text-transform: capitalize;

            &.active {
                color: darken($greenColor, 15);
                font-weight: 600;
                position: relative;
                background-color: white;

                &:before {
                    content: " ";
                    position: absolute;
                    bottom: -.75rem;
                    left: calc(50% - .75rem);
                    width: 0;
                    height: 0;
                    border-left: .75rem solid transparent;
                    border-right: .75rem solid transparent;
                    border-top: .75rem solid white;
                }
            }

            &.coming-soon {
                transform: translateY(-.5rem);
                line-height: .5rem;
                padding-bottom: 1.5rem;
                margin-bottom: -1rem;

                .coming-soon {
                    font-size: 9px;
                    font-weight: normal;
                    font-style: italic;
                    position: absolute;
                    left: 10%;
                    bottom: 7px;
                    height: 7px;
                    line-height: 7px;
                    display: block;
                    text-align: center;
                    width: 80%;

                    span {
                        line-height: 7px;
                        text-transform: uppercase;
                        border: 1px solid $grayColor;
                        border-radius: 20px;
                        padding: 2px 7px;
                        color: #565109;
                        font-size: 8px;
                        font-weight: normal;
                        font-style: italic;
                    }
                }
            }

        }
    }

    .transactions-content {
        color: $historyGray;

        .historyLoader {
            position: relative;

            div {
                * {
                    background-color: $thirdColor;
                }
            }
        }

        .transactionsList {
            &.emptyList {
                width: 100%;
                text-align: center;

                p {
                    text-align: center;
                    padding-top: 50px;
                    padding-bottom: 50px;
                    font-size: 14px;
                    text-transform: uppercase;
                    font-weight: 600;
                }
            }

            .historyList {
                list-style: none;
                margin: 0 30px 0 -15px;

                .TransactionTitle {
                    width: 100%;
                    margin-top: 0px;
                    text-align: left;

                    &:nth-of-type(2) {
                        margin-top: 80px;
                    }
                }

                li {
                    padding-top: 30px;
                }

                .trsDesc {
                    display: flex;
                }

                .trsIcon {
                    width: 40px;
                    height: 24px;
                    text-align: left;

                    .IconSVG {
                        height: 22px;
                        position: relative;
                        top: -2px;
                    }

                    .withdrawCoin {
                        width: 25px;

                        .smallCircle {
                            fill: #dd2d2d;
                        }

                        .bigCircle {
                            fill: #32832f;
                        }

                        .arrow {
                            fill: #32832f;
                        }
                    }

                    .depositCoin {
                        width: 25px;

                        .smallCircle {
                            fill: #32832f;
                        }

                        .bigCircle {
                            fill: #dd2d2d;
                        }

                        .arrow {
                            fill: #dd2d2d;
                        }
                    }
                }

                .historyElementR1 {
                    .trsDesc {
                        * {
                            line-height: 24px;
                            font-size: 14px;
                        }
                    }

                    .trsTimestamp {
                        line-height: 24px;
                        color: $historyBlack;
                        font-weight: 600;
                        font-size: 14px;
                    }

                    .spacer {
                        font-weight: 500;
                        padding-left: 6px;
                        padding-right: 6px;
                    }
                }

                .historyElementR2 {
                    font-size: 12px;

                    .trsTimestamp {
                        position: relative;
                        top: -5px;
                    }

                    .spacer {
                        font-weight: 500;
                        padding-left: 6px;
                        padding-right: 6px;
                    }
                }

                .historyElementR3 {

                    .taxArea {
                        font-size: 12px;
                        border-bottom: 1px solid $grayColor;
                        width: 100%;
                    }
                }

                .historyCancelledElements {
                    .cancelled-list {
                        list-style: none;
                        margin: 0;
                        padding-left: 0;

                        li {
                            padding-top: 0;
                            padding-bottom: 30px;

                            &:first-child {
                                padding-top: 6px;
                            }

                            .trsTimestamp {
                                top: 0;
                                font-size: 12px;
                                font-weight: 500;

                                .spacer {
                                    padding-left: 4px;
                                    padding-right: 4px;
                                }
                            }
                        }
                    }
                }
            }

            .success {
                color: $historyTransactionSuccess;
            }

            .pending {
                color: $historyTransactionPending;
            }

            .failed {
                color: $historyTransactionFailed;
            }

            .amount {
                font-weight: 600;

                &.positive {
                    color: $historyPositive;
                }

                &.negative {
                    color: $historyNegative;
                }
            }

            .transactionStatus {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: 600;
            }

            .spinsList {
                list-style: none;
                margin: 0 30px 0 0;

                .gameSpin {
                    display: flex;
                    width: 100%;
                    padding-top: 26px;

                    .icon {
                        padding-top: 12px;

                        .IconSVG {
                            fill: $grayColor !important;
                        }
                    }

                    .details {
                        width: 100%;
                        display: flex;
                        margin-left: 20px;
                        padding-bottom: 26px;
                        border-bottom: 1px solid $historyBottomLine;

                        .spin-win {
                            strong {
                                font-weight: 700;
                            }

                            &.positive {
                                strong {
                                    color: $historyPositive;
                                }
                            }

                            &.negative {
                                strong {
                                    color: $historyNegative;
                                }
                            }
                        }
                    }

                    .gsDetails {
                        text-align: left;

                        .gameName {
                            font-size: 16px;
                            font-weight: 500;
                        }

                        .stakeRow {
                            display: flex;
                            font-size: 13px;
                            font-weight: 500;
                        }

                        .spacer {
                            padding-left: 4px;
                            padding-right: 4px;
                        }
                    }

                    .timestamp {
                        margin-left: auto;

                        p {
                            padding: 0;
                            margin: 0;
                            text-align: right;

                            &.hour {
                                font-size: 17px;
                                color: $historyBlack;
                                font-weight: 600;
                                text-align: center;
                                letter-spacing: 0.18em;
                            }

                            &.date {
                                font-size: 12px;
                            }
                        }
                    }
                }
            }

            .fbh-show-more {
                cursor: pointer;
            }

            .downloadCsv {
                width: 100%;
                padding-top: 50px;

                a,
                div {
                    color: $thirdColor;
                    font-weight: 600;
                    cursor: pointer;

                    .dIcon {
                        padding-right: 6px;

                        .IconSVG {
                            height: 16px;
                        }

                        &.loading {
                            .IconSVG {
                                -webkit-animation: rotation 1s infinite linear;
                            }
                        }
                    }
                }
            }

            .bonusList {
                list-style: none;
                margin: 0 30px 0 -15px;

                li {
                    padding-top: 30px;
                    padding-left: 30px;
                    padding-right: 30px;
                }

                .trsDesc {
                    display: flex;
                }

                .historyElementR1 {
                    .trsDesc {
                        * {
                            line-height: 24px;
                            font-size: 14px;
                        }

                        .bName {
                            color: $historyBlack;
                            font-weight: 700;
                            font-size: 15px;
                        }
                    }

                    .trsTimestamp {
                        line-height: 24px;
                        color: $historyBlack;
                        font-weight: 600;
                        font-size: 14px;
                    }

                    .spacer {
                        font-weight: 500;
                        padding-left: 6px;
                        padding-right: 6px;
                        font-size: 16px !important;
                    }
                }

                .historyElementR2 {
                    font-size: 12px;

                    .trsTimestamp {
                        position: relative;
                        top: -5px;
                    }

                    .spacer {
                        font-weight: 500;
                        padding-left: 6px;
                        padding-right: 6px;
                    }
                }

                .specialAmount {
                    color: $magenta;
                    font-size: 15px !important;
                }

                .amount {
                    font-size: 15px;
                    font-weight: 500;
                }
            }
        }
    }

    .tcLinkArea {
        width: 100%;
        text-align: center;
        padding-top: 80px;
        padding-bottom: 60px;

        a {
            color: $thirdColor;
            font-weight: 600;
            &:hover{
                cursor: pointer;
                text-decoration: underline;
            }
        }

        .dText {
            text-transform: capitalize;
        }
    }
}

@-webkit-keyframes rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}