.bet-slip-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  height: 40px;
  width: 100vw;
  background: #0b0b0c;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #f9f9fb;
  padding-top: 2px;
  z-index: 1301;

  .ticket-no {
    background-color: #343638;
    color: #CCCCCC;
    font-size: 14px;
    height: 36px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin: 0 1px 0;
  }

  .ticket-no.active {
    background-color: #FFB433;
    color: #fff;
  }

  .ticket-no.dot:after {
    content: ' ';
    width: 5px;
    height: 5px;
    position: absolute;
    left: calc(50% - 2px);
    top: 28px;
    border-radius: 50%;
    background: #FFB433;
  }

  .ticket-no.dot.live:after {
    background: #ff196e;
  }

  .ticket-no.dot.prematch:after {
    background: #1F84E6;
  }

  .ticket-no.animate {
    animation: color-change .5s 1;
  }

  @keyframes color-change {
    0% {
      background-color: #ffb300;
    }

    20% {
      background-color: rgba(0, 0, 0, 0.75);
    }

    40% {
      background-color: #ffb300;
    }

    60% {
      background-color: rgba(0, 0, 0, 0.75);
    }

    80% {
      background-color: #ffb300;
    }

    100% {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }

}

@media (min-width: 848px) {
  .bet-slip-footer {
    display: none;
  }
}