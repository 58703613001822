.momentum-game-component-landscape {
  height: 100dvh;
  width: 140px;
  font-family: "Open Sans", OpenSans;
  font-weight: normal;
  text-align: left;
  box-sizing: border-box;
  padding: 12px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;

  position: relative;
  overflow: hidden;

  background: radial-gradient(202.04% 95.98% at 49.87% 59.79%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 45%, rgba(0, 0, 0, 0.82) 62.92%), #2C054C;

  @media (min-width: 1024px) {
    background: url("../../assets/bg-vector.png") no-repeat center bottom, radial-gradient(185.51% 82.29% at 49.87% 46.1%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 45%, rgba(0, 0, 0, 0.82) 62.92%), #2C054C;
  }

  &.desktop {
    height: calc(100dvh);
    background: url("../../assets/bg-vector.png") no-repeat center bottom, radial-gradient(185.51% 82.29% at 49.87% 46.1%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 45%, rgba(0, 0, 0, 0.82) 62.92%), #2C054C;
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75);
  }

  * {
    box-sizing: border-box;
  }

  .mom-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @media (min-width: 1024px) {
      max-height: 500px;
    }
  }

  &.d-none {
    display: none !important;
  }

  .logo-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .logo {
    width: 118px;
    height: 66px;

    img {
      width: 100%;
      height: 100%;
      display: block;
      object-fit: contain;
    }
  }


  .timer {
    margin-top: 14px;
    height: 14px;
    display: block;
    color: #FFF;
    font-size: 12px;
    font-style: italic;
    font-weight: 600;
    line-height: 14px;

    .muted {
      color: rgba(255, 255, 255, 0.35);
    }

    .expired {
      color: #ED003F;
    }
  }

  .center {
    width: 116px;
    height: 150px;
    position: relative;
    display: flex;
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: stretch;

    .value {
      width: 132px;
      display: flex;
      min-height: 65px;
      height: 65px;
      padding: 0 32px 8px 32px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 50px;
      border-top: 1px solid #1E0A44;
      background: linear-gradient(0deg, rgba(31, 5, 51, 0.00) 0%, #041132 100%);
      gap: 5px;

      .stars {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        gap: 0;
        margin-top: -22px;

        .star {
          width: 21px;
          height: 20px;
          margin: 0 0 0 -6px;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }

        .star:first-child {
          margin-left: 0;
        }
      }

      .text {
        color: rgba(255, 255, 255, 0.90);
        text-align: center;
        text-shadow: 0px 3.629px 10.614px rgba(255, 255, 255, 0.20);
        font-size: 26px;
        font-style: italic;
        font-weight: 600;
        line-height: 90%;
        letter-spacing: 0.52px;
        white-space: nowrap;
        width: 85px;
        display: flex;
        justify-content: center;
        overflow: visible;
        gap: 6px;

        .smaller {
          font-size: 22px;
          text-transform: lowercase;
        }
      }
    }

    .progress-wrapper {
      margin-top: -15px;
      width: 130px;
      height: 24px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .progress {
        width: 120px;
        height: 14px;
        border-radius: 50px;
        border: 1px solid #6916A8;
        padding: 2px;

        background: #2C024D;

        box-shadow: 0px 4px 4.3px 0px rgba(0, 0, 0, 0.56) inset;
        position: relative;

        .progress-bar {
          height: 100%;
          border-radius: 50px;
          background: linear-gradient(92deg, rgba(255, 255, 255, 0.00) 92.4%, #FFF 96.41%, rgba(255, 255, 255, 0.00) 100.42%), linear-gradient(198deg, #FFCC3F 27.22%, #FD692A 93.1%);
          box-shadow: 0.481px 8px 0px 0px rgba(242, 213, 88, 0.44) inset;
          position: relative;
          transition: width 200ms ease-in-out;

          .end-stuff {
            position: absolute;
            top: 3px;
            right: -12px;
            width: 24px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
              position: absolute;
            }

            img.se {
              width: 24px;
              height: auto;
              display: block;
            }
          }
        }

        @keyframes my-bg-animation-portrait {
          100% {
            background-position: 1320px 0;
          }
        }

        .progress-bar-shine {
          height: 6px;
          border-radius: 50px;
          position: absolute;
          top: 3px;
          left: 0;
          overflow: hidden;
          background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 1056px, rgba(255, 255, 255, .5) 1066px, rgba(255, 255, 255, 0) 1076px, transparent);
          animation: my-bg-animation-portrait 10s infinite linear;
          background-repeat: repeat;
          background-position: 0 0;
          background-size: 1320px 6px;
        }
      }

      .end-star {
        position: absolute;
        top: -8px;
        right: -4px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }

        &.op img {
          opacity: 0;
        }
      }

      .end-star.orig {
        top: 2px;
        right: 1px;
        width: 18px;
        height: 18px;
      }
    }
  }

  .progress-animation {
    pointer-events: none;
    position: absolute;
    top: -18px;
    left: -12px;
    width: 140px;
    height: 150px;
    z-index: 5;

    svg {
      width: 100%;
      height: 100%;
    }
  }

  .top-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;

    .first-line {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .info-button,
    .close-button {
      cursor: pointer;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.10);
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        color: #fff;
        width: 16px;
        height: 16px;
        display: block;
      }
    }

    .deposit-button {
      cursor: pointer;
      width: 100%;
      height: 32px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      background: #ED003F;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .overlay {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    bottom: 0;
    right: 0;
    display: block;
    opacity: 0;
    align-items: center;
    justify-content: center;
    background: rgba(42, 2, 75, 0.85);
    transition: opacity 300ms ease-in-out;
    z-index: 1;
    overflow: hidden;
  }

  .overlay-text {
    position: absolute;
    bottom: -58px;
    left: 0;
    right: 0;

    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    padding-bottom: 4px;
    transition: bottom 300ms ease-in-out;
    z-index: 1;
    background: linear-gradient(to bottom, rgba(42, 2, 75, 0), rgba(13, 14, 54, .5) 100%);

    @media (min-width: 1024px) {
      top: -408px;
      bottom: unset;
      padding-top: 350px;
      background: none;
    }
  }

  .overlay-expired-text {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -140px;
    width: 140px;

    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    padding-bottom: 4px;
    transition: bottom 300ms ease-in-out;
    z-index: 3;
    background: linear-gradient(to bottom, rgba(42, 2, 75, 0), rgba(13, 14, 54, .5) 100%);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .subtitle {
      margin-top: -60px;
      margin-bottom: 40px;
      color: #FFF;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: 18px;
      max-width: 120px;
    }

    .title {
      color: rgba(255, 255, 255, 0.90);
      text-align: center;
      text-shadow: 0px 3.629px 10.614px rgba(255, 255, 255, 0.20);
      font-size: 26px;
      font-style: italic;
      font-weight: 600;
      line-height: 90%;
      letter-spacing: 0.52px;
      margin-bottom: 12px;
    }
  }

  .overlay-warning {
    position: absolute;
    top: 0;
    bottom: 0;
    left: -140px;
    width: 140px;

    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    text-align: center;
    transition: bottom 300ms ease-in-out;
    z-index: 3;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .title {
      color: #FFCD00;

      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      line-height: 14px;
      margin-bottom: 40px;
    }

    .subtitle {
      cursor: pointer;
      border-radius: 17.5px;
      background: rgba(255, 255, 255, 0.10);
      padding: 2px 2px 2px 2px;

      gap: 2px;
      color: #D2CFDB;
      text-align: center;
      font-size: 12px;
      font-style: italic;
      font-weight: 600;
      line-height: 11px;
      margin-bottom: 3px;
      pointer-events: all;

      .info-button {
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: inline-block;
        position: relative;
        top: 4px;

        img {
          width: 12px;
          height: 12px;
          display: block;
        }

      }
    }

    @media (min-width: 1024px) {
      max-height: 490px;
    }
  }


  &.show-overlay .overlay,
  &.show-expired .overlay {
    opacity: 1;
  }

  &.show-overlay .overlay-text {
    bottom: 0;

    @media (min-width: 1024px) {
      top: 0;
      bottom: unset;
    }
  }

  &.show-expired .overlay-expired-text,
  &.show-warning .overlay-warning {
    left: 0;
  }

  &.show-expired {
    .center .value {
      z-index: 0;
    }

    .value .text {
      opacity: 0;
    }

    .timer {
      z-index: 4;
      position: relative;
      top: 0px;
    }

    .left-side .logo {
      opacity: 0;
    }
  }

  &.show-warning {
    .stars {
      opacity: 0;
    }

    .progress-wrapper {
      opacity: 0;
    }
  }

  .info-button,
  .close-button,
  .center .value {
    z-index: 2;
  }

  .close-button {
    z-index: 2;
    position: relative;
  }

}