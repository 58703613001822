.promo-winter-main-view {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding-top: calc(0px + env(safe-area-inset-top));
  padding-bottom: calc(0px + env(safe-area-inset-bottom));
  background: linear-gradient(to bottom, #582202 0%, #582202 50%, #7a3312 50%, #7a3312 100%);
  position: relative;

  .loader-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, .4);
    pointer-events: none;

    #Path_16882 {
      fill: #fff;
    }
  }

  .button-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    .days {
      margin-left: 5px;

      .MuiInput-underline:before {
        border-color: #fff;
      }

      .MuiFormLabel-root,
      .MuiSelect-root,
      .MuiSvgIcon-root.MuiSelect-icon {
        color: #fff;
      }
    }

    .pw-button {
      border-radius: 12px;
      background: linear-gradient(to right, rgba(230, 111, 31, .4) 0%, rgba(248, 21, 120, .4) 100%);
      padding: 4px;
      max-width: 220px;

      .pw-button-2 {
        border-radius: 11px;
        background: linear-gradient(to right, rgba(230, 111, 31, .75), rgba(255, 62, 146, .75));
        padding: 4px;

        .pw-button-3 {
          border-radius: 9px;
          background: linear-gradient(to right, #171B58, #3535E8);
          border: 1px solid #fff;
          height: 52px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 11px;

          font-size: 14px;
          color: #fff;
          font-weight: bold;
          text-align: center;
          white-space: nowrap;
        }

        .pw-button-4 {
          background: linear-gradient(to right, #E66F1F, #FF167B);
          padding: 0 65px;
        }

      }
    }

    &.static {
      position: static;
    }
  }

  .promo-winter-wrapper {
    width: 100%;
    height: 100%;
    min-height: 620px;
    max-height: 640px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    position: relative;

    .calendar {
      position: absolute;
      top: 68px;
      right: 5px;
    }

    .tree-wrapper {
      .globe-txt-hidden {
        pointer-events: none;
        display: none;
      }

      #current_step {
        display: none;
        cursor: pointer;
      }

      .animate-scale {
        transform-box: fill-box;
        transform-origin: center;
        animation: selector-scale 1s ease-in-out infinite alternate;
      }

      .animate-scale2 {
        transform-box: fill-box;
        transform-origin: center;
        animation: selector-scale-2 3s ease-in-out infinite alternate;
      }

      @keyframes selector-scale {
        0% {
          transform: scale(1.0);
        }

        100% {
          transform: scale(0.8);
        }
      }

      @keyframes selector-scale-2 {
        0% {
          transform: scale(1.0);
        }

        100% {
          transform: scale(1.2);
        }
      }
    }
  }

  .close-header {
    padding: 0;
    position: absolute;
    width: 29px;
    height: 29px;
    top: calc(10px + var(--notch-top-offset, 0px));
    right: 10px;
    cursor: pointer;
    z-index: 100;
  }

  .overlay {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, .72) 38.3%, rgba(0, 0, 0, .67) 76.4%, rgba(0, 0, 0, 1) 100%);

    .overlay-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .header {
        position: relative;
        text-align: center;
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        white-space: normal;
        width: 100%;
        margin-bottom: 50px;

        .calendar {
          position: absolute;
          right: 0;
          top: -25px;
        }
      }

      .content-wrapper {
        min-height: 265px;
        width: 100%;
        background-image: url(../assets/box-background.svg);
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: auto 196px;
        margin-bottom: 20px;
        position: relative;
        width: 325px;
        padding: 0 15px;
        display: flex;
        justify-content: center;

        .prize-wrapper {
          align-self: flex-start;
          border-radius: 25px;
          background: #ead49c;
          padding: 6px;
          width: 100%;

          .prize-border {
            border-radius: 18px;
            background: #F8EED4;
            padding: 2px;

            .prize-content {
              border-radius: 18px;
              border: 2px solid #E6751E;
              background: #F8EED4;
              min-height: 118px;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 10px 42px 10px 45px;
              font-size: 15px;

              h3 {
                font-size: 22px;
              }

              .ql-align-center {
                text-align: center;
              }

              .ql-align-left {
                text-align: left;
              }

              .ql-align-right {
                text-align: right;
              }

            }
          }
        }

        .decorations {
          position: absolute;
          top: -18px;
          left: 5px;
          pointer-events: none;
        }
      }

      .unlocked-text {
        font-size: 18px;
        font-weight: bold;
        color: #fff;
        white-space: normal;
        text-transform: uppercase;
        margin-bottom: 5px;
      }
    }

    .close-overlay {
      padding: 0;
      position: absolute;
      width: 29px;
      height: 29px;
      top: calc(10px + var(--notch-top-offset, 0px));
      right: 10px;
      cursor: pointer;
      z-index: 100;
    }
  }

  &.show-overlay {
    .close-header {
      display: none;
    }

    .promo-winter-wrapper {
      .calendar {
        display: none;
      }

      .button-wrapper.front {
        display: none;
      }
    }

    .overlay {
      display: flex;
    }
  }
}

.promo-winter-html {
  width: 100%;
  height: 100vh;
  background: #7a3312;

  .promo-winter {
    width: 100%;
    height: 100%;
    background: #7a3312;

    #FloatingActions {
      display: none;
    }

    #root {
      min-height: 0;
      width: 100%;
      height: 100%;

      .App {
        width: 100%;
        height: 100%;
      }

      .App .App-body {
        min-height: 0;
        width: 100%;
        height: 100%;
      }

      .App .pageContent {
        width: 100%;
        height: 100%;
      }

      .App .animatedPageContent {
        min-height: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}