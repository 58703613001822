.winter-fun-modal-choose-nickname {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 16px);
    min-width: calc(100% - 16px);
  }

  @media (min-width:400px) {
    & .MuiDialog-paperFullWidth {
      min-width: 400px;
      max-width: 400px;
    }
  }

  .wf-dialog-content {
    background-color: #fff;
    background: url(./assets/modal-collected-bg@3x.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 110% auto;
    padding: 8px 35px;
  }

  .wf-modal-header {
    margin: -20px -35px 25px;
    background-image: url("./assets/modal-nickname-topbar@3x.png");
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;


    .wf-h-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 26px;
      min-height: 48px;

      .wf-h-w-left {
        display: flex;
        flex-direction: column;

        .wf-h-w-l-title {
          font-size: 20px;
          line-height: 20px;
          color: #fff;
          font-weight: 600;
          margin-top: 5px;
        }

        .wf-h-w-l-subtitle {
          font-size: 12px;
          color: #fff;
          font-weight: normal;
          margin-bottom: 5px;
        }
      }

      .wf-h-w-right {
        padding-left: 18px;
        color: #fff;
        display: flex;
        align-items: center;

        svg {
          width: 18px;
          height: auto;
        }
      }
    }
  }

  .wf-title {
    font-size: 20px;
    color: #131727;
    font-weight: bold;
    text-align: center;
    margin-bottom: 50px;
  }

  .wf-input-wrapper {
    display: flex;
    align-items: center;
    justify-content: stretch;
    border-bottom: 1px solid #E7E9EB;
    padding-bottom: 5px;
    margin-bottom: 8px;

    .wf-icon {
      width: 20px;
      max-width: 20px;
      min-width: 20px;

      &>img {
        display: block;
        position: relative;
        top: -1px;
        left: 2px;
      }
    }

    .wf-input {
      width: 100%;

      &>input {
        font-size: 16px;
        border: 0px;
        width: 100%;
      }
    }
  }

  .wf-info {
    margin-bottom: 100px;
    display: flex;

    .wf-icon {
      margin-right: 5px;

      &>div {
        border-radius: 50%;
        width: 20px;
        height: 20px;
        background-color: #EAEDF6;
        color: #B2B5BB;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-right: 2px;
      }
    }

    .wf-text {


      font-size: 10px;
      color: #000;
      font-weight: normal;
      font-style: italic;

      .wf-op {
        opacity: .5;
      }

      .wf-error {
        font-size: 12px;
        color: #dc3545;
        font-weight: bold;
        font-style: normal;
      }

      .wf-warning {
        font-size: 12px;
        font-style: normal;
        opacity: .8;
        font-weight: bold;
      }
    }
  }

  .wf-nickname {
    height: 58px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 7px;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .84);

    .wf-nick {
      color: #131727;
      font-size: 18px;
      font-weight: bold;
    }

    .wf-description {
      color: #131727;
      font-size: 10px;
      font-weight: bold;
      opacity: .6;
    }
  }


  .wf-button-wrapper {
    display: flex;
    justify-content: center;

    .wf-button {
      cursor: pointer;
      background: linear-gradient(to right, #0A44E4, #006FFF);
      color: #fff;
      min-width: 260px;

      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding: 13px;
      border-radius: 10px;
      margin-bottom: 20px;

      .wf-loader {
        display: none;
        margin-left: 10px;
        position: relative;
        top: 2px;

        &>div {
          display: inline-block;
          width: 16px !important;
          height: 16px !important;
          color: #fff
        }
      }

      &.wf-loading {
        max-width: 160px;
        pointer-events: none;

        .wf-loader {
          display: inline-block;
        }
      }
    }

    .wf-button[disabled] {
      opacity: .8;
    }
  }

  .wf-link {
    cursor: pointer;
    margin: 0 0 20px;
    color: #6B7892;
    font-size: 12px;
    font-weight: bold;
    text-align: center;
    text-decoration: underline;
  }
}