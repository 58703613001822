.tcm-resp-table {
  margin: 10px 0;
  width: 100%;

  tbody tr {
    min-height: 19px;
  }

  tbody>tr:nth-child(even) {
    background: #eee;
  }

  td {
    padding: 2px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    svg {
      height: 15px;
      width: 15px;
      vertical-align: middle;
      position: relative;
      top: -1px
    }
  }

  th {
    background: #eee;
    padding: 4px 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px
  }
}

@media only screen and (max-width: 760px),
(min-device-width: 768px) and (max-device-width: 1024px) {
  table.tcm-resp-table {
    display: block;
  }

  /* Force table to not be like tables anymore */
  .tcm-resp-table {

    thead,
    tbody,
    th,
    td,
    tr {
      display: block;
    }

    /* Hide table headers (but not display: none;, for accessibility) */
    thead tr {
      position: absolute;
      top: -9999px;
      left: -9999px;
    }

    tr {
      border: 1px solid #ccc;
    }

    td {
      /* Behave  like a "row" */
      border: none;
      border-bottom: 1px solid #eee;
      position: relative;
      padding-left: 35%;
    }

    td:before {
      /* Now like a table header */
      position: absolute;
      /* Top/left values mimic padding */
      top: 4px;
      left: 6px;
      width: 30%;
      padding-right: 10px;
      white-space: nowrap;
    }

    /*
	Label the data
	*/
    td:nth-of-type(1):before {
      content: "Match";
    }

    td:nth-of-type(2):before {
      content: "Date/Hour";
    }

    td:nth-of-type(3):before {
      content: "Bet";
    }

    td:nth-of-type(4):before {
      content: "Outcome";
    }

    td:nth-of-type(5):before {
      content: "Odd";
    }

    td:nth-of-type(6):before {
      content: "";
    }
  }

  .tcm-resp-table.ro {
    td:nth-of-type(1):before {
      content: "Meci";
    }

    td:nth-of-type(2):before {
      content: "Data/Ora";
    }

    td:nth-of-type(3):before {
      content: "Pariu";
    }

    td:nth-of-type(4):before {
      content: "Pronostic";
    }

    td:nth-of-type(5):before {
      content: "Cota";
    }
  }
}

.retail-ticket-bonus-bets {
  background: linear-gradient(90deg, #1561a9, #d929bb, #f9a642);
  padding: .5rem;
  margin-top: .5rem;
  color: white;
  border-radius: .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .text {
    ol {
      list-style: decimal;
      padding-inline-start: 2em;
    }

    ul {
      list-style: circle;
      padding-inline-start: 2em;
    }

    strong {
      font-weight: 600;
    }

    sup {
      vertical-align: super;
      font-size: smaller;
    }

    sub {
      vertical-align: sub;
      font-size: smaller;
    }

    h1 {
      font-size: 2em;
      font-weight: bold;
    }

    h2 {
      font-size: 1.5em;
      font-weight: bold;
    }

    h3 {
      font-size: 1.17em;
      font-weight: bold;
    }

    h4 {
      font-size: 1em;
      font-weight: bold;
    }

    h5 {
      font-size: 0.83em;
      font-weight: bold;
    }

    h6 {
      font-size: 0.67em;
      font-weight: bold;
    }

    .ql-align-center {
      text-align: center;
    }

    .ql-align-right {
      text-align: right;
    }

    .ql-align-left {
      text-align: left;
    }

    .ql-align-justify {
      text-align: justify;
    }

    .ql-indent-1 {
      text-indent: 1em;
    }

    .ql-indent-2 {
      text-indent: 2em;
    }

    .ql-indent-3 {
      text-indent: 3em;
    }

    .ql-indent-4 {
      text-indent: 4em;
    }

    .ql-indent-5 {
      text-indent: 5em;
    }
  }
}