.apple-pay-button {
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain; /* Use any supported button type. */
  display: block;
  width: 100%;
  height: 60px;
  border-radius: 30px;
}

.apple-pay-button-black {
  -apple-pay-button-style: black;
}

.apple-pay-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-button-white-with-line {
  -apple-pay-button-style: white-outline;
}