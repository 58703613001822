.voucher-code {
  max-width: calc(100% - 5rem);
  margin: -2rem auto 2rem;
  section {
    display: grid;
    grid-template-columns: 90px 1fr;
    background: #F5F8FA;
    padding: .5rem;
    border-radius: .5rem;
    i {
      border: 1px solid #ffb400;
      border-radius: .5rem;
      background: #FFFFFF;
      height: 90px;
      svg {
        width: 60px;
        height: 60px;
        margin: 15px auto;
        display: block;
        fill: #ffb400;
      }
    }
    div {
      text-align: right;
      padding-top: 1.5rem;
      p {
        text-align: right;
        font-size: .825rem;
        font-weight: normal;
      }
      button {
        display: inline-block;
        align-items: center;
        box-sizing: border-box;
        border: none;
        background: linear-gradient(45deg, #0460A7 0%, #5580D6 100%) !important;
        border-radius: 5px;
        color: white;
        padding: .5rem 1rem;
        margin-top: .5rem;
        margin-right: 1rem;
        &:disabled {
          filter: grayscale(1);
        }
        span {
          text-transform: uppercase;
          font-weight: normal;
          font-size: .825rem;
        }
      }
    }
  }
}

.voucher-code-popup {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 16px);
    min-width: calc(100% - 16px);
  }

  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 16px);
  }

  @media (min-width:400px) {
    & .MuiDialog-paperFullWidth {
      min-width: 400px;
      max-width: 400px;
    }
  }

  .wf-dialog-content {
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    padding: 18px 35px 20px;
  }

  .wf-header-icon {

    width: 165px;
    height: auto;
    display: block;
    margin: 0 auto 12px;
  }

  .wf-title {
    font-size: 20px;
    color: #182598;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }
  .wf-input-body {
    margin: 6rem 0;
  }
  input {

    font-size: 1rem;
    font-weight: 400;
    border: none;
    text-align: center;
    border-bottom: 1px solid #ccc;
    color: #666;
    width: calc(100% - 32px);
    margin: 0 auto 1rem auto;
    display: block;
    &:disabled {
      background-color: transparent;
      border-bottom: none !important;
      margin-bottom: -20px;
    }
    &:focus {
      outline: none;
      border-bottom: 1px solid #ccc;
    }
  }


  .wf-button-wrapper {
    text-align: center;
    .wf-button {
      background: linear-gradient(to right, #0A44E4, #006FFF);
      color: #fff;
      min-width: 260px;
      font-size: .825rem;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding: 1.25rem;
      border-radius: 0;
      margin-bottom: 15px;
      &:disabled {
        filter: grayscale(1);
      }
    }
  }
  .error {
    text-align: center;
    color: red;
    font-weight: bold;
    font-size: .825rem;
  }
}