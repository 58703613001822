@import "variables";
$gold: #AD863F;
$goldDark: #5D4C2E;
$yellow: #FFD02D;
$white: white;
$red: #FF3838;
$brown: #322E28;
$disabledColor: #303030;
$delimiterColor: #433a2b;

$darkModeBackground: black;
$darkModePrimaryText: #F5F5F5;
$footerBackground: #13090B;
$footerTextColor: $gold;
$fontHighContrast: #B9985C;
$fontLowContrast: #806E4C;

.Eldorado:not(.bets) {
  .MainContent {
    background: $darkModeBackground;
    color: $darkModePrimaryText;
  }
  .DinamicImageContainer {
    background: $darkModeBackground;
    color: $darkModePrimaryText;
  }
  .CollectionScreen {
    background: $darkModeBackground;
    color: $darkModePrimaryText;
    .CollectionContainer {
      .CollectionHeader {
        .CollectionTitle {
          color: $fontHighContrast;

          span {
            color: $fontLowContrast;
          }
        }
      }
    }
  }
  .ProductBox {
    background: $brown;
    .BoxContent {
      .BoxTitle {
        color: $fontLowContrast;
      }
      .BoxAmount {
        color: $fontHighContrast;
        span {
          color: $fontLowContrast;
        }
        &.Withdrawals {
          color: $red;
        }
      }
    }
    .BoxIcon .ProductIcon{
      &.walletSportsbook,
      &.walletCasino,
      &.walletCasinoLive,
      &.walletVirtuals,
      &.walletLotto{
        path {
          fill: $red;
        }
      }
    }
  }
  .CollectionsScreen {
    .GamesCollection {
      .CollectionContainer {
        .CollectionHeader {
          .CollectionTitle {
            color: $fontHighContrast;
            span {
              color: $fontLowContrast;
            }
          }
          .CollectionLink {
            color: $fontHighContrast;
          }
        }
      }
    }
  }
  .rightMenu .rightMenuSupport .rightMenuContent {
    background-color: $darkModeBackground;
    .black {
      .nameRow {
        .button {
          button {
            background-color: $fontHighContrast;
            border-color: $fontHighContrast;
          }
          &:last-of-type {
            button {
              color: $fontHighContrast;
              border-color: $fontHighContrast;
            }
          }
        }
      }
    }
    .white {
      background-color: $darkModeBackground;
      ul.menuSection li a {
        background-color: $darkModeBackground;
        .ptext {
          color: $fontHighContrast;
        }
        &:hover,
        &.selected{
          background-color: $darkModeBackground;
          .icon {
            border-left-color: $fontHighContrast !important;
          }
        }
        .icon {
          .pin-address {
            path {
              fill: $fontHighContrast;
            }
            circle {
              fill: $darkModeBackground;
            }
          }
        }

      }
    }
  }
  .gray {
    background-color: $darkModeBackground;
    a {
      .ptext {
        color: $fontHighContrast;
      }
      &.selected,
      &:hover {
        .arrow {
          path {
            fill: $fontHighContrast;
          }
        }
      }
    }
  }
  .page-header {
    .page-header-top {
      .pageIcon {
        .icon {
          .transactionsHistory {
            .cls-1 {
              fill: $darkModeBackground;
            }
            .cls-2,
            .cls-3 {
              stroke: $fontHighContrast;
            }
          }
          .IconSVG {
            &.default {
              .st6 {
                fill: $fontHighContrast ;
              }
            }
          }
          .account-1,
          .wallet-1,
          .withdraw-1{
            fill: $fontHighContrast !important;
            stroke: $fontHighContrast !important;
          }
          .account-2,
          .withdraw-2 {
            fill: $darkModeBackground !important;
          }
          .account-3{
            fill: $fontHighContrast !important;
          }

        }
      }
      .pageName {
        color: $fontHighContrast !important;
      }
    }
    .pageDescription {
      .description {
        color: $fontLowContrast !important;
      }
    }
  }
  .ProfileMenu {
    ul li a:hover .icon, ul li a.selected .icon {
      border-left-color: $fontHighContrast !important;
    }
    .pmHeader {
      h1 {
        color: $fontLowContrast;
      }
    }
  }
  .header-limits {
    background-color: $goldDark !important;
    .limit-item {
      background-color: $gold !important;
      color: $goldDark !important;
      &.coming-soon {
        .coming-soon {
          span {
            color: $yellow !important;
            border-color: $yellow !important;
          }
        }
      }
      &.active {
        color: $brown !important;
        background-color: $gold !important;
        &:before {
          border-top-color: $gold !important;
        }
      }
    }
  }
  .PaymentMethods .payment-methods-content .methods-table thead th {
    color: $fontHighContrast;
  }
  .PaymentMethods .payment-methods-content .methods-table tbody td {
    color: $fontHighContrast;
  }
  .PaymentMethods .payment-methods-content .methods-table tbody tr {
    border-bottom-color: $fontLowContrast;
  }
  .AccountModal .modal-dialog .modal-content .modal-header .modal-title {
    color: white;
    border-color: $gold;
  }
  .AccountModal .modal-dialog .modal-content .modal-footer .modal-footer-body .sms-action-message {
    color: $fontHighContrast;
  }
  .AccountModal .modal-dialog .modal-content .modal-footer {
    background-color: $darkModeBackground;
    .modal-footer-bottom {
      .info-text {
        color: $fontHighContrast;
      }
    }
    .IconSVG {
      fill: $fontHighContrast;
      .st1,.st68 {
        fill: $fontHighContrast !important;
      }
      .st64 {
        fill: $darkModeBackground !important;
      }
      .st73 {
        stroke: $fontHighContrast !important;
      }
    }
  }
  #cookieInfo .content {
    background-color: $darkModeBackground;
    color: $fontHighContrast;
    header {
      background: $gold;
    }

    button {
      &.btn {
        &.btn-success {
          background-color: $gold;
          border-color: $gold;
        }
      }
    }
  }
  .PlayerProfile {

    #accountPage .editFields {
      .fieldsSupport .row {
        border-color: $fontLowContrast;
        .fieldText .field-label {
          color: $fontHighContrast;
        }
      }
      .cls-1 {
        fill: $fontHighContrast;
      }
    }
    #wallet {
      .value {
        color: $fontHighContrast;
        small {
          &.SmallDecimal {
            color: $fontLowContrast;
          }
        }
      }
      .total {
        border-bottom-color: $fontLowContrast;
        .description {
          color: $fontLowContrast;
        }
      }
      .money-widget {
        &.real {
          border-right-color: $fontLowContrast;
        }
        .title {
          color: $fontHighContrast;
        }
        svg {
          .fillMe {
            fill: $fontLowContrast;
          }
          &.green-info {
            .st1 {
              fill: $fontHighContrast !important;
            }
          }
        }
        .buttons {
          .BonusProducts,.PendingProducts {
            border-top-color: $fontLowContrast;
            .ProductItem {
              p {
                color: $fontLowContrast;
              }
            }
          }
        }
      }
      #NoBonus{
        background-color: $brown;
        color: $fontHighContrast;
      }
      .WalletButtons {
        .btn {
          background-color: $gold;
          border-color: $gold;
          color: $darkModePrimaryText !important;
          &.outer {
            background-color: transparent;
            color: $gold !important;
          }
        }
      }
    }
    .pendingWithdrawals.empty p {
      color: $fontHighContrast !important;
    }

    .transactionsHistory {

      .transactions-content {
        color: $fontHighContrast;
        .trsTimestamp {
          color: $fontLowContrast !important;
        }
      }
      .all-bonuses .bonuses-header .center {
        color: $fontLowContrast;
      }
    }
    .limit-widget {
      .details {
        .limit-type {
          color: $brown;
          background-color: $gold;
        }
      }
      .change-limit {
        .edit-btn {
          border-color: $gold;
          color: $gold;
          &:hover {
            background-color: $gold;
            color: $brown;
          }
        }
        .edit-widget {
          background-color: $brown;
          .increase,
          .decrease {
            background-color: $brown;
          }
          .buttons {
            .btn {
              &.success {
                background-color: $gold;
                border-color: $gold;
                &:hover {
                  background-color: transparent;
                  color: $gold;
                }

              }
              &.error {
                background-color: transparent;
              }
            }
          }
        }
      }
    }
    .self-exclusion {
      color: $fontLowContrast;
      .title {
        color: $fontHighContrast;
      }
      .choices {
        .exclusion-choice {
          color: $brown;
          border-color: $brown;
          &.active {
            color: $fontHighContrast;
            border-color: $fontHighContrast;
          }
        }
      }
      .btn-warning{
        background-color: $gold;
        border-color: $gold;
        &.disabled,
        &:disabled{
          background-color: $goldDark;
          color: $gold;
          border-color: $goldDark;
        }
      }
      .take-a-break-button-support a {
        color: white !important;
      }
    }

  }
  .modal-content {
    .modal-header {
      border-color: $fontLowContrast ;
      background-color: $gold !important;
    }
    .modal-content {
      color: $fontHighContrast;
    }
    .modal-body {
      background-color: $darkModeBackground !important;
    }
    .modal-footer {
      border-color: $fontLowContrast ;
    }
  }
  .TaxPopup .TaxPage {
    background-color: $darkModeBackground;
    .TaxBox {
      background-color: $brown;
    }
    .content-withdraw {
      .buttons {
        button {
          &.secondButton {
            background-color: transparent;
          }
        }
      }
    }
  }
  .SpentTimeModal .modal-content .SpentTimeModalBody .messageSupport p {
    color: $fontHighContrast;
  }
  .WithdrawModal .modal-content .WithdrawModalBody {

    .VivaScreen {
      background: $darkModeBackground ;
      .loading-screen{
        background: $darkModeBackground;
      }
      .DepositAmount {
        .BonusAmount {
          .BonusTitle {
            color: $fontHighContrast;
          }
          .BonusValue {
            color: $fontLowContrast;
          }
        }
        input {
          color: #f5f5f5;
        }
      }

    }
    .actions-fixed {
      background: $darkModeBackground;
      .MuiButton-contained:hover {
      }
    }
  }
  .WithdrawModal .modal-content .WithdrawModalBody .withdrawAmount {
    background-color: $darkModeBackground;
    color: $fontHighContrast;
    .sAmountsArea {
      .text {
        color: $fontLowContrast;
      }
      .icon {
        .IconSVG {
          .cls-1 {
            fill: $fontHighContrast;
          }
        }
      }
      .tbAmount {
        color: $fontLowContrast;
        small {
          color: $brown;
        }
      }
    }
    .sAmountButtons {
      .buttonContainer {
        button {
          color: $fontHighContrast;
          border-color: $fontHighContrast;
          &:hover,&.selected {
            background-color: $fontHighContrast;
            color: white;
          }
        }
      }
    }
    .sBigButton {
      button {
        &.btn-secondary {
          background-color: $brown;
          color: $fontLowContrast;
          border-color: $brown;
        }
      }
    }
  }
  .input-centered .input-group input, .input-centered .input-group input:focus, .input-centered .input-group input:active, .input-centered .input-group input:valid {
    background: transparent;
  }

  .faq ol {
    color: $fontHighContrast;
    li {
      &:before {
        color: $fontLowContrast;
      }
      .textContent {
        color: $fontLowContrast;
      }
    }
  }

  #reset-password {
    background-color: $darkModeBackground;
    .container {
      background-color: $darkModeBackground;
    }
    .header-wrapper {
      background-color: $gold;
    }
  }

  /*.App .WalletBonusScreen .WalletBonusContent .ActionsButtons button.DepositButton,*/
  .WalletPreviewScreen .WP-Container .WP-Secondary .ExpandLessButton/*,.WalletPreviewScreen .WP-Container .WP-Header,.App .titleBar*/
  {
    background: linear-gradient(90deg, $fontHighContrast,$goldDark) !important;
    color: white !important;
  }
  .gridSupport {
    background: $darkModeBackground;
    color: $darkModePrimaryText;
  }
  .Footer {
    background-color: $footerBackground;
    color: $footerTextColor;
    .language-select {
      color: $footerTextColor;
      div{
        &:before {
          border-color: transparent $footerTextColor transparent transparent;
        }
        span {
          &:after {
            border-color: transparent transparent transparent $footerTextColor;
          }
        }
      }
    }
    #sound-toggle {
      .fillMe {
        fill: $footerTextColor;
      }
    }
    .FooterContent {
      .PaymentMethods {
        background-color: #13090B;
        border-top: 1px solid $footerTextColor;
        border-bottom: 1px solid $footerTextColor;
        h3 {
          color: $footerTextColor;
        }
      }
    }
  }

  label {
    color: $darkModePrimaryText;
  }
  input,.MuiSelect-select{
    color: $darkModePrimaryText;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus, {
    color: $darkModePrimaryText;
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(255,255,255,.42);
  }
  .MuiButton-contained.Mui-disabled,
  .MuiButtonBase-root.default:disabled{
    background-color: $disabledColor;
    color: #c1c1c1;
    border-color: #808080;
  }
  .password-strength {
    background: $darkModeBackground;
    .header {
      background: $darkModeBackground;
    }
    ul {
      margin-left: 2rem;
    }
  }
  .registerWizard .close.registerClose path{
    fill: $fontHighContrast !important;
  }
  .registerWizard .top .title {
    color: $fontHighContrast;
  }
  .registerWizard .container {
    background: $darkModeBackground;
  }
  .registerWizard .breadcrumb {
    background-color: $darkModeBackground;
  }
  .registerWizard .breadcrumb .breadcrumbStep .icon span svg {
    background-color: $darkModeBackground;
  }
  .registerWizard .breadcrumb .breadcrumbStep .icon span svg path, .registerWizard .breadcrumb .breadcrumbStep .icon span svg circle, .registerWizard .breadcrumb .breadcrumbStep .icon span svg polygon {
    fill: $fontLowContrast;
  }
  .registerWizard .breadcrumb .line .line-content {
    background-color: $fontHighContrast;
  }
  .registerWizard .breadcrumb .breadcrumbStep.active .icon .replace-icon svg path, .registerWizard .breadcrumb .breadcrumbStep.active .icon .replace-icon svg circle, .registerWizard .breadcrumb .breadcrumbStep.active .icon .replace-icon svg polygon {
    fill: $fontHighContrast;
  }
  #register svg {
    fill: $darkModePrimaryText;
  }
  #verification .field .input-group input {
    background-color: $darkModeBackground;
    box-shadow: 0 0 2px 3px $darkModeBackground;
    color: $darkModePrimaryText;
  }
  .PageTitle div {
    h3 {
      color: $fontHighContrast;
    }
    h5 {
      color: $fontLowContrast;
    }
    .IconSVG {
      fill: $fontHighContrast;
    }
  }

  .loginWizard .usernameStep .usernameSupport svg {
    fill: $darkModePrimaryText;
  }
  .loginWizard .usernameStep .usernameSupport label,
  .loginWizard .usernameStep .usernameSupport input{
    color: $darkModePrimaryText !important;
  }
  .WalletManagementScreen .WalletTopWidget .WalletAmount .WalletViewMore .cls-1 {
    fill: $gold;
    stroke: white;
    cx: 14;
    cy: 14;
    r: 13;
  }
  .WalletManagementScreen .WalletTopWidget .WalletAmount,.WalletManagementScreen .WalletTopWidget .WalletType{
    //fill: $gold !important;
    background-color: rgba($gold,.3);
  }



  .WalletPreviewScreen .WP-Container .WP-Primary {
    background: $darkModeBackground;
  }
  .WalletPreviewScreen .WP-Container .WP-Secondary,
  .WalletPreviewScreen .WP-Container .WP-Secondary .ProductsWrapper{
    background: $darkModeBackground;
  }
  .WalletPreviewScreen .WP-Container .WP-Secondary .ProductsWrapper .ProductsSection .SectionTitle {
    color: $fontHighContrast;
  }
  .WalletPreviewScreen .WP-Container .WP-Secondary .ProductsWrapper .ProductsSection .SectionTitle::before {
    background: $fontHighContrast;
  }

  .PromotionsScreen .PromotionsScreenContent {
    background: $darkModeBackground;
    .promotion-card {
      h2 {
        color: $darkModeBackground;
      }
      .get-bonus {
        font-weight: normal;
        font-size: 1rem;
      }
    }
  }
  .PromotionsScreenContent .promotion-detail {
    background: $darkModeBackground;
  }
  a,.LinkStyle {
    color: $yellow !important;
    &.button {
      color: white !important;
    }
  }

  .promotions {
    .promotions-cards {
      padding: 0;
      .wrapper {
        padding: 1rem;
        background: transparent;
        .header {
          max-width: 80%;
          border: 1px solid #36273b;
          border-bottom: none;
          border-radius: .5rem .5rem 0 0;
          padding: 1rem 0;
          margin: 0 auto;
          .title {
            font-size: 1rem;
          }
          .subtitle {
            color: white;
          }
        }
      }
    }
  }
}
.Eldorado:not(.bets) .rightMenu .rightMenuSupport .rightMenuContent .black .nameRow .button:last-of-type button {
  color: $black;
}
.Eldorado:not(.bets) .PromotionsScreenContent .promotion-detail .bottom h2 {
  color: $fontHighContrast;
}