.registerV2 {
  background: #213348;
  min-height: 100vh;
  max-width: 100vw;
  overflow: hidden;
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
  }
  .titleBar {
    position: relative;
    z-index: 3;
  }
  .grid {
    display: grid;
    grid-template-columns: 1fr ;
    overflow: hidden;
  }
  .glow {
    background: rgb(29,124,185);
    background: radial-gradient(circle, rgba(29,124,185,1) 0%, rgba(31,66,96,0.5) 52%, rgba(32,50,71,0) 100%);
    height: 100vh;
    .actual-form {
      height: calc(100vh - 65px - 4rem);
      border-radius: 1.25rem;
      background: white;
      max-width: 30rem;
      margin: 2rem auto 0 auto;
      padding-top: 1rem;
      z-index: 2;
      position: relative;
    }
  }

  #register {
    overflow: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 65px - 14rem);
    min-height: auto;
    .space-top {
      display: none;
    }
    & > .errors {
      color: red;
      font-size: .9rem;
    }
  }
  .titleBar {
    &.big {
      .DinamicImageContainer {
        pointer-events: none;
      }
    }
  }
  .register-disabled {
    padding: 2rem 1rem 0;
  }
  .fields {
    text-align: left;
    padding: 1.5rem 1rem 0 1rem;
    .field{
      padding: .7rem 1rem;
      position: relative;
      width: 100%;
      box-sizing: border-box;
      margin-bottom: .5rem;

      .CheckBoxError {
        padding-left: 2.25rem;
      }
      .checkbox-title {
        color: #009245;
        font-size: .75em;
      }
      .checkbox-subtitle {
        font-weight: normal;
        font-size: .675rem;
      }
      &.more {
        display: block;
        padding-bottom: 0;
        z-index: 99;
        width: auto;
        float: right;
        .toggle {
          text-decoration: underline;
          text-align: right;
          font-weight: normal;
          font-size: .75rem;
          margin-top: -1rem;
          margin-bottom: -2rem;
        }
      }
      &.notifications {
        margin-bottom: -.5rem;
      }
      &.terms {
        margin-top: -1rem;
      }
      .loader {
        position: absolute;
        right: 1.5rem;
        bottom: .5rem;
        .MuiCircularProgress-root {
          width: 1.5rem !important;
          height: 1.5rem !important;
        }
      }
      & > .field {
        margin-top: -1.5rem;
        margin-bottom: 1rem;
        width: calc(100% - 1rem);
        margin-left: 1rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .more-fields {
      margin-bottom: 1rem;
      margin-top: 3rem;
      display: block;

      .field {
        padding-bottom: 0;
        margin-bottom: 0;
        &:first-child {
          margin-top: -2.3rem;
        }
        .MuiTypography-body1 {

        }
      }
    }

    .title {
      text-align: center;
      font-size: 1.5rem;
    }
    .subtitle {
      text-align: center;
      margin-bottom: 2rem;
      font-weight: normal;
    }
  }
  .loading-screen {
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    width: 100vw;
    height: 100vh;
    z-index: 2;
    .gamesLoadingProgress {
      margin-top: 50vh;
      margin-left: -2.5rem;
      left: 50%;
    }
  }
  .UsernameField {
    .messages {
      min-height: 1.25rem;
      height: auto;
      font-size: .75rem;
      p {
        margin-top: -.5rem;
        margin-bottom: .75rem;
      }
    }
    input {
      font-size: .9rem;
    }
    label {
      font-size: .9rem;
    }
    .input-centered .input-group input:focus~label, .input-centered .input-group input:valid~label {
      top: -1.25rem
    }
  }
  .passwordField {
      margin-top: 1rem;
  }
}
.register-modal {
  .dialog-content {
    .dialog-icon {
      .IconSVG {
        fill: #FF196E;
      }
    }
    .dialog-title {
      color: #FF196E;
    }
  }
}