.MuiPopover-root {
  z-index: 1303 !important;

  .MuiPickersBasePicker-container {
    .MuiPickersToolbar-toolbar {
      background: linear-gradient(90deg, #14259B 0%, #070D29 100%);

      height: 60px;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;

      .MuiTypography-h4 {
        font-size: 1.5rem;
        text-transform: capitalize;
      }
    }

    .MuiPickersCalendarHeader-switchHeader {
      .MuiPickersCalendarHeader-transitionContainer {
        .MuiTypography-body1 {
          text-transform: capitalize;
        }
      }
    }

    .MuiPickersDay-daySelected {
      color: #000;
      font-weight: 500;
      background-color: #6eecde;
      border: 1px solid #06D2BD;
    }
  }
}