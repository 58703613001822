.bets-left-nav {
  .flag {
    width: 32px;
    height: 32px;
    display: inline-block;
    background: url(../../assets/flags.png) no-repeat;
    background-size: 100%;
    overflow: hidden;
    text-indent: -9999px;
    text-align: left
  }

  .flag-abw {
    background-position: 0 0
  }

  .flag-afg {
    background-position: 0 -32px
  }

  .flag-ago {
    background-position: 0 -64px
  }

  .flag-alb {
    background-position: 0 -96px
  }

  .flag-and {
    background-position: 0 -128px
  }

  .flag-are {
    background-position: 0 -160px
  }

  .flag-arg {
    background-position: 0 -192px
  }

  .flag-arm {
    background-position: 0 -224px
  }

  .flag-ata {
    background-position: 0 -256px
  }

  .flag-atg {
    background-position: 0 -288px
  }

  .flag-aus {
    background-position: 0 -320px
  }

  .flag-aut {
    background-position: 0 -352px
  }

  .flag-aze {
    background-position: 0 -384px
  }

  .flag-bdi {
    background-position: 0 -416px
  }

  .flag-bel {
    background-position: 0 -448px
  }

  .flag-ben {
    background-position: 0 -480px
  }

  .flag-bfa {
    background-position: 0 -512px
  }

  .flag-bgd {
    background-position: 0 -544px
  }

  .flag-bgr {
    background-position: 0 -576px
  }

  .flag-bhr {
    background-position: 0 -608px
  }

  .flag-bhs {
    background-position: 0 -640px
  }

  .flag-bih {
    background-position: 0 -672px
  }

  .flag-blr {
    background-position: 0 -704px
  }

  .flag-blz {
    background-position: 0 -736px
  }

  .flag-bol {
    background-position: 0 -768px
  }

  .flag-bra {
    background-position: 0 -800px
  }

  .flag-brb {
    background-position: 0 -832px
  }

  .flag-brn {
    background-position: 0 -864px
  }

  .flag-btn {
    background-position: 0 -896px
  }

  .flag-bwa {
    background-position: 0 -928px
  }

  .flag-caf {
    background-position: 0 -960px
  }

  .flag-can {
    background-position: 0 -992px
  }

  .flag-che {
    background-position: 0 -1024px
  }

  .flag-chl {
    background-position: 0 -1056px
  }

  .flag-chn {
    background-position: 0 -1088px
  }

  .flag-civ {
    background-position: 0 -1120px
  }

  .flag-cmr {
    background-position: 0 -1152px
  }

  .flag-cod {
    background-position: 0 -1184px
  }

  .flag-cog {
    background-position: 0 -1216px
  }

  .flag-col {
    background-position: 0 -1248px
  }

  .flag-com {
    background-position: 0 -1280px
  }

  .flag-cpv {
    background-position: 0 -1312px
  }

  .flag-cri {
    background-position: 0 -1344px
  }

  .flag-cub {
    background-position: 0 -1376px
  }

  .flag-cyp {
    background-position: 0 -1408px
  }

  .flag-cze {
    background-position: 0 -1440px
  }

  .flag-deu {
    background-position: 0 -1472px
  }

  .flag-dji {
    background-position: 0 -1504px
  }

  .flag-dma {
    background-position: 0 -1536px
  }

  .flag-dnk {
    background-position: 0 -1568px
  }

  .flag-dom {
    background-position: 0 -1600px
  }

  .flag-dza {
    background-position: 0 -1632px
  }

  .flag-ecu {
    background-position: 0 -1664px
  }

  .flag-egy {
    background-position: 0 -1696px
  }

  .flag-eng {
    background-position: 0 -1728px
  }

  .flag-eri {
    background-position: 0 -1760px
  }

  .flag-esh {
    background-position: 0 -1792px
  }

  .flag-esp {
    background-position: 0 -1824px
  }

  .flag-est {
    background-position: 0 -1856px
  }

  .flag-eth {
    background-position: 0 -1888px
  }

  .flag-fin {
    background-position: 0 -1920px
  }

  .flag-fcp {
    background-position: 0 -6784px
  }

  .flag-fji {
    background-position: 0 -1952px
  }

  .flag-fra {
    background-position: 0 -1984px
  }

  .flag-fro {
    background-position: 0 -2016px
  }

  .flag-fsm {
    background-position: 0 -2048px
  }

  .flag-gab {
    background-position: 0 -2080px
  }

  .flag-gbr {
    background-position: 0 -2112px
  }

  .flag-geo {
    background-position: 0 -2144px
  }

  .flag-gha {
    background-position: 0 -2176px
  }

  .flag-gib {
    background-position: 0 -2208px
  }

  .flag-gin {
    background-position: 0 -2240px
  }

  .flag-gmb {
    background-position: 0 -2272px
  }

  .flag-gnb {
    background-position: 0 -2304px
  }

  .flag-gnq {
    background-position: 0 -2336px
  }

  .flag-grc {
    background-position: 0 -2368px
  }

  .flag-grd {
    background-position: 0 -2400px
  }

  .flag-grl {
    background-position: 0 -2432px
  }

  .flag-gtm {
    background-position: 0 -2464px
  }

  .flag-guy {
    background-position: 0 -2496px
  }

  .flag-hkg {
    background-position: 0 -2528px
  }

  .flag-hnd {
    background-position: 0 -2560px
  }

  .flag-hrv {
    background-position: 0 -2592px
  }

  .flag-hti {
    background-position: 0 -2624px
  }

  .flag-hun {
    background-position: 0 -2656px
  }

  .flag-idn {
    background-position: 0 -2688px
  }

  .flag-ind {
    background-position: 0 -2720px
  }

  .flag-irl {
    background-position: 0 -2752px
  }

  .flag-irn {
    background-position: 0 -2784px
  }

  .flag-irq {
    background-position: 0 -2816px
  }

  .flag-isl {
    background-position: 0 -2848px
  }

  .flag-isr {
    background-position: 0 -2880px
  }

  .flag-ita {
    background-position: 0 -2912px
  }

  .flag-jam {
    background-position: 0 -2944px
  }

  .flag-jor {
    background-position: 0 -2976px
  }

  .flag-jpn {
    background-position: 0 -3008px
  }

  .flag-kaz,
  .flag-kzh {
    background-position: 0 -3040px
  }

  .flag-ken {
    background-position: 0 -3072px
  }

  .flag-kgz {
    background-position: 0 -3104px
  }

  .flag-khm {
    background-position: 0 -3136px
  }

  .flag-kir {
    background-position: 0 -3168px
  }

  .flag-kna {
    background-position: 0 -3200px
  }

  .flag-kor {
    background-position: 0 -3232px
  }

  .flag-kwt {
    background-position: 0 -3264px
  }

  .flag-lao {
    background-position: 0 -3296px
  }

  .flag-lbn {
    background-position: 0 -3328px
  }

  .flag-lbr {
    background-position: 0 -3360px
  }

  .flag-lby {
    background-position: 0 -3392px
  }

  .flag-lca {
    background-position: 0 -3424px
  }

  .flag-lie {
    background-position: 0 -3456px
  }

  .flag-lka {
    background-position: 0 -3488px
  }

  .flag-lso {
    background-position: 0 -3520px
  }

  .flag-ltu {
    background-position: 0 -3552px
  }

  .flag-lux {
    background-position: 0 -3584px
  }

  .flag-lva {
    background-position: 0 -3616px
  }

  .flag-mac {
    background-position: 0 -3648px
  }

  .flag-mar {
    background-position: 0 -3680px
  }

  .flag-mco {
    background-position: 0 -3712px
  }

  .flag-mda {
    background-position: 0 -3744px
  }

  .flag-mdg {
    background-position: 0 -3776px
  }

  .flag-mdv {
    background-position: 0 -3808px
  }

  .flag-mex {
    background-position: 0 -3840px
  }

  .flag-mhl {
    background-position: 0 -3872px
  }

  .flag-mkd {
    background-position: 0 -3904px
  }

  .flag-mli {
    background-position: 0 -3936px
  }

  .flag-mlt {
    background-position: 0 -3968px
  }

  .flag-mmr {
    background-position: 0 -4000px
  }

  .flag-mne {
    background-position: 0 -4032px
  }

  .flag-mng,
  .flag-mgl {
    background-position: 0 -4064px
  }

  .flag-moz {
    background-position: 0 -4096px
  }

  .flag-mrt {
    background-position: 0 -4128px
  }

  .flag-mus {
    background-position: 0 -4160px
  }

  .flag-mwi {
    background-position: 0 -4192px
  }

  .flag-mys {
    background-position: 0 -4224px
  }

  .flag-nam {
    background-position: 0 -4256px
  }

  .flag-ner {
    background-position: 0 -4288px
  }

  .flag-nga {
    background-position: 0 -4320px
  }

  .flag-nic {
    background-position: 0 -4352px
  }

  .flag-nir {
    background-position: 0 -4384px
  }

  .flag-nld {
    background-position: 0 -4416px
  }

  .flag-nor {
    background-position: 0 -4448px
  }

  .flag-npl {
    background-position: 0 -4480px
  }

  .flag-nru {
    background-position: 0 -4512px
  }

  .flag-nzl {
    background-position: 0 -4544px
  }

  .flag-omn {
    background-position: 0 -4576px
  }

  .flag-pak {
    background-position: 0 -4608px
  }

  .flag-pan {
    background-position: 0 -4640px
  }

  .flag-per {
    background-position: 0 -4672px
  }

  .flag-phl {
    background-position: 0 -4704px
  }

  .flag-plw {
    background-position: 0 -4736px
  }

  .flag-png {
    background-position: 0 -4768px
  }

  .flag-pol {
    background-position: 0 -4800px
  }

  .flag-pri {
    background-position: 0 -4832px
  }

  .flag-prk {
    background-position: 0 -4864px
  }

  .flag-prt {
    background-position: 0 -4896px
  }

  .flag-pry {
    background-position: 0 -4928px
  }

  .flag-pse {
    background-position: 0 -4960px
  }

  .flag-qat {
    background-position: 0 -4992px
  }

  .flag-rou {
    background-position: 0 -5024px
  }

  .flag-rus {
    background-position: 0 -5056px
  }

  .flag-rwa {
    background-position: 0 -5088px
  }

  .flag-sau {
    background-position: 0 -5120px
  }

  .flag-sco {
    background-position: 0 -5152px
  }

  .flag-sdn {
    background-position: 0 -5184px
  }

  .flag-sen {
    background-position: 0 -5216px
  }

  .flag-sgp {
    background-position: 0 -5248px
  }

  .flag-slb {
    background-position: 0 -5280px
  }

  .flag-sle {
    background-position: 0 -5312px
  }

  .flag-slv {
    background-position: 0 -5344px
  }

  .flag-smr {
    background-position: 0 -5376px
  }

  .flag-som {
    background-position: 0 -5408px
  }

  .flag-srb {
    background-position: 0 -5440px
  }

  .flag-stp {
    background-position: 0 -5472px
  }

  .flag-sur {
    background-position: 0 -5504px
  }

  .flag-svk {
    background-position: 0 -5536px
  }

  .flag-svn {
    background-position: 0 -5568px
  }

  .flag-swe {
    background-position: 0 -5600px
  }

  .flag-swz {
    background-position: 0 -5632px
  }

  .flag-syc {
    background-position: 0 -5664px
  }

  .flag-syr {
    background-position: 0 -5696px
  }

  .flag-tcd {
    background-position: 0 -5728px
  }

  .flag-tgo {
    background-position: 0 -5760px
  }

  .flag-tha {
    background-position: 0 -5792px
  }

  .flag-tjk {
    background-position: 0 -5824px
  }

  .flag-tkm {
    background-position: 0 -5856px
  }

  .flag-tls {
    background-position: 0 -5888px
  }

  .flag-ton {
    background-position: 0 -5920px
  }

  .flag-tto {
    background-position: 0 -5952px
  }

  .flag-tun {
    background-position: 0 -5984px
  }

  .flag-tur {
    background-position: 0 -6016px
  }

  .flag-tuv {
    background-position: 0 -6048px
  }

  .flag-twn {
    background-position: 0 -6080px
  }

  .flag-tza {
    background-position: 0 -6112px
  }

  .flag-uga {
    background-position: 0 -6144px
  }

  .flag-ukr {
    background-position: 0 -6176px
  }

  .flag-ury {
    background-position: 0 -6208px
  }

  .flag-usa {
    background-position: 0 -6240px
  }

  .flag-uzb {
    background-position: 0 -6272px
  }

  .flag-vat {
    background-position: 0 -6304px
  }

  .flag-vct {
    background-position: 0 -6336px
  }

  .flag-ven {
    background-position: 0 -6368px
  }

  .flag-vnm {
    background-position: 0 -6400px
  }

  .flag-vut {
    background-position: 0 -6432px
  }

  .flag-wal {
    background-position: 0 -6464px
  }

  .flag-wsm {
    background-position: 0 -6496px
  }

  .flag-yem {
    background-position: 0 -6528px
  }

  .flag-zaf {
    background-position: 0 -6560px
  }

  .flag-zmb {
    background-position: 0 -6592px
  }

  .flag-zwe {
    background-position: 0 -6624px
  }

  .flag-atp {
    background-position: 0 -6656px
  }

  .flag-chg {
    background-position: 0 -6688px
  }

  .flag-dcs {
    background-position: 0 -6720px
  }

  .flag-exh {
    background-position: 0 -6752px
  }

  .flag-fed {
    background-position: 0 -6784px
  }

  .flag-hpm {
    background-position: 0 -6816px
  }

  .flag-int {
    background-position: 0 -6848px
  }

  .flag-ipt {
    background-position: 0 -6880px
  }

  .flag-itf {
    background-position: 0 -6912px
  }

  .flag-wta {
    background-position: 0 -6944px
  }

  .flag-s7 {
    background-position: 0 -6976px
  }

  .flag-s133 {
    background-position: 0 -7008px
  }

  .flag-s132 {
    background-position: 0 -7040px
  }

  .flag-s234 {
    background-position: 0 -7072px
  }

  .flag-s679 {
    background-position: 0 -7104px
  }

  .flag-s486 {
    background-position: 0 -7136px
  }

  .flag-s1 {
    background-position: 0 -7168px
  }

  .flag-s138 {
    background-position: 0 -7232px
  }

  .flag-s141 {
    background-position: 0 -7264px
  }

  .flag-s285 {
    background-position: 0 -7296px
  }

  .flag-s276,
  .flag-s432,
  .flag-s436,
  .flag-s437,
  .flag-s439,
  .flag-s440,
  .flag-s496,
  .flag-s497,
  .flag-s499,
  .flag-s548,
  .flag-s549,
  .flag-s551,
  .flag-s552,
  .flag-s553,
  .flag-s554,
  .flag-s557,
  .flag-s558,
  .flag-s559,
  .flag-s560,
  .flag-s561,
  .flag-s663,
  .flag-s564,
  .flag-s565,
  .flag-s1064,
  .flag-s1066,
  .flag-s1121,
  .flag-s1123,
  .flag-s13963,
  .flag-s13965,
  .flag-s23615 {
    background-position: 0 -7200px
  }

  .flag-s308,
  .flag-s13,
  .flag-s309,
  .flag-s295,
  .flag-s11,
  .flag-s14,
  .flag-s20434 {
    background-position: 0 -7328px
  }

  .flag-s295,
  .flag-s2043,
  .flag-s632 {
    background-position: 0 -7360px
  }

  .flag-s308,
  .flag-s28,
  .flag-s668,
  .flag-s463,
  .flag-s2152,
  .flag-s2191,
  .flag-s1890 {
    background-position: 0 -7392px
  }

  .flag-s14,
  .flag-s498,
  .flag-s16918,
  .flag-s638,
  .flag-s2114 {
    background-position: 0 -7424px
  }

  .flag-s13,
  .flag-s1054,
  .flag-s1115,
  .flag-s1250 {
    background-position: 0 -7456px
  }

  .flag-s235 {
    background-position: 0 -7490px
  }

  .flag-s14003,
  .flag-s1796,
  .flag-s935,
  .flag-s1242,
  .flag-S933,
  .flag-s1816,
  .flag-s1814,
  .flag-s1798,
  .flag-s2358,
  .flag-s943,
  .flag-s931,
  .flag-s1936 {
    background-position: 0 -7521px
  }

  .flag-s109,
  .flag-s803,
  .flag-s2456 {
    background-position: 0 -7552px
  }

  .flag-s14872,
  .flag-s31,
  .flag-s1010,
  .flag-s233 {
    background-position: 0 -7584px
  }

  .flag-s288,
  .flag-s30 {
    background-position: 0 -7616px
  }

  .flag-s1169 {
    background-position: 0 -7648px
  }

  .flag-s20004 {
    background-position: 0 -7680px
  }

  .flag-s23744,
  .flag-s20002,
  .flag-s23738 {
    background-position: 0 -7712px
  }

  .flag-s2102 {
    background-position: 0 -7744px
  }

  .flag-s16 {
    background-position: 0 -7776px
  }

  .flag-undef,
  .flag-world {
    background-position: 0 -7808px
  }

  .flag-DEF {
    background-position: 0 -7808px
  }
}