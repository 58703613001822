.wf-raffle {
  .wf-image {
    img {
      display: block;
      width: 100%;
    }
  }

  .wf-text {
    margin-top: 12px;
    font-size: 20px;
    color: #7F7FE0;
    font-weight: 600;
    text-align: center;
  }
}