@import "./../../variables";


.jackpotsPage {
    margin-top: -20px;
    text-align: center;
    background-position: left top;
    background-size: 100%;
    background-repeat: no-repeat;
    font-family: "Open Sans";

    .jackpotSpacer {
        margin-top: 20px;
    }

    .jackpotSpacer2 {
        margin-top: 10px;
    }

    .bg {
        position: relative;
        height: 0;
        overflow: visible;
        width: 100%;

        img {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
        }
    }
}