.PopupBonusDialog {
  z-index: 1304 !important;

  font-family: "Open Sans", OpenSans;

  * {
    box-sizing: border-box;
  }

  & .MuiPaper-rounded {
    border-radius: 10px;
    background-color: #E1E6E8;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 20px);
    margin: 10px;
  }

  .dialogContent {
    padding: 6px !important;


    .dialogBody {
      position: relative;

      .button {
        cursor: pointer;
        height: 40px;
        border-radius: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        flex: 1 1 100%;
      }

      .button:first-child {
        margin-right: 6px;
      }

      .button:last-child {
        margin-left: 6px;
      }

      .pbd-top {
        position: relative;
        margin-bottom: 20px;

        .pdc-tc-content {
          position: absolute;
          left: 9px;
          right: 9px;
          top: 54px;
          bottom: 64px;
          border-radius: 7px;
          background: rgba(255, 255, 255, .9);
          padding: 15px 20px;

          .pdc-row {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-items: flex-start;

            div.icon {
              height: 12px;
              width: 12px;
              background-image: url(./assets/check-on.svg);
              background-size: contain;
              background-repeat: no-repeat;

              margin-top: 4px;
              min-width: 12px;
            }

            div.txt {
              font-size: 13px;
              font-weight: 600;
              text-align: left;
              margin-bottom: 22px;
              padding-left: 5px;

              min-width: 0;
              flex: 1 1 auto;
            }

            .rich-text {
              ol {
                list-style: decimal;
                padding-inline-start: 2em;
              }

              ul {
                list-style: disc;
                padding-inline-start: 2em;
              }

              li {
                display: list-item;
                line-height: normal;
              }

              em {
                font-style: italic;
              }

              strong {
                font-weight: bold;
              }

              sup {
                vertical-align: super;
                font-size: smaller;
              }

              sub {
                vertical-align: sub;
                font-size: smaller;
              }

              h1 {
                font-size: 2em;
                font-weight: bold;
              }

              h2 {
                font-size: 1.5em;
                font-weight: bold;
              }

              h3 {
                font-size: 1.17em;
                font-weight: bold;
              }

              h4 {
                font-size: 1em;
                font-weight: bold;
              }

              h5 {
                font-size: 0.83em;
                font-weight: bold;
              }

              h6 {
                font-size: 0.67em;
                font-weight: bold;
              }

              .ql-align-center {
                text-align: center;
              }

              .ql-align-right {
                text-align: right;
              }

              .ql-align-left {
                text-align: left;
              }

              .ql-align-justify {
                text-align: justify;
              }

              .ql-indent-1 {
                text-indent: 1em;
              }

              .ql-indent-2 {
                text-indent: 2em;
              }

              .ql-indent-3 {
                text-indent: 3em;
              }

              .ql-indent-4 {
                text-indent: 4em;
              }

              .ql-indent-5 {
                text-indent: 5em;
              }
            }

          }

          .pdc-link-tc {
            font-size: 14px;
            text-decoration: underline;
            margin-top: 10px;
          }
        }

        .pdc-tc-content::after {
          content: " ";
          position: absolute;
          top: 100%;
          /* At the bottom of the tooltip */
          left: 77%;
          margin-left: -10px;
          border-width: 10px;
          border-style: solid;
          border-color: rgba(255, 255, 255, .9) transparent transparent transparent;
        }

        img {
          width: 100%;
          height: auto;
          display: block;
          border-radius: 7px;
        }

        .pbd-header {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: 45px;
          border-radius: 7px 7px 0 0;
          background: rgba(255, 255, 255, .56);
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 0 10px;

          .close {
            cursor: pointer;
            width: 29px;
            height: 29px;
            background: #B30000;
            border: 1px solid rgba(255, 255, 255, .77);
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            border-radius: 50%;
          }
        }

        .pbd-footer {
          position: absolute;
          bottom: 0;
          left: 0;
          right: 0;
          height: 58px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          padding: 9px;

          .button.cta {
            background: linear-gradient(to right, #FE1C6B, #F8961D);
            color: #fff;
          }

          .button.tc {
            background: linear-gradient(to right, #0C0B0B, #1F1F1F);
            border: 1px solid #565151;
            color: #BDBDBD;
          }

          .button.tc.hide-cls {
            background: linear-gradient(to right, #0048D7, #113D70);
            border: 1px solid #565151;
            color: #BDBDBD;
          }
        }
      }

      .pbd-bottom {
        .button.other {
          position: relative;
          margin: 0;
          background: #FFFFFF;
          color: #17214D;
          font-size: 13px;
          font-weight: bold;
          height: 45px;

          svg {
            position: absolute;
            top: 10px;
            right: 15px;
            color: #979DAB;
          }
        }
      }
    }

  }
}