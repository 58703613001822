@import "../../../variables.scss";
#ComingSoon {
  //padding-bottom: 0rem;
  padding-top: 6rem;

  .under-construction {
    margin-top: -2.8rem;
    width: 15rem;
    .fillMe {
      fill: $iconGray;
    }
  }

  .title {
    padding: 2.5rem 0 2.2rem 0;
    color: $iconGray;
    font-weight: bold;
  }
  .description {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0 auto;
    line-height: 2rem;
    padding-bottom: 2rem;
    display: block;
  }
  p.description {
    max-width: 25rem;
  }
  a.description {
    max-width: 35rem;
    color: $thirdColor;
  }
}
