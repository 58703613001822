.bets-winner-fun-wrapper {
  overflow: hidden;
  background: #EDF0F0;

  &.dark {
    background: #1B1E2C;
  }

  .bets-winner-fun-front {
    margin: 10px 10px 6px;

    .wf-wrapper {
      cursor: pointer;
      margin: 0 auto;
      background: url(./assets/bg.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      border-radius: 8px;
      height: 57px;
      padding: 0 23px 0 13px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, .5);

      .wf-logo {
        img {
          display: block;
          height: 32px;
          width: auto;
          position: relative;
          top: -3px;
        }
      }

      .wf-text {
        flex: 1 1 100%;
        width: 100%;
        padding: 0 13px;
        color: #fff;
        font-size: 16px;
        font-weight: bold;
        text-align: left;

        .wf-no-break {
          display: inline-block;
          white-space: nowrap;
        }
      }

      .wf-go {
        color: #fff;

        svg {
          display: block;
          width: 24px;
          height: 24px;
        }
      }
    }
  }
}