.bets .wf-betslip-header {
  display: none;
}

.bets.winner_fun {

  .bets-promoted-root {
    padding-top: 0
  }

  .wf-pre-match.wf-light-mode {
    background-image: url("./assets/winner-fun/new/img-sportcard-bg-prematch-classic@3x.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }

  .wf-pre-match.layoutUK.wf-light-mode {
    background-image: url("./assets/winner-fun/new/img-sportcard-bg-prematch@3x.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }

  .wf-live-match {
    &.wf-dark-mode {
      background-image: url("./assets/winner-fun/new/img-sportcard-bg-live@3x.png");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100% auto;
    }

    &.layoutUK.wf-dark-mode {
      background-image: url("./assets/winner-fun/new/img-sportcard-bg-prematch@3x.png");
    }

    &.wf-light-mode {
      background-image: url("./assets/winner-fun/new/img-sportcard-bg-toplive@3x.png");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100% auto;
    }

    &.layoutUK.wf-light-mode {
      background-image: url("./assets/winner-fun/new/img-sportcard-bg-prematch@3x.png");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100% auto;
    }
  }

  .drawer .bets-scroller {

    .wf-prematch-simple {
      background-image: url("./assets/winner-fun/img-topheader-align-right@3x.png");
      background-repeat: no-repeat;
      background-position: right bottom;
      background-size: 100% auto;
    }
  }

  .wf-betslip {}

  .wf-expansion-panel-header-DISABLED {
    background: url("./assets/winner-fun/new/league-bg@3x.png"), #D1E1E9;
    background-repeat: no-repeat;
    background-position: center center, right bottom;
    background-size: 100% 100%, 100% auto;
  }

  .dark.wf-expansion-panel-header {
    background: url("./assets/winner-fun/new/league-bg@3x.png"), linear-gradient(90deg, #070D29 0%, #14259B 100%);
    background-repeat: no-repeat, no-repeat;
    background-position: center center, right bottom;
    background-size: 100% 100%, 100% auto;
  }

  .AppTopMenuBar {
    /*background: url("./assets/winner-fun/new/bg@3x.png"), linear-gradient(to right, #14259b 0%, #312387 20.1%, #620697 46.3%, #ae1a73 84.1%, #ff000f 100%) !important;*/
    background: linear-gradient(to right, #14259b 0%, #312387 20.1%, #620697 46.3%, #ae1a73 84.1%, #ff000f 100%) !important;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100% !important;

    .mobile-header-background {
      display: none;
    }
  }

  @media (min-width: 400px) {
    .wf-pre-match.wf-light-mode {
      background-position: left bottom;
      background-size: auto 100%;
    }

    .wf-pre-match.layoutUK.wf-light-mode {
      background-position: left bottom;
      background-size: auto 100%;
    }

    .wf-live-match {
      &.wf-dark-mode {
        background-position: left bottom;
        background-size: auto 100%;
        background-color: #0B1333;
      }


      &.wf-light-mode {
        background-position: left bottom;
        background-size: auto 100%;
      }

      &.layoutUK.wf-light-mode {
        background-position: left bottom;
        background-size: auto 100%;
      }
    }


    .dark.wf-expansion-panel-header {
      background-position: right center, right bottom;
      background-size: auto 100%, 100% auto;
    }

  }

  .wf-betslip-header {
    display: block;
    position: absolute;
    top: 0px;
    right: 0px;
    height: 24px;
    width: 120px;
    border-top-right-radius: 23px;

    background: linear-gradient(to right, #FFB500, #FF3D00, #FB0088, #BC00FF);

    &.wf-smaller {
      width: 90px;
    }

    &.wf-desktop {
      border-top-right-radius: 0;
      top: 6px;
    }

    img {
      display: block;
      position: relative;
      top: 2px;
      height: 20px;
      width: auto;
      margin: 0 auto;
      padding-right: 15px;
    }

    svg {
      right: auto;
      left: -64px;
    }
  }

  .betslip-footer-odds-change {
    padding-bottom: 20px;
  }

  .betslip-footer {
    display: none;
  }

  .wf-balance-main,
  .wf-balance-secondary {
    .wf-icon {
      min-width: 17px;
      max-width: 17px;
      width: 17px;

      img {
        display: block;
        height: 17px;
        width: 16px;
      }
    }

    .wf-title {
      flex: 1 1 0%;
      font-size: 12px;
      font-weight: normal;
      text-align: left;
      color: #17214D;
      padding: 0 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .wf-sold {
      flex: 1 1 0%;
      font-size: 12px;
      font-weight: bold;
      text-align: left;
      color: #17214D;
      white-space: nowrap;
      min-width: 110px;
      text-align: right;
    }

    .wf-deduct {
      font-size: 12px;
      font-weight: normal;
      text-align: left;
      color: #D72400;
    }

    .wf-empty-help {
      margin-left: 5px;
      width: 16px;
      min-width: 16px;
      height: 16px;
    }

    .wf-help {
      background: #D2DBE7;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      margin-left: 5px;
      width: 16px;
      min-width: 16px;
      height: 16px;
    }

    .wf-no-help {
      min-width: 1px;
      width: 1px;

      margin-right: 5px;
    }
  }

  .wf-balance-main {
    .wf-title {
      font-weight: bold;
    }
  }

  .wf-stake {
    .wf-stake-title {
      flex: 1 1 100%;
    }

    .wf-points {
      flex: 1 1 100%;
      margin-top: 8px;

      .wf-points-text {
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        color: #586682;
        text-align: right;
      }

      .wf-points-subtext {
        font-size: 8px;
        font-weight: 600;
        text-align: left;
        color: #586682;
        text-align: right;
      }
    }

    .wf-help {
      background: #D2DBE7;
      border-radius: 50%;
      width: 15px;
      height: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      margin-left: 5px;
      width: 16px;
      min-width: 16px;
      height: 16px;
      margin-top: 8px;
    }

    .wf-no-help {
      min-width: 1px;
      width: 1px;
      margin-right: 5px;
    }
  }

  &.no-scroll {
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  .pulse_anim .MuiBadge-badge {
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 1);
    animation: pulse_anim_keys 3s infinite;
  }

  @keyframes pulse_anim_keys {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
    }

    50% {
      box-shadow: 0 0 0 10px rgba(255, 255, 255, 0);
    }

    100% {
      box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
  }

}

.IconSVG.ProductIcon.walletWinnerFun2 {
  height: auto !important
}