@import "../../../variables.scss";

.ProgressBar{
    background-color: #e5e5e5 !important;
    border-radius: 20px;
    height: 7px !important;
    
    div{
        border-radius: 20px;
        height: 7px;
        background: $gradientStartColor;
        background: -moz-linear-gradient(left,  $gradientStartColor 0%, $gradientEndColor 100%);
        background: -webkit-linear-gradient(left,  $gradientStartColor 0%,$gradientEndColor 100%);
        background: linear-gradient(to right,  $gradientStartColor 0%,$gradientEndColor 100%);
    }
}