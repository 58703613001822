@import "./../../../variables";

.HotEarnings {
  padding-bottom: 4rem;

  h1 {
    .text {
      color: #c20002 !important;
    }

    .icon {
      .fire-red {
        fill: #c20002 !important;
      }
    }
  }
}