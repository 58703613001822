.ProductBox {
    display: flex;
    flex: 0 calc(50% - 4px);
    box-sizing: border-box;
    max-width: 193px;
    width: 100%;
    margin: 2px;
    background-color: #ffffff;
    padding: 5px 0px 5px 5px;
    border-radius: 3px;
    color: #000;

    &.ComingSoon {

        .BoxIcon,
        .BoxContent .BoxAmount {
            opacity: .2;
        }
    }

    .BoxIcon {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 40px;

        .ProductIcon {
            max-width: 40px;
            max-height: 40px;
            width: 100%;
            height: 100%;

            &.walletSportsbook {
                path {
                    fill: #0BBD80;
                }
            }

            &.walletCasino {
                path {
                    fill: #FF196E;
                }
            }

            &.walletCasinoLive {
                path {
                    fill: #71C108;
                }
            }

            &.walletVirtuals {
                path {
                    fill: #00AEDA;
                }
            }

            &.walletLotto {
                path {
                    fill: #0D6EFF;
                }
            }
        }
    }

    .BoxContent {
        display: flex;
        flex-direction: column;
        flex: 1;
        align-items: flex-end;
        justify-content: center;

        .BoxTitle {
            display: flex;
            line-height: 1;
            padding-right: 10px;
            font-size: 12px;
            font-weight: 500;
            color: #757575;
            margin-bottom: 5px;
        }

        .BoxAmount {
            display: flex;
            align-self: flex-end;
            justify-content: flex-end;
            justify-self: flex-end;
            align-items: flex-end;
            padding-top: 5px;
            padding-right: 10px;
            font-size: 20px;
            font-weight: 600;
            color: #17214d;

            span {
                display: flex;
                align-self: flex-end;
                font-size: 20px;
                font-weight: 600;
                color: #17214d;
                line-height: 1;

                small {
                    display: flex;
                    align-self: flex-end;
                    font-weight: 600;
                    font-size: 16px;
                }

                &.Currency {
                    font-size: 16px;
                    margin-left: 3px;
                }
            }
        }

        .BoxBonus {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            min-height: 17px;
            min-width: 96px;

            &.NoBonus {
                .BonusContent {

                    .BonusIcon,
                    .BonusAmount {
                        opacity: .2;
                    }
                }
            }

            .FreeBetContent {
                padding-top: 10px;

                svg {
                    display: block
                }
            }

            .BonusContent {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 100%;
                border-top: 1px solid rgba(23, 33, 77, 0.1);
                margin-top: 3px;
                padding-top: 5px;
                padding-right: 10px;

                .BonusIcon {
                    display: flex;
                    align-self: center;
                    justify-self: center;
                    width: 12px;
                    height: 12px;
                    margin: 0 10px;
                    opacity: .8;
                    fill: #17214d;
                }

                .BonusAmount {
                    align-self: flex-end;
                    justify-content: flex-end;
                    justify-self: flex-end;
                    align-items: flex-end;
                    color: #17214d;
                    opacity: .8;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 1;
                    display: flex;

                    span {
                        display: flex;
                        align-self: flex-end;
                        font-size: 12px;
                        font-weight: 500;
                        line-height: 1;

                        small {
                            display: flex;
                            align-self: flex-end;
                            font-weight: 500;
                            font-size: 8px;
                        }

                        &.Currency {
                            font-size: 8px;
                            margin-left: 3px;
                        }
                    }
                }
            }
        }
    }
}


.PendingTransactions {
    .ProductsContainer {
        .ProductBox {
            .BoxIcon {
                justify-content: flex-end;

                .ProductIcon {
                    &.withdrawCoin {
                        width: 20px;
                        height: 20px;

                        .smallCircle {
                            fill: #dd2d2d;
                        }

                        .bigCircle {
                            fill: #32832f;
                        }

                        .arrow {
                            fill: #32832f;
                        }
                    }

                    &.depositCoin {
                        width: 20px;
                        height: 20px;

                        .smallCircle {
                            fill: #32832f;
                        }

                        .bigCircle {
                            fill: #dd2d2d;
                        }

                        .arrow {
                            fill: #dd2d2d;
                        }
                    }
                }
            }

            .BoxAmount {
                display: flex;
                align-content: center;
                justify-content: center;
                line-height: 1;
                font-size: 15px;

                &.Withdrawls {
                    color: #d91818;

                    span {
                        color: #d91818;
                    }
                }

                &.Deposits {
                    color: #1ab713;

                    span {
                        color: #1ab713;
                    }
                }

                span {
                    font-size: 15px;
                    font-weight: 600;

                    small {
                        font-size: 11px;
                    }

                    &.Currency {
                        font-size: 11px;
                    }
                }
            }
        }
    }
}