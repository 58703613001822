.tm-booster-info-wrapper {
  background: #FFFAF0;
  margin: -5px 0;
  padding: 10px;

  .limit-left {
    left: 0 !important;
  }

  .limit-right {
    left: unset !important;
    right: 0 !important;
  }

  .tm-title {
    color: #586682;
    font-size: 16px;
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    img {
      display: block;
      height: 28px;
      width: auto;
      margin-right: 5px;
    }
  }

  .tm-booster-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 3px;

    .tm-subtitle {
      color: #586682;
      font-size: 9px;
      font-weight: 600;
      white-space: nowrap;
    }

    .tm-end-title {
      background: #F0F8FB;
      border: 1px solid #D2DBE7;
      border-radius: 9px;
      height: 16px;
      display: flex;
      align-items: center;
      padding: 0 7px 1px;
      color: #586682;
      font-size: 8px;
      font-weight: bold;
      white-space: nowrap;
      position: relative;
    }

    .tm-center {
      flex: 1 1 100%;
      position: relative;
      margin-left: 5px;
      height: 21px;

      .tm-bg {
        position: absolute;
        left: 0;
        right: 0;
        top: 8px;
        background: #D2DBE7;
        border-radius: 7px 0 0 7px;
        height: 5px;
      }

      .tm-bg-progress {
        position: absolute;
        left: 0;
        top: 8px;
        background: linear-gradient(to right, #7C7B79, #1E1E1E);
        border-radius: 7px 0 0 7px;
        height: 5px;
      }

      .tm-info {
        position: absolute;
        display: flex;
        align-items: center;
        z-index: 1;

        .tm-logo {
          background: url("./assets/icon-off.png");
          background-repeat: no-repeat;
          background-size: 100%;
          background-position: center;
          width: 18px;
          height: 21px;
          z-index: 1;
          position: relative;
          top: 1px;
          left: -2px;
        }

        .tm-text {
          height: 16px;
          padding-bottom: 2px;
          padding-left: 8px;
          margin-left: -15px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 48px;
          border: 1px solid #D2DBE7;
          background: #F0F8FB;
          border-radius: 9px;

          color: #586682;
          font-size: 10px;
          font-weight: bold;
        }
      }
    }



    &.tm-active-boost {
      .tm-center {
        .tm-bg-progress {
          background: linear-gradient(to right, #FFB011, #FD6130);
        }

        .tm-info {
          .tm-logo {
            background-image: url("./assets/icon-on.png");
          }

          .tm-text {
            border: 1px solid #FD6130;
            color: #FF7600;
          }
        }
      }

      &.tm-max-boost {
        .tm-end-title {
          background-color: #FD6130;
          border: 1px solid #FD6130;
          color: #fff;
        }

      }
    }

    &.tm-max-boost {
      .tm-end-title {
        background-color: #586682;
        border: 1px solid #586682;
        color: #fff;
      }

      .tm-center {
        .tm-info {
          .tm-text {
            border-right: 0;
            border-top-right-radius: 0px;
            border-bottom-right-radius: 0px;
          }
        }
      }
    }
  }
}