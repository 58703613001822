@import "./../../../../../variables";

.WithdrawSuccess {
    background-color: #ffffff;
    color: $firstColor;

    .top {
        padding-top: 60px;
        padding-bottom: 60px;
        border-bottom: 1px solid $withdrawHorLine;

        h1 {
            width: 100%;
            text-align: center;
            text-transform: uppercase;
        }
    }

    .middle {
        padding-top: 40px;
        text-align: center;

        .successChar,
        .errorChar {
            width: 120px;
            margin: 0 auto;
            background-color: #ffffff;
            position: relative;
            top: -80px;

            .pwSuccess {
                color: $thirdColor;
                height: 80px;
            }
        }

        .errorChar {
            .pwSuccess {
                color: $historyTransactionFailed;
            }
        }
    }

    .bottom {
        padding: 60px 80px 40px;

        button {
            width: 100%;
            background-color: $thirdColor;
            border-color: $thirdColor;
            font-weight: 600;
            height: 66px;
            font-size: 1.4rem;
            position: relative;
            top: 0;

            &:hover {
                background-color: lighten($thirdColor, 3%);
            }

            &:active {
                top: 2px;
                background-color: lighten($thirdColor, 6%) !important;
            }
        }
    }

    &.activeBonuses {
        min-height: 730px;

        .top {
            text-align: center;
            padding-top: 50px;

            h1 {
                font-size: 1.5rem;
                font-weight: 600;
                text-transform: none;
            }

            border-bottom-color: #fac6c8;

            span {
                color: $withdrawPin;
                text-transform: uppercase;
                text-align: center;
                font-size: 1rem;
                font-weight: 600;
            }
        }

        .middle {
            .animated {
                display: flex;
                justify-content: center;
                align-items: stretch;
                flex-flow: row wrap;
                text-align: center;
            }

            .warningMessage {
                padding-top: 20px;
                min-height: 60px;

                p {
                    text-align: center;

                    span {
                        color: $withdrawPin;
                        text-transform: uppercase;
                        text-align: center;
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }
            }

            .errorChar {
                position: absolute;
                height: 82px;
                min-height: 82px;
            }

            .IconSVG {
                .cls-2{fill:#fff;}
            }

            p {
                padding-left: 80px;
                padding-right: 80px;
                text-align: left;
                flex: 1 100%;
                color: $firstColor;
                font-size: 15px;
                margin-bottom: 0;
            }

            .tcbl {
                a {
                    color: $thirdColor;
                    text-decoration: underline;
                }

                b {
                    color: $withdrawPin;
                }
            }

            .messageWalletSupport {
                padding-left: 80px;
                padding-right: 80px;
                padding-top: 10px;
                padding-bottom: 30px;

                .messageWallet {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    //flex-flow: column wrap;
                    flex-direction: row;

                    .moneyPig,
                    .moneyBonus {
                        height: 50px;
                    }

                    .mwColumn {
                        flex: 1 1 50%;
                        display: flex;

                        * {
                            text-align: left;
                        }

                        p {
                            margin: 0;
                            padding: 0;
                            color: $iconGray;
                            font-size: 12px;
                            font-weight: 500;
                        }

                        .mwIcon {
                            //height: 50px;
                            line-height: 50px;
                            padding-top: 0px;
                            padding-bottom: 10px;
                            padding-right: 15px;

                            .IconSVG {
                                fill: $iconGray;
                            }
                        }

                        .mwText {
                            p {
                                font-weight: 600;
                                padding-bottom: 1px;

                                &:last-child {
                                    font-size: 24px;
                                    color: $firstColor;
                                    font-weight: 700;
                                }
                            }
                        }

                        &:last-child {
                            .mwText {
                                p {
                                    color: $magenta;
                                    font-size: 14px;

                                    &:last-child {
                                        color: $withdrawPin;
                                        text-decoration: line-through;
                                        font-size: 24px;
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .bottom {
            padding-top: 30px;

            .btn-outline-danger {
                background-color: white;
                border-color: #dc3545;
                margin-top: 14px;

                &:hover,
                &:active {
                    background-color: #dc3545 !important;
                }
            }
        }
    }
}