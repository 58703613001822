.search {
  position: relative;
  background-color: var(--ns-search-bg);
  border-bottom: 1px solid var(--ns-search-border-bottom-color);

  .tabs {
    position: relative;
    height: 52px;
    border-bottom: 1px solid var(--ns-search-border-bottom-color);
    padding: 0;

    .tabs-wrap {
      position: static;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;

      .tab {
        display: block;
        float: left;
        height: 100%;
        line-height: 52px;
        padding: 0 20px;
        color: #666;
        text-decoration: none;
        font-size: 0.8125em;
        outline: none;
        margin-top: 0;
        background: inherit;
        border-right: none;
        cursor: pointer;
        text-transform: capitalize;
        font-weight: normal;

        .more-svg {
          margin-left: 10px;
          position: relative;
          top: 8px;
          color: #666;
          width: .8em;
        }
      }

      .tab:hover {
        color: #fff;
      }

      .tab.active {
        border-bottom: 1px solid var(--ns-action-color);
        color: var(--ns-search-tab-active-color);

        .more-svg {
          color: #FF5F23;
        }
      }
    }
  }

  .input {
    position: relative;
    color: var(--ns-search-input-color);
    height: 38px;
    margin: 7px 5px;
    padding-left: 90px;
    overflow: hidden;

    &.full {
      padding-left: 0;
    }

    .textbox {
      width: 100%;
      height: 38px;
      margin: 0;
      padding: 5px 45px 5px 10px;
      float: left;
      background-color: var(--ns-search-input-color);
      border: 1px solid var(--ns-search-input-border-color);
      color: var(--ns-search-input-border-color);
      outline: 0;
      font-size: 16px;
      transition: right 0.4s linear;
      z-index: 1;
      border-radius: 0;
    }

    .textbox::placeholder {
      color: rgba(0, 0, 0, .5);
    }

    .button {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      max-width: 3em;
      height: 38px;
      margin: 0;
      padding: 0 6px 0 7px;
      background-color: var(--ns-search-button-bg);
      border: 1px solid var(--ns-search-button-border-color);
      outline: 0;
      color: var(--ns-search-button-color);
      font-size: .8em;
      cursor: pointer;
      z-index: 2;
      transition: background 0.5s;

      svg {
        width: 1em;
        height: 1em;
        margin-top: 4px;
      }
    }
  }

  .controls {
    position: absolute;
    bottom: 0;
    left: 15px;
    line-height: 47px;
    padding-top: 5px;

    >span {
      cursor: pointer;

      >svg.cup-icon {
        fill: var(--ns-search-control-color);
        width: 1em;
        height: 1em;
        display: inline-block;
        font-size: 1.5rem;
        transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
        flex-shrink: 0;
        user-select: none;
      }

      >svg {
        fill: var(--ns-search-control-color);
      }
    }

    >span:hover>svg {
      fill: var(--ns-search-control-hover-color);
    }

    >span>svg {
      position: relative;
      top: 4px;
    }

    >span:nth-child(2)>svg {
      left: 4px
    }

    >span.active>svg {
      fill: var(--ns-action-color);
    }
  }

  @media screen and (min-width: 992px) {
    &.live {
      .input {
        float: none;
        width: auto;
      }
    }
  }

  @media screen and (min-width: 1440px) {
    &.live {
      .tabs {
        float: left;
        border-bottom: none;
      }

      .input {
        float: right;
        width: 175px;

        .textbox {
          width: 175px;
          position: absolute;
          right: 0
        }
      }

      .controls {
        left: auto;
        right: 195px;
      }
    }
  }

  @media screen and (max-width: 848px) {
    &.prematch {
      .tabs {
        overflow: hidden;
        overflow-x: scroll;
        background: #0060e0;
        margin: 0 -7px;
        height: 35px;


        .tabs-wrap {
          white-space: nowrap;

          .tab {
            display: inline-block;
            float: none;
            color: #fff;
            height: 35px;
            line-height: 35px;
          }

          .tab.active {
            color: #fdd4c4 !important;
          }
        }
      }

      .input {
        float: none;
        width: 0;
      }

      .controls {
        left: 5px;
        bottom: 0;
        top: auto !important;
      }
    }
  }

  @media screen and (min-width: 849px) {
    &.prematch {
      .tabs {
        float: left;
        border-bottom: none;
      }

      .input {
        float: right;
        width: 175px;

        .textbox {
          width: 175px;
          position: absolute;
          right: 0
        }
      }

      .controls {
        left: auto;
        right: 195px;
      }
    }
  }

  .mobile-sort-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    padding: 0;
    background: #eeedf2;
    position: relative;

    .sports-filter-wrap {
      width: calc(100% - 8.5rem);
      padding: .5rem;
      padding-left: 0;

      .selected-sport-option {
        display: flex;
        flex-direction: row;
        height: 100%;
        justify-content: center;
        align-items: center;
        padding: 0;
        background: #fff;

        .sport-label {
          width: 100%;
          max-width: calc(100% - 1.6875rem);
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          font-size: .875rem;
          color: #333;
          padding: 0 .625rem;
          user-select: none;
        }

        svg {
          width: 16px;
          color: #666;
          margin-right: .5rem;
        }
      }
    }

    .grid-modes-search-header {
      display: flex;
      flex-direction: row;
      padding: .5rem 0;

      .grid-modes {
        display: flex;
        margin-right: .5rem;

        .sort-tab {
          width: 2.5rem;
          height: 2.5rem;
          border-radius: .125rem;
          background-color: #eeedf2;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--ns-search-control-color);
          transition: all .5s ease-in-out;

          svg {
            position: relative;
            top: 2px;
            fill: var(--ns-search-control-color);
          }

          .sort-tab-wrap {
            height: 100%;
            width: 100%;
            justify-content: center;
            display: flex;
            align-content: center;
            align-items: center;
          }
        }

        .sort-tab.active {
          background-color: #DE2635;
          color: #fff;

          svg {
            fill: #fff;
          }
        }
      }

      .search-tab {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        .search-input {
          display: flex;

          .search-input-field {
            position: absolute;
            left: calc(100% - 3.25rem);
            right: 3.75rem;
            width: 0;
            height: 40px;
            background-color: #fff;
            border: none;
            outline: none;
            font-family: "Open Sans", "OpenSans";
            font-size: .875rem;
            font-weight: 400;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: rgba(0, 0, 0, 1);
            transition: all .2s;
          }

          .search-input-field.active {
            left: .5rem;
            width: calc(100% - 3.75rem);
            padding: .7rem .625rem;
          }

          .search-button {
            flex-grow: 0;
            width: 2.5rem;
            height: 2.5rem;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #000;
            border-radius: .125rem;
            margin-right: .5rem;
          }
        }
      }
    }
  }

  .nav-bar {
    display: none;
    /*border-bottom: 1px solid var(--ns-search-border-bottom-color);*/
    height: 50px;
    background: var(--ns-left-nav-bg);



    .navigation-item {
      color: var(--ns-left-nav-navigation-item-color);
      position: relative;
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      height: 48px;
      padding: 8px;
      border-bottom: 2px solid #811e1e;
      border-left: 1px solid var(--ns-left-nav-navigation-item-border-bottom);
      outline: none;
      transition: color 0.25s linear;
      cursor: pointer;

      .icon {
        width: 100%;
        display: block;
        position: absolute;

        svg {
          width: 32px;
          height: 32px;
          fill: var(--ns-left-nav-navigation-item-color);
          transition: fill 0.25s linear;
        }
      }
    }

    .navigation-item.active {
      color: var(--ns-left-nav-navigation-item-active-color);

      .icon {
        svg {
          fill: var(--ns-left-nav-navigation-item-active-color);
        }
      }
    }

    @each $colorNo in $sports-color {
      .sport-border-bottom-color-#{$colorNo} {
        border-bottom-color: var(--ns-sport-color-#{$colorNo});
      }
    }

    .home-wrapper {
      width: 50px;
      height: 50px;
      border-right: 2px solid #000;
      text-align: center;
      color: #BEBEBE;
      font-size: 11px;
      font-weight: 600;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .topIcon {
        padding-top: 5px
      }
    }

    .sport-wrapper {
      width: calc(100% - 50px);
      display: flex;
      align-items: center;
      justify-content: flex-start;
      overflow-y: hidden;
      overflow-x: auto;
    }
  }

  @media screen and (max-width: 991px) {
    .nav-bar {
      display: flex;
    }
  }
}

@media screen and (min-width: 1440px) {
  .search {
    height: 52px;
  }
}