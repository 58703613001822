@import './../../../../../variables';

.docsSections {
    .docsSection {
        width: 100%;

        .section {
            border-bottom: 1px solid $iconGray;
            padding-bottom: 10px;
            padding-top: 3rem;

            &.review {
                .message {
                    color: #f89a2d !important;
                }

                .btn-outline-success {
                    color: $thirdColor;
                    border-color: $thirdColor;

                    &:hover {
                        background-color: $thirdColor;
                        border-color: $thirdColor;
                        color: $white;
                    }
                }
            }

            &.rejected {
                .reasons {
                    display: block !important;

                    .cls-1 {
                        fill:#c20002;
                    }
                }
            }

            &.verified {
                .reasons {
                    display: block !important;
                    position: relative;
                    top: -34px;

                    .cls-1 {
                        fill:#09ba8a;
                    }
                }

                .timeMessageSupport {
                    display: none;
                }
            }

            .section-header {
                display: flex;
                flex-direction: row;

                .texts {
                    text-align: left;

                    .title {
                        font-size: 16px;
                        font-weight: bold;
                    }

                    .message {
                        font-size: 14px;
                        color: $redColor;
                        font-weight: bold;
                    }

                    div {
                        line-height: 25px;
                    }
                }

                .icon {
                    width: 100px;
                    max-width: 100px;
                    min-width: 100px;

                    display: flex;
                    align-items: center;
                    justify-content: center;


                    .IconSVG {
                        height: 50px;

                        .cls-1 {
                            fill: $iconGray;
                        }

                        .cls-2 {
                            fill: $white;
                        }
                    }
                }
            }

            .section-body {
                padding-left: 100px;
                text-align: left;
                padding-top: 1rem;

                .reasons {
                    display: none;

                    p {
                        padding: 0;
                        font-size: 13px;
                        line-height: 14px;
                        color: #888888;

                        .reason-symbol {
                            width: 13px;
                            height: 13px;
                        }
                    }
                }

                .actionButton {

                    .btn {
                        font-weight: bold;
                        padding: 10px 26px;
                        transition: all 0.1s ease-in;
                        position: relative;
                        top: 0;
                        margin-top: 5px;

                        &.btn-success {
                            background-color: $thirdColor;
                            border-color: $thirdColor;

                            &:hover{
                                background-color: lighten($thirdColor, 4%);
                                border-color: lighten($thirdColor, 4%);
                            }

                            &:active {
                                background-color: darken($thirdColor, 10%);
                            }
                        }

                        &:focus,
                        &:active {
                            outline: none !important;
                            box-shadow: none !important;
                        }

                        &:active {
                            top: 2px;
                        }
                    }
                }

                .timeMessageSupport {
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;

                    .message {
                        display: flex;
                        color: $grayColor !important;
                        font-size: 16px;

                        .icon-support {
                            width: 40px;

                            .IconSVG {
                                height: 25px;
                            }

                            .cls-1 {
                                fill: $iconGray;
                            }

                            .cls-2 {
                                fill: $white;
                            }
                        }
                    }

                    .eta {
                        padding-left: 40px;
                        font-size: 15px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}

.timeMessageSupport {
    display: none !important;
}
