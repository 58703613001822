.WalletManagementScreen{
    display: flex;
    align-self: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;

    .Loading {
        color: #ffb400;
        width: 20px !important;
        height: 20px !important;
    }

    .WalletTopWidget{
        display: flex;
        align-self: flex-end;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;
        height: 100%;
        max-height: 42px;
        max-width: 170px;
        border-radius: 20px;
        box-sizing: border-box;
        
        .WalletType{
            display: flex;
            align-items: flex-start;
            justify-content: center;
            position: relative;
            padding: 3px 2px 0px 7px;
            width: 100%;
            height: 42px;
            max-width: 40px;
            box-sizing: border-box;
            border-radius: 20px 0px 0px 20px;
            background-color: rgba(0, 0, 0, 0.3);
            cursor: pointer;
            line-height: 1;
            
            .PreviewIcon{
                display: flex;
                width: 33px;
                height: 33px;
                opacity: 0.6;
            }

            .PreviewIcon.walletWinnerFun {
                opacity: 1;
            }

            .DropDownIcon{
                position: absolute;
                bottom: -3px;
                left: 7px;
                right: 2px;
                margin: auto;
                fill: #ffb400;
            }
        }
        
        .WalletAmount{
            padding: 7px 7px 7px 0px;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            height: 42px;
            box-sizing: border-box;
            border-radius: 0px 20px 20px 0px;
            background-color: rgba(0, 0, 0, 0.45);
            
            span{
                cursor: pointer;
                font-size: 14px;
                font-weight: 500;
                .SmallDecimal{
                    font-weight: 500;
                    font-size: 11px;
                    opacity: .6;
                }
            }

            .WalletViewMore{
                width: 1.75rem;
                height: 1.75rem;
                margin-left: 10px;
                cursor: pointer;
                .cls-1{
                    fill: #ffb400; 
                }
                .cls-2{
                    fill: #ffffff; 
                }
            }
        }
    }
}