:root {
    --gutter: 20px;
}

.CollectionsScreen{
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > .Loading {
        margin: 20px;
        color: #ffb400;
        width: 20px !important;
        height: 20px !important;
    }

    .GamesCollection{
        width: 100%;
        max-width: 1280px;
        .JackpotContent{
            padding: 0px 15px 30px 15px;
        }

        .CollectionContainer{
            padding: 0px 0px 30px 0px;
            display: grid;
            grid-gap: 5px 0;
            grid-template-columns: var(--gutter) 1fr var(--gutter);
            align-content: start;
            
            & > *{
                grid-column: 1 / -1;
            }
    
            .CollectionHeader{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0px 15px;
            
                .CollectionTitle{
                    text-align: left;
                    color: #606060;
                    font-weight: 700;
                    font-size: 20px;
                    cursor: pointer;
                    span{
                        color: #888;
                        font-weight: 500;
                        font-size: 15px;
                    }
                }
                .CollectionLink{
                    color: #888;
                    font-weight: 500;
                    font-size: 15px;
                    cursor: pointer;
                    &:hover{
                        text-decoration: underline;
                    }
                }
            }
    
            .GamesContainer{
                padding-left: 15px;
                display: grid;
                grid-auto-flow: column;
                grid-gap: calc(var(--gutter) / 1.5);
                grid-template-rows: minmax(50px, 1fr);
    
                @for $i from 1 through 15{
                    &.Columns-#{$i}{ grid-template-columns: repeat(#{$i}, calc(14rem - var(--gutter) * 1.5)) 5px; }
                }
                                
                &.SingleRow{
                    grid-auto-flow: row;
                    .EmptyColumn{
                        grid-column: auto / auto;
                        grid-row: auto / auto;
                        width: 10px;

                    }
                }
        
                .GameItem{
                    position: relative;
                    min-height: 145px;
                    height: 100%;

                    &.FirstGame{
                        grid-column: span 2 / auto;
                        grid-row: span 2 / auto;
                    }
                    .ShowMore{
                        width: 100%;
                        height: 100%;
                        max-width: 100%;
                        max-height: 145px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        background-color: rgb(22, 33, 78);
                        box-shadow: 3px 3px 4px 1px rgba(0,0,0,0.28);
                        border-radius: 5px;
                        cursor: pointer;
                        transition: transform .2s;
                        &:hover{
                            transform: scale(1.03);
                        }
                        p{
                            width: 40px;
                            height: 40px;
                            margin: 0;
                            font-size: 20px;
                            border: 2px solid #FFF;
                            font-weight: 600;
                            border-radius: 100%;
                            line-height: 1.3;
                            color: #FFF;
                            letter-spacing: 0px;
                            padding: 0;
                            text-align: center;
                        }
                    }
                    &.EmptyColumn{
                        grid-column: auto / span 2;
                        grid-row: auto / span 2;
                    }
                }
            }
        }
    }
}

@media (max-width: 414px){
    .CollectionsScreen{
        .GamesCollection{
            .CollectionContainer{
                .GamesContainer{
                    @for $i from 1 through 15{
                        &.Columns-#{$i}{ grid-template-columns: repeat(#{$i}, calc(11rem - var(--gutter) * 4)) 5px; }
                    }
                }
            }
        }
    }    
}

@media (min-width: 415px) and (max-width: 768px){
    .CollectionsScreen{
        .GamesCollection{
            .CollectionContainer{
                .GamesContainer{
                    @for $i from 1 through 15{
                        &.Columns-#{$i}{ grid-template-columns: repeat(#{$i}, calc(12rem - var(--gutter) * 4)) 5px; }
                    }
                }
            }
        }
    }    
}

@media (min-width: 768px) and (max-width: 820px){
    .CollectionsScreen{
        .GamesCollection{
            .CollectionContainer{
                .GamesContainer{
                    @for $i from 1 through 15{
                        &.Columns-#{$i}{ grid-template-columns: repeat(#{$i}, calc(13rem - var(--gutter) * 4)) 5px; }
                    }
                }
            }
        }
    }    
}