.component-egt_jackpot {
  margin: 10px auto;
  padding-bottom: 5px;
  max-width: 1528px;

  .cgl-super-header {
    margin: 0 10px;

    .category {
      color: #353540;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-content {
    margin: 11px 5px 0;
  }

  .egtWrapper {
    padding-bottom: 16px;
  }


  .egypt-quest-theme {
    background:
      url("./assets/top-left-corner.png"),
      url("./assets/top-right-corner.png"),
      url("./assets/bottom-left-corner.png"),
      url("./assets/bottom-right-corner.png"),
      url("./assets/top.png"),
      url("./assets/top.png"),
      url("./assets/left.png"),
      url("./assets/left.png");
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
    background-position: top left, top right, bottom left, bottom right, top left, bottom left, top left, top right;
    background-size: 8px 8px, 8px 8px, 8px 8px, 8px 8px, auto 4px, auto 4px, 4px auto, 4px auto;
    padding: 4px 4px 4px 4px;
    margin: 11px 5px 0 5px;

    .egtWrapper {
      padding-bottom: 0;
    }


    .cgl-content {
      margin: 0;
    }

    .jackpot-headerV2.front {
      border-radius: 0px;
    }

    .jackpot-headerV2.front .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner {
      border-radius: 0px;
    }

    .egt-button {
      margin: 0 16px 16px;
    }

    .mr-right {
      position: relative;
    }

    .mr-right::after {
      content: "";
      position: absolute;
      right: -3px;
      top: 0px;
      bottom: 0px;
      width: 4px;
      background: url("./assets/left.png");
      background-repeat: no-repeat;
      background-position: top right;
      background-size: 4px auto;
    }
  }

  .cgl-jackpot {
    width: 100%;

    .outer {
      width: 100%;
      padding-top: 100%;
      position: relative;

      .inner {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;

        display: flex;
        align-items: center;
        justify-content: center;

        .jackpot-headerV2.front {
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }

        .jackpot-headerV2.front .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner {
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }

        @media screen and (min-width: 1528px) {
          .jackpot-headerV2.front {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
          }

          .jackpot-headerV2.front .jackpot-wrapper .jackpot-header-wrap .jackpot-header-inner {
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 0px;
            border-top-right-radius: 0px;
          }
        }

        .jackpot-state-wrapper {
          transform: scale(1.8);
          transform-origin: center bottom;
        }

        .jackpot-name {
          font-size: 16px !important;
        }

        .jackpot-headerV2.front .jackpot-wrapper .jackpot-header-wrap .jackpot-header-gradient .jackpot-main-value .big-counter2 .radix,
        .jackpot-headerV2.front .jackpot-wrapper .jackpot-header-wrap .jackpot-header-gradient .jackpot-main-value .big-counter2 .digit-wrapper {
          height: 17px;

          @media screen and (min-width: 1024px) {
            height: 22px;
          }
        }

        @media screen and (min-width: 821px) {
          .jackpot-name {
            font-size: 20px !important;
          }
        }

        .wrapperHistory {
          .jackpot-name {
            font-size: 14px !important;
          }

          .jackpot-counter {
            font-size: 20px !important;
          }

          .muted-text {
            font-size: 14px !important;
          }

          .user {
            font-size: 14px !important;
          }

          .amount {
            font-size: 16px !important;
            font-weight: 600 !important;
          }

          .date {
            font-size: 16px !important;
            font-weight: 600 !important;
          }
        }
      }
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;

    .cgl-grid {
      width: 100%;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-top: 10px;
      padding-bottom: 10px;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &.centered {
        justify-content: center;
      }

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 105px;
        max-width: 210px;
        width: 100%;
        margin-right: 10px;
        cursor: pointer;
        overflow: visible;

        &.empty {
          max-width: 105px;
        }

        .outer {
          width: 100%;
          padding-top: 146.66%;
          position: relative;
          overflow: visible;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: visible;
          }
        }

        .inner>div {
          transition: transform .25s ease;
        }

        .inner>div:hover {
          position: relative;
          transform-origin: center;
          transform: translateZ(0) scale(1.05);
          z-index: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 7px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }

      .cgl-item.more {
        transition: transform .25s ease;

        .inner {
          background: linear-gradient(to bottom, #001A6D, #0A122B);
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 30px;
          font-weight: bold;
          white-space: nowrap;
          border-radius: 27px;

        }


      }

      .cgl-item.more:hover {
        position: relative;
        transform-origin: center;
        transform: translateZ(0) scale(1.05);
        z-index: 1;
      }
    }
  }

  @media screen and (min-width:992px) {
    .cgl-content {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      max-height: 350px;

      .cgl-jackpot {
        min-width: 350px;
        max-width: 350px;
        height: 350px;

        .jackpot-state-wrapper {
          transform: unset;
          transform-origin: bottom;
        }
      }

      .cgl-list {
        margin-top: 0;
        padding-top: 0;
        height: 350px;
        width: 100%;
        flex: 1 1 100%;

        .cgl-grid {
          height: 350px;
        }
      }
    }
  }


  .egt-button {
    background: linear-gradient(to right, #E32002, #FFA113);
    height: 38px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    margin: 0 16px 0;
    position: relative;
  }
}

@media screen and (min-width:1528px) {
  .component-egt_jackpot {
    .cgl-list {
      overflow: hidden;
    }
  }
}