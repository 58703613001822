.betslip-full {
  position: relative;
  background: var(--ns-betslip-bg);
  color: var(--ns-betslip-color);
  padding: 0 10px 0 10px;

  .top-menu {
    position: relative;

    .tabs {
      margin: 0;
      border-bottom: 0;
      min-height: initial;

      .tab {
        font-size: 0.875em;
        font-weight: 300;
        color: var(--ns-betslip-tab-color);
        text-transform: capitalize;

        .badge {
          position: relative;
          top: -1px;
          display: inline-block;
          background: var(--ns-betslip-tab-badge-bg);
          color: var(--ns-betslip-tab-badge-color);
          border-radius: 50%;
          min-width: 20px;
          font-weight: 600;
          font-size: 11px;
        }
      }

      .MuiTabs-indicator {
        background: var(--ns-betslip-tab-indicator-bg);
      }

      .Mui-selected {
        font-size: 0.875em;
        font-weight: 300;
        color: var(--ns-betslip-tab-selected-color);
      }
    }

    .copy-message {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: #3B4550;
      border-bottom: 2px solid #FFAB1B;
      color: #FFAB1B;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 12px;
      font-weight: 600;
      cursor: pointer;

      .clear-status-icon {
        position: absolute;
        top: 0px;
        right: 5px;
        color: #999;

        svg {
          width: 16px;
        }
      }
    }
  }

  .bets-wrapper {

    .items-empty {
      height: 248px;
      padding: 40px;
      font-size: 1.375em;
      font-weight: 300;
      background-color: var(--ns-bets-empty-bg);

      .wrap {
        position: relative;
        top: 50%;
        transform: translateY(-50%);

        .items-empty-line {
          position: absolute;
          top: 0;
          left: 0;
          width: 25%;
          height: 1px;
          background: var(--ns-bets-empty-line-bg);
        }

        .items-empty-text {
          padding: 10px 0;
          color: inherit;
        }

        .items-empty-icon {
          float: left;
          width: 1.6em;
          height: 1.6em;
          padding: 4px 0 0 8px;
          border: 1px solid var(--ns-bets-empty-icon-border-color);
          border-radius: 50%;
          color: var(--ns-bets-empty-icon-color);
          text-align: center;
        }
      }
    }

    .panel-margin {
      margin: 0 1px;
    }

    .items {
      margin-top: 2px;
    }

    .footer-text {
      font-size: 12px;
      text-align: center;
      padding-bottom: 10px;
    }

  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .top-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 1px;
  }

  .ticket-no {
    background-color: #343638;
    color: #CCCCCC;
    font-size: 14px;
    height: 36px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    margin: 0 1px 0;
  }

  .ticket-no.dot:after {
    content: ' ';
    width: 5px;
    height: 5px;
    position: absolute;
    left: 16px;
    top: 28px;
    border-radius: 50%;
    background: #FFB433;
  }

  .ticket-no.notif {
    border: 3px solid #FFD68F;
    position: relative;

    &::after {
      content: ' ';
      position: absolute;
      bottom: -11px;
      width: 20px;
      height: 17px;
      background-image: url(../assets/arrow-up.png);
      background-size: cover;
      z-index: 1;
    }
  }

  @media (min-width: 992px) {
    .ticket-no {
      width: 45px;
    }

    .ticket-no.dot:after {
      left: 21px;
    }
  }

  .ticket-no.dot.live:after {
    background: #ff196e;
  }

  .ticket-no.dot.prematch:after {
    background: #1F84E6;
  }

  .ticket-no.active {
    background-color: #FFB433;
    color: #fff;
  }

  .ticket-copy {
    background-color: #343638;
    color: #FFD68F;
    font-size: 14px;
    height: 36px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 1;


    svg {
      width: 16px;
      height: 16px;
      position: relative;
      top: 1px;
    }
  }

  .bet-slip-header {
    position: absolute;
    top: -48px;
    left: 0;
    right: 0;
    height: 42px;
    color: #fff;
    background: var(--ns-header-bg);

    >div {
      height: 42px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      >svg {
        position: relative;
        top: 5px;
      }
    }
  }
}