.account-limits-dialog {
  font-family: "Open Sans", OpenSans;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }


  .MuiPaper-rounded {
    border-radius: 15px;
  }

  .clear {
    position: absolute;
    top: 16px;
    right: 13px;
    cursor: pointer;
    border-radius: 50%;
    background: #F4F4F4;
    color: #AA0808;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 16px;
      position: relative;
    }
  }

  .account-limits-dialog-body {
    padding: 0 20px;

    .logo {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;
      margin-bottom: 10px;
    }

    h2.title {
      font-size: 19px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
      color: #000;
    }

    div.divider {
      position: relative;
      font-size: 12px;
      font-weight: 600;
      text-align: center;
      text-transform: uppercase;
      color: #C72525;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;

      &>div {
        padding: 0 15px;
        background-color: #fff;
        position: relative;
      }

      &::before {
        content: "";
        position: absolute;
        top: 8px;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #C72525;
      }
    }

    div.limits {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin-bottom: 10px;

      &>div {
        flex: 1 1 auto;
        background-color: #F5F8FA;
        border-radius: 7px;
        padding: 12px 11px 19px;

        &>div.subtitle {
          font-size: 12px;
          font-weight: normal;
          color: #6F777F;
        }

        &>div.value {
          font-size: 24px;
          font-weight: bold;
          color: #151D26;

          span.currency {
            font-size: 15px;
            font-weight: bold;
            color: #151D26;
          }
        }
      }

      &>div.left-limit {
        margin-right: 5px;
      }

      &>div.right-limit {
        margin-left: 5px;

        &>div.value {
          color: #C72525;
          text-decoration: line-through;

          span.currency {
            color: #C72525;
            text-decoration: line-through;
          }
        }
      }
    }

    div.explanation {
      font-size: 11px;
      font-weight: 600;
      color: #C72525;
      margin-bottom: 50px;
    }

    .regular-button {
      width: 100%;
      height: 59px;
      border-radius: 10px;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      margin: .5em 0 .5em;
      background: linear-gradient(90deg, #295A91 0%, #2174E0 100%);

      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

    .cancel-button {
      width: 100%;
      height: 59px;
      border-radius: 10px;
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      margin: 0 0 1em;
      border: 2px solid #E90505;

      color: #E90505;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }

  }
}