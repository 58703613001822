.promo-calendar-choose-prize-dialog {
  z-index: 1304 !important;

  text-align: left;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 20px);
    margin: 10px;
  }

  .dialogContent {
    font-size: 12px;
    color: #17214D;
    font-weight: bold;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 30px;
    position: relative;

    .close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
      color: #64717D;
    }


    .dialogBody {

      .title {
        color: #1A1C1E;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-top: 28px;
        margin-bottom: 24px;
      }

      .prize {
        max-width: 320px;
        margin: 32px auto 20px;
        text-align: left;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 12px;

        .bonus {
          flex: 1 1 50%;
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 8px;
          border-radius: 8px;
          background: #EDEFF7;
          border: 2px solid #EDEFF7;
          padding: 24px 12px;
          position: relative;
          cursor: pointer;

          &.checked {
            border: 2px solid #7387FF;
            color: #fff;
          }

          .checkbox {
            width: 18px;
            height: 18px;
            position: absolute;
            right: 8.5px;
            top: 8px;
            border: 1px solid #7387FF;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;

            .sign {
              width: 14px;
              height: 14px;
              display: none;
              color: #fff;
            }

            &.checked {
              background: #7387FF;

              .sign {
                display: block;
                color: #fff;
              }
            }

          }

          .bonus-image {
            flex: 0 0 80px;
            width: 80px;
            height: 80px;
            border-radius: 6px;
            overflow: hidden;
            margin-bottom: 12px;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          texts {
            flex: 1 1 auto;
            width: 100%;
          }

          .bonus-title {
            width: 100%;
            color: #142232;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 16px;
            padding-bottom: 4px;
          }

          .bonus-subtitle {
            width: 100%;
            color: #64717D;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
          }
        }
      }
    }


    .dialogOK {
      width: 100%;
      padding: 13px 0;
      border-radius: 3px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 0;
      background: linear-gradient(90deg, #295A91 0%, #2174E0 100%);
      height: 45px;
      margin-left: 5px;
      text-transform: uppercase;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 8px;

      svg {
        color: #fff;
      }

      &.disable {
        filter: grayscale(1);
        pointer-events: none;
      }
    }
  }
}