.landscape-player-gallery {
  height: 100vh;
  width: 140px;
  position: relative;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  background-image: url('../assets/player-gallery-bg-landscape.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: flex-start;
  justify-content: center;



  .header-wrapper {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;

    .menus {
      display: flex;
      width: 140px;
      align-items: center;
      justify-content: space-between;
      padding: 3px 15px 0;

      .deposit {
        cursor: pointer;
        border-radius: 3px;
        height: 32px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 24px;
        background: linear-gradient(to right, #EC5E00, #981D91);
        border: 1px solid #FFC7DB;
        color: #fff;
        font-size: 10px;
        text-transform: uppercase;
        font-weight: 600;
        font-family: OpenSans, "Open Sans";
        margin: 5px 0;
      }

      .menu {
        width: 31px;
        height: 31px;
        border-radius: 50%;
        border: 2px solid #5D097A;
        background: #6c1ea8;
        color: #fff;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          display: block;

        }
      }

      .menu.close {
        border: 2px solid rgba(256, 256, 256, 0.5);
        background: rgba(0, 0, 0, 0.5);
      }
    }

    .header {
      height: 22px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 0;

      .tmr {
        display: flex;
        align-items: center;
        color: #BDBDBD;
        font-size: 10px;
        font-weight: 600;
        white-space: nowrap;

        img {
          display: block;
          margin-left: 4px;
          margin-right: 5px;
          width: 21px;
          height: 21px;
          opacity: .6;
        }
      }
    }

    .hr {
      height: 2px;
      width: 100%;
      background: linear-gradient(to right,
          #14248a00,
          rgba(28, 200, 232, .3) 40%,
          #1ef1ffcc 50%,
          rgba(31, 197, 230, .3) 60%,
          #232a8f00);
    }
  }

  .gallery-wrapper {
    width: 140px;
    overflow: visible;
    margin-top: 114px;
    position: relative;
    user-select: none;

    .empty {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 10px;
      color: #AAAFE7;
      font-weight: bold;
      padding: 0 5px;
    }

    .gallery {
      display: grid;

      grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
      grid-row-gap: 5px;
      position: relative;
      max-width: 140px;

      .container {
        position: absolute;
        width: 166px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .container.before {
        left: -189px;
      }

      .container.after {
        right: -189px;
      }
    }

    .gallery.float {
      position: absolute;
      top: 0px;
      width: 140px;
    }

    .item {
      pointer-events: none;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transform-origin: center;

      .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: linear-gradient(to bottom,
            #E494FF,
            #6B22A7);

        &.off {
          background: transparent;
        }

        img {
          margin: 2px;
          border-radius: 50%;
          width: 36px;
          height: 36px;
          display: inline-block;
        }
      }

      .pos {
        width: 32px;
        height: 11px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -6px;

        img {
          width: 32px;
          height: 11px;
          object-fit: contain;
        }

        .no {
          position: absolute;

          color: #BFBFBF;
          font-size: 10px;
          font-weight: bold;
          padding-bottom: 2px;
          white-space: nowrap;
        }
      }

      .score {
        margin-top: 3px;
        color: #00FFFF;
        font-size: 11px;
        font-weight: bold;
        line-height: 11px;
        white-space: nowrap;
      }
    }

    @media screen and (min-height: 500px) {
      .item {
        margin-top: 10px;
        margin-bottom: 10px;
      }
    }

    .item.current {
      .score {
        opacity: 0;
      }
    }

    .item.big {
      transform: scale(1.2);

      .avatar {
        background: linear-gradient(to bottom,
            #FFEE00,
            #FF9E00 45%,
            #C85D00 100%);

      }

      .score {
        margin-top: 1px;
        color: #fff;
        text-shadow: 0 1px 4px rgba(0, 255, 255, .75);
      }

      .pos {
        position: relative;

        .award-icon {
          position: absolute;
          bottom: 0px;
          right: -8px;

          img {
            width: auto;
            height: 16px;
            display: block;
            object-fit: contain;
          }
        }
      }
    }

    .item.bigger {
      transform: translate(0, -3px) scale(1.2);


      .pos {
        opacity: 0;
      }

      .score {
        opacity: 0;
        margin-top: 1px;
        color: #fff;
        text-shadow: 0 1px 4px rgba(0, 255, 255, .75);
      }
    }

    .item.float {
      position: absolute;
    }

    .item.float.pos-0 {
      top: 3px;
      margin-left: calc(50% - 20px);
    }

    .item.float.pos-1 {
      top: calc(29% - 28px);
      margin-left: calc(50% - 20px);
    }

    .item.float.pos-2 {
      top: calc(49% - 28px);
      margin-left: calc(50% - 20px);
    }

    .item.float.pos-3 {
      top: calc(69% - 28px);
      margin-left: calc(50% - 20px);
    }

    .item.float.pos-4 {
      top: calc(100% - 65px);
      margin-left: calc(50% - 20px);
    }
  }


}