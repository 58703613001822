@import "./../../../variables";

.PromotedGames {
  padding-bottom: 4rem;

  h1 {
    .texts {
      float: left;
      .text {
        color: $firstColor !important;
        padding-top: 5px;
        float: none;
        display: block;
      }

      .small-text {
        font-size: 12px;
        color: $grayColor;
        text-transform: none;
        display: block;
      }
    }

    .icon {
      .IconSVG {
        width: 24px;
        fill: $firstColor !important;

        .st22 {
          stroke-width:0.26458332;
        }
      }
    }
  }

}