@import "./../../variables";


.PlayerProfile {
    .profileContainer
    {
        opacity: 0;
        transition: all .4s ease-in;

        &.done {
            opacity: 1;
        }
    }

    .pageIcon {
        .docsIconPath {
            fill: $thirdColor;
        }

        .transactionsHistory {
            .cls-1{fill:#fff;}
            .cls-2,
            .cls-3{fill:none;stroke:#017048;stroke-miterlimit:10;stroke-width:1.5px;}
            .cls-2{stroke-linecap:round;}
        }
    }

    .page-header {
        .subpage_22 {
            .pageName {
                text-transform: capitalize !important;
                font-size: 16px !important;
                line-height: 26px;
            }
        }

        .pageIcon {
            .icon {
                .IconSVG {
                    height: 26px;
                }
            }
        }
    }
    @media screen and (max-width: 1600px) {
        .col {
            &.hide-custom {

                &:last-child {
                    display: none;
                }
            }
        }
        .expand-custom {
            flex: 0 0 75%;
            max-width: 75%;
        }
    }
    @media screen and (max-width: $hideMenuMaxWidth) {
        .col {
            &.hide-custom {
                display: none;
            }
        }
        .expand-custom {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.modal {
    .modal-content {
        .inputFieldMaterial {
            font-size: 14px;
        }
    }
}
