.component-games-list {
  margin: 10px auto;
  padding-bottom: 10px;
  max-width: 1528px;

  .before {
    display: none;
    z-index: 1;
  }

  .after {
    display: none;
    z-index: 1;
  }

  .cgl-super-header {
    margin: 0 10px;
    z-index: 2;
    position: relative;

    .category {
      color: #353540;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;

    .title {
      color: #353540;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      border: 1px solid #BEC9D6;
      border-radius: 11px;
      padding: 2px 12px 3px;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: -1px;
      }
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;

    .cgl-grid {
      width: 100%;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 5px;
      padding-top: 5px;
      overflow: visible;

      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 137px;
        width: 100%;
        margin-right: 2px;
        cursor: pointer;
        overflow: visible;

        &.empty {
          max-width: 137px;
        }

        .outer {
          width: 100%;
          padding-top: 146%;
          position: relative;
          overflow: visible;

          .inner {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: visible;
          }
        }

        .inner>div {
          transition: transform .25s ease;
        }

        .inner>div:hover {
          position: relative;
          transform-origin: center;
          transform: translateZ(0) scale(1.05);
          z-index: 1;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }

        &.more {
          .inner>div {
            margin: 4px 2px;
            height: calc(100% - 8px);
            background: linear-gradient(to bottom, #001A6D, #0A122B);
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 30px;
            font-weight: bold;
            white-space: nowrap;
            border-radius: 11px;
          }

          @media screen and (min-width:1528px) {
            display: none;
          }
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .cgl-grid.large {

      &::before,
      &::after {
        min-width: 9px;
        max-width: 9px;
      }


      .cgl-item {
        min-width: 164px;

        &.empty {
          max-width: 164px;
        }
      }
    }
  }
}

@media screen and (min-width:1528px) {
  .component-games-list {
    .cgl-list {
      overflow: hidden;

      .cgl-grid::before,
      .cgl-grid::after {
        display: none;
      }
    }
  }
}