.wf-tickets-history {
  padding-bottom: 160px;

  .wf-header {
    background: linear-gradient(to bottom, #721FEE, rgba(4, 8, 187, 0));
    padding: 8px 16px 23px;
    margin-bottom: -15px;
    border-radius: 7px 7px 0 0;

    .wf-close {
      display: flex;
      justify-content: flex-end;
      color: #fff;
      margin-bottom: 5px;
      margin-right: -8px;
    }

    .wf-info {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .wf-logo {
        background: linear-gradient(to right, #FF9D48, #FFF017);
        border-radius: 50%;

        width: 55px;
        min-width: 55px;
        height: 55px;

        display: flex;
        align-items: center;
        justify-content: center;

        img {
          border-radius: 50%;
          width: 49px;
          height: 49px;
        }
      }

      .wf-nickname {
        flex: 1 1 100%;
        font-size: 14px;
        font-weight: bold;
        text-align: left;
        color: #fff;
        padding: 0 10px;
      }

      .wf-points {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .wf-text {
          font-size: 9px;
          font-weight: 600;
          text-align: right;
          color: #fff;
          padding: 0 10px 5px 0;
        }

        .wf-wrapper {
          position: relative;
          background: linear-gradient(to bottom, #fcf81e 0%, #fca913 25.6%, #fcb915 42%, #b75f23 57.4%, #fcaa13 88.7%, #fce61b 100%);
          padding: 2px;
          border-radius: 27px;
          height: 30px;

          .wf-inner-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: stretch;
            border-radius: 27px;
            background: radial-gradient(circle, rgba(209, 64, 204, 1) 0%, rgba(27, 20, 100, 1) 67%, rgba(27, 20, 100, 1) 100%);
            padding: 0 4px;



            img {
              width: 21px;
              height: 21px;
              display: block;
              margin-right: 5px;
            }

            .wf-text {
              color: #fff;
              font-size: 20px;
              font-weight: 600;
              text-shadow: 1px 1px rgba(0, 0, 0, 1);
              width: 100%;
              text-align: center;
              padding-top: 2px;

            }
          }

          &.wf-full .wf-inner-wrapper {
            background: radial-gradient(circle, #DA494E 0%, #A51419 67%, rgb(102, 0, 4) 100%);
          }
        }
      }
    }
  }

  .wf-empty {
    text-align: center;
    padding: 40px 0;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
  }

  .wf-show-more {
    text-align: center;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
    padding: 20px 0 0;
    color: #fff;
    cursor: pointer;
  }


}