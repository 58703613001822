@import "./../../../variables";

.DepositModal {
  z-index: 99998;
  //min-width: 615px;
  width: 100%;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  .modal-content {
    border-radius: 0px;
    border-color: rgba(0, 0, 0, 0.0);
    position: relative;
    z-index: 99999;
    animation: normalModal .5s ease-in;
    width: 615px;

    .modal-header {
      background-color: $withdrawModalGreen;
      border-radius: 0;
      text-align: center;
      padding: 1.5rem 1rem;
      height: 74px;
      position: relative;
      animation: showModalHead .5s ease-in;

      .modal-title {
        text-align: center;
        font-size: 0.9rem;
        line-height: 1.6rem;
        font-weight: 700;
        text-transform: uppercase;
        width: 100%;
      }

      .close {
        position: relative;
      }
    }

    .WithdrawModalBody {
      overflow: auto;
      padding: 0;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #ffffff;
    }

    .depositMessage {
      background-color: #ffffff;
      color: $firstColor;

      .top {
        padding-top: 40px;
        padding-bottom: 40px;
        border-bottom: 1px solid $withdrawHorLine;

        h1 {
          width: 100%;
          text-align: center;
          text-transform: uppercase;
          font-size: 20px;
        }
      }

      .middle {
        padding-top: 40px;
        text-align: center;

        .headLine {
          width: 240px;
          margin: 0 auto;
          background-color: #ffffff;
          position: relative;
          top: -49px;
          color: #b3b3b3;
          font-size: 14px;
          text-transform: uppercase;

          h2 {
            font-size: 14px;
            font-weight: 600;
          }
        }

        .errorChar {
          .pwSuccess {
            color: $historyTransactionFailed;
          }
        }

        .successChar {
          position: relative;
          top: -30px;
          min-height: 110px;
        }

        .message {
          text-align: left;
          padding-right: 80px;
          padding-left: 80px;
        }
      }

      .sub-msg {
        padding-left: 80px;
        padding-top: 40px;
      }

      .bottom {
        padding: 20px 80px 40px;

        button {
          width: 100%;
          background-color: $thirdColor;
          border-color: $thirdColor;
          font-weight: 600;
          height: 66px;
          font-size: 1.4rem;
          position: relative;
          top: 0;

          &:hover {
            background-color: lighten($thirdColor, 3%);
          }

          &:active {
            top: 2px;
            background-color: lighten($thirdColor, 6%) !important;
          }

          &.btn-info {
            background-color: #ffffff;
            color: $infoColor;
            border-color: $thirdColor;

            &:hover {
              background-color: $thirdColor;
              color: #ffffff;
              border-color: $thirdColor;
            }
          }
        }
      }
    }
  }

  button,
  a {
    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }
  }
}