.LayoutMessages {
	background: #ed003f;
	display: flex;
	align-items: center;
	align-self: stretch;
	cursor: pointer;
	padding: 12px;
	width: 100dvw;
	box-sizing: border-box;
	
	.msgContent {
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	  flex: 1 0 0;
  
	  img {
		width: 24px;
		height: 24px;
	  }
  
	  h1 {
		color: #fff;
		text-align: center;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: 109%;
		text-transform: uppercase;
  
		.underline {
		  text-decoration-line: underline;
		}
  
		.strong {
		  font-weight: 700;
		}
	  }
	}
  }
  
  @media (min-width: 900px) {
	.LayoutMessages {
	  position: sticky;
	  top: 85px;
	  .msgContent {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 24px;
	  }
	}
  }
  