.live-casino-left-menu {
  margin: 0;
  width: 100%;
  position: sticky;
  top: 57px;
  z-index: 1;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, .4);

  .bar-tabs-menu {
    background: linear-gradient(to right, #0E258A, #00134A);

    .MuiTab-root {
      padding: 6px 10px;
      min-width: 50px;
    }
  }
}

.live-casino-left-menu.mobile {
  display: block;
}

.live-casino-left-menu.desktop {
  display: none;
}

.live-casino-wrapper {
  min-height: 100vh;
  /*
  background: linear-gradient(180deg, #210213 0%, #541336 20%, #210213 50%, #0b110b 100%);
  */

  background: linear-gradient(225deg, #0c0c2d 0%, #0023c7 28.5%, #101c84 52.7%, #181439 100%);
  background-position: 0px 48px;
}

.live-casino-navbar-mobile {
  margin-top: calc(57px + var(--notch-top-offset, 0px)) !important;
}

.live-casino-home {
  margin: 0 auto;
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;

  .live-casino-header {
    padding: 15px 10px 0;
    width: calc(100% - 20px);

    .live-casino-header-subtitle {
      text-align: left;
      font-weight: bold;
      font-size: 11px;
      color: #fff;
    }

    .live-casino-header-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      span {
        display: block;
        text-align: left;
        font-weight: normal;
        font-size: 18px;
        color: #fff;
      }

      .live-casino-header-table-count {
        font-weight: 600;
        font-size: 11px;
        color: #979DAB;
      }
    }
  }

  .right-content {
    flex: 1 1 auto;
    margin-top: 30px;
    margin-left: 5px;
    margin-right: 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;

    .live-casino-table {
      flex: 1 1 150px;
      margin: 0 5px 10px;
      border-radius: 5px;
      /*
      background-color: #3c537c;
      border: 1px solid #3c537c;
      */
      overflow: hidden;
      position: relative;
      cursor: pointer;
      max-width: calc(50% - 10px);

      &.table-close {
        pointer-events: none;
        cursor: default;
        filter: grayscale(100%);

        .table-play-icon {
          display: none !important;
        }
      }

      .table-image {
        width: 100%;
        height: 150px;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .table-name {
        color: #fff;
        font-size: 14px;
        text-align: left;
        font-weight: 600;
        padding: 5px 10px;
      }

      .table-play-icon {
        opacity: .4;
        width: 60px;
        height: 60px;
        position: absolute;
        top: calc(50% - 40px);
        left: calc(50% - 30px);

        .table-play-icon-svg {
          .circle {
            fill: rgba(255, 255, 255, .4);
          }

          .play {
            fill: rgb(255, 255, 255);
          }
        }
      }

      &:hover {
        .table-play-icon {
          opacity: 1;
        }
      }
    }
  }

  .right-content-table {
    text-align: left;

    .go-back {
      text-align: left;
      text-decoration: none;
      font-weight: bold;
      display: block;
      margin-bottom: 10px;
    }
  }
}

.live-casino-wrapper-table {
  background: #000;
  z-index: 10000;
  padding-top: calc(var(--notch-top-offset, 0px));

  .table {
    width: 100vw;

    .table-header {
      background: #000;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, .4);
      text-align: right;
      z-index: 1;

      /*
      position: absolute;
      width: 50px;
      height: 48px;
      left: calc(50% - 25px);
      */

      button {
        color: #fff;
      }
    }

    .table-game {
      width: 100vw;
      transition: width 0.3s ease 0s, height 0.3s ease 0s, right 0.3s ease 0s, bottom 0.3s ease 0s;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      position: relative;
      height: calc(100vh - 52px - var(--notch-top-offset, 0px));
      padding-bottom: 4px;

      .evolution-iframe.d-none {
        display: none !important;
      }

      img {
        width: 100vw;
        height: calc(100vh - 52px - var(--notch-top-offset, 0px));
        object-fit: cover;
        cursor: pointer;
      }

      iframe {
        width: 100%;
        height: 100%;
      }

      .table-resource-info {
        position: absolute;

        &>div {
          color: #ffffff;
          font-weight: 600;
          font-size: 16pt;
          white-space: normal;
        }
      }

      .table-play-icon {
        width: 80px;
        height: 80px;
        position: absolute;
        cursor: pointer;

        .table-play-icon-svg {
          .circle {
            fill: rgba(255, 255, 255, .4);
          }

          .play {
            fill: rgb(255, 255, 255);
          }
        }
      }

      .table-loader {
        width: 80px;
        height: 80px;
        position: absolute;
        cursor: pointer;

        >div {
          width: 80px !important;
          height: 80px !important;
          color: #fff;
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .table {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1021;
    }
  }
}

@media only screen and (min-width: 560px) {
  .live-casino-home {
    .right-content {
      .live-casino-table {
        flex: 1 1 auto;
        min-width: 300px;
        max-width: 300px;
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 15px;
        background-color: #3c537c;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .table-image {
          height: 200px;
        }

        .table-name {
          padding: 10px;
        }

        .table-play-icon {
          opacity: .3;
          width: 80px;
          height: 80px;
          position: absolute;
          top: 60px;
          left: 110px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .live-casino-wrapper {
    background-position: 0px 0px;
  }

  .live-casino-navbar-mobile {
    display: none;
  }

  .live-casino-left-menu.mobile {
    display: none;
  }

  .live-casino-left-menu.desktop {
    display: block;
  }

  .live-casino-left-menu {
    position: sticky;
    top: 120px;
    margin: 0 10px 0 20px;
    min-width: 200px;
    max-width: 200px;
    display: flex;
    flex-direction: column;
    box-shadow: none;

    .menu-item {
      &>div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-align: left;
        text-decoration: none;
        cursor: pointer;
        margin-bottom: 5px;

        .menu-item-name {
          font-weight: normal;
          font-size: 16px;
          color: #fff;
        }

        .menu-item-tables {
          font-weight: 600;
          color: rgba(255, 255, 255, .8);
          font-size: 14px;
        }
      }

      &.active>div {

        .menu-item-name {
          font-weight: bold;
        }

        .menu-item-tables {
          font-weight: bold;
        }
      }
    }
  }


  .live-casino-wrapper {
    padding: 30px 0;
    min-height: auto;
    box-sizing: border-box;
  }

  .live-casino-home {
    flex-direction: row;
    margin: 0 auto;
    box-sizing: border-box;
    padding-top: 0;

    .live-casino-header {
      display: none;
    }

    .right-content {
      flex: 1 1 auto;
      margin: 0 20px 0 10px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: flex-start;

      .live-casino-table {
        flex: 1 1 auto;
        min-width: 300px;
        max-width: 300px;
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 15px;
        background-color: #3c537c;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        .table-image {
          height: 220px;
        }

        .table-name {
          padding: 10px;
        }

        .table-play-icon {
          opacity: .3;
          width: 80px;
          height: 80px;
          position: absolute;
          top: 60px;
          left: 110px;
        }
      }
    }
  }
}

body.table-full-screen {
  height: calc(100vh + 100px);
  background: #000;

  #root {
    background: #000;

    &>.App {
      background: #000;

      .App-body {
        background: #000;

        .pageContent {
          background: #000;

          .animatedPageContent {
            background: #000;
          }
        }
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    overflow: hidden !important;
    height: 100vh !important;

    .live-casino-wrapper-table {
      min-height: 100vh;
    }
  }
}