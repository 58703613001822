.rcs-custom-scroll {
  $customScrollBarMargin: 3px;
  $scroll-margin-top: 6px;
  min-height: 0;
  min-width: 0;

  .rcs-outer-container {
    overflow: hidden;

    .rcs-positioning {
      position: relative;
    }

    &:hover .rcs-custom-scrollbar {
      opacity: 1;
      transition-duration: 0.2s;
    }
  }

  .rcs-inner-container {
    overflow-x: hidden;
    overflow-y: scroll;

    -webkit-overflow-scrolling: touch;

    &:after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 0;
      background-image: linear-gradient(to bottom,
          rgba(0, 0, 0, 0.2) 0%,
          rgba(0, 0, 0, 0.05) 60%,
          rgba(0, 0, 0, 0) 100%);
      pointer-events: none;
      transition: height 0.1s ease-in;
      will-change: height;
    }

    &.rcs-content-scrolled:after {
      height: 5px;
      transition: height 0.15s ease-out;
    }
  }

  &.rcs-scroll-handle-dragged .rcs-inner-container {
    user-select: none;
  }

  .rcs-custom-scrollbar {
    position: absolute;
    height: 100%;
    width: 6px;
    right: $customScrollBarMargin;
    opacity: 0;
    z-index: 1;
    transition: opacity 0.4s ease-out;
    padding: 6px 0;
    box-sizing: border-box;
    will-change: opacity;
    pointer-events: none;

    &.rcs-custom-scrollbar-rtl {
      right: auto;
      left: $customScrollBarMargin;
    }
  }

  &.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
    opacity: 1;
  }

  .rcs-custom-scroll-handle {
    position: absolute;
    width: 100%;
    top: 0;
  }

  .rcs-inner-handle {
    height: calc(100% - #{2 * $scroll-margin-top});
    margin-top: $scroll-margin-top;
    background-color: rgba(#4eb7f5, 0.7);
    border-radius: 3px;
  }
}