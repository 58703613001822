.wf-ranking-history {
  padding-bottom: 160px;

  &>.wfrh-header {
    background: linear-gradient(to bottom, #721FEE, rgba(4, 8, 187, 0.7));
    padding: 8px 16px 23px;
    margin-bottom: -15px;
    border-radius: 7px 7px 0 0;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .wfrh-close {
      color: #fff;
    }

    .wfrh-info {
      flex: 1 1 100%;
      color: #fff;
      font-size: 13px;
      font-weight: bold;
    }
  }

  .wf-empty {
    text-align: center;
    padding: 40px 0;
    font-size: 15px;
    font-weight: 600;
    color: #fff;
  }

  .wf-show-more {
    text-align: center;
    font-size: 14px;
    text-decoration: underline;
    font-weight: 600;
    padding: 20px 0 0;
    color: #fff;
    cursor: pointer;
  }

  .wf-ranking {
    border-radius: 7px;
    background: #fff;

    .wf-rows {
      border-radius: 0 0 7px 7px;
      /*
      max-height: calc(var(--inner-height, 100vh) - 283px - var(--notch-bottom, 0px));
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 3px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;

      }

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(to bottom, #FFEF00, #FF9900);
        border-radius: 9px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(to bottom, #FFEF00, #FF9900);
      }
      */
    }

    .wf-row {
      display: flex;
      align-items: center;
      justify-content: stretch;
      cursor: pointer;

      padding: 0 10px;

      width: 100%;
      height: 48px;
      border-bottom: 1px solid rgba(183, 205, 216, .35);

      &.wf-row-0 {
        background: #EAF4FB;
      }

      &.wf-row-1 {
        background: #F0F7FC;
      }

      &.wf-row-2 {
        background: #FAFCFE;
      }

      &>div {
        flex: 1 1 auto;
      }

      .wfrh-header {
        color: #979DAB !important;
        font-size: 9px !important;
        font-weight: bold !important;
        padding: 0 2px;
      }

      .wf-col.wf-left {
        text-align: left;
      }

      .wf-col.wf-right {
        text-align: right;
      }

      .wf-col.wf-center {
        text-align: center;
      }

      .wf-col1 {
        width: 25px;
        min-width: 25px;
        max-width: 25px;

        &>div {
          font-size: 10px;
          font-weight: 600;
          color: #586682;
          text-align: center;
          border-radius: 50%;
          margin: 0 auto;
        }

        .wf-gold {
          background: linear-gradient(to right, #FF971D, #FFB400);
          border: 1px solid #FCC735;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-silver {
          background: linear-gradient(to right, #B5C9DC, #9AC7F2);
          border: 1px solid #81A0BE;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-bronze {
          background: linear-gradient(to right, #EB7120, #C65F05);
          border: 1px solid #F6A169;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-regular {
          border: 1px solid rgba(255, 255, 255, 0);
          height: 16px;
        }
      }

      .wf-col2 {
        width: 27px;
        min-width: 27px;
        max-width: 27px;

        img {
          display: block;
          width: 27px;
          height: 27px;
        }
      }

      .wf-col3 {
        padding: 0 10px;
        color: #17214D;
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .wf-col4 {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        color: rgba(23, 33, 77, .24);
        font-size: 10px;
        font-weight: bold;
      }

      .wf-col5 {
        width: 80px;
        min-width: 80px;
        max-width: 80px;
        color: #17214D;
        font-size: 10px;
        font-weight: bold;
      }

      &.wf-show-more {
        height: auto;

        .wf-col-full {
          padding: 20px 10px;
          color: #17214D;
          font-size: 14px;
          text-decoration: underline;
          font-weight: 600;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }

    .wf-row.wfrh-header {
      height: 29px;
    }

    .wf-row:last-child {
      border-radius: 0 0 7px 7px;
    }

    .wf-row.wf-active {
      background: linear-gradient(to right, #FF7700, #FFC400);
      padding: 0 5px 0 10px;
      border-bottom: 1px solid transparent;

      position: sticky;
      bottom: 0;
      top: 0;

      .wf-col1>div {
        color: #fff;
      }

      .wf-col2 {
        width: 35px;
        min-width: 35px;
        max-width: 35px;

        img {
          display: block;
          width: 35px;
          height: 35px;
        }
      }

      .wf-col3 {
        font-size: 14px;
        color: #fff;
      }

      .wf-col4 {
        width: auto;
        min-width: unset;
        max-width: unset;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        white-space: nowrap;
        padding-right: 5px;
      }

      .wf-col5 {
        width: auto;
        min-width: unset;
        max-width: unset;
        border-radius: 11px;
        border: 1px solid #000000;
        background: #fff;
        color: #17214D;
        font-size: 12px;
        text-align: center;
        height: 22px;
        line-height: 22px;
      }

      .wf-edit-nickname {
        white-space: nowrap;
        display: flex;
        align-items: center;
        cursor: pointer;

        .wf-edit-icon {
          border-radius: 50%;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          display: inline-block;
          margin-left: 5px;

          svg {
            display: block;
            width: 18px;
            height: 18px;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }



}