.bets-live-final-win {
  z-index: 1301 !important;

  & .MuiPaper-rounded {
    background: transparent;
    margin: 0;
  }

  @media screen and (min-width:1024px) {
    .MuiDialog-paperFullWidth {
      width: auto;
      transform: scale(1.5);
    }
  }

  .dialog-content {
    background: transparent;
    padding: 0 !important;
    margin: 0;

    .bets-live-ended {
      //position: absolute;
      //width: 100vw;
      //height: 100%;
      //background: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      overflow: hidden;

      * {
        box-sizing: border-box;
      }

      &.ended-landscape {
        align-items: center;
      }

      &.ended-portrait {
        align-items: start;
      }

      .ended-wrapper {
        background: linear-gradient(180deg, #004DFF 0, #2FFFF1 100%);
        padding: 4px;
        border-radius: 12px;
        min-width: 280px;

        .ended-innerWrapper {
          height: 100%;
          background: linear-gradient(180deg, #113392 0, #0044FF 100%);
          position: relative;
          padding: 17px 27px 32px 27px;
          border-radius: 8px;

          &>.ended-fi {
            position: absolute;
            top: 14px;
            right: 14px;

            svg {
              width: 29px;
              height: 29px;
            }
          }

          &>.ended-title {
            background-image: radial-gradient(closest-side, rgba(2, 160, 255, 0.5), rgba(182, 0, 250, 0));
            color: #C4CEFF;
            font-size: 18px;
            font-weight: bold;
            text-align: center;
            max-width: 270px;
            margin: 0 auto;
          }

          &>.ended-subtitle {
            margin-top: 4px;
            color: #ffffff;
            text-align: center;
            font-size: 12px;
            font-weight: normal;

            .ended-semibold {
              font-weight: 600;
            }
          }

          .ended-prizes {
            display: flex;
            align-items: center;
            height: 130px;
            justify-content: center;
            margin-top: 39px;

            .ended-prize-miss,
            .ended-prize-won {
              min-width: 140px;
              height: 100%;
              padding: 7px 20px 17px;
              background: rgba(0, 0, 0, 0.41);
              border-radius: 11px;

              &>.ended-title {
                text-align: center;
                font-size: 12px;
                font-weight: normal;
                color: #ffc4d6;
              }

              &>.ended-gift {
                margin-top: 15px;
                text-align: center;

                img {
                  width: 40px;
                  height: 38px;
                }
              }

              &>.ended-amount {
                margin-top: 0px;
                white-space: nowrap;
                text-align: center;
                font-size: 22px;
                font-weight: bold;
                color: #ffd23f;
              }
            }

            .ended-prize-miss {
              margin-right: 6px;

              &>.ended-amount {
                color: #cfcfcf;
              }
            }

            .ended-prize-won {
              margin-left: 6px;
            }
          }
        }
      }

      &.ended-portrait {
        .ended-wrapper {
          width: 100%;
        }
      }

      &.ended-landscape {
        .ended-wrapper {
          .ended-innerWrapper {
            &>.ended-fi {
              top: 5px;
            }
          }
        }
      }
    }

  }
}