.wf-my-progress {
  width: 41px;
  height: 41px;
  line-height: 41px;
  background: none;
  margin: 0 auto;
  box-shadow: none;
  position: relative;
}

.wf-my-progress>svg {
  display: block;
  position: relative;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}