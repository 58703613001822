.lotto-left-nav {
  position: absolute;
  top: 45px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  overflow: auto;
  font-size: 0.8125em;
  z-index: 300;
  background-color: #EEF0F1;
  transition: width 0.5s;
  scrollbar-color: #c1c1c1 #f1f1f1;
  scrollbar-width: thin;
  padding: 4px 0;

  .needs,
  .empty {
    font-size: 1em;
    text-align: center;
    padding: 10px;
    color: #000;
  }

  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: #c1c1c1;
    border-radius: 3px;
  }

  .ReactVirtualized__Grid.ReactVirtualized__List {
    outline: none !important;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 0px;
    }

    &::-webkit-scrollbar-thumb {
      background: #c1c1c1;
      border-radius: 3px;
    }
  }

  .navigation-group {
    display: block;
    margin: 24px 0 16px 0px;
    padding: 0 8px;
    border-bottom: 0;
    font-size: 20px;
    font-weight: 600;
    color: #222E63;
    height: 26px;
    line-height: 26px;
    border-left: 3px solid #F8991C;
  }

  .navigation-item {
    color: #17214D;
    background: #D1E1E9;
    position: relative;
    min-height: 40px;
    padding: 12px 50px 0 0;
    outline: none;
    transition: color 0.25s linear;
    border-radius: 7px;
    margin: 0 4px 4px;

    cursor: pointer;

    .icon {
      width: 20px;
      margin-right: 10px;
      float: left;

      .flg {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        overflow: hidden;
        border: 0;
        box-shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.10);
        position: relative;
        top: -2px;

        &>div {
          width: 0;
          margin: auto;
          display: flex;
          justify-content: center;
        }

        & img,
        & svg {
          flex: 1 0 auto;
          width: auto;
          height: 20px;
          display: block;
          margin: auto;
        }

        & svg {
          height: 16px;
          position: relative;
          top: 2px
        }
      }
    }

    .label {
      display: block;
      //max-width: 65%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      color: #17214D;
      font-weight: bold;
      text-transform: uppercase;

      &.extended {
        display: flex;
        flex-direction: column;
        height: 20px;
        overflow: visible;
        position: relative;
        top: -7px;

        .dt {
          font-weight: normal;
          font-size: 11px;
          line-height: 11px;
        }
      }
    }

    .match-count {
      position: absolute;
      top: 12px;
      right: 26px;
      color: #17214D;
      padding-left: 5px;
      font-size: 12px;
      font-weight: bold;
    }

    .match-count.no-right {
      right: 20px;
    }

    .navigation-item-arrow {
      position: absolute;
      right: 0;
      top: 0;
      display: none;
      width: 30px;
      height: 30px;
      color: #17214D;
      font-size: 0.8em;
      font-weight: 400 !important;
      text-align: center;
      line-height: 30px;
      padding-top: 12px;

      svg {
        opacity: .57;
        width: 0.8em;
        height: 0.8em;
      }
    }
  }

  .navigation-item-wrapper {
    padding: 0 4px 4px 4px;

    .navigation-item {
      margin: 0 !important;
    }
  }

  .navigation-item.single {
    padding: 16px 10px 0;

    .label {
      width: 100%;
    }
  }

  .navigation-item.category {
    background-color: #f8f9f9;
    margin-left: 12px;
  }

  .navigation-item.category:hover,
  .navigation-item.category.active {
    background-color: #dbe1e1;

    .label {
      color: #001C98;
    }
  }

  .navigation-item.category.full {
    padding-left: 10px;
    padding-right: 10px;

    .label {
      text-align: center;
    }
  }

  .navigation-item.tournament {
    background-color: #f8f9f9;
    margin-left: 20px;
    padding-left: 10px;
  }

  .navigation-item.tournament:hover,
  .navigation-item.tournament.active {
    background-color: #dbe1e1;

    .label {
      color: #001C98;
    }
  }

  .navigation-item.tournament.full {
    background: #D1E1E9;
    margin-left: 4px;
    padding-left: 10px;
    padding-right: 10px;

    &:hover,
    &.active {
      background-color: #b0cbd9;
    }
  }

  .navigation-item.sport:hover,
  .navigation-item.sport.active {
    background-color: #b0cbd9;

    .label,
    .match-count {
      color: #001C98;
    }
  }

  .navigation-item.active .navigation-item-arrow,
  .navigation-item:hover .navigation-item-arrow {
    display: block;
  }
}

.lotto-left-nav {
  .table {
    font-size: 16px;

    .table-header:hover .navigation-item-arrow {
      display: block
    }

    .table-header {
      color: #17214D;
      background: #D1E1E9;
      position: relative;
      min-height: 40px;
      padding: 16px 16px 0 8px;
      margin-bottom: 4px;
      outline: none;
      transition: color 0.25s linear;
      border-radius: 7px;
      margin: 4px;

      cursor: pointer;

      .match-count {
        position: absolute;
        top: 16px;
        right: 26px;
        color: #17214D;
        padding-left: 5px;
        font-size: 12px;
        font-weight: bold;
      }

      .navigation-item-arrow {
        position: absolute;
        right: 0;
        top: 0;
        display: none;
        width: 30px;
        height: 30px;
        color: #17214D;
        font-size: 0.8em;
        font-weight: 400 !important;
        text-align: center;
        line-height: 30px;
        padding-top: 11px;

        svg {
          opacity: .57;
          width: 0.8em;
          height: 0.8em;
        }
      }

      .icon {
        width: 13px;
        margin-right: 10px;
        margin-left: 10px;
        float: left;

        svg {
          width: 16px;
          height: 16px;
          position: relative;
          top: -3px;
        }

        .flg {
          transform-origin: 0 -3px;
          transform: scale(0.5);
        }
      }

      .table-title {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 12px;
        color: #17214D;
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    .table-row {
      background: #fff;
      color: #17214D;
      margin: 2px 4px;
      border-radius: 7px;
      cursor: pointer;

      .match-item {
        min-height: 60px;
        position: relative;
        display: block;
        border: 0;
        font-size: 0.75em;
        text-decoration: none;
        color: #17214D;

        &.active {
          background-color: rgba(0, 113, 184, 0.25);
          border-color: #0071b8;
        }
      }

      .match-time-interval,
      .match-item-time {
        padding-left: 30px;
      }

      .match-time-interval,
      .match-time {
        padding-left: 0;
        position: absolute;
        left: 10px;
        top: 10px;
        font-weight: 700;
        color: #009ED9;
      }

      .match-time-interval {
        color: #06D2BD,
      }

      .match-time {
        top: 33px;
      }

      .match-favorite {
        display: none;
        position: absolute;
        left: 10px;
        bottom: 2px;
        color: #898989;
        cursor: pointer;
        line-height: 1;

        svg {
          width: auto;
          height: 16px;
          vertical-align: middle;
        }
      }

      .match-favorite.active {
        display: block;
        color: #ffc400;
      }

      .match-title {
        width: 100%;
        padding: 5px 10px 10px;
        line-height: 1.2;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #17214D;
        margin-bottom: 5px;

        .match-team {
          position: relative;
          width: 100%;
          padding-right: 10%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          font-weight: 600;
          padding: 2px 10% 5px 0;
        }
      }

      .match-result {
        position: absolute;
        right: 0;
        top: 0;
        color: #ffc107;
        font-weight: 700;
      }

      .match-locked {
        position: absolute;
        right: 7px;
        bottom: 1px;
        color: #898989;
        line-height: 1;

        svg {
          width: auto;
          height: 14px;
          vertical-align: middle;
        }
      }

      @media screen and (min-width: 992px) {
        .match-item:hover .match-favorite {
          display: block;
        }
      }
    }
  }

  .h0 {
    height: 0;
  }

  .dnone {
    display: none;
  }
}

.lotto-left-nav.live {
  top: 93px
}

.lotto-left-nav-tabs-menu {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}