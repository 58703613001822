@import "./../../../../variables";

@keyframes button-loading-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(-360deg); }
}

.wActionModal {
  .modal-content {
    border-radius: 0;
    border-color: rgba(0, 0, 0, 0.0);

    .wModalHeader {
      color: $black;
      text-align: center;
      text-transform: uppercase;
      border-bottom: 0;

      h5 {
        width: 100%;
        font-size: 14px;
        font-weight: bold;
      }

      button.close {
        position: absolute;
        top: -5px;
        right: 0;
        font-size: 60px;
        font-weight: normal;
        opacity: 1;
        color: $thirdColor;

        &:hover {
          opacity: 0.8;
        }
      }
    }

    .wModalBody {
      color: $black;

      .wContent {
        text-align: center;
      }
      .wErrors {
        text-align: center;
        color: red;
      }
      .wButtons {
        display: flex;

        .wSupport {
          width: 50%;
          padding-left: 12%;
          padding-right: 12%;
          padding-top: 20px;
        }
      }

      .cancelButton {
        border-color: #d05c61;
        border-width: 2px;
        color: #941b20;
        text-transform: capitalize;
        font-weight: bold;

        &:hover {
          color: $white;
        }
      }

      .actionButton {
        color: $buttonGreen;
        font-weight: bold;
        background-color: $buttonYellow;

        &:hover {
          background-color: lighten($buttonYellow, 5%);
        }

        .buttonLoader {
          padding-top: 5px;
          line-height: 14px;
        }
      }

      .fast-deposit-support {
        padding: 20px 26% 30px 26%;

        .input-group {
          text-align: center;

          input {
            text-align: center;
            font-weight: bold;
            font-size: 1.2rem;
          }

          label {
            margin: 0 auto;
            text-align: center;
          }
        }
      }
    }

    .btn {
      position: relative;
      top: 0;
      transition: all 0.1s ease-in;

      &:focus,
      &:active {
        outline: none !important;
        box-shadow: none !important;
      }

      &:active {
        top: 2px;
      }
    }

    .currency-value {
      display: inline-block;
      font-weight: bold;
    }
  }
}