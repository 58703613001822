@import "./../../../../variables.scss";

.PromotedGames {
  padding-bottom: 20px;
  padding-top: 20px;
  .GamesCards {
    .gameColumn.big {
      grid-column: span 2;
      grid-row: span 2;
    }
  }
}