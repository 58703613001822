@import "./../../../../variables";

.DocumentsUploadModal {
    width: 90%;
    max-width: 1080px !important;

    @media screen and (max-height: 800px) {
        width: 100%;
        top: 0px;
        left: 0px;
        position: fixed;
    }

    .modal-content {
        border-radius: 0;
        border-color: rgba(0, 0, 0, 0.0);

        @media screen and (max-height: 800px) {
            position: fixed;
            bottom: 0px;
            left: 0px;
            top: 0;
        }

        .DocumentsUploadModalHeader {
            background-color: $docsUploadModalHeader;
            border-radius: 0;

            .modal-title {
                color: $buttonGreen;
                text-align: center;
                width: 100%;
                text-transform: uppercase;
                font-size: 15px;
                font-weight: bold;
            }
        }

        .DocumentsUploadModalBody {
            height: 800px;
            overflow: hidden;
            color: $black;
            padding: 80px;

            @media screen and (max-height: 640px) {
                height: auto;
                overflow: auto;
                padding-top: 20px;
                padding-bottom: 0px;
            }

            @media screen and (max-height: 800px) {
                height: auto;
                overflow: auto;
                padding-top: 40px;
                padding-bottom: 0px;
            }

            .main-screen {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;

                .inRemove {
                    fill: $iconGrayTwo;
                }

                .area {
                    flex-grow: 2;
                    width: 50%;

                    .file-drag-and-drop {
                        content: "";
                        padding: 10px;
                        border: dashed 2px $grayColor;
                        border-collapse: separate;
                        border-spacing: 10pt;
                        min-height: 450px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        @media screen and (max-height: 460px) {
                            //min-height: 380px;
                            min-height: 100%;
                        }

                        .upload-area-middle {
                            max-width: 80%;
                            text-align: center;
                            width: 80%;

                            .cloud-upload-icon {
                                height: 82px;

                                .cls-1 {
                                    fill: $iconGray;
                                }
                            }

                            .msg-1 {
                                text-transform: uppercase;
                                font-size: 15px;
                                font-weight: bold;
                                color: $almostBlack;
                                margin-top: 36px;
                                margin-bottom: 0;
                            }

                            .msg-2,
                            .msg-3 {
                                font-size: 12px;
                                font-weight: bold;
                                color: $grayColor;
                                margin-top: 10px;
                                margin-bottom: 10px;
                            }

                            .msg-3 {
                                margin: 0;
                            }

                            .btn {
                                margin-bottom: 29px;
                                border-color: $thirdColor;
                                color: $thirdColor;
                                font-size: 14px;
                                font-weight: bold;
                                border-width: 2px;
                                padding-left: 22%;
                                padding-right: 22%;
                                border-radius: 7px;

                                &:hover {
                                    background-color: $thirdColor;
                                    color: $white;
                                }

                                &:focus,
                                &:active {
                                    outline: none !important;
                                    box-shadow: none !important;
                                }

                                &:active {
                                    top: 2px;
                                }
                            }
                        }
                    }

                    &.files-list {
                        padding-left: 1.8rem;
                        overflow: auto;
                        max-height: 520px;

                        ul {
                            list-style: none;
                            padding-left: 0;
                            margin: 0;

                            li {
                                padding-top: 25px;
                                padding-bottom: 25px;
                                border-bottom: 2px solid $iconGray;

                                &:first-child {
                                    padding-top: 0;

                                    .icon {
                                        padding-top: 0;
                                    }
                                }

                                &:last-child {
                                    border-bottom: 0;
                                }
                            }

                            &.fileErrors {
                                list-style-type: disc;
                                margin-bottom: 20px;

                                li {
                                    color: #dc3545;
                                    font-size: 11pt;
                                    font-weight: 700;
                                    border-bottom: 0;
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                }
                            }
                        }
                    }

                    &.upload-area {
                        padding-right: 1.8rem;
                        padding-bottom: 70px;

                        @media screen and (max-height: 800px) {
                            padding-bottom: 30px;
                        }
                    }
                }
            }

            .footer-buttons {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: center;
                padding-top: 70px;
                border-top: 1px solid $iconGray;
                position: relative;
                top: 0;
                transition: .2s ease-in all;
                background-color: $white;
                min-height: 84px;
                background-position: center 61px;
                content: " ";
                visibility: visible;

                @media screen and (max-height: 660px) {
                    bottom: 0;
                    top: auto;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    position: sticky !important;
                    background-position: center center;
                }

                @media screen and (max-height: 800px) {
                    bottom: 0;
                    top: auto;
                    padding-top: 20px;
                    padding-bottom: 20px;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    background-position: center center;
                }

                &.hide {
                    //top: 200px;
                    .button-support {
                        //display: none;
                        visibility: hidden;
                    }
                }

                .button-support {
                    flex-grow: 2;
                    background-color: $white;

                    &:first-child {
                        text-align: right;
                        padding-right: 20px;
                    }

                    &:last-child {
                        padding-left: 20px;
                    }

                    .btn {
                        font-weight: bold;
                        text-transform: capitalize;
                        padding: 8px 70px;
                        border-width: 2px;
                        border-radius: 7px;
                        position: relative;
                        top: 0;
                        transition: all 0.1s ease-in;

                        &:focus,
                        &:active {
                            outline: none !important;
                            box-shadow: none !important;
                        }

                        &:active {
                            top: 2px;
                        }
                    }

                    .btn-success {
                        background-color: $thirdColor;
                        border-color: $thirdColor;

                        &:hover {
                            background-color: lighten($thirdColor, 5%);
                            border-color: lighten($thirdColor, 5%);
                        }
                    }

                    .btn-outline-danger {
                        border-width: 2px;
                    }
                }
            }
        }
    }
}