.wf-gratuities {
  padding: 20px;

  background-color: #06084b;
  background-image: url("../assets/bg-winner-fun2@3x.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;

  .wf-mt-28 {
    margin-top: 28px;
  }

  .wf-spacer {
    height: 130px;
  }
}