@import "./../../../../../../variables";

.approved-section {
    .head-area {
        display: flex;

        .icon {
            .IconSVG {
                height: 54px;
            }
        }

        .texts-support {
            padding-left: 17px;
            color: #000;
            padding-top: 7px;
            padding-bottom: 30px;

            .title {
                font-size: 17px;
                font-weight: bolder;
                color: $firstColor;
            }

            .subtitle {
                font-size: 13px;
                color: $grayColor;
                padding-top: 3px;
            }
        }
    }

    .list-area {
        .wlist {
            padding-left: 74px;
            padding-right: 74px;

            .WithdrawalAmount{
                font-size: 12px;
                font-weight: 500;
                color: #777;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                .amount {
                    color: $infoColor;
                    font-weight: bolder;
                    font-size: 17px;
                    margin-right: 5px;
                }
                .TransactionID {
                    margin-left: 5px;
                    font-weight: 600;
                    font-size: 12px;
                    color: #000;
                    text-decoration: underline;
                }
            }

            .method {
                color: $grayColor;
                font-size: 13px;
            }

            .VerificationCode{
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 300px;
                padding: 10px 0px;
                margin: 20px 0px 0px 0px;
                border-top: 1px solid rgba(128, 128, 128, 0.3);
                border-bottom: 1px solid rgba(128, 128, 128, 0.3);

                h2{
                    color: #0071bc;
                    text-decoration: underline;
                    font-weight: 700;
                    margin: 10px 0px;
                    font-size: 18px;
                    cursor: pointer;
                }

                span{
                    color: #757575;
                    font-size: 12px;
                    font-weight: 500;
                }

                p{
                    color: #0071bc;
                    font-weight: 700;
                    font-size: 16px;
                    margin-top: 5px;
                }
            }

            .buttons {
                display: flex;
                padding-top: 20px;

                .cancelButton {
                    border-color: $warningColor;
                    color: $warningColor;
                    border-width: 2px;
                    font-weight: 600;

                    &:hover {
                        color: $white;
                        background-color: $warningColor;
                    }
                }

                .buttonPlace {
                    &:first-child {
                        padding-right: 10px;
                    }

                    &:last-child {
                        padding-left: 10px;
                    }
                }

                .actionBtn {
                    border-color: $thirdColor;
                    background-color: $thirdColor;
                    font-weight: 600;

                    &:hover {
                        background-color: lighten($thirdColor, 8%);
                        border-color: lighten($thirdColor, 8%);
                        //border-right: 2px;
                    }
                }

                .infoBtn {
                    border-color: #0a3d30;
                    background-color: #0a3d30;

                    &:hover {
                        background-color: darken(#0a3d30, 8%);
                        border-color: darken(#0a3d30, 8%);
                    }

                    .btnInfo {
                        width: 20px;

                        .cls-1{
                            fill:$white;
                        }
                    }
                }

                .mapBtn {
                    display: flex;
                    border-color: $infoColor;
                    background-color: $white;

                    .icon {
                        width: 20px;
                        margin-right: 10px;

                        .cls-1 {
                            fill: $infoColor;
                        }
                    }

                    .text {
                        color: $infoColor !important;
                    }

                    &:hover {
                        background-color: $infoColor;

                        .icon {

                            .cls-1 {
                                fill: $white;
                            }
                        }

                        .text {
                            color: $white !important;
                        }
                    }
                }
            }
        }
    }

    .btn {
        position: relative;
        top: 0;
        transition: all 0.1s ease-in;

        &:focus,
        &:active {
            outline: none !important;
            box-shadow: none !important;
        }

        &:active {
            top: 2px;
        }
    }
}