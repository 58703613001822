.wf-score {
  padding: 20px 14px 0;

  background-color: #06084b;
  background-image: url("../assets/bg-winner-fun2@3x.png");
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;

  .wf-switcher {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    margin-bottom: 10px;
    width: 100%;

    .wf-tab {
      width: 160px;
      min-width: 160px;
      max-width: 160px;
      opacity: .6;
      cursor: pointer;
      -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
      position: relative;

      img {
        display: block;
        width: 160px;
        height: auto;
      }

      .wf-text {
        position: absolute;
        top: 0px;
        left: 10px;
        right: 10px;
        bottom: 0px;
        color: #FFF;
        font-size: 10px;
        line-height: 10px;
        text-align: left;
        display: flex;
        align-items: center;
        overflow: hidden;
      }
    }

    .wf-tab.wf-active {
      opacity: 1;
      transform: scale(1.05);
      transform-origin: center center;

      img {
        border-radius: 7px;
        border: 1px solid #fff;
        box-shadow: 0px 5px 6px 0px rgba(0, 0, 0, .24);
      }
    }
  }

  .wf-header-title {
    font-size: 13px;
    font-weight: bold;
    text-align: left;
    color: #fff;
    margin-bottom: 7px;
  }

  .wf-tabs {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 15px;

    .wf-tab {
      font-size: 10px;
      font-weight: bold;
      opacity: .5;
      text-align: left;
      color: #fff;
      margin-right: 20px;
      cursor: pointer;
    }

    .wf-tab.wf-active {
      opacity: 1;
    }
  }

  .wf-ranking {
    border-radius: 7px;
    background: #fff;

    .wf-rows {
      border-radius: 0 0 7px 7px;
      /*
      max-height: calc(var(--inner-height, 100vh) - 283px - var(--notch-bottom, 0px));
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;

      &::-webkit-scrollbar {
        width: 3px;
        height: 5px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;

      }

      &::-webkit-scrollbar-thumb {
        background: linear-gradient(to bottom, #FFEF00, #FF9900);
        border-radius: 9px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: linear-gradient(to bottom, #FFEF00, #FF9900);
      }
      */
    }

    .wf-row {
      display: flex;
      align-items: center;
      justify-content: stretch;
      cursor: pointer;

      padding: 0 10px;

      width: 100%;
      height: 48px;
      border-bottom: 1px solid rgba(183, 205, 216, .35);

      &.wf-row-0 {
        background: #EAF4FB;
      }

      &.wf-row-1 {
        background: #F0F7FC;
      }

      &.wf-row-2 {
        background: #FAFCFE;
      }

      &>div {
        flex: 1 1 auto;
      }

      .wf-header {
        color: #979DAB !important;
        font-size: 9px !important;
        font-weight: bold !important;
        padding: 0 2px;
      }

      .wf-col.wf-left {
        text-align: left;
      }

      .wf-col.wf-right {
        text-align: right;
      }

      .wf-col.wf-center {
        text-align: center;
      }

      .wf-col1 {
        width: 25px;
        min-width: 25px;
        max-width: 25px;

        &>div {
          font-size: 10px;
          font-weight: 600;
          color: #586682;
          text-align: center;
          border-radius: 50%;
          margin: 0 auto;
        }

        .wf-gold {
          background: linear-gradient(to right, #FF971D, #FFB400);
          border: 1px solid #FCC735;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-silver {
          background: linear-gradient(to right, #B5C9DC, #9AC7F2);
          border: 1px solid #81A0BE;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-bronze {
          background: linear-gradient(to right, #EB7120, #C65F05);
          border: 1px solid #F6A169;
          width: 17px;
          height: 17px;
          color: #FFF;
          line-height: 14px;
        }

        .wf-regular {
          border: 1px solid rgba(255, 255, 255, 0);
          height: 16px;
        }
      }

      .wf-col2 {
        width: 27px;
        min-width: 27px;
        max-width: 27px;

        img {
          display: block;
          width: 27px;
          height: 27px;
          transform: translateZ(0);
        }
      }

      .wf-col3 {
        padding: 0 10px;
        color: #17214D;
        font-size: 12px;
        font-weight: 600;
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .wf-col4 {
        width: 50px;
        min-width: 50px;
        max-width: 50px;
        color: rgba(23, 33, 77, .24);
        font-size: 10px;
        font-weight: bold;
      }

      .wf-col5 {
        width: 80px;
        min-width: 80px;
        max-width: 80px;
        color: #17214D;
        font-size: 10px;
        font-weight: bold;
      }

      &.wf-show-more {
        display: flex;
        align-items: center;
        justify-content: center;
        height: auto;
        cursor: pointer;

        .wf-col-full {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 20px 10px;
          color: #17214D;
          font-size: 14px;
          font-weight: 600;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          img {
            width: auto;
            height: 25px;
            margin-right: 10px;
          }
        }
      }
    }

    .wf-row.wf-header {
      height: 29px;
    }

    .wf-row:last-child {
      border-radius: 0 0 7px 7px;
    }

    .wf-row.wf-active {
      background: linear-gradient(to right, #FF7700, #FFC400);
      padding: 0 5px 0 10px;
      border-bottom: 1px solid transparent;

      position: sticky;
      bottom: 0;
      top: 0;

      .wf-col1>div {
        color: #fff;
      }

      .wf-col2 {
        width: 35px;
        min-width: 35px;
        max-width: 35px;

        img {
          display: block;
          width: 35px;
          height: 35px;
          transform: translateZ(0);
        }
      }

      .wf-col3 {
        font-size: 14px;
        color: #fff;
      }

      .wf-col4 {
        width: auto;
        min-width: unset;
        max-width: unset;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
        white-space: nowrap;
        padding-right: 5px;
      }

      .wf-col5 {
        width: auto;
        min-width: unset;
        max-width: unset;
        border-radius: 11px;
        border: 1px solid #000000;
        background: #fff;
        color: #17214D;
        font-size: 12px;
        text-align: center;
        height: 22px;
        line-height: 22px;
      }

      .wf-edit-nickname {
        white-space: nowrap;
        display: flex;
        align-items: center;
        cursor: pointer;

        .wf-edit-icon {
          border-radius: 50%;
          width: 22px;
          height: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          display: inline-block;
          margin-left: 5px;

          svg {
            display: block;
            width: 18px;
            height: 18px;
            position: relative;
            top: 1px;
          }
        }
      }
    }
  }

  .wf-regulations {
    border-radius: 7px;
    background: #fff;
    color: #000;
    padding: 12px;

    .wf-header {
      font-size: 18px;
      font-weight: bold;
      color: #000;
      text-align: left;
    }

    .wf-text {
      font-size: 15px;
      font-weight: normal;
      color: #000;
      text-align: left;

      .wf-strong {
        font-weight: bold;
      }
    }
  }

  .wf-spacer {
    height: 130px;
  }
}