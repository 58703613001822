.maintenance-full-page {
  min-height: 100vh;
  width: 100vw;
  background: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  box-sizing: border-box;

  * {
    box-sizing: border-box;
    text-align: left;
  }

  .mp-header {
    width: 100vw;
    height: 80px;
    background: #151D26;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding-bottom: 13px;

    &>div {
      font-size: 20px;
      font-weight: 600;
      text-align: center;
      color: #fff;
    }
  }

  .mp-body {
    padding-top: 75px;

    .mp-title {
      color: #000000;
      text-align: center;
      font-size: 22px;
      font-weight: bold;
      margin: 0 24px 30px;
    }

    .mp-content {
      color: #151D26;
      font-weight: 600;
      font-size: 14px;
      margin: 0 24px 30px;
      text-align: center;

      & * {
        text-align: center;
      }

      & h1 {
        font-size: 70px;
      }

      & h2 {
        font-size: 50px;
      }

      & h3 {
        font-size: 40px;
      }

      & h4 {
        font-size: 30px;
      }

      & h5 {
        font-size: 20px;
      }

      & h6 {
        font-size: 16px;
      }
    }

    .mp-button {
      background: linear-gradient(to right, #EC5E00, #981D91);
      color: #fff;
      padding: 9px 0;
      font-size: 15px;
      font-weight: bold;
      border-radius: 8px;
      text-align: center;
      margin: 0 60px;
      cursor: pointer;
    }

    .mp-link {
      color: #737B83;
      font-size: 12px;
      font-weight: normal;
      text-align: center;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}