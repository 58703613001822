$anim-duration: 800ms;

.wheel-main-view-wrapper {
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url("https://t5a4q7k3.stackpathcdn.com/dialog-bg-1635769840636.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: left top;
  //position: relative;
  overflow-y: auto;
  padding-top: calc(10px + env(safe-area-inset-top));
  padding-bottom: calc(10px + env(safe-area-inset-bottom));

  .wheel-main-view {

    width: 100%;
    height: 100%;
    min-height: 620px;
    max-height: 640px;
    position: relative;

    &.center {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .close-header {
      padding: 0;
      position: fixed;
      width: 29px;
      height: 29px;
      top: calc(10px + var(--notch-top-offset, 0px));
      right: 10px;
      cursor: pointer;
      z-index: 100;
    }

    .multiplier-effect {
      filter: grayscale(1);
      opacity: .7;

      &.bg-image {
        opacity: .2;
      }

      &.indicator {
        opacity: 1;
      }
    }

    &.with-multiplier {
      .multiplier-effect {
        filter: none;
        opacity: 1;

        &.bg-image {
          opacity: 1;
        }
      }
    }

    .multiplier-effect.indicator {
      display: none;
    }

    &.isSpinning {
      .multiplier-effect.indicator {
        display: block;
      }
    }

    *,
    :before,
    :after {
      box-sizing: border-box;
      margin: 0;
      padding: 0;
    }

    .newton-loader-winner {
      svg {
        #Path_16882 {
          fill: #fff !important;
        }
      }
    }

    .newton-loader {
      svg.MuiCircularProgress-svg {
        circle {
          color: #101fde;
        }
      }
    }

    .product-value-status {
      width: 166px;
      height: 80px;
      background: linear-gradient(to left, #FFCF0F, #FFD427, #FFEE9A, #FFCF0F, #FF9100);
      border-radius: 10px;
      z-index: 1;
      margin-bottom: -10px;
      display: none;

      .product-value-status-wrapper {
        height: calc(100% - 6px);
        width: calc(100% - 6px);
        border-radius: 10px;
        background: linear-gradient(to left, #002FFF, #001C9B);
        margin: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .product-status {
          margin-top: 5px;
          font-size: 18px;
          color: #fff;
          font-weight: 800;
          white-space: nowrap;
          text-align: center;
        }

        .value-status {
          font-size: 30px;
          color: #fff;
          font-weight: 800;
          white-space: nowrap;
          text-align: center;
        }
      }
    }

    &.isSpinning {
      .product-value-status {
        display: block;
      }
    }

    .spin-wrapper {
      width: 360px;
      max-width: 360px;
      padding-top: 19px;
      height: auto;
      position: relative;
      transform-origin: top center;
      transform: scale(.87);
      transition: transform $anim-duration ease-in-out;

      .spin {

        .oswald-text {
          font-family: Oswald;
        }

        .multiplicatorGroup {
          transform-origin: 50px 48px;
        }

        .productGroup {
          transform-origin: 50px 50px;
        }

        .valueGroup {
          transform-origin: 50px 50px;
        }

        .value-title {
          filter: url(#text_shadow);
        }
      }

      img.overlay {
        position: absolute;
        left: 2px;
        top: 0px;
        width: 355px;
      }
    }

    @media screen and (min-height: 670px) {
      .spin-wrapper {
        transform: scale(0.92);
      }
    }

    &.isSpinning {
      .spin-wrapper {
        padding-bottom: 20px;
        transform: scale(1);
      }
    }

    .available-keys {
      opacity: .5;
      font-size: 10px;
      color: #fff;
      font-weight: bold;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;
      padding: 0 20px;
      margin: 5px auto 3px;
      max-width: 375px;

      svg {
        height: 14px;
        width: auto;

        path {
          fill: #fff;
        }
      }

      .question {
        background: #070D54;
        border-radius: 50%;
        width: 16px;
        height: 16px;
        font-size: 10px;
        line-height: 0;
        color: #fff;
        font-weight: 600;
        border: 1px solid rgba(255, 255, 255, .45);
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .sep {
        color: #E6E7F3;
        font-size: 12px;
        font-weight: normal;
        padding: 0 4px;
      }
    }

    .anim-wrapper {
      position: relative;
      width: 100%;
      min-height: 82px;
    }

    @keyframes multiplier-slide-in {
      0% {
        transform: translateY(100%);
        opacity: 0;
      }

      100% {
        transform: translateY(0%);
        opacity: 1
      }
    }

    .multiplier-status {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      transition: opacity 500ms ease-in-out;
      pointer-events: none;
      opacity: 0;
      display: none;

      .multipliers-wrapper {
        background-image: url("../assets/cote@3x.png");
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center;

        min-width: 320px;
        max-width: 320px;
        width: 320px;
        height: 69px;
        color: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;

        .left-side {
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
          min-width: 60px;
          max-width: 60px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
          padding-left: 18px;
          opacity: 0;
        }

        .right-side {
          border-top-right-radius: 5px;
          border-bottom-right-radius: 5px;
          min-width: 60px;
          max-width: 60px;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          padding-right: 18px;
          opacity: 0;
        }

        .multipliersStatusLeft,
        .multipliersStatusRight {
          font-size: 20px;
          font-weight: bold;
          color: #fff;
          white-space: nowrap;
          opacity: .8;
        }

        .double-multi-text {
          font-size: 11px;
          font-weight: 600;
          color: #fff;
          white-space: nowrap;
        }

        .center {
          //background: #0061FF;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          flex: 1 1 auto;

          overflow: visible;

          .text {
            position: relative;
            top: -3px;
            font-size: 32px;
            font-weight: bold;
            color: #fff;
          }
        }
      }
    }

    &.isSpinning {
      .multiplier-status {
        animation-name: multiplier-slide-in;
        animation-duration: $anim-duration;
        animation-timing-function: ease-in;
        animation-fill-mode: both;
        display: block;
      }
    }

    .wheel-buttons-wrapper {
      transition: opacity $anim-duration ease-in-out;
      position: absolute;
      opacity: 1;
      top: 0;
      left: 0;
      right: 0;
    }

    &.isSpinning {
      .wheel-buttons-wrapper {
        opacity: 0;
        pointer-events: none;
      }
    }

    .wheel-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      padding: 0 10px;
      height: 68px;

      &.disabled {
        filter: grayscale(1);
        pointer-events: none;
      }

      .rotate-wheel {
        background: linear-gradient(to right, #1A33D9, #1664FF);
        font-size: 14px;
        color: #FFFFFF;
        font-weight: bold;
        font-family: 'Open Sans', sans-serif;
        box-shadow: 0 -3px 6px rgba(0, 0, 0, .16);
        height: 53px;
        min-width: 126px;
        max-width: 126px;
        width: 126px;
        margin-left: 7px;
        margin-right: 7px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 9px;
        cursor: pointer;
        position: relative;

        .warning-icon {
          position: absolute;
          top: 18px;
          left: 10px;
        }

        &.disabled {
          filter: grayscale(1);
          pointer-events: none;
        }
      }

      .rotate-wheel_with_multiplier {
        background: linear-gradient(to right, rgba(230, 111, 31, .4), rgba(248, 21, 120, .4));
        box-shadow: 0 -3px 6px rgba(0, 0, 0, .16);
        height: 68px;
        width: 100%;
        border-radius: 12px;
        padding: 4px;
        max-width: 215px;
        cursor: pointer;

        .middle {
          background: linear-gradient(to right, rgba(230, 111, 31, .74), rgba(255, 62, 146, .75));
          padding: 4px;
          border-radius: 12px;
          height: 100%;

          .inner {
            background: linear-gradient(to right, #E66F1F, #FF167B);
            border-radius: 12px;
            border: 1px solid #fff;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: space-between;

            .txt {
              font-size: 14px;
              color: #FFFFFF;
              font-weight: bold;
              font-family: 'Open Sans', sans-serif;
              max-width: 100px;
              text-align: left;
              margin-left: 9px;
            }

            .graphic-lock {
              display: none;
            }

            .graphic {
              display: flex;
              align-items: center;
              justify-content: center;

              .txt {
                font-size: 20px;
                color: #FFFFFF;
                font-weight: bold;
                font-family: 'Open Sans', sans-serif;
                white-space: nowrap;
              }

              .icon {
                margin-right: 5px;

                svg {
                  height: 38px;
                  width: auto;
                }
              }
            }
          }
        }

        &.disabled {
          //background: transparent;
          box-shadow: none;
          //pointer-events: none;

          .middle {
            //background: transparent;

            .inner {
              background: linear-gradient(to right, #4E4E4E, #242424);
              border: 2px solid #fff;


              .txt {
                color: #fff;
                flex: 1 1 100%;
                width: 100%;
                min-width: calc(100% - 55px);
              }

              .graphic-lock {
                display: block;
                height: 42px;
                min-width: 39px;
                max-width: 39px;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                margin: 0 0 0 4px;
                color: #c41010;
              }

              .graphic {
                display: none;
              }
            }
          }
        }
      }
    }

    &.isProcessing .wheel-buttons,
    &.isSpinning .wheel-buttons {

      .rotate-wheel,
      .rotate-wheel_with_multiplier {
        pointer-events: none;
      }
    }

    &.isSpinning .puzzle-container {
      display: none;
    }

    .test {
      position: absolute;
      bottom: env(safe-area-inset-bottom);
      left: 0;
      right: 0;
      text-align: center;
      color: #fff;
    }

    .wrapper-top {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }



    .wrapper-bottom {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      opacity: 1;

    }

    @keyframes bottom-slide-out {
      0% {
        transform: translateY(0%);
        opacity: 1;
      }

      99% {
        transform: translateY(100%);
        opacity: 0
      }

      100% {
        display: none;
      }
    }

    &.isSpinning {
      .wrapper-top {
        position: static;
      }

      .wrapper-bottom {
        animation-name: bottom-slide-out;
        animation-duration: $anim-duration;
        animation-timing-function: ease-in;
        animation-fill-mode: both;
      }
    }

    &.isSpinning {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    .inactive-multiplier-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
      position: relative;
      top: -100px;

      img {
        display: block;
        margin: 0 auto;
        width: 235px;
        height: auto;
      }
    }

    &.isSpinning {
      .inactive-multiplier-wrapper {
        display: none;
      }
    }

    .wheel-notch-wrapper {
      width: 40px;
      display: none;
      position: absolute;
      left: calc(50% - 20px);
      bottom: 15px;

      .wheel-notch {
        display: block;
        height: 50px;
        width: auto;
      }
    }

    &.double-multiplier {
      .notch1 {
        bottom: 30px;
        left: calc(50% - 86px);
        transform-origin: center;
        transform: rotate(30deg);
      }

      .notch2 {
        bottom: 30px;
        left: calc(50% + 46px);
        transform-origin: center;
        transform: rotate(-30deg);
      }

      .multiplier-status {
        .multipliers-wrapper {

          .right-side,
          .left-side {
            opacity: 1;
          }
        }
      }
    }

    &.isSpinning {
      .wheel-notch-wrapper.notch1 {
        display: block;
      }
    }

    &.isSpinning.double-multiplier {
      .wheel-notch-wrapper.notch2 {
        display: block;
      }
    }

    &.disabled-multiplier {
      .wheel-notch-wrapper {
        filter: grayscale(1);
      }
    }


  }

  .product-done .product-value-status .product-value-status-wrapper .product-status {
    color: #ECECEC;
  }

  .value-done .product-value-status .product-value-status-wrapper .value-status {
    color: #ECECEC;
  }

  .multiplier-done .multiplier-status .multipliers-wrapper .center .text {
    color: #ECECEC;
  }
}

.IconSVG.winter-time {
  height: 37px;
  width: auto;
  margin: 0 auto;
  display: block;
  margin-left: 8px;
}

.wheel-real-html {
  //background: linear-gradient(to bottom, #131a91, #0d1473);
  background-color: #0d1473;
  width: 100%;
  height: 100vh;

  .wheel-real {
    width: 100%;
    height: 100%;

    #FloatingActions {
      display: none;
    }

    #root {
      min-height: 0;
      width: 100%;
      height: 100%;

      .App {
        width: 100%;
        height: 100%;
      }

      .App .App-body {
        min-height: 0;
        width: 100%;
        height: 100%;
      }

      .App .pageContent {
        width: 100%;
        height: 100%;
      }

      .App .animatedPageContent {
        min-height: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}