.bets-tickets-in-progress {

  .btip-header {
    background: #00599D;
    border-radius: 7px;
    margin-top: 5px;
    padding: 13px 15px 11px;
    cursor: pointer;
  }

  .lostColor {
    color: #FF196E;
  }

  .wonColor {
    color: #06D2BD;
  }

  .ticketDataItem {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    flex: 1 1 0;
    white-space: nowrap;
    text-align: left;
    white-space: nowrap;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .tar {
    justify-content: flex-end;
  }

  .status {
    font-size: 15px;
    font-weight: bold;
    text-align: center;
    color: #fff;
    padding: 3px 0 4px;
    white-space: nowrap;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .possibleWin {
    display: flex;
    align-items: center;
    margin-right: 10px;

    &.no-mrg {
      line-height: 14px;
      font-size: 14px;
      margin-right: 0;
    }
  }

  .rightSvg {
    height: 14px;
    width: auto;
    fill: #94A0AF;
    vertical-align: middle;
    float: right;
    position: relative;
  }

  .tickets-icon {
    display: inline-block;
    height: 16px;
    width: auto;
    margin-right: 4px;
  }

  .btip-bet {
    cursor: pointer;

    .bet-in-progress-card {
      margin: 5px 0 0;
    }
  }

  .btip-switch {
    background: #E6EEF1;
    color: #586682;
    border-radius: 10px;
    font-size: 12px;
    font-weight: 600;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
    margin-bottom: 10px;

    .rsc-default .rsl {
      background: #C4CED2;
    }
  }

  .empty {
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    white-space: normal;
    color: #29405f;
  }
}