.component-website_switcher {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px auto;
  padding: 16px;
  width: 1528px;
  max-width: 100%;
  box-sizing: border-box;

  div{
    box-sizing: border-box;
  }

  .bannerContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #fff;
    position: relative;
    padding: 16px;

    .bannerContent {
      display: flex;
      max-width: 190px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      align-self: stretch;
      z-index: 1;

      .bannerDescription {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        width: 100%;
        .bannerTitle {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          text-align: start;
          gap: 7px;
          color: #00166d;
          font-size: 16px;
          font-weight: 700;
          line-height: 16px;
          text-transform: uppercase;
          span {
            display: flex;
            padding: 4px 6px;
            justify-content: center;
            align-items: center;
            border-radius: 26px;
            background: #ed003f;
            color: #fff;
            font-size: 10px;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: 0.3px;
            text-transform: uppercase;
          }
        }
        .bannerSubtitle {
          text-align: start;
          color: #000;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
        }
      }

      .bannerButton {
        display: flex;
        height: 28px;
        padding: 0px 12px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        border-radius: 6px;
        background: #0a34dd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #fff;
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        text-transform: uppercase;
        text-decoration: none;
        text-align: center;
        cursor: pointer;
      }
    }
    .bannerImg {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      position: absolute;
      right: 16px;
      &.img-1 {
        top: 0;
      }
      &.img-2 {
        height: 100px;
      }
      &.img-3 {
        right: -10px;
      }
      &.img-4 {
        top: 0;
        right: 0;
        height: 110px;
      }
      &.img-5 {
        right: 0;
      }
    }

    &.darkMode {
      background: linear-gradient(166deg, #193463 0%, #161c26 90.29%), #fff;
      .bannerContent {
        .bannerDescription {
          .bannerTitle,
          .bannerSubtitle {
            color: #fff;
          }
        }
      }
      .bannerImg {
        &.img-3 {
          right: 16px;
        }
      }
    }
  }
}
