html {
  height: 100%;
}

body.nsoft-bets {
  margin: 0;
  font-family: var(--ns-font-family);
  -webkit-text-size-adjust: 100%;
  height: 100%;
  touch-action: manipulation;
}

body.nsoft-bets>#root {
  height: 100%;
}

body>#root>.nsoft {
  height: 100%;
}

.font-alt {
  font-family: var(--ns-font-family-alternative);
}

body.nsoft-bets .clearfix:before,
body.nsoft-bets .clearfix:after {
  content: " ";
  display: table;
  clear: both;
}

body.nsoft-bets .text-uc {
  text-transform: uppercase;
}

.nsoft-bets .AppBets *,
.nsoft-bets .AppBets *::before,
.nsoft-bets .AppBets *::after,
.nsoft-bets .App *,
.nsoft-bets .App *::before,
.nsoft-bets .App *::after {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba($black, 0);
}

.nsoft-bets .match-details-dialog *,
.nsoft-bets .match-details-dialog *::before,
.nsoft-bets .match-details-dialog *::after {
  box-sizing: border-box;
}

.nsoft-bets .AppBets {
  text-align: unset;
}

.nsoft-bets .AppBets * {
  font-weight: inherit;
}

.nsoft-bets .App {
  text-align: initial;
}

.nsoft-bets .nsoft-betslip-swipe {
  .react-swipeable-view-container {

    .panel-margin {
      padding-bottom: 10px;
    }

    &>div[aria-hidden="false"],
    &>div[aria-hidden="true"] {
      overflow: auto hidden !important;
    }

    &>div[aria-hidden="true"] {
      &>.panel-margin {
        height: 0;
        overflow: hidden;
      }
    }
  }
}

.n-page {
  .input.full.event-view {
    display: none;
  }

  .spc {
    display: none;
  }
}

.device-ios {

  /* tp prevent input field zoom on iOS */
  .n-page .search .input .textbox,
  .n-page .search .mobile-sort-container .grid-modes-search-header .search-tab .search-input .search-input-field {
    font-size: 16px;
  }

  .n-page {
    .spc {
      display: block;
      height: 150px;
    }
  }
}

body.nsoft-bets #settings {
  display: none;
}

body.nsoft-bets .App .headerTopMenu {
  z-index: 1302;
}

body.nsoft-bets .WalletPreviewScreen .WP-Container .WP-Secondary .ExpandLessButton {
  text-align: center;
}

body.nsoft-bets {
  .search-warning {
    margin: 20px 20px;
    text-align: center;
    color: #000;
    padding: 5px;
    font-size: 12px;
  }
}