.betslip-pre-match-simple {
  font-family: OpenSans, "Open Sans";

  .betBuilder {
    padding: 6px 16px 0;

  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .icon {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;

      svg {
        display: block;
        width: 100%;

      }
    }

    .title {
      flex: 1 1 auto;
      color: #00166D;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 0 6px;

    border-bottom: 1px solid #E2E2E5;
    margin-bottom: 6px;

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      height: 20px;
      position: relative;
      padding-left: 8px;
      margin-left: 10px;

      &::before {
        position: absolute;
        top: 0px;
        left: -1px;
        content: "";
        height: 10px;
        width: 1px;
        background: #adadad;
      }

      &::after {
        position: absolute;
        top: 10px;
        left: -1px;
        content: "";
        height: 10px;
        width: 1px;
        background: #adadad;
      }

      .name,
      .odd {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }

      .odd {
        font-weight: 500;
        flex: 1 1 auto;
      }

      .sep {
        height: 16px;
        width: 1px;
        background-color: #999;
      }

      .bullet {
        position: absolute;
        top: 7px;
        left: -3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #999;
      }
    }

    .item:first-child {
      &::before {
        display: none;
      }
    }

    .item:last-child {
      &::after {
        display: none;
      }
    }
  }

  .oddInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    margin: 0 -16px;
    padding-left: 10px;

    .title {
      color: #020203;
      font-size: 13px;
      font-weight: bold;
      flex: 1 1 auto;
    }

    .value {
      color: #020203;
      font-size: 15px;
      font-weight: bold;
      white-space: nowrap;
      margin-right: 17px;

      &.st {
        text-decoration: line-through;
      }
    }

    .delIcon {
      cursor: pointer;
      height: 15px;
      margin: -30px -15px -15px;
      padding: 20px 15px 20px 20px;

      img {
        display: block;
        width: auto;
        height: 16px;
      }
    }
  }
}