.BetBuilderSummary {
  font-family: OpenSans, "Open Sans";
  background-color: #fff;
  padding: 0 16px 16px;

  &.in-ticket {
    padding: 0;
  }

  .top-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 32px;
    margin-top: -15px;

    .delIcon {
      display: flex;
      height: 32px;
      padding: 0px 12px 0px 8px;
      justify-content: center;
      align-items: center;
      border-radius: 12px;
      border: 1px solid rgba(221, 10, 10, 0.20);
      background: #FFEAEA;
      cursor: pointer;
      gap: 2px;

      color: #DD0A0A;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 16px;


      svg {
        width: 16px;
        height: 16px;
        display: block;
      }
    }

    .arrowDown {
      display: flex;
      width: 32px;
      height: 32px;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-radius: 12px;
      background: #EDEDED;

      svg {
        display: block;
      }

    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;

    .icon {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;

      svg {
        display: block;
        width: 100%;

      }
    }

    .delIcon {
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        display: block;
        width: auto;
        height: 16px;
      }

      .desktop {
        display: none;
      }
    }

    .title {
      flex: 1 1 auto;
      color: #00166D;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  .see-more {
    border-radius: 8px;
    background: #F3F3F3;
    height: 28px;
    padding: 0 4px 0 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    cursor: pointer;
    margin-bottom: 6px;

    .title {
      color: #000;
      font-size: 13px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .icon {
      width: 24px;
      height: 20px;
      border-radius: 6px;
      background: #FFF;

      svg {
        display: block;
        color: #272727;
      }
    }
  }

  &.expanded {
    .header {
      .delIcon {
        display: none;
      }
    }
  }

  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 5px 0 6px;

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 4px;
      height: 20px;
      position: relative;
      padding-left: 8px;
      margin-left: 10px;

      &::before {
        position: absolute;
        top: 0px;
        left: -1px;
        content: "";
        height: 10px;
        width: 1px;
        background: #adadad;
      }

      &::after {
        position: absolute;
        top: 10px;
        left: -1px;
        content: "";
        height: 10px;
        width: 1px;
        background: #adadad;
      }

      .name,
      .odd,
      .score {
        color: #000;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px;
        white-space: nowrap;
      }

      .scores {
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .odd {
        font-weight: 500;
        flex: 1 1 auto;
      }

      .sep {
        height: 16px;
        width: 1px;
        background-color: #999;
      }

      .bullet {
        position: absolute;
        top: 7px;
        left: -3px;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #999;
      }

      .delIcon {
        flex: 0 0 20px;
        width: 20px;
        height: 20px;
        display: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        img {
          display: block;
          width: auto;
          height: 16px;
        }
      }

      &.won {
        .bullet {
          background-color: #558707;
        }

        .name,
        .odd,
        .score {
          color: #558707;
        }
      }

      &.lost {
        .bullet {
          background-color: #FF196E;
        }

        .name,
        .odd,
        .score {
          color: #FF196E;
        }
      }
    }

    &.expanded .item {
      height: 40px;

      &::before {
        height: 20px;
      }

      &::after {
        top: 20px;
        height: 20px;
      }

      .bullet {
        top: 17px;
      }

      .delIcon {
        display: flex;
      }
    }

    .item:first-child {
      &::before {
        display: none;
      }
    }

    .item:last-child {
      &::after {
        display: none;
      }
    }

  }

  div.error {
    font-size: 10px;
    line-height: 14px;
    font-weight: 600;
    font-family: OpenSans, "Open Sans";
    letter-spacing: 0px;
    color: #FF1A6C;
    padding: 0 0 8px;
  }

  .button {
    width: 100%;
    cursor: pointer;
    display: flex;
    height: 48px;
    margin-top: 6px;
    padding: 0px 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 6px;
    border-radius: 8px;
    background: #00166D;

    .title {
      color: #FFF;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 14px;
      text-transform: uppercase;
    }

    .subtitle {
      color: #FFF;
      text-align: center;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 12px;
    }

    &.disabled {
      background: linear-gradient(to right, #9F9F9F, #646464);
      pointer-events: none;

      .title,
      .subtitle {
        color: #fff;
      }
    }
  }

  &.isDesktop {
    padding: 16px;
    border-radius: 10px;

    .top-header {
      display: none;
    }

    .header {
      .delIcon {
        display: flex;
        height: 32px;
        width: auto;
        padding: 0px 12px 0px 8px;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        border: 1px solid rgba(221, 10, 10, 0.20);
        background: #FFEAEA;
        cursor: pointer;
        gap: 2px;

        color: #DD0A0A;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;


        svg {
          width: 16px;
          height: 16px;
          display: block;
        }

        .not-desktop {
          display: none;
        }

        .desktop {
          display: block;
        }
      }
    }

    .list .item {
      height: 28px;

      &::before {
        height: 14px;
      }

      &::after {
        top: 14px;
        height: 14px;
      }

      .bullet {
        top: 12px;
      }

      .delIcon {
        display: none;
      }
    }

    .list .item:hover {
      .delIcon {
        display: flex;
      }
    }
  }
}