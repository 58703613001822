.MaterialInputField {
    .input-centered {
        padding-left: 5px;
        padding-right: 5px;

        .input-group {
            input {

                &:valid
                ~ {
                    label {
                        color: gray;
                    }
                }
                &:focus {
                    ~ {
                        label {
                            color: #0071bc;
                        }
                        .input-bar {
                            background-color: transparent;
                            &:after {
                                width: 100%;
                                left: 0%;
                            }
                        }
                    }
                }
            }
            .input-bar {
                &:after {
                    content: " ";
                    position: absolute;
                    top: 0px;
                    height: 2px;
                    background-color: #0071bc;
                    width: 0%;
                    left: 50%;
                    -webkit-transition: all 0.2s ease-in;
                    -moz-transition: all 0.2s ease-in;
                    -ms-transition: all 0.2s ease-in;
                    -o-transition: all 0.2s ease-in;
                    transition: all 0.2s ease-in;
                }
            }

            label {
                font-size: 16px;
            }

            .IconSVG
            {
                position: absolute;
                right: 0;
            }

            .showPassword
            {
                position: absolute;
                right: 0;
                display: block;
                height: 100%;
                width: 34px;
                background: transparent;
                border: none;
                -webkit-appearance: none;
                outline: none;
                cursor: pointer;
            }

            .showPassword .IconSVG
            {
                top: 0;
            }
        }
    }
}