.match-locked-bets {
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .mbl-lockIcon {
    color: #999;

    & svg {
      fill: #999;
    }
  }

  .mbl-lockText {
    color: #999;
    font-size: 12px;
  }
}