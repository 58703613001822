.mobile-banner {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  width: 100%;
  padding: 5px 5px;

  &>a {
    box-shadow: 3px 3px 10px 0px rgba(0, 0, 0, 0.3);
    text-decoration: none;
    display: block;
    height: 80px;
    border-radius: 7px;
    flex: 1 1 auto;
    display: flex;
    align-items: flex-end;
  }

  .new-website {
    background-image: url(./assets/new-website.jpg);
    background-size: cover;
    margin-right: 5px;
    width: calc(44% - 10px);
  }

  .casino {
    background-image: url(./assets/casino.jpg);
    background-size: cover;
    margin-right: 5px;
    width: calc(28% - 5px);
  }

  .virtuale {
    background-image: url(./assets/virtuale.jpg);
    background-size: cover;
    width: calc(28% - 5px);
  }

  &>a>div {
    color: #fff;
    font-size: 12px;
    font-weight: 600;
    padding: 6px 8px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 100%);
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    width: 100%;

    svg {
      vertical-align: middle;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }
}