.bet-builder-info-dialog {
  z-index: 1304 !important;

  text-align: left;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    max-width: 350px;
    margin: 10px;
  }

  .dialog-content {
    position: relative;
    padding: 0 !important;
    border-radius: 13px;
    overflow: hidden;

    .closeButton {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 12px;
      cursor: pointer;
      z-index: 1;
    }

    .slick-dots {
      display: flex !important;
      align-items: center;
      justify-content: center;
      list-style: none;
      margin: 0;
      padding: 0;
      width: 100%;
      margin: 15px 0px;

      li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 10px;
        margin: 0 3px;

        button {
          display: inline-block;
          background-color: #ced1dc;
          width: 5px;
          height: 5px;
          border-radius: 10px;
          color: transparent;
          border: none;
          outline: none;
          white-space: nowrap;
          text-indent: -9999px;
          transition: all .3s ease;
        }

        &.slick-active {
          width: 20px;

          button {
            background-color: #001d8a;
            width: 20px;
          }
        }
      }
    }


    .slick-slide {
      outline: none !important;
    }

    .step {
      width: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;
      outline: none !important;

      img {
        display: block;
        width: 100%;
        margin-bottom: 24px;
      }

      .title {
        font-size: 20px;
        font-weight: 600;
        color: #000;
        padding: 0 24px 9px;
        text-align: center;
      }

      .subtitle {
        font-size: 13px;
        font-weight: 400;
        color: #707070;
        padding: 0 24px 40px;
        text-align: center;
      }

      &.step-1 {
        display: none;
      }

      &.step-2 {
        display: none;
      }

      &.step-3 {
        display: none;
      }

      &.step-4 {
        display: none;
      }
    }

    &.step-1 .step-1 {
      display: flex;
    }

    &.step-2 .step-2 {
      display: flex;
    }

    &.step-3 .step-3 {
      display: flex;
    }

    &.step-4 .step-4 {
      display: flex;
    }

    .bullets {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 9px;
      margin-bottom: 24px;

      .bullet {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #CBCBCB;

        &.selected {
          background-color: #0A34DD;
        }
      }
    }

    .buttonWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      padding: 0 24px;

      .button {
        height: 48px;
        padding: 0 24px;
        color: #0A34DD;
        font-size: 15px;
        line-height: 14px;
        font-weight: 600;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .button.back {
        color: #939393;
      }
    }
  }
}

.bet-builder-summary-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #fff;
  padding: 10px;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}