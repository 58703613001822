.ContentError {
    opacity: 0;
    top: 40px;
    position: relative;
    z-index: 10;
    padding-bottom: 60px;
}

.ContentError .alert
{
    width: 400px;
    margin: 0 auto;
}

.ContentError h2 {
    font-size: 16px;
    text-align: center;
}

.ContentError p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 0;
}