.winter-fun-modal-referral-popup {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 16px);
    min-width: calc(100% - 16px);
  }

  & .MuiDialog-paperScrollPaper {
    max-height: calc(100% - 16px);
  }

  @media (min-width:400px) {
    & .MuiDialog-paperFullWidth {
      min-width: 400px;
      max-width: 400px;
    }
  }

  .wf-dialog-content {
    background-color: #fff;
    background: url(./assets/modal-collected-bg@3x.png);
    background-repeat: no-repeat;
    background-position: center bottom;
    background-size: 100% auto;
    padding: 18px 35px 20px;
  }

  .wf-header-icon {

    width: 165px;
    height: auto;
    display: block;
    margin: 0 auto 12px;
  }

  .wf-title {
    font-size: 20px;
    color: #182598;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
  }

  .wf-input-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;

    .wf-text {
      text-align: center;
      color: #0D1740;
      font-size: 14px;
      font-weight: 600;
      margin-bottom: 15px;
    }

    .wf-input {
      color: #7071a7;
      width: calc(100% - 20px);

      textarea#referral-link-input-2 {
        font-size: 16px;
        word-break: break-all;
      }

      &>.MuiFormControl-root {
        width: 100%;

        .MuiInputLabel-root {
          color: #7071A7;
        }

        .MuiInputBase-root {
          color: #7071A7;
          padding: 10px 5px 5px 10px;
          background: #fff;
        }

        .MuiButtonBase-root {
          background-color: #0B0C69;
          border: 1px solid #48498F;
          border-radius: 10px;
          margin-right: 0px;
          padding: 7px;
        }

        .MuiIconButton-label {
          color: #fff;
        }

        .MuiOutlinedInput-notchedOutline {
          border-color: #42429A;
        }

        .MuiTouchRipple-child {
          background-color: #fff;
        }

      }
    }
  }

  .wf-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 20px;
    min-width: 180px;

    &>div {
      font-size: 16px;
      color: #000;
      font-weight: bold;
    }
  }

  .wf-coins {
    position: relative;
    background: linear-gradient(to bottom, #fcf81e 0%, #fca913 25.6%, #fcb915 42%, #b75f23 57.4%, #fcaa13 88.7%, #fce61b 100%);
    padding: 2px;
    border-radius: 27px;
    height: 54px;
    margin-bottom: 30px;

    .wf-inner-wrapper {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-radius: 27px;
      background: radial-gradient(circle, rgba(209, 64, 204, 1) 0%, rgba(27, 20, 100, 1) 67%, rgba(27, 20, 100, 1) 100%);
      padding: 0 4px;

      img {
        width: 45px;
        height: 45px;
        display: block;
        margin-right: 5px;
      }

      .wf-text {
        color: #fff;
        font-size: 36px;
        font-weight: 600;
        text-shadow: 1px 1px rgba(0, 0, 0, 1);
        padding: 0 20px 0 10px;
      }
    }
  }

  .wf-icons {
    border-radius: 11px;
    background: #0D1740;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    margin: -20px 0 0;
    padding: 33px 30px 10px 30px;

    .wf-icon {
      cursor: pointer;
      display: block;
      text-decoration: none;

      img {
        display: block;
        width: 40px;
        height: 40px;
      }
    }
  }

  .wf-button-wrapper {
    display: flex;
    justify-content: center;

    .wf-button {
      background: linear-gradient(to right, #0A44E4, #006FFF);
      color: #fff;
      min-width: 260px;

      font-size: 14px;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
      padding: 13px;
      border-radius: 10px;
      margin-bottom: 15px;
    }
  }
}