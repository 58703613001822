$border-radius: 5px;

.ticker-winners {
    margin: 0 2rem 40px 2rem;
    padding: 1rem 2rem;
    border-radius: $border-radius;
    background-color: #29304D;

    .top {
        text-align: left;

        .title {
            color: #fff;
            font-size: 12px;
            padding-top: 2px;
        }
    }

    .bottom {
        padding: 0;
        overflow: hidden;
        white-space: nowrap;
        text-align: left;

        .element {
            padding: 0;
            height: 90px;
            width: 100%;
            vertical-align: top;
            color: #808080;
            font-size: 12px;
            display: flex !important;
            align-items: center;

            .title {
                margin-bottom: .5rem;
                font-size: 18px;
            }

            .subtitle {
                font-size: 16px;
            }

            .amount {
                color: #fff;
            }

            .game {
                color: #fff;
            }

            .gold {
                color: #FFB400
            }

            .DinamicImageContainer{
                float: left;
                display: block;
                width: auto;
                img {
                    height: 65px;
                    border-radius: 3px;
                    margin: 0 1rem 0 0;
                }
            }
        }
    }
}