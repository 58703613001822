.sold-card {
  background: var(--ns-sold-card-bg);
  color: var(--ns-sold-card-color);
  font-family: "Open Sans", OpenSans;

  .totals {
    padding: 10px 10px 5px 10px;
    font-size: 0.8125em;

    .rw {
      line-height: 30px;
    }

    .label {
      width: 50%;
      float: left;
      font-weight: 700;
    }

    .value {
      width: 50%;
      float: right;
      text-align: right;
      font-weight: 700;
    }

    .message {
      color: #FF1A6C;
      font-size: 10px;
      font-weight: 600;
    }

    .bonusHasErrors {
      color: #FF1A6C;
      font-weight: 700;
      font-size: 12px;
      padding-right: 4px;
    }

    .bonusHasWarnings {
      color: #F8991D;
      font-weight: 700;
      font-size: 12px;
      padding-right: 4px;
    }

    .messageErr {
      color: #D72400;
      font-size: 12px;
      font-weight: bold;
    }

    .stakeBonusBalanceErrors {
      margin-top: 10px;
      text-align: center;
    }

    .bonusErrorBar {
      background: #FF1A6C;
      height: 19px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 0 15px;
      font-size: 9px;
      font-weight: bold;
      text-align: left;
      border-radius: 10px;
      margin: 6px 10px;
      cursor: pointer;

      &>.txt {
        color: #fff;
        font-size: 9px;
        font-weight: bold;
        text-align: left;
      }

      &>.bar-question-mark {
        color: #fff;
        background: #FF5491;
        font-size: 15px;
        font-weight: bold;
        text-align: center;
        box-sizing: border-box;
        width: 23px;
        height: 23px;
        border: 2px solid #fff;
        border-radius: 50%;
        position: relative;
        right: -2px;
        padding: 0;
        line-height: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .bonusWarningBar {
      background: #FF7700;

      &>.bar-question-mark {
        background: #FF9940;
      }
    }

    .hr2 {
      background-color: rgba(183, 205, 216, .35);
      height: 1px;
      margin: 5px 0 5px;
    }

    .bonusBalanceErrors {
      margin-left: 10px;
    }

    .bonuses {
      border-left: 2px solid var(--ns-sold-card-bonuses-border-color);
      padding-left: 7px;
      margin: 5px 0;
      color: #8D8D8D;

      .rw {
        line-height: 20px;
      }

      .rw.bonuses {
        color: #CF7700;
      }

      .label,
      .value {
        font-size: 14px;
      }

      .value {
        font-weight: 600;
      }
    }

    .rw.odds {
      color: var(--ns-sold-card-odds-color);
      background: var(--ns-sold-card-odds-bg);
      margin: -10px -10px 10px -10px;
      padding: 13px 18px 13px;

      .label {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
      }

      .value {
        font-size: 20px;
        font-weight: bold;

        .regular {
          font-weight: normal;
        }
      }
    }

    .rw.total-stake,
    .rw.future {
      padding-left: 10px;
      background-color: var(--ns-sold-card-total-stake-bg-color);
      background-image: var(--ns-sold-card-total-stake-bg-image);
    }


    .rw.stk {
      .label {
        font-size: 12px;
        font-weight: bold;
      }

      .value {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .sold-small {
      font-size: 12px;
    }

    .rw.available {
      .label {
        font-size: 12px;
        font-weight: bold;
        text-transform: capitalize;
      }

      .value {
        font-size: 12px;
        font-weight: bold;
      }
    }

    .input {
      width: 70px;
      height: 30px;
      margin: 0;
      padding: 0 10px;
      color: var(--ns-sold-card-input-color);
      background-color: var(--ns-sold-card-input-bg-color);
      border: 2px solid var(--ns-sold-card-input-brd-color);
      outline: 0;
      font-size: 16px;
      text-align: right;
      font-weight: bold;

      &.warning-input {
        border: 2px solid #FF1A6C;
      }
    }
  }

  .important {
    margin-bottom: 0;
    color: var(--ns-sold-card-important-color);
    font-size: 12px;
    font-weight: bold;

    .value {
      font-size: 20px;
      font-weight: normal;
    }
  }

  .controls {
    padding: 0 10px;

    [disabled] {
      opacity: .25;
      cursor: default;
      color: var(--ns-sold-card-disabled-color);
    }

    .empty {
      width: 15%;
      margin-right: 5px;
    }

    .col {
      float: left;
      padding: 3px 0;
    }

    .payin {
      width: calc(85% - 5px);
    }

    .button {
      display: block;
      width: 100%;
      position: relative;
      overflow: hidden;
      height: 36px;
      padding: 2px 0 0;
      color: var(--ns-sold-card-controls-color);
      background-color: var(--ns-sold-card-controls-bg-color);
      background-image: var(--ns-sold-card-controls-bg-image);
      border: 1px solid var(--ns-sold-card-controls-border-color);
      box-shadow: 0 2px 5px 0 none;
      outline: 0;
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      text-align: center;
      cursor: pointer;
      border-radius: 2px;
      font-family: inherit;
    }

    .button.del {
      padding: 2px 0 0;
      background: var(--ns-sold-card-del-bg);
      color: var(--ns-sold-card-del-color);
    }

    .button.user {
      svg {
        position: relative;
        top: -2px;
        vertical-align: middle
      }
    }

    .important {
      background: var(--ns-sold-card-controls-important-bg);
      background-image: none;
      color: var(--ns-sold-card-controls-important-color);
      border: 1px solid var(--ns-sold-card-controls-important-border-color);
      font-weight: 600;
    }
  }

  .accept-odds {
    padding: 6px 10px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;

    .label {
      font-size: 10px;
      font-weight: 600;
    }
  }

  .switch-place {
    padding: 6px 10px 10px;

    .label {
      color: var(--ns-sold-card-warning-color);
      font-size: 11px;
      font-weight: 600;
      margin-bottom: 10px;
      text-align: center;
    }
  }

  .footer-text {
    color: #fb2e7a;
  }

  .warning-text {
    color: #FF1A6C;
    font-size: 10px;
    text-align: right;
    font-weight: 600;
  }

  /* free bet */
  .fb_increment {
    border: 1px solid #B7CDD8;
    padding: 8px 20px;
    background: #fff;
    color: #3C4260;
    font-size: 11px;
    border-radius: 2px;
    touch-action: manipulation;
  }

  .fb_freeBetButton {
    position: relative;
    font-size: 12px;
    padding: 0 20px 6px;
    height: 33px;
    width: 94px;
    text-align: center;
    align-items: center;
    border: 1px solid #3C4260;

    & b {
      font-weight: bold;
      display: block;
      font-size: 16px;
      color: #3C4260;
      margin-top: 5px;

      & .smaller {
        font-size: 12px;
      }
    }
  }

  .fb_freeBetButtonSelected {
    border: 1px solid #FAC125;
    background: #FF9419;
    color: #000;

    & b {
      color: #000;

    }
  }

  .fb_freeBetButtonDisable {
    background: linear-gradient(to right, #A7A7A7, #DBDBDB);
    border: 0px;

    & b {
      color: #787878;
    }

    & .freeBetTicker {
      color: #D8D8D8;
      background: #727272;
    }
  }

  .fb_freeBetTicker {
    position: absolute;
    top: -11px;
    right: 5px;
    font-size: 11px;
    font-weight: 600;
    color: #A06000;
    background: #FFC820;
    border-radius: 2px;
    height: 15px;
    width: 54px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
  }

  .fb_freeBetQuery {
    position: absolute;
    top: -11px;
    left: 15px;
    font-size: 11px;
    font-weight: 600;
    color: #A06000;
    background: #FFC820;
    border-radius: 50%;
    height: 16px;
    width: 16px;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    border: 1px solid #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;
  }

  .fb_stake {
    height: 30px;
    padding: 0 10px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    text-align: right;
    font-weight: bold;
    background-color: var(--ns-sold-card-input-bg-color);
    border: 2px solid var(--ns-sold-card-input-brd-color);
    color: var(--ns-sold-card-input-brd-color);
    flex: 1 1 0;
  }

  .fb_incrementsHolder {
    margin: 6px 10px;

    &.has-free-bets {
      overflow-y: auto;
      overflow-x: visible;
      margin: 0 10px;
      padding-top: 11px;
      padding-bottom: 8px;

      &>button {
        margin-right: 6px;
        min-width: 94px;
        max-width: 94px;
        white-space: nowrap;
      }

      &>div {
        margin-right: 6px;
        white-space: nowrap;
      }

      &>div:last-child {
        margin-right: 0;
      }
    }
  }
}