.component-user_promotions {
  background-color: #EBEEF1;
  background-image: url("./assets/player-promotions-bg.png");
  background-position: left center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: left;
  padding-bottom: 10px;

  .component-wrapper {
    margin: 10px auto;
    max-width: 1528px;
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .subtitle {
      color: #D4E1E4;
      font-size: 12px;
      font-weight: 600;
      text-align: left;
      margin-top: 27px;
    }

    .title {
      color: #FFFFFF;
      font-size: 22px;
      font-weight: bold;
      text-align: left;
      margin-bottom: 18px;
    }
  }

  .cgl-list {
    overflow-y: hidden;
    overflow-x: auto;

    .cgl-grid {
      width: 100%;
      display: grid;
      overflow: visible;
      white-space: nowrap;
      pointer-events: all;
      padding-bottom: 10px;

      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      &::before,
      &::after {
        content: '\00a0';
        min-width: 9px;
        max-width: 9px;
      }

      .cgl-item {
        min-width: 293px;
        width: 100%;
        margin-right: 20px;
        border-radius: 10px;
        overflow: hidden;
        cursor: pointer;

        .yp-grid {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .yp-first {
          position: relative;
          padding-bottom: 20px;
          height: 200px;

          &>div {
            border-radius: 7px;
            overflow: hidden;
            min-height: 180px;
            max-height: 180px;

            &>div {
              position: relative;
              top: -20px;
            }
          }

          img {
            display: block;
            width: 100%;
            height: auto;
            box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.4);
            border-radius: 7px;
            max-height: 180px;
            border: 2px solid rgba(255, 255, 255, .3);
          }

          .tag-line {
            color: #E10000;
            font-size: 13px;
            font-weight: bold;
            background: #fff;
            padding: 7px 25px;
            border-radius: 7px;
            position: absolute;
            top: 12px;
            left: 12px;
          }

          .ok-class {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
              vertical-align: middle;
              width: 90px;
              height: 90px;
            }
          }
        }

        .yp-second {
          padding-bottom: 20px;
          text-align: left;
          width: 100%;

          .yps-title {
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
            text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.57);
          }

          .yps-text {
            min-height: 28px;
            margin-bottom: 18px;
            white-space: normal;

            &>span {
              color: #fff;
              font-size: 13px;
              font-weight: normal;
              line-height: 17px;
              opacity: .67;
            }
          }

          .yps-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 0 auto;

            .yps-time {
              position: relative;
              z-index: 1;
              background-color: #FFC400;
              border: 2px solid #fff;
              border-radius: 3px;
              color: #000;
              font-size: 11px;
              font-weight: bold;
              padding: 1px 10px;
              min-width: 90px;
              text-align: center;

              .muted,
              .yps-muted {
                opacity: .5;
              }
            }

            .yps-time.hidden {
              opacity: 0;
            }

            .yps-button {
              cursor: pointer;
              position: relative;
              top: -3px;
              border-radius: 3px;
              padding: 10px 50px;
              box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.5);
              background: linear-gradient(90deg, #511ce8 0%, #de10e5 35.1%, #ff5b13 63.6%, #ffa509 100%);
              color: #fff;
              font-size: 14px;
              font-weight: bold;
            }

            .yps-button.checked {
              background: linear-gradient(90deg, #434343 0%, #787878 100%);
            }

            .yps-button:active {
              top: -1px;
              left: 1px;
              box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
            }
          }
        }


        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

}