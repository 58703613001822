.small-counter {
	padding: 0;
	display: inline-block;
	border-radius: 0;
	font-weight: bold;

	.counter-wrapper {
		display: flex;
		align-items: flex-end;
	}

	.digit-wrapper {
		height: 14px;
		line-height: 13px;
		overflow-y: hidden;

		background-size: 100%;
		text-align: center;
		padding: 0 2px;

		.disabled {
			color: #442665;
		}
	}

	.radix {
		height: 14px;
		line-height: 13px;
		display: inline-block;
		width: 0px;
		overflow: visible;
		position: relative;
		z-index: 1;
		top: 0px;
		left: -2px;
	}
}