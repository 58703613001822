.register-bonus {
  width: calc(100% + 3rem);
  margin: 1rem -1.5rem 0;
  pointer-events: none;
  .bonus-wrapper {
    position: relative;
    .bonus-image {
      img {
        width: 100%;
      }
    }
    .bonus-info {
      position: absolute;
      z-index: 1;
      width: 45%;
      margin-left: 50%;
      color: white;
      height: 4rem;
      top: 1rem;
      display: grid;
      align-items: center;
      text-align: center;
      .bonus-value {
        font-size: 2.5rem;
        font-weight: bold;
        small {
          font-size: 40%;
        }
      }
      .small-description {
        font-size: 1rem;
        font-weight: normal;
      }
    }
  }
}