@import "./../../../../../../variables";

.transactions-section {
  .head-area {
    display: flex;

    .icon {
      .IconSVG {
        height: 54px;
      }
    }

    .texts-support {
      padding-left: 17px;
      color: #000;
      padding-top: 7px;
      padding-bottom: 30px;

      .title {
        font-size: 17px;
        font-weight: bolder;
        color: $firstColor;
      }

      .subtitle {
        font-size: 13px;
        color: $grayColor;
        padding-top: 3px;
      }
    }
  }

  .list-area {
    .history {
      padding-left: 74px;
      padding-right: 74px;

      li {
        border-bottom: 0px;
        padding-left: 10px;
        padding-bottom: 10px;
        padding-top: 10px;

        .historyElement {
          display: flex;

          .elemState {
            width: 150px;
            border-radius: 20px;
            border: 1px solid $white;
            height: 38px;
            text-transform: uppercase;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            line-height: 38px;
          }

          .elemDetails {
            padding-left: 20px;
            padding-top: 8px;

            .row1 {
              font-size: 16px;
              color: $grayColor;
              display: flex;
            }

            .currency-value {
              margin-left: 7px;
              color: #000;
              font-weight: 600;
            }

            .row2 {
              font-size: 14px;
              color: $grayColor;
              display: flex;
              font-weight: 600;

              a {
                color: $grayColor;
                padding-left: 7px;
                padding-top: 1px;
              }

              span {
                color: $grayColor;
                padding-left: 7px;
                font-size: 16px;
              }
            }
          }
        }

        .type_cancelled {
          .elemState {
            color: $dangerColor;
            background-color: $waitingBackground;
          }

          .elemDetails {
            .row2 {
              color: $dangerColor;
            }
          }
        }

        .type_failed {
          .elemState {
            color: $warningColor;
            background-color: $failedBackground;
          }

          .elemDetails {
            .row2 {
              color: $warningColor;
            }
          }
        }

        .type_success {
          .elemState {
            color: $processedColor;
            background-color: $processedBackground;
          }

          .elemDetails {
            .row2 {
              color: $processedColor;
            }
          }
        }
      }
    }
  }

  .history-link {
    padding-left: 254px;
    padding-top: 20px;

    a {
      color: $thirdColor;
    }
  }

  .btn {
    position: relative;
    top: 0;
    transition: all 0.1s ease-in;

    &:focus,
    &:active {
      outline: none !important;
      box-shadow: none !important;
    }

    &:active {
      top: 2px;
    }
  }
}

.pendingPopover {
  border-radius: 3px;
  box-shadow: 3px 3px 4px 1px rgba(0,0,0,0.28);
  border: 1px solid rgba(0,0,0,0.02);
  left: 5px !important;
  top: 0px;

  .popover-body {
    padding: 0 20px 0px 20px;
  }

  .pendingBody {
    font-size: 12px;
    font-style: italic;

    .close-button {
      right: -15px;
      position: relative;
      font-size: 35px;
      font-weight: normal;
      color: $dangerColor;
      opacity: 1;

      &:hover {
        opacity: 0.8;
        color: $dangerColor;
      }

      &:focus,
      &:active {
        outline: none !important;
        box-shadow: none !important;
      }

      &:active {
        top: 2px;
      }
    }

    h2 {
      font-size: 14px;
      padding-top: 30px;
    }
  }
}