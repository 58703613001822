.component-promo_calendar {
  margin: 10px auto;
  padding-bottom: 5px;
  max-width: 1528px;

  .cgl-super-header {
    margin: 0 10px;

    .category {
      color: #353540;
      font-size: 10px;
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-weight: 600;
      text-align: left;
      cursor: pointer;
    }
  }

  .cgl-body {
    position: relative;
    margin: 8px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 170px;

    .img {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 8px;
      overflow: hidden;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: right center;
      }

      img.mobile {
        display: block;
      }

      img.desktop {
        display: none;
      }

      @media screen and (min-width: 845px) {
        img.mobile {
          display: none;
        }

        img.desktop {
          display: block;
        }
      }
    }

    .texts {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      height: 100%;
      width: 50%;
      z-index: 1;
      gap: 4px;
      padding: 16px 0 16px 16px;
      text-align: left;

      @media (min-width: 845px) {
        padding: 24px 0 24px 24px;
        align-items: flex-start;
      }
    }

    .short_description {
      margin-bottom: 4px;
      color: #FFF;
      text-align: left;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 2.8px;
      text-transform: uppercase;
    }

    .long_description {
      margin-bottom: 16px;
      color: var(--fixed-white-fixed, #FFF);
      text-align: left;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;


      @media (min-width: 845px) {
        line-height: 26px;
      }
    }

    .cta_text {
      cursor: pointer;
      min-width: 170px;
      padding: 12px 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 8px;
      background: #0A34DD;

      color: #FFF;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: uppercase;
    }
  }
}