.drag-scroller {
  position: relative;
  overflow: hidden;

  .scroller-arrow {
    display: none;
    position: absolute;
    top: calc(50% - 25px);
    color: #4b5154;
    z-index: 1;
    transform: translate(0, 0);
    cursor: pointer;

    svg {
      width: 50px;
      height: auto;
      display: block;
    }

    &.arrow-left {
      left: -33px;

    }

    &.arrow-right {
      right: -33px;
    }
  }

  .scroller-arrow:hover {
    color: #7b858a;
  }


  @media only screen and (max-width: 1024px) {
    .scroller-arrow {
      display: block;
    }
  }
}