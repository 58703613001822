.hh-front-root {
  height: 122px;
  background-image: url(../../assets/bg.png);
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 7px;
  position: relative;
  overflow: hidden;

  * {
    box-sizing: border-box;
  }

  .hh-front-new {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;

    .hh-win-portrait {
      background-image: none;

      .hh-win-container {
        margin-top: 6px;
      }
    }
  }

  &.grey {
    filter: grayscale(1);
  }

  .hh-pills {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    .hh-pill {
      width: 12px;
      height: 8px;
      border: 1px solid transparent;
      opacity: 1;
      background: #5D0B0B;
      margin: 0 3px;
      border-radius: 4px;

      &.op-26 {
        opacity: 0.26;
      }

      &.op-50 {
        opacity: 0.5;
      }

      &.op-29 {
        opacity: 0.29;
      }

      &.op-60 {
        opacity: 0.60;
      }

      &.op-88 {
        opacity: 0.88;
      }

      &.ml-5 {
        margin-left: 5px !important;
      }

      &.mr-5 {
        margin-right: 5px !important;
      }

      &.m-3 {
        margin: 0 3px !important;
      }

      &.active {
        width: 16px;
        height: 10px;
        border: 1px solid #fff;
        background: #EE3416;
        margin: 0;
        border-radius: 5px;
      }
    }
  }

  .hh-pills-text {
    line-height: 17px;
    text-align: center;

    .hh-current-prize {
      font-size: 13px;
      font-weight: bold;
      color: #fff;
      padding-left: 20px;
    }

    .hh-all-prizes {
      font-size: 10px;
      font-weight: bold;
      color: #FFC400;
    }
  }

  .hh-prizes {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    margin-top: 6px;

    .hh-prize-after,
    .hh-prize-before {
      height: 41px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #E3E7E9;
      font-size: 13px;
      font-weight: bold;
      min-width: 73px;
      background-color: rgba(80, 18, 135, 0.48);
      border: 1px solid #FFC7DB;
      border-radius: 31px;
    }

    .hh-prize-after {
      color: #FFD759;
    }

    .hh-prize-current {
      margin: 0 8px;
      height: 61px;
      min-width: 144px;
      background-color: rgba(80, 18, 135, 0.48);
      border: 1px solid #FFC7DB;
      border-radius: 31px;

      .hh-flx {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 8px;

        .hh-img {
          svg {
            width: 25px;
            height: auto;
            position: relative;
            left: -4px;
            vertical-align: middle;
          }

          img {
            width: 25px;
            height: auto;
            position: relative;
            left: -4px;
          }
        }

        .hh-txt {
          color: #FFC400;
          font-size: 22px;
          font-weight: bold;
        }
      }

      .hh-bet {
        color: #dcc9c9;
        text-align: center;
        font-size: 13px;
        font-weight: normal;
        line-height: 13px;
      }
    }

    .hh-special-prize-front.hidden,
    .hh-prize-before.hidden,
    .hh-prize-after.hidden {
      opacity: 0;
    }

    .hh-special-prize-front {
      width: 73px;
      height: 73px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;
      position: relative;
      overflow: visible;

      .hh-sp-img {
        display: block;
        max-width: 73px;
        height: auto;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        top: 0px;
      }

      .hh-sp-title-front {
        color: #F4C850;
        font-size: 11px;
        font-weight: bold;
        text-align: center;
      }
    }
  }

  .hh-footer,
  .hh-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 18px 24px 0 24px;
    position: relative;

    .hh-center {
      flex: 1 1 0px;
      margin-top: 9px;
    }

    .hh-name {
      width: 60px;
      overflow: visible;
      font-size: 12px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
      text-align: left;
      line-height: 14px;
    }

    .hh-timer {
      display: flex;
      align-items: center;
      justify-content: center;

      .hh-hour,
      .hh-min,
      .hh-sec {
        .hh-top {
          font-size: 18px;
          font-weight: 600;
          color: #fff;
          text-align: center;
          line-height: 18px;
        }

        .hh-bottom {
          font-size: 8px;
          font-weight: normal;
          color: #fcaac9;
          text-align: center;
        }
      }

      .hh-min {
        margin: 0 5px;
      }

      .disabled {
        opacity: 0.4;
      }
    }

    &.nomrg {
      margin: 0;
      justify-content: center;

      .hh-name {
        position: absolute;
        top: 10px;
        left: 20px;
      }

      .hh-timer {
        position: absolute;
        top: 10px;
        right: 20px;
        width: auto !important;
      }
    }

    .hh-sp-left {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 1 1 0px;

      .hh-name {
        width: 90px;
        top: 9px;
      }

      .hh-timer {
        left: 30px;
        right: auto;
        top: 24px;

        .hh-hour,
        .hh-min,
        .hh-sec {
          .hh-top {
            font-size: 14px;
            line-height: 14px;
          }

          .hh-bottom {
            font-size: 7px;
            line-height: 7px;
          }
        }

      }
    }

    .hh-sp-right {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 9px;
      flex: 1 1 0px;

      .hh-sp-title-front {
        font-size: 12px;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        line-height: 14px;
        text-align: center;
      }

      .hh-sp-name {
        font-size: 14px;
        font-weight: 600;
        color: #FFD23F;
        text-align: center;
        line-height: 14px;
      }
    }
  }
}

.hh-front-desktop {
  background-image: url(../../assets/bg-desktop.png);
  height: 210px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 34px;
  font-family: OpenSans, "Open Sans";
  background-size: 100% 100%;
  background-repeat: no-repeat;
  max-width: 1280px;
  margin: 15px auto 30px;
  overflow: visible;

  * {
    box-sizing: border-box;
  }

  .hh-wrapper {
    height: 100%;
    overflow: visible;
    display: flex;
    align-items: center;

    .hh-front-root {
      background-image: url(../../assets/bg-front-desktop.png);
      height: 215px;
      width: 669px;
      box-shadow: 0px 3px 10px 0 rgba(0, 0, 0, .5);
      border-radius: 12px;

      .hh-header {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        margin: 0;

        .hh-center {
          flex: 1 1 0px;
          margin-top: 10px;
        }

        .hh-name {
          position: absolute;
          top: 20px;
          left: 40px;
          font-size: 23px;
          font-weight: bold;
          word-break: initial;
          width: 150px;
          line-height: 23px;
        }

        .hh-timer {
          position: absolute;
          top: 20px;
          right: 40px;

          .hh-hour,
          .hh-min,
          .hh-sec {
            .hh-top {
              font-size: 34px;
            }

            .hh-bottom {
              font-size: 15px;
              margin-top: 5px;
            }
          }
        }

        .hh-pills {
          .hh-pill {
            width: 23px;
            height: 15px;
            margin: 0 5px;
            border: 1px solid transparent;
            opacity: 1;
            background: #5D0B0B;
            margin: 0 3px;
            border-radius: 4px;

            &.op-88 {
              opacity: .88;
            }

            &.op-60 {
              opacity: .60;
            }

            &.op-29 {
              opacity: .29;
            }

            &.active {
              width: 30px;
              height: 19px;
              margin: 0 7px;
              border: 1px solid #fff;
              background: #EE3416;
              border-radius: 5px;
            }
          }
        }

        .hh-sp-left {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1 1 0px;

          .hh-name {
            width: 160px;
            top: 20px;
          }

          .hh-timer {
            left: 60px;
            right: auto;
            top: 46px;

            .hh-hour,
            .hh-min,
            .hh-sec {
              .hh-top {
                font-size: 30px;
                line-height: 30px;
              }

              .hh-bottom {
                font-size: 13px;
                line-height: 13px;
              }
            }

          }
        }

        .hh-sp-right {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-top: 20px;
          flex: 1 1 0px;

          .hh-sp-title-front {
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            text-transform: uppercase;
            line-height: 24px;
            text-align: center;
          }

          .hh-sp-name {
            font-size: 28px;
            font-weight: 600;
            color: #FFD23F;
            text-align: center;
            line-height: 28px;
          }
        }
      }

      .hh-pills-text {
        margin-top: 8px;

        .hh-current-prize {
          font-size: 24px;
          padding-left: 0;
        }

        .hh-all-prizes {
          font-size: 18px;
        }

        .hh-all-prizes.hidden {
          opacity: 0;
        }
      }

      .hh-prizes {
        margin-top: 20px;

        .hh-prize-current {
          height: 110px;
          min-width: 310px;
          margin: 0 15px;

          .hh-img {
            img {
              width: 50px;
            }
          }

          .hh-txt {
            font-size: 42px
          }

          .hh-bet {
            font-size: 25px;
            line-height: 28px;
          }
        }

        .hh-prize-before,
        .hh-prize-after {
          margin-top: 15px;
          height: 80px;
          font-size: 25px;
          padding: 22px 28px;
        }

        .hh-special-prize-front {
          width: 141px;
          height: 110px;

          .hh-sp-img {
            max-width: 100px;
          }

          .hh-sp-title-front {
            color: #F4C850;
            font-size: 25px;
            position: relative;
          }
        }
      }
    }
  }

  .hh-campaign-info {
    min-width: 397px;
    width: 397px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
    padding: 20px 0 30px 0;

    .hh-title {
      font-size: 14px;
      font-weight: normal;
      color: #4E4E4E;
      text-decoration: underline;
      cursor: pointer;
      margin-top: 0px;
    }

    .hh-cta {
      width: 397px;
      min-height: 60px;
      height: 60px;
      background-image: url(../../assets/bg-cta-button.png);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      font-weight: 600;
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      position: relative;
      overflow: visible;
      box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.57);
      border-radius: 5px;

      .hh-date {
        position: absolute;
        right: 10px;
        top: -10px;
        border: 1px solid #fff;
        background-color: #FFC400;
        font-size: 13px;
        font-weight: bold;
        color: #000000;
        padding: 0 10px;
        border-radius: 3px;

        .disabled {
          opacity: .5;
        }
      }
    }
  }
}

.hh-front-sp-root {
  height: 122px;
  border-radius: 7px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  background-color: #08121E;

  * {
    box-sizing: border-box;
  }

  .hh-image {
    position: absolute;
    top: 0;
    bottom: 18px;
    left: 0;
    right: 0;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;

    &>img {
      height: auto;
      width: 346px;
      display: block;
    }
  }

  .hh-title {
    width: 60px;
    overflow: visible;
    font-size: 12px;
    font-weight: bold;
    color: #fff;
    text-transform: uppercase;
    text-align: left;
    line-height: 14px;
    padding-bottom: 18px;
    margin-left: 35px;
    position: relative;
  }

  @media screen and (min-width: 1200px) {
    .hh-title {
      width: 100px;
    }
  }

  .hh-footer {
    height: 18px;
    background: linear-gradient(to right, #1b375e 0%, #1a4075 38.5%, #152843 72.2%, #03152e 100%);

    text-align: center;
    line-height: 18px;
    color: #F2C44E;
    font-size: 11px;
    font-weight: 600;

    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }

  .hh-help {
    width: 21px;
    height: 21px;

    border: 2px solid #08121E;
    background: #3B6093;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 15px;
    border-radius: 50%;
    cursor: pointer;

    position: absolute;
    left: 10px;
    bottom: 6px;
  }
}

.hh-front-sp-root.hh-front-sp-desktop {
  height: 215px;
  width: 669px;

  .hh-image {
    &>img {
      width: 100%;
      height: auto;
    }
  }

  .hh-help {
    bottom: 11px;
  }

  .hh-title {
    font-size: 23px;
    line-height: 23px;
    padding-bottom: 21px;
  }

  .hh-footer {
    font-size: 14px;
    height: 21px;
    line-height: 21px;
  }
}