.n-page.live {
  overflow: unset;
  height: unset;

  .content {
    height: unset;

    .content-wrap {
      overflow: unset;
      height: unset;

      .match-results {
        top: 57px;
      }
    }
  }

  .left-nav.lvl {
    top: 57px;
    position: fixed;
  }

  .left-nav.lvl.expanded {
    width: 100%;
    min-width: 214px;
    height: calc(100vh - 95px);
  }

  @media screen and (min-width: 848px) {
    .left-nav.lvl {
      position: sticky;
      top: 57px;
      height: calc(100vh - 57px);
    }

    .frame .sidebar {
      position: sticky;
      top: 57px;
      height: calc(100vh - 57px);
      overflow: unset;
      min-width: 412px;

      >div {
        overflow: auto;
        height: 100%;

        scrollbar-color: var(--ns-scrollbar-thumb-bg) var(--ns-scrollbar-track-bg);
        scrollbar-width: thin;

        &.lvl {
          top: -56px;
          height: 0;
          z-index: 95;
        }

        &::-webkit-scrollbar {
          width: 5px
        }

        &::-webkit-scrollbar-track {
          background: var(--ns-scrollbar-track-bg);
          border-radius: 0px
        }

        &::-webkit-scrollbar-thumb {
          background: var(--ns-scrollbar-thumb-bg);
          border-radius: 3px
        }

      }
    }

    .content .frame {
      display: flex;

      .content-wrap {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .content .frame .content-wrap {
      min-height: calc(100vh - 57px);
      flex: 1 1 auto;
      overflow: unset;

      .flex {
        display: flex;
      }

      .content-flex-one {
        width: 100%;
        min-width: 0;
      }

      .content-flex-two {
        padding: 0 3px;
      }
    }

    .content .page-header {
      z-index: 100;
    }
  }
}