@import "../../../variables.scss";

.MuiButtonBase-root{

    &.default{
        position: relative;
        background: #e5e5e5;
        border: none;
        border-radius: 6px;
        font-weight: 700;
        padding: 15px 25px;
        text-transform: uppercase;
        font-size: 13px;
        width: fit-content;
        cursor: pointer;
        
        &.active{
            background: rgb(255,151,29);
            background: -moz-linear-gradient(135deg, rgba(255,151,29,1) 0%, rgba(255,189,13,1) 100%);
            background: -webkit-linear-gradient(135deg, rgba(255,151,29,1) 0%, rgba(255,189,13,1) 100%);
            background: linear-gradient(135deg, rgba(255,151,29,1) 0%, rgba(255,189,13,1) 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff971d",endColorstr="#ffbd0d",GradientType=1);
            color: rgb(255, 255, 255);
            
            span{
                color: rgba(255, 255, 255, .7);
            }
        }

        &.gray{
            background: rgb(214,218,239);
            background: -moz-linear-gradient(top,  rgba(214,218,239,1) 0%, rgba(197,201,221,1) 100%);
            background: -webkit-linear-gradient(top,  rgba(214,218,239,1) 0%,rgba(197,201,221,1) 100%);
            background: linear-gradient(to bottom,  rgba(214,218,239,1) 0%,rgba(197,201,221,1) 100%);
            color: rgb(255, 255, 255);
            
            span{
                color: rgba(255, 255, 255, .7);
            }
        }
        
        &.gradient{
            background: $gradientStartColor;
            background: -moz-linear-gradient(left,  $gradientStartColor 0%, $gradientEndColor 100%);
            background: -webkit-linear-gradient(left,  $gradientStartColor 0%,$gradientEndColor 100%);
            background: linear-gradient(to right,  $gradientStartColor 0%,$gradientEndColor 100%);
            color: rgb(255, 255, 255);
            
            span{
                color: rgba(255, 255, 255, .7);
            }
        }

        &.full{
            width: 100%;
        }

        &:disabled{
            background: #e5e5e5;
            color: rgb(158, 158, 158);
            cursor: not-allowed;
        }

        span{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 11px;
            font-weight: 500;
            line-height: 1;
            color: #777;
        }

        .LeftIcon{
            position: absolute;
            left: 20px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            height: 30px;
            width: 30px;

            svg{
                max-width: 30px;
                max-height: 30px;
                fill: rgb(255, 255, 255);
            }
        }

        .RightIcon{
            position: absolute;
            right: 20px;
            top: 0px;
            bottom: 0px;
            margin: auto;
            height: 30px;
            width: 30px;

            svg{
                max-width: 30px;
                max-height: 30px;
                fill: rgb(255, 255, 255);
            }
        }
    }
}