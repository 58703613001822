@import "../../../../variables.scss";
$oldRedColor: $redColor;
$colorType: #cce3f2;
$fontColorType: #0171bc;
$greenColor: #07ba8a;
$redColor: #d6346b;
$grayColor: #b3b3b3;
$darkGreen: #007048;

.limit-widget {
  padding: 4rem 2rem 1.5rem;
  text-align: left;
  position: relative;
  .line {
    height: 1px;
    background-color: $grayColor;
    max-width: 100%;
    position: absolute;
    bottom: 0;
    width: calc(100% - 4rem);
    left: 2rem;
  }
  .details {
    width: 50%;
    min-width: 300px;
    .limit-type {
      text-transform: uppercase;
      background-color: $colorType;
      float: left;
      padding: .5rem 2rem;
      color: $fontColorType;
      font-weight: bold;
      -webkit-border-radius: 1.2rem;
      -moz-border-radius: 1.2rem;
      border-radius: 1.2rem;
      margin-bottom: 1rem;
    }
    .value {
      font-weight: bold;
      font-size: 1.5rem;
      .currency-value {
        font-weight: bold;
        font-size: 1.5rem;
        display: inline;
      }
      * {
        font-weight: normal;
      }
      .remained {
        color: $greenColor;
        font-size: 1rem;
        font-weight: 700;
        .currency-value {
          border-left: 3px solid $grayColor;
          margin-left: .5rem;
          padding-left: .5rem;
          font-size: 1rem;
          margin-top: -.25rem;
          display: inline-block;

          font-weight: 700;
        }
      }
      .progress {
        background-color: $greenColor;
        height: 0.925rem;
        -webkit-border-radius: 1rem;
        -moz-border-radius: 1rem;
        border-radius: 1rem;
        max-width: 240px;
        margin-bottom: -2px;
        margin-top: 5px;
        .progress-bar {
          background-color: $redColor;
          border-radius: 1rem;
        }
      }
      .consumed {
        .percent {
          color: $redColor;
          font-weight: bold;
          font-size: 1.5rem;
          .description {

            color: $grayColor;
          }
        }
      }
    }
  }
  .modify-limit {

  }
}

.header-limits {
    width: calc(100% - 4rem);
    background-color: lighten($colorType,5);
    margin-left: 2rem;
    padding-top: 3rem;
    border-radius: 5px;
    .limit-item {
        display: inline-block;
        width: 15%;
        background-color: lighten($colorType,10);
        padding: .5rem 0;
        margin: 0 .25rem .75rem;
        cursor: pointer;
        font-weight: 500;
        color: darken($grayColor,30);
        position: relative;
        &.active {
          color: darken($greenColor,15);
          font-weight: 600;
          position: relative;
          background-color: white;
          &:before {
            content: " ";
            position: absolute;
            bottom: -.75rem;
            left: calc(50% - .75rem);
            width: 0;
            height: 0;
            border-left: .75rem solid transparent;
            border-right: .75rem solid transparent;
            border-top: .75rem solid white;
          }
        }
      &.coming-soon {
        transform: translateY(-.5rem);
        line-height: .5rem;
        padding-bottom: 1.5rem;
        margin-bottom: -1rem;
        pointer-events: none;
        .coming-soon {
          font-size: 9px;
          font-weight: normal;
          font-style: italic;
          position: absolute;
          left: 10%;
          bottom: 7px;
          height: 7px;
          line-height: 7px;
          display: block;
          text-align: center;
          width: 80%;

          span {
            line-height: 7px;
            text-transform: uppercase;
            border: 1px solid $grayColor;
            border-radius: 20px;
            padding: 2px 7px;
            color: #565109;
            font-size: 8px;
            font-weight: normal;
            font-style: italic;
          }
        }
      }

    }
}

.alert {
  &.danger {
    background-color: $oldRedColor;
    color: $white;
    font-weight: bold;
  }
}