.ButtonNext {
    width: 100%;
    padding-top: 10px;
}

.ButtonNext .btn
{
    box-shadow: 3px 3px 5px 1px rgba(0,0,0,0.20);
}

.ButtonNext .btn .icon
{
    width: 32px;
    display: inline-block;
}

.ButtonNext .btn .text
{
    padding-left: 10px;
    padding-right: 10px;
    text-transform: capitalize;
    display: inline-block;
    font-size: 18px;
}

.ButtonNext .btn .icon.animate {
    animation: ButtonNext-spin infinite 1s linear;
}

@keyframes ButtonNext-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(-360deg); }
}

.ButtonNext .btn *
{
    color: #0a4230;
    fill: #0a4230;
}

.ButtonNext .btn.btn-warning:hover
{
    background-color: #fccd00;
    border-color: #fccd00;
}