.next-step {
  &.register-button {
    width: 100%;
    padding: 1rem 1.5rem 0;
    position: relative;
    box-sizing: border-box;
    button {
      width: 100%;
      background: linear-gradient(173deg,#FF971D,#FFB400);
      &:disabled,
      .disabled {
        color: rgba(0, 0, 0, 0.26);
        box-shadow: none;
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }
}