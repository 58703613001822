.tournament-info-component {
  margin: 10px auto;
  max-width: 1528px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .cgl-super-header {
    margin: 0 10px;
    z-index: 2;
    position: relative;

    .category {
      color: #B5BFD0;
      font-size: clamp(10px, 1vw, 12px);
      font-weight: bold;
      text-align: left;
    }
  }

  .cgl-header {
    margin: 0 10px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    position: relative;

    .title {
      color: #D5DAE4;
      font-size: 18px;
      font-size: clamp(18px, 1.8vw, 26px);
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #646e7d;
      font-size: 12px;
      font-size: clamp(12px, 1.2vw, 17px);
      font-weight: 600;
      text-align: left;
      cursor: pointer;
      white-space: nowrap;
      border: 1px solid #646e7d;
      border-radius: 11px;
      padding: 0 12px;
      height: 22px;
      display: flex;
      align-items: center;
      line-height: 0;

      span {
        padding: 0 5px;
      }

      svg {
        position: relative;
        top: 1px;

        path {
          fill: #646e7d;
        }
      }
    }
  }

  .tournament-body-wrapper {
    max-width: 800px;
    margin: 0 10px;
    padding: 2px;
    border-radius: 18px;
    display: flex;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    background: linear-gradient(135deg,
        #213348, #142232 20%);
    border: 2px solid #2d3e5e;

    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;

    .tournament-body {
      position: relative;
      z-index: 1;
      width: calc(100% + 8px);
      margin: -4px;
      border-radius: 18px;
      background: linear-gradient(to top,
          rgba(33, 51, 72, 0),
          #2a0d66 14.2%,
          #3b0f67 22.3%,
          rgba(29, 30, 62, 0) 60.6%,
          rgba(20, 34, 50, 0),
        );
    }

    @media screen and (min-width:800px) {
      .tournament-body {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &>div {
          flex: 1 1 auto;
        }

        &>div:first-child {
          margin-right: 20px;
        }

        &>div:last-child {
          margin-right: 20px;
        }
      }
    }
  }

  .tournament-body-wrapper .tournament-body {
    .body-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0px 18px 0px 1px;

      .title-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin-right: 0px;
          height: 53px;
          overflow: visible;
          display: flex;
          align-items: center;
          justify-content: flex-start;

          img {
            display: block;
            width: auto;
            height: 53px;
            object-fit: cover;
          }
        }

        font-size: 12px;
        font-weight: 600;
        color: #B5BFD0;
      }

      .date-range {
        font-size: 10px;
        font-weight: regular;
        color: #B5BFD0;
      }
    }

    .prize-info-images {
      padding: 0 25px;

      display: flex;
      width: 100%;
      max-width: 370px;
      margin: 0 auto;

      align-items: center;
      justify-content: space-between;

      .prize-image {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          object-fit: contain;
          display: block;
        }

        &.left {
          width: 80px;
          height: 80px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        &.center {
          width: 110px;
          height: 110px;

          img {
            width: 110px;
            height: 110px;
          }

          .txt .title {
            font-size: 28px;
            line-height: 28px;
            position: relative;
          }
        }

        &.right {
          width: 80px;
          height: 80px;

          img {
            width: 80px;
            height: 80px;
          }
        }

        &.left {
          width: 70px;
          height: 70px;

          img {
            width: 70px;
          }
        }


        .txt {
          position: absolute;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .subtitle {
            font-size: 9px;
            line-height: 9px;
            color: #DFE3E7;
            font-weight: bold;
          }

          .title {
            font-size: 15px;
            line-height: 15px;
            color: #DFE3E7;
            font-weight: bold;
            text-shadow: 1px 2px rgb(0, 0, 0);
          }
        }
      }
    }

    .prize-info-texts {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px;
      width: 100%;
      max-width: 370px;
      margin: 0 auto;
      margin-top: -10px;

      .prize-info {
        font-size: 12px;
        color: #b9bdc2;
        font-weight: bold;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        white-space: nowrap;

        display: flex;

        &.left {
          width: 70px;
        }

        &.center {
          width: 100px;
        }

        &.right {
          width: 70px;
        }

        .qm {
          margin-right: 5px;
          border-radius: 50%;
          height: 15px;
          width: 15px;
          min-width: 15px;
          max-width: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 10px;
          font-weight: bold;
          color: #B9BDC2;
          border: 1px solid rgba(249, 249, 249, .23);
        }
      }
    }

    .progress-timer-wrapper {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 18px;



      .counter-texts {
        margin: 30px 0 20px;

        .counter-header {
          font-size: 12px;
          font-weight: normal;
          color: #898989;
          text-align: center;
        }

        .timers {
          display: flex;
          align-items: center;
          justify-content: center;

          .timer {
            min-width: 40px;
            max-width: 40px;
            font-size: 24px;
            font-weight: bold;
            color: #fff;
            text-align: center;
          }
        }

        .timers-labels {
          display: flex;
          align-items: center;
          justify-content: center;

          .timer {
            min-width: 40px;
            max-width: 40px;
            font-size: 11px;
            font-weight: normal;
            color: #fff;
            text-align: center;
          }
        }

        .not-started {
          font-size: 24px;
          font-weight: 600;
          color: #fff;
          margin-bottom: 10px
        }
      }

      .progress-wrapper {
        max-width: 200px;
        min-width: 200px;
        height: 5px;
        background: rgba(255, 255, 255, .07);
        border-radius: 3px;
        margin-bottom: 2px;

        .progress-br {
          height: 5px;
          background: linear-gradient(to right, #F98D00, #FFCA1E);
          border-radius: 3px;
        }
      }

      .mb25px {
        margin-bottom: 25px;
      }

      .mb30px {
        margin-bottom: 30px;
      }

      .progress-title {
        font-size: 12px;
        color: #c4c4c4;
        font-weight: bold;
        text-align: center;
      }

      .progress-text {
        font-size: 12px;
        color: #FF5B00;
        font-weight: bold;


        .muted {
          color: #8D441B;
        }

        .days {
          color: #FF975D;
        }
      }

      .progress-text.begins {
        color: #31f500;

        .muted {
          color: #2a722c;
        }

        .days {
          color: #55e559
        }
      }
    }

    .big-text-plus-icon {
      font-size: 18px;
      color: #fff;
      font-weight: bold;

      .text-transition {
        white-space: nowrap;
        width: 100%;

        .text-transition_inner>div {
          text-align: center;
          width: 100%;
          white-space: nowrap;
        }
      }

      img {
        margin-right: 5px;
        width: 17px;
        height: 17px;
        object-fit: contain;
        position: relative;
        top: 1px;
      }
    }

    .tm-btn {
      margin: 5px 20% 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 34px;
      position: relative;
      z-index: 1;

      background: linear-gradient(to right,
          #EE034A, #3700F3);
      border-radius: 7px;
      cursor: pointer;

      font-size: 12px;
      color: #fff;
      font-weight: 600;
      text-transform: uppercase;

      &.regular {
        background: linear-gradient(to right,
            #EEB003, #F36900);
      }

      &.blocked {
        background: linear-gradient(to right,
            #8803EE, #3700F3);
      }

      &.muted {
        opacity: .7;
        pointer-events: none;
        filter: grayscale(1);
      }
    }

    .tm-buttons-group {
      display: flex;
      align-items: center;
      justify-content: center;

      .tm-btn {
        margin: 5px 5px 20px;
        min-width: 140px;
        max-width: 140px;
      }

      .tm-btn.random {
        min-width: 75px;
        max-width: 75px;

        background: linear-gradient(to right,
            #0A1520, #213348);

        border: 2px solid #445167;
      }
    }

    .tm-ranking {
      padding: 0 2px;

      .empty {
        font-size: 12px;
        text-align: center;
        padding: 10px 0;
        font-weight: normal;
        color: #ffffffaa;
      }

      .tm-row.tm-header {
        height: 29px;
        background: transparent;
      }

      .tm-row {
        display: flex;
        align-items: center;
        justify-content: stretch;
        /*cursor: pointer;*/

        padding: 0 10px;

        width: 100%;
        height: 42px;
        border-bottom: 1px solid #304154;

        &:not(.tm-header) {
          background: #25113e99;
        }

        &.tm-row-0 {
          background: #50476B;
        }

        &.tm-row-1 {
          background: #4E3471;
        }

        &.tm-row-2 {
          background: #3F1A6E;
        }

        &>div {
          flex: 1 1 auto;
        }

        .tm-header {
          color: #979DAB !important;
          font-size: 9px !important;
          font-weight: bold !important;
          padding: 0 2px;
        }

        .tm-col.tm-left {
          text-align: left;
        }

        .tm-col.tm-right {
          text-align: right;
        }

        .tm-col.tm-center {
          text-align: center;
        }

        .tm-col1 {
          width: 25px;
          min-width: 25px;
          max-width: 25px;

          &>div {
            font-size: 10px;
            font-weight: 600;
            color: #8D92A0;
            text-align: center;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1px;
          }

          .tm-gold {
            background: linear-gradient(to right, #FF971D, #FFB400);
            border: 1px solid #FCC735;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-silver {
            background: linear-gradient(to right, #B5C9DC, #9AC7F2);
            border: 1px solid #81A0BE;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-bronze {
            background: linear-gradient(to right, #EB7120, #C65F05);
            border: 1px solid #F6A169;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-regular {
            border: 1px solid rgba(255, 255, 255, 0);
            height: 16px;
          }
        }

        .tm-col2 {
          width: 40px;
          min-width: 40px;
          max-width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            display: block;
            width: 27px;
            height: 27px;
            transform: translateZ(0);
          }
        }

        .tm-col3 {
          padding: 0 10px;
          color: #C2C4C8;
          font-size: 12px;
          font-weight: 600;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .tm-col4 {
          width: 50px;
          min-width: 50px;
          max-width: 50px;
          color: rgba(255, 255, 255, .26);
          font-size: 10px;
          font-weight: bold;
        }

        .tm-col5 {
          width: 80px;
          min-width: 80px;
          max-width: 80px;
          color: #BEC0C5;
          font-size: 10px;
          font-weight: 600;
        }

        &.tm-show-more {
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          cursor: pointer;

          .tm-col-full {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 10px;
            color: #17214D;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            img {
              width: auto;
              height: 25px;
              margin-right: 10px;
            }
          }
        }
      }

      .tm-row.tm-active {
        background: linear-gradient(to right, #FF7700, #FFC400);
        padding: 0 5px 0 10px;
        border-bottom: 1px solid transparent;

        position: sticky;
        bottom: 0;
        top: 0;
        z-index: 1;

        .tm-col1>div {
          color: #fff;
        }

        .tm-col2 {
          width: 35px;
          min-width: 35px;
          max-width: 35px;

          img {
            display: block;
            width: 35px;
            height: 35px;
            transform: translateZ(0);
          }
        }

        .tm-col3 {
          font-size: 14px;
          color: #fff;
        }

        .tm-col4 {
          width: auto;
          min-width: unset;
          max-width: unset;
          font-size: 16px;
          color: #fff;
          font-weight: bold;
          white-space: nowrap;
          padding-right: 5px;
        }

        .tm-col5 {
          width: auto;
          min-width: unset;
          max-width: unset;
          border-radius: 11px;
          background: #fff;
          color: #17214D;
          font-size: 12px;
          text-align: center;
          height: 22px;
          line-height: 22px;
        }

        .tm-edit-nickname {
          white-space: nowrap;
          display: flex;
          align-items: center;
          cursor: pointer;

          .tm-edit-icon {
            border-radius: 50%;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            display: inline-block;
            margin-left: 5px;

            svg {
              display: block;
              width: 18px;
              height: 18px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }

    .footer-text {
      height: 38px;
      padding: 0 20px 4px;
      font-size: 12px;
      color: #B9BDC2;
      font-weight: 600;

      display: flex;
      align-items: center;
      justify-content: flex-end;
      cursor: pointer;
    }
  }

  .cgl-list {
    .cgl-grid {
      .cgl-item {
        display: none;
      }
    }
  }

  .first,
  .last {
    .backdrop {
      display: none;
    }
  }

  @media screen and (min-width: 1528px) {
    .cgl-list {
      overflow: visible;
      padding-bottom: 0px;

      .cgl-grid {
        width: 100%;
        overflow: visible;
        white-space: nowrap;
        pointer-events: all;
        padding-bottom: 10px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-start;

        .tournament-body-wrapper {
          margin-left: 0px;
          min-width: 800px;
          cursor: pointer;
        }

        .cgl-item {
          max-width: 232px;
          min-width: 232px;
          width: 100%;
          margin-right: 10px;

          overflow: visible;
          display: flex;
          flex-direction: column;
          cursor: pointer;

          height: 347px;
          margin-top: -22px;

          .header-tag-wrapper {
            margin-bottom: 5px;
            margin-left: 15px;
            text-align: left;

            .header-tag {
              height: 18px;
              display: inline-flex;
              align-items: center;
              justify-content: center;
              padding: 0 10px;

              font-size: 10px;
              font-weight: bold;
              color: #fff;
              text-transform: uppercase;

              background: #351097;
              border-radius: 4px;
            }
          }

          &.double .header-tag-wrapper .header-tag {
            background: #EEAD03;
          }

          .header {
            color: #B5BFD0;
            font-size: 12px;
            font-weight: 600;
            opacity: .53;
            padding: 0 15px 5px;
            text-align: left;
          }

          .body-wrapper {
            flex: 1 1 auto;
            height: 100%;
            padding: 2px;
            border-radius: 18px;
            display: flex;
            box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
            background: linear-gradient(135deg,
                #213348, #142232 20%);
            border: 2px solid #2d3e5e;

            .body {
              position: relative;
              z-index: 1;
              width: calc(100% + 8px);
              height: 218px;
              margin: -4px;
              border-radius: 18px;
              background: linear-gradient(to top,
                  rgba(33, 51, 72, 0),
                  #2a0d66 14.2%,
                  #3b0f67 22.3%,
                  rgba(29, 30, 62, 0) 60.6%,
                  rgba(20, 34, 50, 0),
                );

              .body-header {
                display: flex;
                align-items: flex-start;
                justify-content: space-between;



                .logo {
                  align-items: stretch;
                  margin-top: 0;
                  margin-left: 0;
                  width: 43px;
                  height: 50px;
                  overflow: visible;

                  img {
                    width: 62px;
                    height: 53px;
                    object-fit: contain;
                  }
                }

                .prizes {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 10px;
                  padding-top: 8px;
                  background: radial-gradient(at 50% 5px, rgba(72, 231, 255, .3) 0%, rgba(0, 14, 29, 0) 70%);
                  position: relative;

                  &::before {
                    content: "";
                    position: absolute;
                    top: 0;
                    left: calc(50% - 16px);
                    width: 32px;
                    height: 2px;
                    border-radius: 5px;
                    background: linear-gradient(to right,
                        #48e7ff00,
                        #48e7ff 50.2%,
                        rgba(72, 231, 255, .937) 53.4%,
                        #48e7ff00);
                  }

                  .icon {
                    margin-top: 5px;
                    margin-right: 5px;

                    img {
                      display: block;
                      width: 30px;
                      height: 18px;
                      object-fit: contain;
                    }
                  }

                  .txt {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;

                    .title {
                      color: #4BE7FF;
                      font-size: 11px;
                      font-weight: bold;
                      line-height: 11px;
                      margin-bottom: 5px;
                    }

                    .subtitle {
                      color: #4BE7FF;
                      font-size: 9px;
                      font-weight: normal;
                      line-height: 9px;
                    }
                  }
                }
              }

              .body-title {
                margin: 0 10px;
                border-bottom: 1px solid #5e7186;

                .txt {
                  font-size: 12px;
                  font-weight: 600;
                  color: #B5BFD0;
                  text-transform: uppercase;
                  padding: 0 10px 5px;
                  text-align: center;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
              }

              .body-images {
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 35px 0 35px;

                &.mt10px {
                  margin-top: 10px;
                }

                &>div {
                  margin: 5px;

                  img {
                    width: 80px;
                    height: 80px;
                    object-fit: contain;
                  }
                }

                .img-simple {
                  position: relative;

                  img {
                    margin-top: 5px;
                    width: 100px;
                    height: 100px;
                    object-fit: contain;
                  }

                  .txt {
                    position: absolute;
                    bottom: 0px;
                    left: 0;
                    right: 0;

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                    font-size: 11px;
                    font-weight: bold;
                    text-shadow: 2px 2px #2d1354;
                  }
                }

                .img-award {
                  width: 100px;
                  height: 100px;
                  position: relative;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  top: 5px;

                  img {
                    margin-top: 5px;
                    width: 100px;
                    height: 100px;
                    object-fit: contain;
                  }

                  .txt {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;

                    .subtitle {
                      font-size: 10px;
                      line-height: 10px;
                      color: #DFE3E7;
                      font-weight: bold;
                    }

                    .title {
                      font-size: 18px;
                      line-height: 18px;
                      color: #DFE3E7;
                      font-weight: bold;
                      text-shadow: 1px 2px rgb(0, 0, 0);
                    }
                  }

                  .timer {
                    position: absolute;
                    bottom: 5px;
                    left: 0;
                    right: 0;
                    height: 18px;
                    background: linear-gradient(to right,
                        #13181E00,
                        rgba(19, 24, 30, 88) 20%,
                        rgba(19, 24, 30, 88) 80%,
                        #13181E00,
                      );

                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #F36900;
                    font-size: 12px;
                    font-weight: bold;

                    .muted {
                      opacity: .55;
                    }

                    .days {
                      color: #FF975D;
                    }
                  }
                }
              }

              .sticker {
                position: absolute;
                bottom: 31px;
                left: 0;
                right: 0;
                height: 17px;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 1px solid #fff;

                font-size: 10px;
                line-height: 11px;
                font-weight: 600;
                color: #fff;

                background: linear-gradient(to right,
                    #800d37,
                    #ff196e 17.6%,
                    #ff196e 82.2%,
                    #800d37);
              }

              .timers-wrapper {
                margin-top: 10px;

                .header {
                  font-size: 8px;
                  line-height: 8px;
                  font-weight: 600;
                  color: #fff;
                  text-align: center;
                  text-transform: uppercase;
                  opacity: 1;
                }

                .timers {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .timer {
                    min-width: 28px;
                    max-width: 28px;
                    font-size: 18px;
                    line-height: 18px;
                    font-weight: 600;
                    color: #fff;
                    text-align: center;

                    &.muted {
                      opacity: .55;
                    }
                  }
                }

                .timers-labels {
                  display: flex;
                  align-items: center;
                  justify-content: center;

                  .timer {
                    min-width: 28px;
                    max-width: 28px;
                    font-size: 8px;
                    font-weight: normal;
                    color: #A4A4A4;
                    text-align: center;

                    &.muted {
                      opacity: .55;
                    }
                  }
                }
              }

              .button {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                height: 29px;
                border-radius: 0 0 7px 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                font-size: 12px;
                font-weight: 600;
                color: #fff;
                text-transform: uppercase;

                background: linear-gradient(to right,
                    #EE034A, #3700F3);
              }

              .button.regular {
                background: linear-gradient(to right,
                    #EEB003, #F36900);
              }
            }
          }
        }

        .cgl-item:last-child {
          margin-right: 0;
        }
      }
    }

    &.no-ranking {
      .cgl-list {
        .cgl-grid {
          .tournament-body-wrapper {
            min-width: 400px;
          }
        }
      }
    }

    .tournament-body-wrapper .tournament-body .tm-ranking {
      padding: 5px 0 0;
    }

    .extended-layout {
      .body-header {
        opacity: 0;
      }
    }

    .extended-layout.first {
      position: relative;
      height: 435px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 10px;

      &>* {
        position: relative;
      }

      .backdrop {
        position: absolute;
        top: 2px;
        bottom: 2px;
        right: 0;
        left: 2px;
        width: calc(100% - 2px);
        height: calc(100% - 4px);
        display: block;
        border-top-left-radius: 18px;
        border-bottom-left-radius: 18px;
        background: #12092d;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: center top;
          border-top-left-radius: 18px;
          border-bottom-left-radius: 18px;
        }

        .overlay-filter {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          border-top-left-radius: 18px;
          border-bottom-left-radius: 18px;
          background: linear-gradient(to bottom, #12092d00 0,
              #40537313 100px,
              #405373e1 150px,
              #12092d81 250px, #12092d);
        }
      }

      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
      margin-right: 0 !important;
    }

    .extended-layout.last {
      background-size: cover;
      background-position: center top;
      background-repeat: no-repeat;
      border-top-right-radius: 18px;
      border-bottom-right-radius: 18px;
      margin-right: 0 !important;
      height: 435px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;

      &>* {
        position: relative;
      }

      .backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        border-top-right-radius: 18px;
        border-bottom-right-radius: 18px;
        overflow: hidden;

        img {
          position: relative;
          width: 100%;
          height: 100%;
          display: block;
          object-fit: cover;
          object-position: center top;
          border-top-right-radius: 18px;
          border-bottom-right-radius: 18px;
          opacity: .3;
          filter: blur(20px);
        }
      }

      .tm-ranking {
        margin-left: 20px;
        margin-right: 20px;
        padding: 5px 0 0;
      }
    }
  }
}