#star_travel_complet_v3_169x90-u-radial_rays_3 {
  animation: star_travel_complet_v3_169x90-u-radial_rays_3_c_o 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-radial_rays_3_c_o {
  0% {
    opacity: 0
  }

  11.666667% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  14.333333% {
    opacity: 0.64;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  35% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

#star_travel_complet_v3_169x90-u-radial_rays_2_ts {
  animation: star_travel_complet_v3_169x90-u-radial_rays_2_ts__ts 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-radial_rays_2_ts__ts {
  0% {
    transform: translate(83.747579px, 62.543691px) scale(0.652149, 0.652159)
  }

  11.666667% {
    transform: translate(83.747579px, 62.543691px) scale(0.652149, 0.652159);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  14.333333% {
    transform: translate(83.747579px, 62.543691px) scale(2.288087, 2.2881);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  20% {
    transform: translate(83.747579px, 62.543691px) scale(1.670146, 1.670149);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  33.333333% {
    transform: translate(83.747579px, 62.543691px) scale(2.520876, 2.520876)
  }

  100% {
    transform: translate(83.747579px, 62.543691px) scale(2.520876, 2.520876)
  }
}

#star_travel_complet_v3_169x90-u-radial_rays_2 {
  animation: star_travel_complet_v3_169x90-u-radial_rays_2_c_o 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-radial_rays_2_c_o {
  0% {
    opacity: 0
  }

  11.666667% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  14.333333% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  33.333333% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

#star_travel_complet_v3_169x90-u-glow_generic {
  animation: star_travel_complet_v3_169x90-u-glow_generic_c_o 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-glow_generic_c_o {
  0% {
    opacity: 0
  }

  11.666667% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  14.333333% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  18.666667% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  41.666667% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

#star_travel_complet_v3_169x90-u-glow_generic_yellow {
  animation: star_travel_complet_v3_169x90-u-glow_generic_yellow_c_o 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-glow_generic_yellow_c_o {
  0% {
    opacity: 0
  }

  11.666667% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  14.333333% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  18.666667% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  38% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

#star_travel_complet_v3_169x90-u-star_exp_start_ts {
  animation: star_travel_complet_v3_169x90-u-star_exp_start_ts__ts 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-star_exp_start_ts__ts {
  0% {
    transform: translate(82.259562px, 63.430217px) scale(0.054635, 0.054635)
  }

  8% {
    transform: translate(82.259562px, 63.430217px) scale(0.054635, 0.054635);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  15.666667% {
    transform: translate(82.259562px, 63.430217px) scale(1.310576, 1.310578);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  39.666667% {
    transform: translate(82.259562px, 63.430217px) scale(2.073367, 2.073368)
  }

  100% {
    transform: translate(82.259562px, 63.430217px) scale(2.073367, 2.073368)
  }
}

#star_travel_complet_v3_169x90-u-star_exp_start {
  animation: star_travel_complet_v3_169x90-u-star_exp_start_c_o 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-star_exp_start_c_o {
  0% {
    opacity: 1
  }

  8% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  15.666667% {
    opacity: 1;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  33% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

#star_travel_complet_v3_169x90-u-shockwave_yellow_ts {
  animation: star_travel_complet_v3_169x90-u-shockwave_yellow_ts__ts 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-shockwave_yellow_ts__ts {
  0% {
    transform: translate(193.503151px, 17.30218px) scale(0.012381, 0.012381)
  }

  51.666667% {
    transform: translate(193.503151px, 17.30218px) scale(0.012381, 0.012381);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  54.666667% {
    transform: translate(193.503151px, 17.30218px) scale(0.100637, 0.100646);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  64% {
    transform: translate(193.503151px, 17.30218px) scale(0.259004, 0.259038)
  }

  100% {
    transform: translate(193.503151px, 17.30218px) scale(0.259004, 0.259038)
  }
}

#star_travel_complet_v3_169x90-u-shockwave_yellow {
  animation: star_travel_complet_v3_169x90-u-shockwave_yellow_c_o 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-shockwave_yellow_c_o {
  0% {
    opacity: 0
  }

  51.666667% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  54.666667% {
    opacity: 0.63;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  64% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

#star_travel_complet_v3_169x90-u-small_ts {
  animation: star_travel_complet_v3_169x90-u-small_ts__ts 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-small_ts__ts {
  0% {
    transform: translate(193.503151px, 17.30218px) scale(0.015504, 0.015504)
  }

  51.666667% {
    transform: translate(193.503151px, 17.30218px) scale(0.015504, 0.015504);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  54.666667% {
    transform: translate(193.503151px, 17.30218px) scale(0.167224, 0.167222);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  67.666667% {
    transform: translate(193.503151px, 17.30218px) scale(0.324364, 0.324362)
  }

  100% {
    transform: translate(193.503151px, 17.30218px) scale(0.324364, 0.324362)
  }
}

#star_travel_complet_v3_169x90-u-small {
  animation: star_travel_complet_v3_169x90-u-small_c_o 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-small_c_o {
  0% {
    opacity: 0
  }

  51.666667% {
    opacity: 0;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  54.666667% {
    opacity: 0.63;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  67.666667% {
    opacity: 0
  }

  100% {
    opacity: 0
  }
}

#star_travel_complet_v3_169x90-u-star_to {
  animation: star_travel_complet_v3_169x90-u-star_to__to 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-star_to__to {
  0% {
    offset-distance: 0%;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  8% {
    offset-distance: 0%;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  33.333333% {
    offset-distance: 0.3644%;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  53% {
    offset-distance: 100%
  }

  100% {
    offset-distance: 100%
  }
}

#star_travel_complet_v3_169x90-u-star_tr {
  animation: star_travel_complet_v3_169x90-u-star_tr__tr 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-star_tr__tr {
  0% {
    transform: rotate(0deg)
  }

  8% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.8, 0.12, 1, 1)
  }

  53% {
    transform: rotate(-1080.000001deg)
  }

  100% {
    transform: rotate(-1080.000001deg)
  }
}

#star_travel_complet_v3_169x90-u-star_ts {
  animation: star_travel_complet_v3_169x90-u-star_ts__ts 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-star_ts__ts {
  0% {
    transform: scale(0.123881, 0.124242);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  8% {
    transform: scale(0.123881, 0.124242);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  15.666667% {
    transform: scale(0.176426, 0.178583);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  33.333333% {
    transform: scale(0.123881, 0.124242);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  44% {
    transform: scale(0.221594, 0.222245);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  53% {
    transform: scale(0.060875, 0.061052);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  58% {
    transform: scale(0.160751, 0.16122);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  63.333333% {
    transform: scale(0.123881, 0.124242)
  }

  100% {
    transform: scale(0.123881, 0.124242)
  }
}

#star_travel_complet_v3_169x90-u-star2 {
  animation: star_travel_complet_v3_169x90-u-star2__fl 3000ms linear 1 normal forwards
}

@keyframes star_travel_complet_v3_169x90-u-star2__fl {
  0% {
    filter: blur(0px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  23.333333% {
    filter: blur(0px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  30% {
    filter: blur(3.162278px);
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1)
  }

  36.666667% {
    filter: blur(0px)
  }

  100% {
    filter: blur(0px)
  }
}