.raffle-main-view {
  font-family: "Open Sans", OpenSans;
  box-sizing: border-box;

  form {
    display: contents;
  }

  & * {
    box-sizing: border-box;
  }

  .button {
    padding: 0 2rem;

    button {
      width: 100%;

      .MuiButton-label {
        position: relative;

        .loader {
          width: 1rem;
          height: 1rem;
          position: absolute;
          top: 2px;
          right: 1.5rem;

          >div {
            width: 1rem !important;
            height: 1rem !important;
            color: #fff;

            svg {
              top: 0;
              left: 0;
              max-height: 3rem;
              max-width: 3rem;
            }
          }
        }
      }
    }


    button.MuiButton-containedPrimary {
      background: linear-gradient(to bottom, #FF971D, #FFB400);
      border: 1px solid #FFD02D;
      box-shadow: none;
    }

    &.check-phone {
      button.MuiButton-containedPrimary {
        background: #3f51b5;
        border: 0;
      }
    }
  }

  .sms-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    &>div {
      width: auto !important;
    }

    input {
      margin-right: 14px;
      font-size: 25px;
      color: #000;
      font-weight: 600;
      text-align: center;
      border-radius: 3px;
      border: 1px solid #717171;
    }

    input:focus {
      outline: none;
      border: 3px solid #53A5FF;
    }

    input:last-child {
      margin-right: 0;
    }
  }

  .button-link {
    text-align: center;
    padding: 20px;
    font-size: 12px;
    font-weight: bold;
    white-space: normal;
    color: #6B7892;
    text-decoration: underline;
    cursor: pointer;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: calc(16px + var(--notch-top-offset, 0px));
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 53px;

    background: #122499 !important;

    .title {
      flex: 1 1 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      font-weight: normal;
      color: #fff;
      white-space: normal;
      padding-left: 30px;
    }

    .close-button {
      &>button {
        width: 30px;
        height: 30px;
      }

      svg {
        width: 10px;
        height: auto;
        fill: #fff;
      }
    }


    &.step-one {
      padding-bottom: 16px;
      margin-bottom: 10px;
    }
  }

  .logo-wrapper {
    width: 100%;
    text-align: center;
    top: -45px;
    max-width: 345px;
    margin: 0 auto -45px;
    position: relative;
    min-height: 160px;
    border: 4px solid #fff;
    border-radius: 10px;

    img {
      width: 100%;
      border-radius: 10px;
    }

    .text {
      position: absolute;
      top: 10px;
      left: 8px;
      background: rgba(0, 0, 0, .7);
      border-radius: 5px;
      padding: 2px 10px 3px;
      font-size: 11px;
      font-weight: bold;
      color: #fff;
      text-transform: uppercase;
    }

    .footer {
      position: absolute;
      background: rgba(255, 255, 255, .56);
      bottom: 10px;
      padding: 0 15px;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: right;
      height: 45px;

      .count-down {
        display: flex;
        align-items: center;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);


        .txt {
          height: 28px;
          background: #2E3D4B;
          border-radius: 3px 0 0 3px;
          font-size: 13px;
          color: #fff;
          font-weight: normal;
          white-space: nowrap;
          padding: 0 7px 0 10px;
          display: flex;
          align-items: center;
        }

        .count {
          height: 28px;
          background: #FFC400;
          border-radius: 0 3px 3px 0;
          font-size: 16px;
          color: #000;
          font-weight: bold;
          white-space: nowrap;
          padding: 0 10px 0 7px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  .error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
    margin: 0 20px 15px;
    padding: 10px;
    border-radius: 3px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    white-space: normal;
  }

  .fields {
    margin: 10px 0 20px;

    .raffle-field {
      position: relative;

      .MuiFormControl-root,
      label.MuiFormControlLabel-root {
        width: calc(100% - 3rem);
      }

      .MuiInput-underline:before {
        border-bottom: 1px solid #E7E9EB;
      }

      svg {
        max-width: 1rem;
        max-height: 1rem;
        background: transparent;
        position: absolute;
        top: 22px;
        left: 1.5rem;
        z-index: 1;
        fill: rgba(0, 0, 0, 0.54);
      }

      label {
        left: 5px;
      }

      .MuiInputLabel-shrink {
        transform: translate(0, 7px) scale(0.75);
        transform-origin: top left;
      }

      input {
        text-indent: 5px;
        font-size: 16px;

        &:-internal-autofill-selected {
          background-color: white !important;
        }
      }

      &.hide-select-icon {
        .MuiSelect-icon {
          opacity: 0;
        }
      }

      &.ticket-serial {
        position: relative;

        .MuiFormLabel-root {
          font-size: 1.2rem;
        }

        .MuiInputLabel-shrink {
          transform: translate(0, 7px) scale(0.65);
          transform-origin: top left;
        }

        input {
          font-size: 20px;
          padding-right: 50px;
        }

        .scan-image {
          position: absolute;
          width: 45px;
          right: 25px;
          top: 5px;
          cursor: pointer;

          img {
            width: 45px;
            height: auto;
          }
        }
      }

      &.passwordField {
        svg {
          &.IconSVG {
            left: 0;
          }

          &.VisibilityIcon {
            max-width: 2rem;
            max-height: 2rem;
            margin-top: -.5rem;
            left: 0;
            top: 0.625rem;
          }
        }
      }
    }
  }

  .warning-text {
    margin: 10px;
    background: #FFF6DF;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .icon {
      padding: 7px 8px;
    }

    .txt {
      flex: 1 1 100%;
      font-size: 11px;
      color: #8C5400;
      text-align: left;
      font-weight: normal;
      white-space: normal;
    }

  }

  .big-header {
    font-size: 22px;
    color: #151D26;
    text-align: center;
    font-weight: 600;
    white-space: normal;
    margin: 40px 0 20px;
  }

  .small-header {
    font-size: 14px;
    color: #151D26;
    text-align: center;
    font-weight: normal;
    white-space: normal;
    margin: 0 0 10px;
  }

  .sms-footer {
    width: calc(100% - 3.5rem);
    margin: 10px auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .phone-number {
      font-size: 14px;
      color: #020202;
      text-align: center;
      font-weight: normal;
      white-space: normal;
    }

    .change-phone-number {
      font-size: 14px;
      color: #FEB200;
      text-align: center;
      font-weight: bold;
      text-decoration: underline;
      white-space: normal;
      cursor: pointer;
    }
  }

  .tickets-enter-phone-text {
    color: #151D26;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    white-space: normal;
    padding: 25px 0 60px;
  }

  .tickets {
    padding: 0 20px;

    .tickets-header {
      color: #151D26;
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      white-space: normal;
      padding: 47px 0 0;
    }

    .tickets-total {
      color: #151D26;
      font-size: 59px;
      font-weight: 600;
      text-align: center;
      white-space: normal;
      padding: 11px 0 0;
    }

    .tickets-list {
      color: #151D26;
      font-size: 20px;
      font-weight: 300;
      text-align: left;
      white-space: normal;
      padding: 32px 0 8px;
    }

    .ticket {
      margin-bottom: 0 10px 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .date-time {
        color: #151D26;
        font-size: 12px;
        font-weight: bold;
        text-align: left;
        white-space: normal;
      }

      .serial {
        color: #151D26;
        font-size: 12px;
        font-weight: normal;
        text-align: right;
        white-space: normal;
      }
    }
  }

  .left-side {
    display: none;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      width: 305px;
      margin: 0 auto;

      img {
        display: block;
        width: 305px;
        height: auto;
      }
    }

    .title {
      color: #fff;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      white-space: normal;
      margin-top: 24px;
    }

    .text {
      max-width: 720px;
      width: 100%;
      margin-top: 24px;
      color: #fff;
      font-size: 20px;
      font-weight: 300;
      text-align: left;
      white-space: normal;
    }
  }

  @media screen and (min-width: 1024px) {

    .content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      min-height: calc(100vh - 62px);

      &>div {
        flex: 1 1 50%;
      }

      .left-side {
        display: flex;
      }

      .right-side {
        min-width: 845px;
        max-width: 845px;
        padding: 35px 110px 45px 110px;

        .side-wrapper {
          min-height: calc(100vh - 154px);

          background: #fff;
          padding: 15px;
          border-radius: 21px;
        }
      }
    }

    .header {
      padding-bottom: 16px;
    }

    .logo-wrapper {
      top: 0;
      margin-bottom: 0;
      max-width: 100%;

      .text {
        top: 13px;
        left: 12px;
      }

      .footer {
        bottom: 15px;
      }
    }
  }
}

.raffle-real-html {
  #headerSticky {
    display: none !important;
  }

  .App .animatedPageContent.lighten {
    background-color: #fff;

    @media screen and (min-width: 1024px) {
      background: linear-gradient(to bottom, #213348, #142232);
    }
  }

  @media screen and (min-width: 1024px) {
    .App-main {
      background: linear-gradient(to bottom, #213348, #142232);
    }
  }
}