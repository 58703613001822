$sticky-top: 150px;
$sticky-height: 130px;

.winHistoryStickyWrapper {
    position: sticky;
    top: $sticky-top;
    width: 100%;
    height: 0;
    overflow: visible;
    will-change: transform;
    transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    transform: translate3d(0, -260px, 0);
    z-index: 3;
    opacity: 0;

    .winHistorySticky {
        width: 100%;
        background-color: #FFF;
        background-position: left top;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        display: flex;

        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, .4);

        height: $sticky-height;
        overflow: visible;

        .wrapperAll {
            display: flex;
            flex-direction: column;
            align-items: center;
            flex: 0 1 300px;
            margin: 0 15px;
            height: $sticky-height;
            overflow: visible;

            .wrapper {
                background-position: 50% 50%;
                background-size: 100% 100%;
                background-repeat: no-repeat;
                width: 100%;
                height: 135px;
                text-align: center;
                padding: .5em 0;
                z-index: 1;

                .title {
                    color: #FF9F03;
                    margin: .5em .1em 0 .1em;
                    font-weight: 300;
                    font-size: 20px;
                    height: 37px;
                    background-repeat: no-repeat;
                }

                .counter {
                    color: #fff;
                    padding-top: 0;
                    font-weight: 600;
                    font-size: 35px;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .currency {
                        margin-left: 10px;
                    }
                }
            }

            .wrapperText {
                margin-top: -10px;
                width: 92%;
                padding: 20px 16px 13px;
                border-bottom-left-radius: 7px;
                border-bottom-right-radius: 7px;
                background-color: #39006C;
                will-change: transform;
                transform: translate3d(0, -100%, 0);
                transition: transform 300ms ease-in-out;
            }

            .muted-text {
                color: #6E767E;
                font-size: 17px;
                font-weight: 600;
            }

            .text-right {
                text-align: right;
            }

            .text-left {
                text-align: left;
            }

            .amount {
                color: #FFA811;
                font-size: 25px;
                font-weight: bold;
            }

            .cap {
                text-transform: capitalize;
            }

            .date {
                color: #FFF;
                font-size: 17px;
                padding: 2px 0;
            }

            .delimiter {
                height: 2px;
                margin: 13px 0 15px;
                background-color: #C4B3D3;
            }
        }

        .wrapperAll:first-child {
            margin-left: 0;
        }

        .wrapperAll:last-child {
            margin-right: 0;
        }

        .wrapperAll:hover .wrapperText {
            transform: translate3d(0, 0, 0);
        }
    }

}

.winHistoryStickyWrapper.show {
    transform: translate3d(0, -20px, 0);
    opacity: 1;
    position: sticky;
}