.PopupWebsiteSwitchDialog {
  z-index: 1304 !important;

  font-family: 'Open Sans', OpenSans;

  * {
    box-sizing: border-box;
  }

  & .MuiPaper-rounded {
    border-radius: 16px;
    background-color: #fff;
    overflow: visible;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 20px);
    max-width: 360px;
    margin: 10px;
  }

  .dialogContent {
    padding: 0px !important;
    overflow: visible;

    .dialogBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      position: relative;
      padding: 48px 24px 32px 24px;

      .button {
        user-select: none;
        cursor: pointer;
        height: 48px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 600;
        flex: 1 1 100%;
      }

      .flex-full {
        flex: 1 1 100%;
      }

      .button:first-child {
        margin-right: 6px;
      }

      .button:last-child {
        margin-left: 6px;
      }

      .pbd-header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .main-title {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          h2 {
            color: #000;
            text-align: center;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: 109%; /* 34.88px */
            text-transform: uppercase;
          }
          .sub-title {
            display: flex;
            padding: 9px 0px 6px 0px;
            justify-content: center;
            align-items: center;
            gap: 12px;
            width: 100%;

            h1 {
              color: #000;
              text-align: center;
              font-size: 32px;
              font-style: normal;
              font-weight: 600;
              line-height: 109%; /* 43.6px */
              text-transform: uppercase;

              .pink {
                padding: 0 10px;
                color: #fff;
                background: #ed003f;
                font-weight: 700;
              }
              .strong {
                font-weight: 700;
              }
            }
          }
        }

        .close {
          position: absolute;
          top: 16px;
          right: 16px;
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #64717D;
          border-radius: 50%;
          opacity: 1;
        }
      }

      .pbd-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 16px;
        border: 2px solid rgba(10, 52, 221, 0.1);
        background: rgba(218, 225, 255, 0.25);

        &.darkmode {
          border-radius: 16px;
          border: 2px solid #3249aa;
          background: linear-gradient(166deg, #193463 0%, #161c26 90.29%), #fff;

          .step {
            .title {
              color: #fff;
            }
          }

          .slick-dots {
            li {
              button {
                &::before {
                  color: #454545;
                }
              }
            }
          }
        }

        .slick-dots {
          display: flex !important;
          align-items: center;
          justify-content: center;
          list-style: none;
          margin: 0;
          padding: 0;
          width: 100%;
          margin: 10px 0px 16px 0px;

          li {
            cursor: pointer;
            height: 20px;
            width: 20px;
            display: inline-block;
            margin: 0;
            padding: 0;
            position: relative;

            button {
              border: 0;
              display: block;
              font-size: 0;
              line-height: 0;
              padding: 5px;
              cursor: pointer;
              height: 20px;
              width: 20px;
              background: transparent;
              color: transparent;
              outline: 0;
              text-indent: inherit;

              &::before {
                color: #cbcbcb;
                content: '•';
                font-size: 24px;
                height: 20px;
                left: 0;
                line-height: 20px;
                opacity: 1;
                position: absolute;
                text-align: center;
                top: 0;
                width: 20px;
                transition: all 0.3s ease;
              }
            }

            &.slick-active {
              button {
                &::before {
                  color: #0a34dd;
                  opacity: 1;
                }
              }
            }
          }
        }

        .slick-slide {
          outline: none !important;
        }

        .step {
          width: 350px;
          display: flex !important;
          flex-direction: column !important;
          align-items: center !important;
          outline: none !important;

          img {
            display: block;
            width: 100%;
            margin-bottom: 16px;
          }

          .title {
            color: #000;
            text-align: center;
            font-size: 19px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            max-width: 280px;
          }

          &.step-1 {
            display: none;
          }

          &.step-2 {
            display: none;
          }

          &.step-3 {
            display: none;
          }

          &.step-4 {
            display: none;
          }
        }

        &.step-1 .step-1 {
          display: flex;
        }

        &.step-2 .step-2 {
          display: flex;
        }

        &.step-3 .step-3 {
          display: flex;
        }

        &.step-4 .step-4 {
          display: flex;
        }

        .bullets {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 9px;
          margin-bottom: 24px;

          .bullet {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: #cbcbcb;

            &.selected {
              background-color: #0a34dd;
            }
          }
        }
      }

      .pbd-footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;

        .button.cta {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background: #0a34dd;
          padding: 12px 16px;
          gap: 4px;
          color: #fff;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
          text-transform: uppercase;

          .disabled {
            pointer-events: none;
            background-color: #e8e8eb !important;
            color: #a2aab1 !important;
          }
        }
      }
    }
  }
}
