@import "./../../../variables";

.gray {
  background-color: #fbfbfb;
}

.csSideMenu {
  ul {
    min-height: 600px;

    li {
      .ptext {
        text-transform: none !important;
      }
    }
  }
}

