.top-offer-header {
  margin: 0 0 10px 0;
  padding-top: 40px;
  width: 100%;

  >.button {
    display: inline-block;
    width: 100%;
    padding: 0 10px;
    margin-top: 2px;
    background-color: var(--ns-top-offer-header-button-bg);
    line-height: 36px;
    cursor: pointer;
    text-align: center;
    font-size: 0.875em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  >.button.tab-count-2,
  >.button.tab-count-5 {
    width: 50%
  }

  >.button.tab-count-3,
  >.button.tab-count-5:nth-child(n+3) {
    width: 33.3%
  }

  >.button.tab-count-4 {
    width: 25%
  }

  >.button:hover {
    color: var(--ns-top-offer-header-hover-color) !important;
    background-color: var(--ns-top-offer-header-hover-bg) !important;
  }

  >.button.active {
    color: var(--ns-top-offer-header-active-color) !important;
    background-color: var(--ns-top-offer-header-active-bg) !important;
  }
}

@media (min-width: 768px) {
  .top-offer-header {
    padding-top: 0;
    padding-right: 100px;

    >.button {
      width: 150px !important;
    }

    >.button:nth-child(2) {
      margin-left: 10px;
    }

    >.button:nth-child(n+3) {
      width: 55px !important;
      margin-left: 2px;
    }
  }
}

.top-offer-view-select {
  position: absolute;
  top: 7px;
  right: 7px;

  >.button {
    display: inline-block;
    width: 40px;
    padding: 0 5px;
    margin-top: 2px;
    background-color: var(--ns-top-offer-header-button-bg);
    line-height: 36px;
    cursor: pointer;
    text-align: center;
    font-size: 0.875em;
    overflow: hidden;
    white-space: nowrap;

    svg {
      position: relative;
      top: 7px
    }
  }

  >.button.active {
    color: var(--ns-top-offer-header-active-color) !important;
    background-color: var(--ns-top-offer-header-active-bg) !important;
  }

  >.button:nth-child(2) {
    margin-left: 5px;
  }
}

.tabs-sport {
  margin-bottom: 10px;
  background: var(--ns-tab-sports-bg);
  min-height: 30px;

  >.tab {
    float: left;
    height: 36px;
    padding: 5px 10px;
    font-size: 1.4em;
    text-align: center;
    border-bottom: 1px solid var(--ns-content-element-border);
    border-right: 1px solid var(--ns-content-element-border);
    background-color: var(--ns-top-offer-header-button-bg);
    cursor: pointer;

    >.icon {
      width: 100%;
      display: block;
      text-align: center;

      svg {
        width: 24px;
        height: 24px;
        fill: var(--ns-content-color);
        transition: fill 0.25s linear;
      }
    }

    >span {
      display: none;
    }
  }

  >.tab:hover {
    color: var(--ns-top-offer-header-hover-color) !important;
    background-color: var(--ns-top-offer-header-hover-bg) !important;

    >.icon {
      svg {
        fill: var(--ns-top-offer-header-hover-color) !important;
      }
    }
  }

  >.tab.active {
    color: var(--ns-top-offer-header-active-color) !important;
    background-color: var(--ns-top-offer-header-active-bg) !important;

    >.icon {
      svg {
        fill: var(--ns-top-offer-header-active-color) !important;
      }
    }
  }

  @media (min-width: 768px) {
    >.tab {
      width: 20%;
      height: 30px;
      min-width: 125px;
      padding: 0.55em 10px;
      font-size: .8125em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      >span {
        display: inline;
      }

      >.icon {
        width: auto;
        display: inline-block;
        margin-right: 5px;
        position: relative;
        top: 2px;

        svg {
          width: 13px;
          height: 13px;
        }
      }
    }
  }

  @media (min-width: 992px) {
    >.tab {
      width: 50%;
    }
  }

  @media (min-width: 1366px) {
    .tab {
      width: auto;
    }
  }
}