.WalletPreviewScreen{
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    z-index: 1022;
    align-items: stretch;
    justify-content: stretch;
    flex-grow: 1;
    flex-direction: column;
    flex-basis: 100%;
    min-height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.8);
    transition: all .3s ease-in-out;
    font-family: "Segoe UI", sans-serif;
    
    .Loading {
        padding: 2rem 1rem;
        color: #ffb400;
        width: 20px !important;
        height: 20px !important;
    }
    
    .WP-Container{
        position: absolute;
        display: flex;
        align-items: center;
        align-self: flex-end;
        justify-content: flex-start;
        flex-direction: column;
        opacity: 0;
        right: 0;
        width: 100%;
        height: 100vh;
        
        &.OpenPopup{
            animation: SlideDown .3s ease-in-out;
            animation-fill-mode: forwards;
        }
        &.ClosedPopup{
            animation: SlideUp .3s ease-in-out;
            animation-fill-mode: forwards;
        }

        .WP-Header{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            background: #14259b;
            border-bottom: 1px solid #bd1823;
            padding: 20px;
            box-sizing: border-box;

            .Title{
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size: 18px;
                font-weight: 600;
                color: #ffffff;
            }
            
            .CloseButton{
                display: flex;
                width: 10px;
                cursor: pointer;

                .CloseIcon{
                    fill: #ffffff;
                }
            }
        }

        .WP-Primary{
            display: flex;
            align-items: flex-start;
            justify-items: center;
            flex-direction: column;
            box-sizing: border-box;
            background: #151d26;
            width: 100%;
            padding: 5px 15px 30px 15px;
            box-sizing: border-box;

            .AmountContainer{
                display: flex;
                align-items: flex-start;
                justify-items: center;
                box-sizing: border-box;
                width: 100%;
                margin-top: 5px;

                .WalletBox{
                    display: flex;
                    flex-direction: column;
                    margin-left: 15px;
                    height: 100%;
                    justify-content: space-between;
                    min-height: 50px;

                    &:first-of-type{
                        margin-left: 0px;
                    }

                    &.TotalWallet {
                        align-self: flex-start;
                        flex: 1;
                        .BoxTitle{
                            font-size: 14px;
                            text-align: left;
                            font-weight: 600;
                            text-transform: uppercase;
                        }
                        .BoxAmount{
                            display: flex;
                            text-align: left;
                            align-content: center;
                            justify-content: flex-start;
                            cursor: pointer;
                            
                            .WalletIcon{
                                width: 25px;
                                height: 20px;
                                fill: #ffb70b;
                                margin-right: 7px;
                                margin-bottom: -2px;
                            }
                            
                            span{
                                display: flex;
                                align-self: flex-end;
                                color: #ffffff;
                                font-size: 16px;
                                font-weight: 600;
                                line-height: 1;
                                small{
                                    display: flex;
                                    align-self: flex-end;
                                    font-weight: 600;
                                    font-size: 12px;
                                }
                                &.Currency{
                                    font-size: 12px;
                                    margin-left: 3px;
                                }
                            }
                        }
                    }
                    &.AvailableWallet,
                    &.ReservedWallet,
                    &.BonusWallet{
                        justify-self: flex-end;
                    }
                    
                    .BoxTitle{
                        display: flex;
                        color: #d1d2d4;
                        font-weight: 500;
                        font-size: 11px;
                        text-align: right;
                        margin-bottom: 10px;
                    }
                    .BoxAmount{
                        text-align: right;
                        display: flex;
                        justify-content: flex-end;
                        line-height: 1;
                        span{
                            color: #d1d2d4;
                            font-weight: 500;
                            font-size: 11px;
                            line-height: 1;
                            small{
                                font-weight: 500;
                                font-size: 8px;
                                line-height: 1;
                            }
                        }
                    }
                }
            }

            .DepositButton{
                display: flex;
                align-self: flex-end;
                width: auto;
                position: relative;
                border: none;
                text-transform: uppercase;
                width: fit-content;
                cursor: pointer;
                line-height: 1;
                margin-top: 15px;
                font-size: 16px;
                font-weight: 600;
                padding: 14px 34px;
                border-radius: 6px;
                background: rgba(223,83,26,1);
                background: linear-gradient(to right,  rgba(223,83,26,1) 0%,rgba(151,15,183,1) 100%);
                color: rgb(255, 255, 255);
            }
        }

        .WP-Secondary{
            position: relative;
            display: flex;
            align-items: center;
            justify-items: center;
            flex-direction: column;
            padding-bottom: 50px;
            box-sizing: border-box;
            background: #cccccc;
            width: 100%;

            .ProductsWrapper{
                display: flex;
                align-items: flex-start;
                justify-items: center;
                flex-direction: column;
                background: #eef0f1;
                width: 100%;

                .ProductsSection{
                    display: flex;
                    align-items: flex-start;
                    justify-items: center;
                    flex-direction: column;
                    padding-top: 10px;
                    box-sizing: border-box;
                    width: 100%;

                    .SectionTitle{
                        display: flex;
                        color: #17214d;
                        font-size: 12px;
                        font-weight: 500;
                        
                        &::before{
                            content: '';
                            width: 3px;
                            margin-right: 5px;
                            background: #17214d;
                        }
                    }

                    .ProductsContainer{
                        display: flex;
                        flex-wrap: wrap;
                        align-items: flex-start;
                        justify-items: center;
                        box-sizing: border-box;
                        padding: 10px;
                        width: 100%;
                        
                    }
                }
            }

            .ExpandLessButton {
                position: absolute;
                display: flex;
                align-items: center;
                justify-content: center;
                bottom: -16px;
                width: 130px;
                border-radius: 5px;
                box-sizing: border-box;
                background: rgb(6,97,168);
                background: linear-gradient(50deg, rgba(6,97,168,1) 0%, rgba(85,128,214,1) 100%);
                cursor: pointer;
                svg{
                    width: 36px;
                    height: 36px;
                }
            }
        }
    }
}

@media (min-width: 415px) {
    .WalletPreviewScreen{
        .WP-Container{
            max-width: 415px;
            .WP-Secondary{
                border-radius: 0px 0px 0px 7px;
            }
        }
    }
}

@keyframes SlideDown {
    from {opacity: 0; top: -100vh;}
    to {opacity: 1; top: 0;}
}
@keyframes SlideUp {
    from {opacity: 1; top: 0;}
    to {opacity: 0; top: -100vh;}
}