$light-border: #eef0f1;

.n-page.prematch {
  .w100 {
    width: 100%;
  }

  .sidebar {
    background: var(--ns-body-prematch-bg);
    color: var(--ns-body-prematch-color);
    border: 0;
  }

  .betslip-full {
    background: var(--ns-body-prematch-bg);

    .bets-wrapper .items-empty {
      background-color: #fff;
      color: #333;
    }

    .sold-card {
      box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.15);
      margin-top: 5px;
      background: #fff;
      color: #000;
    }

    .top-menu {
      background-color: #fff;
      margin-bottom: 2px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }

    //color: var(--ns-body-prematch-color);
    .top-menu .tabs .tab {
      color: #333;
    }

    .top-menu .tabs .Mui-selected {
      color: #000;
    }
  }

  .left-nav {
    .navigation-item {
      background: #242628;
    }

    .navigation-item.category {
      background: #181a1b;
    }

    .navigation-item.tournament {
      background: #242628;
    }

    .navigation-group {
      background: #0b0b0d;
      margin: 12px 0 0;
      padding: 10px 8px;
      height: 40px;
      line-height: 20px;
      border-left-width: 5px;
    }
  }

  @media (min-width: 992px) {
    .left-nav {
      background-color: var(--ns-body-prematch-bg);
    }
  }

  .match-header {
    .time {
      color: #fff;
    }
  }

  .content {
    color: var(--ns-body-color);
    background-color: var(--ns-body-prematch-bg);
  }

  .tournaments {
    border-color: $light-border;
  }

  .tournaments .tournament-header {
    margin-top: 10px;
    height: 40px;
    background-color: #38383d;
    background-image: unset;
    border-color: $light-border;

    >div:not(.bets-wrap):not(.stats-wrap) {
      height: 40px;
      padding-top: 5px;
    }

    .bet-name {
      height: 40px;
      padding-top: 5px;
    }

    .stats-wrap .brd-left {
      height: 40px;
      text-align: right;
      padding-top: 8px;
      padding-right: 8px;
      cursor: pointer;

      svg.favorite-icon {
        color: rgba(137, 137, 137, 0.4);
        width: .8125em;
        position: relative;
        top: -1px;
        margin-right: 5px;
        cursor: pointer;
      }

      svg.favorite-icon.active {
        color: #FDC138
      }

      svg {
        width: 16px;
      }
    }
  }

  .tournaments .date-header {
    background-color: #ffffff;
    color: #a1a0a2;
    background-image: unset;
    border-color: $light-border;

    .outcomes {
      border-color: $light-border;
    }
  }

  .tournaments .match-row {
    background-color: #f9f9fb;
    color: #000000;
    background-image: unset;
    border-color: $light-border;

    .match-time {
      color: #bebec0;
    }

    .match-display-name {
      color: #000000;
    }

    .odd-container {
      border-color: $light-border;

      @media screen and (min-width: 768px) {
        .odd {
          color: #2f2f2f;
          border-color: $light-border;
        }
      }
    }
  }

  .tournaments .tournament-header .pin svg path,
  .tournaments .tournament-header .stats svg path {
    fill: #fff;
  }

  .elements .brd-left {
    border-color: $light-border;
  }

  .tournaments .match-row .match-total-bets {
    border-color: $light-border;
  }

  .tournaments .tournament-header .bet-name {
    border-color: $light-border;
  }

  .bets-list {
    border-color: $light-border;

    .bets-header {
      background-color: #38383d;
      background-image: unset;
      border-color: $light-border;
    }

    .odds-container {
      background-color: #f9f9fb;
      background-image: unset;
      border-color: $light-border;

      .button-wrapper .button {
        box-shadow: none;
        background-color: #fff;
        color: #000;
        border-color: $light-border;
        padding: 4px 8px 4px;
      }

      @media (hover: hover) and (pointer: fine) {
        .button-wrapper .button:hover {
          background-color: #dedede;
        }
      }

      .outcome .value {
        color: #222;
      }

      .button-wrapper.active .button {
        color: #eee;
      }

      .button-wrapper.active .button {
        color: #eee;

        .value {
          color: #fff;
        }
      }

      @media screen and (max-width: 640px) {
        .type {
          padding-right: 0;
          float: none;
          text-align: center;
          display: block;
        }

        .value {
          position: static;
          text-align: center;
          display: block;
          right: 5px;
        }
      }
    }
  }

  .bets-list-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    color: #000;
    font-size: 14px;
    font-weight: 600;

    svg {
      margin-right: 5px;
    }
  }

  .sport-header {
    margin: 0;
  }

  .search {
    padding: 0 7px;
    background: #eeedf2;
    border-bottom: 0;

    .tabs .tabs-wrap .tab:hover {
      color: #000;
    }

    .tabs .tabs-wrap .tab.active {
      color: #ff5f23;
      border-bottom: 3px solid #ff5f23;
    }

    .input {
      margin-top: 7px;

      .textbox {
        background: #fff;
        border: 0;
        border-radius: 2px;
      }

      .button {
        background: #fff;
        border: 0;
        color: #2a46af;
      }
    }

    .controls {
      top: 0;
      line-height: unset;
      padding: 7px 0 0;
    }

    .controls>span {
      padding: 5px 10px;
      border-radius: 2px;
      display: inline-block;

      svg {
        top: 2px;
        left: 0;
      }
    }

    .controls>span.active {
      background-color: #de2635;

      svg {
        fill: #fff !important;
      }
    }

    .controls>span:not(.active):hover>svg {
      fill: #000;
    }
  }

  .bet-prematch {
    background-color: #fff;
    color: #17214d;

    &.unavailable-bg {
      background: #ffebf3;
    }

    &.odd-changed-bg {
      background: #d9edff;
    }

    &.evaluate-border-warning {
      border: 2px solid #F8991D;
    }

    &.evaluate-border-error {
      border: 2px solid #E286A7;
    }

    .evaluate-message-error {
      color: #FF1A6C;
      font-size: 10px;
      font-weight: 600;
      margin-top: 5px;
      margin-left: 0px;
    }

    .evaluate-message-warning {
      color: #F8991D;
      font-size: 10px;
      font-weight: 600;
      margin-top: 5px;
      margin-left: 0px;
    }

    .second-column {
      .item-remove {
        color: #bf0000;
      }
    }

    .fix-button {
      background: #b7cdd8;
      color: #fff;

      &.active {
        background: #0337f9;
      }
    }
  }

  .sold-card .controls .button.del {
    background: #de2635;
    border-color: #de2635;
  }

  .sold-card .ns-rsc-ticket-switch .rsl,
  .sold-card .ns-rsc-default .rsl {
    background: #eeedf2;
  }

  .sold-card .ns-rsc-ticket-switch .rsl .rsb,
  .sold-card .ns-rsc-default .rsl .rsb {
    background: #586682;
  }

  .sold-card .totals .bonuses {
    border-color: #ececec;
  }

  .systems {
    background: #fff;

    .system {
      color: #333;
      background: #d9d9d9;
    }

    .no-combinations {
      color: #68686a;
    }
  }

  #pre_match_grad_1>stop+stop {
    stop-color: #fff !important;
  }

  .content .frame {
    height: calc(100% - 54px);
  }

  .content .frame .content-wrap .elements {
    padding-top: 0;
  }

  .elements .empty {
    margin-top: 10px;
    background: #000;
    color: #fff;
  }

  .verify-ticket.prematch {
    display: none;
  }

  .verify-ticket .input .textbox {
    background-color: #fff;
    border: 1px solid #fff;
    color: #000;

    &::placeholder {
      color: rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (min-width: 850px) {
    .verify-ticket.prematch {
      display: flex;
    }
  }

  @media screen and (min-width: 850px) {
    .verify-ticket.live {
      display: none;
    }
  }

  .back-header {
    background-color: transparent;
    border-bottom: 0;

    .input .textbox {
      background: #fff;
    }
  }

  .banner {
    padding: 0 10px 0 7px;
    margin-bottom: 10px;

    &>div {
      background-color: transparent;
    }
  }


}

.n-page.prematch {
  aside.ScrollUpButton__Container {
    right: 80px !important;
  }

  @media screen and (min-width: 848px) {
    aside.ScrollUpButton__Container {
      right: 10px !important;
    }
  }
}

.n-page.prematch.mobile {
  overflow: unset;
  height: unset;



  .content {
    height: unset;

    .page-header {
      position: sticky;
      top: 57px;
      z-index: 1;
      background-color: #eeedf2;
    }
  }

  .left-nav.prematch {
    top: 57px;
    position: fixed;
  }

  .left-nav.prematch.expanded {
    width: 100%;
    min-width: 214px;
    height: calc(100vh - 95px);
  }

  .match-details {
    height: calc(100vh - 120px);
    position: sticky;
    top: 115px;
    max-width: 610px;
    //min-width: 610px;
    margin-right: 2px;
  }

  @media screen and (min-width: 848px) {
    .left-nav.prematch {
      position: sticky;
      top: 110px;
      height: calc(100vh - 110px);
    }

    .frame .sidebar {
      position: sticky;
      top: 110px;
      height: calc(100vh - 110px);
      overflow: unset;
      min-width: 412px;

      >div {
        overflow: auto;
        height: 100%;

        scrollbar-color: var(--ns-scrollbar-thumb-bg) var(--ns-scrollbar-track-bg);
        scrollbar-width: thin;

        &.prematch {
          top: -56px;
          height: 0;
          z-index: 95;
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: var(--ns-scrollbar-track-bg);
          border-radius: 0px;
        }

        &::-webkit-scrollbar-thumb {
          background: var(--ns-scrollbar-thumb-bg);
          border-radius: 3px;
        }
      }
    }

    .content .frame {
      display: flex;

      .content-wrap {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
      }
    }

    .content .frame .content-wrap {
      min-height: calc(100vh - 110px);
      flex: 1 1 auto;
      overflow: unset;

      .flex {
        display: flex;
      }

      .content-flex-one {
        width: 100%;
        min-width: 0;
      }

      .content-flex-two {
        padding: 0 3px;
      }
    }

    .content .page-header {
      z-index: 100;
    }
  }
}

.device-ios .bet-slip-footer {
  height: 60px;
}

.device-ios .n-page.prematch.mobile .left-nav.prematch.expanded {
  height: calc(100% - 110px);
}