.wheel-keys-status {
  width: 100%;

  .BoxIcon {
    align-items: center;

    img {
      width: 20px;
      height: auto;
    }
  }

  .ProductBox.keys-status {
    flex-basis: 100%;
    max-width: 100%;
    padding-left: 15px;
    margin-bottom: 20px;
  }

  .BoxContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.keys-status {
      align-items: flex-end;
    }

    .wager-txt {
      padding-left: 5px;
      text-align: left;
      white-space: normal;
      font-size: 10px;
      color: #BABBD1;
      font-weight: normal;
    }

    .box-content-table {
      flex: 1 1 100%;
    }

    .text-header {
      color: #17214D;
      font-size: 10px;
      font-weight: normal;
      text-align: left;
    }

    .keys-info {
      cursor: pointer;
      min-width: 30px;
      height: 24px;
      border-left: 1px solid #E6E7F3;
      display: flex;
      align-items: center;
      justify-content: center;

      .keys-info-wrapper {
        width: 16px;
        height: 16px;
        border: 1px solid rgba(20, 37, 155, .6);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #14259B;
        font-size: 12px;
        font-weight: 600;
        border-radius: 50%;
        line-height: 0px;
      }
    }
  }

  .box-content-table {
    width: 100%;

    .tar {
      text-align: right;

      p {
        justify-content: flex-end;
      }
    }

    .first-row {
      vertical-align: bottom;
    }

    .spc {
      color: #F46E05;
      font-size: 10px;
      font-weight: normal;
      white-space: nowrap;
      text-align: left;
      line-height: normal;
    }

    .prg-wrapper {
      display: flex;
      align-items: center;
      height: 3px;
      border-radius: 3px;
      background-color: #E6E7F3;
      margin: 0 10px 0 0;

      .prg {
        height: 3px;
        border-radius: 3px;
        background: linear-gradient(to right, #9900F0, #F46E05);
      }
    }
  }

  .o-none {
    opacity: 0 !important;
  }

  .d-none {
    display: none !important;
  }
}