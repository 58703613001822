@import "./../../../../variables";

.pendingWithdrawals {
  .alert-funds-icon {
    width: 78px;

    .cls-1 {
      fill: $iconGray;
    }
    .cls-2{
      fill: $white;
    }
  }

  .blocked-funds-support {
    .alertIcon {
      padding-bottom: 10px;
    }

    .amount-display {
      font-size: 46px;
      font-weight: bolder;
    }

    .blocked-funds-message {
      p {
        line-height: 26px;
        font-size: 16px;
        color: $grayColor;
      }
    }
  }

  .menu-danger-icon {
    width: 26px;
    //margin-left: 6px;

    .cls-1 {
      fill: $dangerColor;
    }
    .cls-2{
      fill: $white;
    }
  }

  .lists-support {
    padding-top: 80px;

    ul {
      list-style: none;
      padding-right: 2rem;
      padding-left: 2rem;

      li {
        text-align: left;
        border-bottom: 2px solid $iconGray;
        padding-top: 40px;
        padding-bottom: 40px;

        &:first-child {
          padding-top: 0px;
        }

        &:last-child {
          border-bottom: 0px;
        }
      }
    }
  }

  .gray-icon-common {
    .cls-1{fill:#ccc;}
    .cls-2{fill:#fff;}
  }

  .questionmark-icon {
    .cls-1{fill:#fff;}
    .cls-2{fill:#0a3d30;}
  }

  .notAvailable {
    color: $grayColor;
    font-size: 14px;
    font-style: italic;
  }
}