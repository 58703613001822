@import "./../../../../../../variables";

.MapContainer {
  color: $firstColor;

  & > div {
    width: 74% !important;

    & > div {
      width: 100% !important;
    }
  }

  .markerWindow {
    background-color: $white;
    min-height: 20px;
    min-width: 100px;
  }

  .markerWindowEtas {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    p {
      //width: 50%;
      text-align: right;

      &:last-child {
        text-align: left;
        padding-left: 16px;
      }

      .IconSVG {
        height: 16px;
      }
    }
  }

  .gm-style {
    button {
      display: none !important;
    }
  }

  .gm-style-iw {
    margin-left: 12px !important;
  }

  .gmInfoWindow {
    //padding-left: 30px;

    h4 {
      color: $thirdColor;
      width: 100%;
      text-align: center;
      font-size: 22px;
      font-weight: 500;
      padding-top: 20px;
    }

    p {
      color: #666666;
      font-weight: 500;

      &.gmIwAddress {
        font-size: 12px;
        width: 100%;
        text-align: center;
        color: #9c9c9c;
      }

      .IconSVG {
        fill: #666666;
      }
    }

    .withdrawButton {
      width: 100%;
      padding-bottom: 5px;
      padding-top: 6px;

      .btn {
        display: block !important;
        width: 100%;
        font-weight: 600;
        position: relative;
        top: 0px;

        &.btn-success {
          background-color: $thirdColor;
          border-color: $thirdColor;
          height: 42px;

          &:hover {
            background-color: lighten($thirdColor, 4%);
          }

          &:active {
            top: 2px;
          }
        }
      }
    }
  }
}