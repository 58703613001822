.carousel-header {
  text-align: left;
  color: #000;
  font-size: 22px;
  line-height: 22px;
  padding-right: 2rem;
  margin-bottom: 0px;
  padding-bottom: 15px;

  & .icon {
    padding-right: 5px;
    display: block;
    margin-right: 0px;
    float: left;

    & svg {
      width: 20px;
      height: 20px;
    }
  }

  & .text {
    font-weight: bold;
    position: relative;
    top: 2px;
  }
}

.carousel-grid-wrapper {
  word-break: normal;
  position: relative;
  margin: 0 -10px;

  .grid {
    display: grid;
    grid-column: 2 / -2;
    grid-gap: calc(var(--home-grid-gutter) / 2);
    grid-template-columns: 10px;
    grid-template-rows: minmax(100px, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: calc(33% - var(--home-grid-gutter) * 2);

    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 10px;
  }

  .grid.one {
    display: flex;
    align-items: center;
    justify-content: center;

    .grid-item-happy-hour {
      width: 340px;
    }
  }

  .grid-happy-hours {
    grid-auto-columns: 320px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .grid-item-happy-hour {
      scroll-snap-align: center;

      &>div {
        width: 100%;
      }
    }

    &.one {
      grid-auto-columns: 340px;
    }
  }

  .grid:before,
  .grid:after {
    content: '';
    width: 10px;
  }
}