.winter-promo-calendar {
  background: var(--surface-surface, #EDEDED);
  box-sizing: border-box;

  padding-bottom: calc(0px + env(safe-area-inset-bottom));

  *,
  ::after,
  ::before {
    box-sizing: border-box;
    font-family: "OpenSans", "Open Sans";
  }

  svg:not(.MuiSvgIcon-root) {
    fill: none;
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .wpc-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: calc(56px + env(safe-area-inset-top));
    padding: 0 16px;
    padding-top: calc(0px + env(safe-area-inset-top));
    background: var(--header, #0A34DD);
    gap: 8px;

    .icon {
      width: 32px;
      flex: 0 0 32px;
      height: 32px;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .ArrowBackIcon {
        width: 24px;
      }

      .CloseIcon {
        width: 14px;
      }

      .ArrowBackIcon,
      .CloseIcon {
        height: auto;

        .cls-1,
        .cls-2 {
          fill: #fff;
        }

        .st61 {
          fill: #fff;
        }
      }
    }

    .title {
      flex: 1 1 auto;
      color: var(--on-header, #FFF);
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 100%;
    }
  }

  .wpc-header {
    height: 198px;
    position: relative;

    .wpc-header-bg {
      position: absolute;
      inset: 0;

      img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .wpc-header-info {
      position: absolute;
      bottom: 16px;
      left: 16px;
      right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 8px;

      border-radius: 8px;
      background: rgba(6, 15, 47, 0.80);
      backdrop-filter: blur(2px);

      .col-sep {
        opacity: .2;
        background-color: #E0E0E0;
        width: 1px;
        height: 38px;
      }

      .col {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 2px;

        .title {
          color: #FFF;
          font-size: 24px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
          letter-spacing: -0.48px;

          .red {
            color: #ED003F;
          }
        }

        .subtitle {
          color: #DADBE0;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
        }
      }
    }
  }

  &.in-dialog {
    .wpc-header {
      height: 300px;

      .wpc-header-info {
        bottom: 24px;
        left: 24px;
        right: 24px;
        justify-content: space-around;

        .title {
          font-size: 26px;
          line-height: 31px;
          letter-spacing: -0.52px;
        }

        .subtitle {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  .wpc-status {
    height: 24px;

    &>div {
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;

      svg {
        position: relative;
        top: -1px;
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }

    &.yes {
      background: #C3FFDB;
      color: #00AE26;
    }

    &.no {
      background: #FECDD9;
      color: #DD0A0A;
    }
  }

  .wpc-days-wrp {
    width: 100%;
    overflow: hidden;
  }

  .wpc-days {
    padding: 24px 0 16px;
    gap: 16px;

    .day {
      flex: 0 0 82px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 82px;
      height: 82px;
      background-image: url('../assets/today-off.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;

      .day-number {
        color: #848B93;
        text-align: center;
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        padding: 24px 0 0;
      }

      .day-title {
        color: #848B93;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }
    }

    .day.current {
      background-image: none;
      position: relative;
      overflow: visible;

      img {
        position: absolute;
        top: -12px;
        height: auto;
        width: 112.5px;
      }

      div {
        position: relative;
      }

      .day-number {
        color: #142232;
      }

      .day-title {
        color: #1A1C1E;
      }
    }
  }

  .wpc-prizes {
    padding: 0 16px 24px;
    margin-bottom: -8px;
    overflow-x: auto;
    overflow-y: hidden;

    .body-wrp {
      display: flex;
      align-items: stretch;
      justify-content: space-between;
      gap: 16px;
    }

    .wpc-prize {
      width: 100%;

      &.tomorrow {
        .header {
          color: #BBC2D2;
        }
      }

      .body {
        box-shadow: 0px 34px 21.6px -25px rgba(61, 35, 133, 0.56);

        border-radius: 8px;
        position: relative;
        overflow: hidden;

        flex: 0 0 307px;
        width: 307px;
        min-width: 307px;
        max-width: 307px;
        min-height: 300px;
        height: calc(100% - 40px);

        .prize-image {
          position: absolute;
          inset: 0;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .prize-txt {
          position: absolute;
          left: 12px;
          right: 12px;
          bottom: 12px;

          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;

          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
        }

        .text {
          color: #FFF;
        }

        .button {
          cursor: pointer;
          border-radius: 8px;
          background: #FFDE72;
          padding: 0 12px;
          color: #142232;
        }
      }
    }

    .wpc-prize:last-child {
      padding-right: 16px;
    }

    .wpc-prize.awarded {
      .body {
        background-color: #fff;

      }

      .prize-header {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;

        height: 63px;
        padding: 8px;
        border-radius: 8px 8px 0px 0px;
        border: 1px solid #3C2284;
        background: linear-gradient(90deg, #B50E59 0%, #3B2184 86.46%);

        .image {
          position: absolute;
          top: 0px;
          left: 0px;
          right: 0px;
          bottom: 0px;
          width: 100%;
          height: 100%;

          img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }

        .texts {
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          gap: 2px;

          .date {
            width: 100%;
            color: #FFF;
            font-size: 8px;
            font-style: normal;
            font-weight: 600;
            line-height: 10px;
            text-align: right;
          }

          .title {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 19px;
          }

          .subtitle {
            color: #FFF;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
            /* 116.667% */
          }
        }

      }

      .d-none {
        display: none !important;
      }

      .prize-info {
        padding: 16px 8px 16px;
        width: auto;
        /*height: calc(100% - 65px);*/
        text-align: left;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 8px;

        .p-text {
          display: flex;
          align-items: center;
          gap: 2px;

          .i>img {
            width: 16px;
            height: 16px;
            display: block;
          }

          .t {
            color: #25415B;
            font-size: 14px;
            font-style: italic;
            font-weight: 500;
            line-height: 14px;
          }
        }

        .or {
          width: 26px;
          height: 26px;
          border-radius: 50%;
          background-color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          color: #25415B;
          font-size: 10px;
          font-style: italic;
          font-weight: 500;
          line-height: 10px;

          margin: -16px 0 -16px 23px;
          z-index: 1;
        }

        .bonus {
          flex: 1 1 auto;
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          gap: 12px;
          padding: 12px;
          border-radius: 8px;
          background: #EDEFF7;


          .bonus-image {
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
            border-radius: 6px;
            overflow: hidden;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          &>div+div {
            flex: 1 1 auto;
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 2px;
          }

          .bonus-title {
            color: #142232;
            text-align: left;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 100%;
          }

          .bonus-subtitle {
            color: #64717D;
            text-align: left;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 14px;
          }
        }

        .sep {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 8px;
          overflow: visible;

          .sep-line {
            flex: 1 1 50%;
            width: 100%;
            height: 1px;
            background-color: #EDEDED;
          }

          .sep-text {
            text-align: center;
            flex: 0 0 35px;
            width: 35px;
            padding: 0 5px;
            font-size: 25px;
            line-height: 25px;
            font-weight: 400;
            color: #ededed;
          }
        }

        .raffle {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 8px;
          width: 100%;
          padding: 0 12px;

          .raffle-image {
            flex: 0 0 40px;
            width: 40px;
            height: 40px;
            border-radius: 6px;
            overflow: hidden;

            img {
              display: block;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }

          .raffle-title {
            .title {
              color: #142232;
              font-size: 16px;
              font-style: normal;
              font-weight: 700;
              line-height: 16px;

            }

            .subtitle {
              color: #64717D;
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 14px;
            }
          }
        }

        .status-text {
          height: 44px;
          flex: 0 0 44px;
          display: flex;
          align-items: center;
          justify-content: center;

          width: 100%;
          color: #00AE26;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 14px;

          &>* {
            display: inline;
          }

          svg {
            position: relative;
            top: -1px;
            display: inline;
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }
        }

        .status-link {
          width: calc(100% - 64px);
          margin: 8px auto 0;
          cursor: pointer;
          border-radius: 8px;
          background: #0A34DD;
          padding: 0 24px;
          color: #fff;

          font-size: 13px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-transform: uppercase;

          height: 44px;
          flex: 0 0 44px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          text-decoration: none;
        }
      }
    }
  }

  &.in-dialog {
    .body-wrp {
      gap: 24px;
    }

    .wpc-prizes {
      .body {
        flex: 0 0 250px;
        width: 250px;
        min-width: 250px;
        max-width: 250px;
        min-height: 300px;
        height: calc(100% - 40px);

        .prize-header {
          height: 63px;
        }
      }

      .wpc-prize:last-child {
        padding-right: 0px;
      }
    }
  }

  .wpc-prizes-info {
    padding: 0 16px 16px;

    .body {
      position: relative;
      display: flex;
      justify-content: flex-end;

      .image {
        border-radius: 8px;
        position: absolute;
        overflow: hidden;
        width: 100%;
        height: 100%;

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .info {
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        position: relative;
        gap: 8px;

        .cell {
          height: 54px;
          padding: 0 8px 0 16px;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          border-radius: 8px;
          background: linear-gradient(90deg, rgba(181, 14, 89, 0.25) 0%, rgba(59, 33, 132, 0.25) 86.46%);
        }

        .title {
          color: #FFF;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px;
        }

        .subtitle {
          color: #DADBE0;
          text-align: center;
          font-size: 12px;
          font-style: normal;
          font-weight: 500;
          line-height: 12px;
        }
      }
    }
  }

  &.in-dialog {
    .wpc-prizes-info {
      .body {
        .info {
          display: grid;
          gap: 8px;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }

  .wpc-s-header {
    height: 32px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-bottom: 8px;

    color: var(--surface-on-surface, #1A1C1E);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19px;

    .icon {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        display: block;
      }
    }

    &.muted {
      color: #BBC2D2;
    }
  }

  .wpc-rules {
    padding: 0 16px 24px;

    .body {
      border-radius: 8px;
      background: #FFF;
      padding: 16px;

      color: var(--surface-on-surface-02, #64717D);
      font-size: 11px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
      opacity: 0.9;
      text-align: left;

      ol {
        list-style: decimal;
        padding-inline-start: 2em;
      }

      ul {
        list-style: circle;
        padding-inline-start: 2em;
      }

      strong {
        font-weight: 600;
      }

      sup {
        vertical-align: super;
        font-size: smaller;
      }

      sub {
        vertical-align: sub;
        font-size: smaller;
      }

      h1 {
        font-size: 2em;
        font-weight: bold;
      }

      h2 {
        font-size: 1.5em;
        font-weight: bold;
      }

      h3 {
        font-size: 1.17em;
        font-weight: bold;
      }

      h4 {
        font-size: 1em;
        font-weight: bold;
      }

      h5 {
        font-size: 0.83em;
        font-weight: bold;
      }

      h6 {
        font-size: 0.67em;
        font-weight: bold;
      }

      .ql-align-center {
        text-align: center;
      }

      .ql-align-right {
        text-align: right;
      }

      .ql-align-left {
        text-align: left;
      }

      .ql-align-justify {
        text-align: justify;
      }

      .ql-indent-1 {
        text-indent: 1em;
      }

      .ql-indent-2 {
        text-indent: 2em;
      }

      .ql-indent-3 {
        text-indent: 3em;
      }

      .ql-indent-4 {
        text-indent: 4em;
      }

      .ql-indent-5 {
        text-indent: 5em;
      }
    }
  }
}