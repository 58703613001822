.bet {
  display: block;
  position: relative;
  width: 100%;
  float: left;
  padding: 10px;
  margin-bottom: 1px;
  background: var(--ns-bet-bg);
  color: var(--ns-bet-color);
  font-size: 0.8125em;
  text-decoration: none;
  cursor: pointer;
  border-top: 1px solid var(--ns-bet-border-color);
  border-bottom: 1px solid var(--ns-bet-border-color);

  &.unavailable-bg {
    background: var(--ns-bet-unavailable-bg);
  }

  &.odd-changed-bg {
    background: var(--ns-bet-odd-changed-bg);
  }

  &.evaluate-border-warning {
    border: 2px solid #F8991D;
  }

  &.evaluate-border-error {
    border: 2px solid #E286A7;
  }

  .evaluate-message {
    clear: left;
  }

  .evaluate-message-error {
    color: #ff80ac;
    font-size: 10px;
    font-weight: 600;
    padding-top: 5px;
    margin-left: 1px;
    font-weight: bold;
  }

  .evaluate-message-warning {
    color: #fbc57e;
    font-size: 10px;
    font-weight: 600;
    padding-top: 5px;
    margin-left: 1px;
    font-weight: bold;
  }

  .item-content {
    padding-left: 0;

    &.fixable {
      padding-left: 50px;
    }

    .item-title {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
      font-size: 12px;
      font-weight: bold;
    }

    .item-subtitle {
      width: 90%;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--ns-bet-subtitle-color);
    }

    .item-label {
      position: relative;
      float: left;
      overflow: hidden;
      width: 100%;
      height: 30px;
      padding: 0 10px;
      line-height: 30px;
      border-radius: 3px;
      background: var(--ns-bet-label-bg);
      margin-right: 2px;
      color: var(--ns-bet-label-color);
      font-size: 13px;
      font-weight: 600;

      .item-pick {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 270px;
        display: inline-block;
      }

      .item-odd-change {
        position: absolute;
        right: 0.1em;
        font-size: 0.875em;
      }

      .item-odd-change.up {
        top: 0.1em
      }

      .item-odd-change.down {
        bottom: 0
      }

      .item-odd {
        font-size: 15px;
        font-weight: bold;
        float: right;
        clear: both;
        color: var(--ns-bet-odd-color);
        font-weight: 700;

        .old-value {
          padding-right: 10px;
          opacity: .6;
          text-decoration: line-through;
        }
      }

      .item-odd.item-down {
        color: var(--ns-bet-odd-down-color)
      }

      .item-odd.item-up {
        color: var(--ns-bet-odd-up-color)
      }

    }

    .match-result {
      color: var(--ns-table-row-match-result-color);
      font-weight: 700;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-bottom: 5px;

      span {
        padding-right: 5px;
      }

      span:nth-last-child(2) {
        color: var(--ns-table-row-match-result-second-child-color);
      }

      span:last-child {
        color: var(--ns-table-row-match-result-last-child-color);
      }
    }
  }

  .item-button {
    width: 100%;
    height: 22px;
    background-color: var(--ns-bet-button-bg);
    font-size: 12px;
    text-align: center;
    line-height: 22px;
    cursor: pointer;
    border-radius: 2px;

    &:hover {
      background-color: var(--ns-bet-button-hover-bg);
      color: var(--ns-bet-button-hover-color);
    }
  }

  .error {
    float: left;
    width: 100%;
    overflow: hidden;
    margin-top: 5px;
    padding: 5px;
    color: var(--ns-bet-error-color);

    .item-message {
      margin-bottom: 5px;
      text-align: center;
    }

    .item-button {
      background-color: #FF196E;
    }
  }

  .odd-changed {
    float: left;
    width: 100%;
    overflow: hidden;
    padding-top: 5px;
  }

  .item-fix {
    border-radius: 2px;
    position: absolute;
    top: 10px;
    left: 10px;
    width: 35px;
    height: 20px;
    line-height: 18px;
    background-color: var(--ns-bet-fix-bg);
    color: var(--ns-bet-fix-color);
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;

    &.active {
      background-color: var(--ns-bet-fix-active-bg);
      color: var(--ns-bet-fix-active-color);
    }
  }

  .item-remove {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 26px;
    height: 26px;
    font-size: 2em;
    font-weight: 300;
    text-align: right;
    color: var(--ns-bet-remove-color);
    line-height: 28.6px;
    cursor: pointer;
    z-index: 1000;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .item-remove:hover {
    color: var(--ns-bet-remove-hover-color);
  }

  @media screen and (min-width: 992px) {
    .item-remove {
      width: 20px;
      height: 20px;
      font-size: 1.5em;
      line-height: 22px;
    }
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 5px;
  }

  .time {
    white-space: nowrap;
    font-size: 12px;
    font-weight: 600;
    padding-left: 10px;

    .interval-letter {
      padding-right: 5px;
      opacity: 44%;
    }

    .interval-time {
      opacity: 64%;
    }
  }
}

.bet-prematch {
  background: var(--ns-bet-bg);
  color: var(--ns-bet-color);
  padding: 8px;
  margin: 1px 0;

  &.unavailable-bg {
    background: var(--ns-bet-unavailable-bg);
  }

  &.odd-changed-bg {
    background: var(--ns-bet-odd-changed-bg);
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
  }

  .flex-column {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
  }

  .justify-between {
    justify-content: space-between;
  }

  .first-column {
    width: 100%;
    min-width: 0;
  }

  .second-column {
    width: 50px;
    padding-left: 7px;
    cursor: pointer;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .second-row {
    margin-top: 5px;
  }

  .item-remove {
    cursor: pointer;

    svg {
      width: 14px;
      height: 14px;
    }
  }


  .teams {
    font-size: 12px;
    font-weight: bold;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    white-space: nowrap;
  }

  .date {
    font-size: 10px;
    white-space: nowrap;
    padding-left: 10px;
  }

  .odd-text {
    font-size: 12px;
    font-weight: 600;
  }

  .odd-value {
    font-size: 15px;
    font-weight: bold;
    white-space: nowrap;

    .old-odd-value {
      font-weight: regular;
      text-decoration: line-through;
      opacity: .7;
      padding-right: 10px;
    }
  }

  .fix-button {
    border-radius: 2px;
    font-size: 11px;
    font-weight: bold;
    padding: 3px 10px;
    display: inline-block;
    margin-right: 10px;
    background-color: var(--ns-bet-fix-bg);
    cursor: pointer;
    position: relative;
    top: 0px;

    &.active {
      background-color: var(--ns-bet-fix-active-bg);
      color: var(--ns-bet-fix-active-color);
    }
  }

  .item-button {
    width: 100%;
    height: 22px;
    background-color: var(--ns-bet-button-bg);
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    line-height: 22px;
    cursor: pointer;
    border-radius: 2px;

    &:hover {
      background-color: var(--ns-bet-button-hover-bg);
      color: var(--ns-bet-button-hover-color);
    }
  }

  .error {
    width: 100%;
    overflow: hidden;
    margin-top: 5px;
    padding: 5px 0;
    color: var(--ns-bet-error-color);

    .item-message {
      margin-bottom: 5px;
      text-align: center;
      font-size: 12px;
      color: #FF196E;
    }

    .item-button {
      background-color: #FF196E;
    }
  }
}