.winner-fun-team-enroll {
  z-index: 1304 !important;

  & .MuiPaper-rounded {
    border-radius: 13px;
  }

  & .MuiDialog-paperFullWidth {
    width: calc(100% - 20px);
    margin: 10px;
  }

  .dialogTitle {
    padding-top: 32px;
    padding-bottom: 0;

    .dh-logo {
      width: 98px;
      height: 98px;
      display: block;
      transform: translateZ(0);
      margin: 0 auto;
    }

    .dh-text {
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #131727;
      padding: 0;
    }

    .muted {
      padding-top: 10px;
      color: #909092;
      font-size: 13px;
      font-weight: normal;
    }
  }

  .dialogContent {
    font-size: 12px;
    color: #17214D;
    font-weight: bold;
    text-align: center;
    padding-top: 23px;
    padding-bottom: 30px;

    .dialogBody {
      ul {
        list-style: none;
        padding-left: 1em;
      }

      li {
        &:before {
          content: '';
          display: inline-block;

          position: relative;
          top: 1px;
        }

        div {
          color: #006D0E;
          font-size: 13px;
          font-weight: 600;
          text-align: left;
          margin-bottom: 22px;
        }
      }
    }

    .dialogButtons {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }

    .dialogLater {
      padding: 13px 0;
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      background: #17214D;
      height: 45px;
      margin-right: 5px;
      text-transform: uppercase;
      white-space: nowrap;
      flex: 1 1 50%;
    }

    .dialogOK {
      padding: 13px 0;
      border-radius: 10px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      background: linear-gradient(to right, #F8991C 0%, #FF5619 100%);
      height: 45px;
      margin-left: 5px;
      text-transform: uppercase;
      white-space: nowrap;
      flex: 1 1 50%;
    }
  }

  .wf-but-loader {
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 2px;

    &>div {
      display: inline-block;
      width: 16px !important;
      height: 16px !important;
      color: #fff
    }
  }
}