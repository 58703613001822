.PromotionsScreen {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    flex-basis: 100%;
    width: 100%;
    flex-grow: 1;
    box-sizing: border-box;
    background: linear-gradient(to right, #0b1021 0%, #142460 10%, #142460 90%, #0b1021 100%);

    .PromotionsScreenContent {
        display: block;
        padding: 1.2rem;
        max-width: 1220px;
        margin: 0 auto;
        background: #d8e1e9;
        width: 100%;

        .claim-now {
            grid-gap: 0 !important;

            h1 {
                padding-bottom: 0;
            }

            .bonusAvailable {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
            }
        }

        h1 {
            color: #0A122B;
            padding: 0 0 1rem;
            text-transform: initial;
            font-weight: bold;
            text-align: left;

            svg {
                width: 2rem;

                circle {
                    fill: none;
                }
            }
        }


        .special-promos {
            width: 100%;
            padding-bottom: 15px;

            h1 {
                padding: 0;
            }

            .flex-promos {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                &>div {
                    max-width: 385px;
                    min-width: 385px;
                    margin-right: 5px;

                    .wheel-cta-promotions {
                        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, .30);
                    }
                }
            }
        }
    }
}

.MainContent {
    background: transparent;
}

.ContentPage.topPadding {
    padding-top: 0;
}

@media screen and (max-width: 1600px) {
    .PromotionsScreen {
        background: #d8e1e9;
    }
}