$bets-self-win-bg: url("../../assets/bets/self-win-prematch.png");
$bets-self-win-prize: linear-gradient(to right, #E32212 0%, #FF196E 100%);
$bets-self-win-button: linear-gradient(to right, #FF7700 0%, #FFC400 100%);

.bets-prematch-self-win {
  z-index: 1301 !important;

  & .MuiPaper-rounded {
    border-radius: 20px;
    margin: 10px;
  }

  .MuiDialog-paperFullWidth {
    max-width: 340px;
  }

  @media screen and (min-width:1024px) {
    .MuiPaper-rounded {
      border-radius: 25px;
    }
  }

  .dialog-content {
    background: $bets-self-win-bg;
    background-position: top left;
    background-size: 100% auto;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;

    .hh-header {
      margin: -20px -24px 0;
      display: flex;
      align-items: flex-start;

      .hh-left {
        color: #fff;
        font-size: 10px;
        font-weight: 600;
        font-style: italic;
        margin: 0 35px 0 20px;
        padding-top: 1px;
      }

      .hh-right {
        color: #fff;
        font-size: 11px;
        font-weight: 600;
        padding-top: 2px;
      }
    }

    .hh-title {
      color: #020202;
      font-size: 20px;
      font-weight: bold;
      text-align: center;
      margin-top: 40px;
      margin-bottom: 7px;
    }

    .hh-subtitle {
      color: #020202;
      font-size: 13px;
      font-weight: normal;
      text-align: center;
      margin-bottom: 56px;
    }

    .hh-prize-current {
      margin: 0 auto;
      height: 80px;
      min-width: 250px;
      background: $bets-self-win-prize;
      border-radius: 31px;
      margin-bottom: 11px;
      display: flex;
      align-items: center;
      justify-content: center;

      .hh-flx {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 4px;


        .hh-img {
          padding-right: 5px;

          svg {
            width: 36px;
            height: auto;
            position: relative;
            left: -4px;
            vertical-align: middle;
          }

          img {
            width: 36px;
            height: auto;
            position: relative;
            left: -4px;
          }
        }

        .hh-txt {
          color: #FFC100;
          font-size: 35px;
          font-weight: bold;
        }
      }
    }

    .hh-note {
      color: #020202;
      font-size: 13px;
      font-weight: normal;
      text-align: center;
      margin-bottom: 56px;
    }

    .hh-button {
      margin: 0 auto;
      min-width: 260px;
      background: $bets-self-win-button;
      border-radius: 10px;
      margin-bottom: 50px;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      height: 45px;
    }
  }
}