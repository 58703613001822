:root {
  --home-grid-gutter: 20px;
}

@font-face {
  font-family: 'OpenSans';
  src: local('Open Sans')
}

.grid-wrapper {
  word-break: normal;
  margin: 10px 0;
  position: relative;
  font-family: OpenSans, "Open Sans" !important;

  .text-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    color: #000;
    font-size: 18px;
    line-height: 18px;
    padding-right: 2rem;
    padding-left: 20px;
    margin-bottom: 0px;
    padding-bottom: 15px;
    text-align: left;
    text-transform: none;

    .icon {
      padding-right: 10px;
      display: block;
      margin-right: 0px;
      width: initial;
      line-height: normal;
      height: initial;
      position: relative;
      top: -2px;

      svg {
        width: 20px;
        height: 20px;

      }
    }

    .txt {
      font-weight: bold;
      position: relative;
      color: #000;
      min-width: 0;
      flex: 1 1 auto;
    }
  }

  .grid.one {
    display: flex;
    align-items: center;
    justify-content: center;

    .grid-item-happy-hour {
      width: 340px;
    }
  }

  .grid {
    display: grid;
    grid-column: 2 / -2;
    grid-gap: calc(var(--home-grid-gutter) / 2);
    grid-template-columns: 10px;
    grid-template-rows: minmax(100px, 1fr);
    grid-auto-flow: column;
    grid-auto-columns: calc(33% - var(--home-grid-gutter) * 2);

    overflow-x: auto;
    scroll-snap-type: x mandatory;
    padding-bottom: 10px;
  }

  .no-scroll-snap {
    scroll-snap-type: unset;
  }

  .grid-happy-hours {
    grid-auto-columns: 320px;
    margin-bottom: 25px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .grid-item-happy-hour {
      scroll-snap-align: center;
    }
  }

  .grid-products {
    grid-auto-columns: 86px;
    margin-bottom: 25px;
    background: #fff;
    padding: 12px 10px 11px 10px;
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
    -ms-overflow-style: none;
    scrollbar-width: none;
    border-radius: 7px;

    &::-webkit-scrollbar {
      display: none;
    }

    .grid-item {
      cursor: pointer;
    }
  }

  .grid-of-interest {
    grid-auto-columns: 247px;
    grid-template-rows: 1fr 1fr;

    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #C8D1DB;

    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: #FF7700;
      border-radius: 9px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #FF7700;
    }
  }

  .grid-free-bet {
    grid-auto-columns: 294px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .grid-home-collection {
    grid-auto-columns: 100px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .grid-event-promotions {
    grid-auto-columns: 320px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .grid-competition-promotions {
    grid-auto-columns: 320px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .grid-starting-soon {
    grid-auto-columns: 320px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .grid-item-match {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
      border-radius: 7px;
      overflow: hidden;
      background: #fff;

      &>div {
        box-sizing: border-box;
        height: 147px;
      }

      .moreBets {
        padding-bottom: 4px;
      }
    }
  }

  .grid-news {
    grid-auto-columns: 190px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .grid-multi-awards {
    grid-auto-columns: 320px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .grid-item-multi-awards {
      scroll-snap-align: center;
    }
  }

  .grid-player-promotions {
    grid-auto-columns: 320px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .grid-item-player-promotion {
      scroll-snap-align: center;
    }
  }

  .grid-top-live {
    grid-auto-columns: 320px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 3px !important;

    &::-webkit-scrollbar {
      display: none;
    }

    .bet-select-more {
      position: relative;
      top: -5px;
    }

    .grid-item-match {
      border-radius: 7px;

      .teamName {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 180px;
      }

      .myBetElement {
        min-width: 70px;
        max-width: 200px;
      }
    }

    .grid-item-match>div {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5);
      height: 185px;

      .stat-score-timeline {
        padding: 0;
        border-radius: 7px 7px 0 0;
        overflow: hidden;

        .stat-score-timeline-gradient {
          display: none;
        }

        .STATSCOREWidgetContainer>.STATSCOREWidget {
          padding: 0 !important;
        }

        .STATSCOREWidgetContainer .STATSCOREWidget--liveBar.STATSCOREWidget--liveBar--borderRadius {
          border-radius: 4px 4px 0 0;
        }

        .STATSCOREWidgetContainer .STATSCOREWidget--liveBar .STATSCOREWidget--liveBar__incident.STATSCOREWidget--liveBar__incident--result.STATSCOREWidget--liveBar__incident--left .STATSCOREWidget--liveBar__incidentInfo {
          border-radius: 0 3px 0 0;
        }

        .STATSCOREWidgetContainer .STATSCOREWidget--liveBar .STATSCOREWidget--liveBar__incident.STATSCOREWidget--liveBar__incident--result.STATSCOREWidget--liveBar__incident--right .STATSCOREWidget--liveBar__incidentInfo {
          border-radius: 3px 0 0 0;
        }

        .STATSCOREWidgetContainer .STATSCOREWidget--liveBar .STATSCOREWidget--liveBar__incident.STATSCOREWidget--liveBar__incident--other .STATSCOREWidget--liveBar__incidentInfo.STATSCOREWidget--liveBar__incidentInfo--left {
          border-radius: 0 3px 0 0;
        }

        .STATSCOREWidgetContainer .STATSCOREWidget--liveBar .STATSCOREWidget--liveBar__incident.STATSCOREWidget--liveBar__incident--other .STATSCOREWidget--liveBar__incidentInfo.STATSCOREWidget--liveBar__incidentInfo--right {
          border-radius: 3px 0 0 0;
        }

        .STATSCOREWidgetContainer .STATSCOREWidget--liveBar .STATSCOREWidget--liveBar__statusContainer .STATSCOREWidget--liveBar__innerBar {
          border-radius: 0;
        }
      }

      .stats-score-available {
        display: none;
      }

      .event-card-title {
        height: 28px;
        display: flex;
        align-items: center;
      }
    }
  }

  @media (hover: hover) {
    .grid-top-live {
      scroll-snap-type: unset;
    }
  }

  .grid-new-and-recommended,
  .grid-play-history {
    grid-auto-columns: 100px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .MainImage {
      width: 100px;
      height: 100px;
    }

    .grid-game-item {
      scroll-snap-align: center;
      min-height: 100px;

      .MuiPaper-root {
        width: 100px;
        height: 100px;

        .MuiButtonBase-root {
          width: 100px;
          height: 100px;

          .DinamicImageContainer {
            width: 100px;
            height: 100px;

            .MainImage {
              width: 100px;
              height: 100px;
            }
          }
        }
      }

      &.show-more {
        height: 100%;
        max-width: 100%;
        max-height: 139px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgb(22, 33, 78);
        box-shadow: 3px 3px 4px 1px rgba(0, 0, 0, 0.28);
        border-radius: 7px;
        cursor: pointer;

        p {
          width: 40px;
          height: 40px;
          margin: 0;
          font-size: 20px;
          border: 2px solid #FFF;
          border-radius: 100%;
          line-height: 1.5;
          color: #FFF;
          letter-spacing: 1px;
          white-space: nowrap;
          text-align: center;
          padding: 0;
        }
      }
    }
  }

  .grid.full {
    grid-column: 1 / -1;
  }

  .grid .big {
    grid-column: span 2/auto;
    grid-row: span 2/auto;
  }

  .grid .small {
    grid-column: span 1/auto;
    grid-row: span 1/auto;
  }

  .grid:before,
  .grid:after {
    content: '';
    width: 10px;
  }

  .grid-gap {
    width: 10px;
  }

  .grid-item {
    scroll-snap-align: center;
    background: #fff;
    border-radius: 8px;
    min-height: 100px;
  }

  .grid-item-competition {
    scroll-snap-align: center;
    border-radius: 8px;
    min-height: 165px;
    position: relative;
  }

  .grid-item-promotions {
    scroll-snap-align: center;
    min-height: 160px;
    border-radius: 8px;

    &>div {
      border-radius: 8px;
      height: 160px;
    }
  }

  .grid-item-match {
    scroll-snap-align: center;
    border-radius: 8px;

    &>div {
      margin: 0;

      .MuiButtonBase-root.myBetElement {
        min-width: 80px;
      }
    }
  }

  .grid-product {
    scroll-snap-align: center;
    border-radius: 15px;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .grid-item-competition.drop-shadow,
  .grid-item-promotions.drop-shadow,
  .grid-product.drop-shadow,
  .grid-item.drop-shadow {
    box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.3);
  }

  .grid-item-image {
    width: 86px;
    height: 86px;
    border-radius: 17px;
    display: block;
  }

  .grid-item-title {
    font-size: 11px;
    font-weight: bold;
    text-align: center;
  }

  .ofil {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 20px;
    height: 10px;
    background: #EBEEF1;
  }

  .ofir {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20px;
    height: 10px;
    background: #EBEEF1;
  }

  .all-matches {
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
    color: #606060;
  }

  @media screen and (min-width: 768px) {
    .grid-happy-hours {
      grid-auto-columns: calc(50% - 25px);
    }

    .grid-products {
      grid-auto-columns: 112px;

      .grid-item-image {
        width: 112px;
        height: 112px;
      }

      .grid-item-title {
        font-size: 15px;
      }
    }

    .grid-of-interest {
      grid-auto-columns: 290px;
    }

    .grid-free-bet {
      grid-auto-columns: 344px;
    }

    .grid-home-collection {
      grid-auto-columns: 170px;
    }

    .grid-event-promotions {
      grid-auto-columns: 320px;
    }

    .grid-competition-promotions {
      grid-auto-columns: 320px;
    }

    .grid-play-history {
      grid-auto-columns: 150px;
    }

    .grid-starting-soon {
      grid-auto-columns: 320px;
    }

    .grid-new-and-recommended {
      grid-auto-columns: 150px;
    }

    .grid-top-live {
      grid-auto-columns: 320px;
    }

    .grid-multi-awards {
      grid-auto-columns: 344px;
    }

    .grid-player-promotions {
      grid-auto-columns: 344px;
    }

    .grid-multi-awards.two,
    .grid-player-promotions.two {
      grid-auto-columns: calc(50% - 25px);
    }

    .grid-new-and-recommended,
    .grid-play-history {
      .grid-game-item {
        min-height: 135px;

        .MainImage {
          width: 150px;
          height: 135px;
        }

        .MuiPaper-root {
          width: 150px;
          height: 135px;

          .MuiButtonBase-root {
            width: 150px;
            height: 135px;

            .DinamicImageContainer {
              width: 150px;
              height: 135px;

              .MainImage {
                width: 150px;
                height: 135px;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 1240px) {
    &>div {
      max-width: 1200px;
      margin: 0 auto;
    }


    .text-header {
      .icon {
        svg {
          position: relative;
          top: -2px;
        }
      }
    }

    .grid-happy-hours {
      grid-auto-columns: calc(33.3% - 7.5px);
    }

    .grid-products {
      grid-auto-columns: 112px;

      .grid-item-image {
        width: 112px;
        height: 112px;
      }

      .grid-item-title {
        font-size: 15px;
      }
    }

    .grid-of-interest {
      grid-auto-columns: calc(25% - 7.5px);
    }

    .grid-free-bet {
      grid-auto-columns: calc(33.3% - 7.5px);
    }

    .grid-home-collection {
      grid-auto-columns: calc(16.6% - 7.5px);
    }

    .grid-event-promotions {
      grid-auto-columns: calc(33.3% - 7.5px);
      padding-bottom: 5px !important;

      .myBetElement {
        .bet-odd {
          /*width: auto;*/
        }
      }

      .grid-item-promotions {
        min-height: 200px;

        &>div {
          height: 200px;
        }
      }
    }

    .grid-competition-promotions {
      grid-auto-columns: calc(33.3% - 7.5px);
      padding-bottom: 5px !important;

      .grid-item-competition {
        min-height: auto;

        img {
          width: 100%;
          height: auto;
        }
      }
    }

    .grid-play-history {
      grid-auto-columns: calc(16.6% - 7.5px);
    }

    .grid-starting-soon {
      grid-auto-columns: calc(33.3% - 7.5px);
      padding-bottom: 2px !important;

      .myBetElement {
        min-width: 100px;

        .bet-odd {
          /*width: auto;*/
        }
      }
    }

    .grid-new-and-recommended {
      grid-auto-columns: calc(16.6% - 7.5px);
    }

    .grid-top-live {
      grid-auto-columns: 320px;

      .myBetElement {
        .bet-odd {
          /*width: auto;*/
        }
      }
    }

    .grid-multi-awards {
      grid-auto-columns: calc(50% - 7.5px);
    }

    .grid-player-promotions {
      grid-auto-columns: calc(33.3% - 7.5px);
    }

    .grid-multi-awards.two,
    .grid-player-promotions.two {
      grid-auto-columns: calc(50% - 7.5px);
    }

    .grid-new-and-recommended,
    .grid-play-history {
      padding-top: 5px !important;
      padding-bottom: 5px !important;

      .grid-game-item {
        margin-bottom: 5px;
        min-height: 140px;

        .MainImage {
          width: 190px;
          height: 140px;
        }

        .MuiPaper-root {
          width: 190px;
          height: 140px;

          .MuiButtonBase-root {
            width: 190px;
            height: 140px;

            .DinamicImageContainer {
              width: 190px;
              height: 140px;

              .MainImage {
                width: 190px;
                height: 140px;
              }
            }
          }
        }
      }
    }

    .grid {
      grid-template-columns: none;
      padding: 0 15px;
      margin-bottom: 20px;

      .grid-gap {
        display: none;
      }
    }

    .grid:before,
    .grid:after {
      content: none;
      width: auto;
    }
  }

  .grid-products-parent {
    overflow-x: auto;
    padding: 0 20px 0 20px;

    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    .grid.grid-products {
      padding: 10px 15px;
      display: inline-grid;
      grid-template-columns: none;
    }

    .grid.grid-products:after,
    .grid.grid-products:before {
      content: none;
      width: auto;
    }

    .grid-products-wrapper {
      display: inline-block;
      min-width: calc(100vw - 40px);

    }
  }

  @media screen and (min-width: 1600px) {
    .grid-top-live {
      grid-auto-columns: calc(33.3% - 7.5px);
    }
  }
}