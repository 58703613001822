.day-multi-bet-betslip {
  border-radius: 7px;
  background-color: #fff;
  margin-bottom: 19px;

  padding: 20px 0 14px;
  text-align: left;

  .header {
    padding: 0 14px 16px 17px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    border-bottom: 1px solid #B7CDD8;

    .bonus {
      font-size: 12px;
      font-weight: 700;
      color: #596783;
      display: flex;
      align-items: center;
      gap: 3px;

      .icon {
        svg {
          display: block;
        }
      }
    }

    .title {
      font-size: 12px;
      font-weight: 700;
      color: #17214D;
    }
  }

  .info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 14px 0 17px;

    .txt {
      font-size: 11px;
      font-weight: 600;
      color: #586682;
    }

    .totalOddsStrike {
      font-size: 15px;
      font-weight: 600;
      color: #586682;
      text-decoration: line-through;
    }

    .totalOddsBoosted {
      font-size: 18px;
      font-weight: 600;
      color: #01AE3B;

      display: flex;
      align-items: center;
      gap: 10px;

      .icon {
        svg {
          display: block;
        }
      }

    }
  }
}