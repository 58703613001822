.day-multi-bet-main {
  font-family: OpenSans, "Open Sans" !important;
  text-align: left;

  &.body {
    word-break: normal;
    margin: 10px 0;
    position: relative;
  }

  .text-header {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    position: relative;
    color: #000;
    font-size: 18px;
    line-height: 18px;
    padding-right: 2rem;
    padding-left: 20px;
    margin-bottom: 0px;
    padding-bottom: 15px;
    text-align: left;
    text-transform: none;

    .icon {
      padding-right: 10px;
      display: block;
      margin-right: 0px;
      width: initial;
      line-height: normal;
      height: initial;
      position: relative;

      svg {
        display: block;
      }
    }

    .txt {
      font-weight: bold;
      position: relative;
      color: #000;
      min-width: 0;
      flex: 1 1 auto;
    }
  }

  --home-grid-gutter: 20px;

  .grid {
    display: grid;
    grid-column: 2 / -2;
    grid-gap: calc(var(--home-grid-gutter) / 2);
    grid-template-columns: 10px;
    grid-template-rows: minmax(100px, 1fr);
    grid-auto-flow: column;

    overflow-x: auto;
    scroll-snap-type: x mandatory;

    grid-auto-columns: 350px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    padding-bottom: 3px !important;

    &::-webkit-scrollbar {
      display: none;
    }

    &:before,
    &:after {
      content: "";
      width: 10px;
    }

    @media screen and (min-width: 1240px) {
      grid-template-columns: none;
      padding: 0 15px;
      padding-bottom: 0px;

      &:before,
      &:after {
        content: none;
        width: auto;
      }
    }

    @media (hover: hover) {
      scroll-snap-type: unset;
    }

    @media (min-width: 1600px) {
      grid-auto-columns: calc(33.3% - 7.5px);
    }

    @media (min-width: 1920px) {
      grid-auto-columns: 376px;
    }
  }

  .grid-item-card {
    scroll-snap-align: center;
    border-radius: 7px;

    padding: 16px 7px 8px;
    background: #fff;

    .header {
      display: flex;
      gap: 10px;
      padding-bottom: 8px;
      margin-bottom: 8px;
      border-bottom: 1px solid #B7CDD8;

      .icon {
        width: 16px;
        height: 16px;
      }

      .texts {
        text-align: left;

        .title {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: bold;
          color: #17214D;
        }

        .subtitle {
          font-size: 10px;
          font-weight: normal;
          color: #586682;
        }
      }
    }

    .dateList {
      padding: 0 15px;
      margin-bottom: 15px;

      .title {
        margin-bottom: 6px;
        font-size: 10px;
        font-weight: 600;
        color: #B3B6C4;
      }
    }

    .date {
      display: flex;
      gap: 10px;
      align-items: center;


      .icon {
        width: 18px;
        height: 18px;
      }

      .date-text {
        color: #17214D;
        font-size: 11px;
        font-weight: bold;
      }
    }

    .matches {
      display: flex;
      flex-direction: column;
      gap: 12px;
      padding: 0 15px;
      margin-bottom: 12px;

      .match {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .left {
          flex: 1 1 auto;

          .title {
            font-size: 11px;
            font-weight: normal;
            color: #7C88A0;
          }

          .team {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;

            .icon {
              width: 15px;
              height: 15px;

              img {
                height: 15px;
                width: auto;
                max-width: 15px;
                display: block;
              }
            }

            .name {
              flex: 1 1 auto;
              width: 0;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;

              font-size: 14px;
              font-weight: 600;
              color: #17214D;
            }
          }
        }

        .right {
          .market {
            font-size: 9px;
            font-weight: 600;
            color: #8E97AC;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-bottom: 4px;
          }

          .odd {
            display: flex;
            align-items: center;
            justify-content: space-between;
            min-width: 100px;
            gap: 16px;

            border-radius: 6px;
            background-color: #F2F6FB;
            height: 30px;
            padding: 0 12px 0 7px;

            .name {
              font-size: 14px;
              font-weight: 600;
              color: #7F838A;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .value {
              font-size: 14px;
              font-weight: 600;
              color: #000000;
              white-space: nowrap;
            }
          }
        }
      }
    }

    .more {
      color: #17214D;
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
      padding: 0 15px 27px 15px;
      cursor: pointer;

      &.hide {
        opacity: 0;
      }
    }

    .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 26px 15px 15px;

      &.in-expanded {
        padding-top: 16px;
      }

      .txt {
        color: #01AE3B;
        font-size: 11px;
        font-weight: 600;
      }

      .totalOddsStrike {
        color: #586682;
        font-size: 15px;
        font-weight: 600;
        text-decoration: line-through;
      }

      .totalOddsBoosted {
        color: #01AE3B;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        gap: 10px;

        .icon {
          svg {
            display: block;
          }
        }
      }
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;
    }


    .button {
      height: 42px;
      padding: 0 16px;
      width: 100%;
      border-radius: 11px;
      background: linear-gradient(to right, #F8991C 0%, #FF196E 100%);

      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;

      display: flex;
      align-items: center;
      justify-content: center;

      &.cancel {
        background: #fff;
        color: #748eb2;
        border: 1px solid #718FB1;
      }

      &.disabled {
        pointer-events: none;
        background: linear-gradient(to right, #ACACAC 0%, #949494 100%);
      }
    }
  }

  .MuiDialog-paper {
    width: calc(100% - 32px);
    max-width: 360px;
    border-radius: 15px;
    margin: 16px;

    .dialogContent {
      padding: 0 17px 8px 11px;

      .dialog-title {
        color: #131727;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        margin-top: 20px;
        margin-bottom: 7px;

      }

      .dialog-subtitle {
        color: #909092;
        font-size: 13px;
        font-weight: normal;
        text-align: center;
        margin-bottom: 50px;
      }
    }
  }
}