.momentum-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.75);
  width: 100%;
  height: 100%;
}

.momentum-info-panel {
  position: fixed;
  padding: 24px 16px;
  padding-top: calc(24px + var(--notch-top-offset, 0px));
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  z-index: 1000;

  @media (orientation: landscape) {
    left: 0;
    top: 0;
    bottom: 0;
    height: 100dvh;
    width: 560px;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0px 8px 8px 0px;
    background: radial-gradient(127.68% 78.07% at 50.13% 32.42%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 48.76%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(90deg, #2C054C 78.39%, rgba(44, 5, 76, 0.63) 100.08%);
    box-shadow: 20px 0px 20px 0px rgba(0, 0, 0, 0.75);
  }

  @media (orientation: portrait) {
    left: 0;
    top: 0;
    right: 0;
    width: 100%;
    height: fit-content;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    border-radius: 0px 0px 8px 8px;
    background: radial-gradient(154.09% 94.21% at 50.13% 50%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 48.76%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, #2C054C 67.07%, rgba(44, 5, 76, 0.63) 100%);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.75);
  }

  .top-side {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .logo {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    position: relative;

    &>img {
      width: 174px;
      height: 100px;
      object-fit: contain;
    }

    .close {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.10);

      &>img {
        width: 24px;
        height: 24px;
        object-fit: contain;
      }
    }
  }

  .progress-wrapper {
    width: 169px;
    height: 24px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .progress {
      width: 159px;
      height: 14px;
      border-radius: 50px;
      border: 1px solid #6916A8;
      padding: 2px;

      background: #2C024D;

      box-shadow: 0px 4px 4.3px 0px rgba(0, 0, 0, 0.56) inset;

      .progress-bar {
        height: 100%;
        border-radius: 50px;
        background: linear-gradient(92deg, rgba(255, 255, 255, 0.00) 92.4%, #FFF 96.41%, rgba(255, 255, 255, 0.00) 100.42%), linear-gradient(198deg, #FFCC3F 27.22%, #FD692A 93.1%);
        box-shadow: 0.481px 8px 0px 0px rgba(242, 213, 88, 0.44) inset;
        position: relative;

        .end-stuff {
          position: absolute;
          top: 5px;
          right: -12px;
          width: 24px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            position: absolute;
          }

          img.se {
            width: 24px;
            height: auto;
            display: block;
          }
        }
      }
    }

    .end-star {
      position: absolute;
      top: 0;
      right: 0;
      width: 24px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }
  }

  .progresswp {
    position: relative;
    display: flex;
    justify-content: center;

    .progress-wrapper {
      opacity: 0.2;
      filter: grayscale(1);
    }

    .eye {
      position: absolute;
      width: 50px;
      top: -8px;

      img {
        width: 100%;
        height: auto;
        display: block;
      }
    }
  }

  .list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0;
    padding: 16px 20px;
    gap: 16px;

    .item {
      width: 100%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: -13px;
        left: 16px;
        bottom: 35px;
        width: 1px;
        background: rgba(255, 255, 255, 0.10);
      }

      &:first-child::before {
        display: none;
      }

      .no {
        z-index: 1;
        flex: 0 0 32px;
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.10);

        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
      }

      .content {
        width: 100%;
        padding: 0 46px 0 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;

        .icon {
          width: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: auto;
            display: block;
          }
        }

        .text {
          width: 100%;
          text-align: center;
          padding-bottom: 5px;

          color: #FFF;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }

        .sub-text {
          color: #FFF;

          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 18px;
          opacity: 0.5;
        }
      }
    }
  }

  &.warning .list {
    padding: 16px 0;

    .item {
      .content {
        padding: 0 14px;
      }
    }

    .item::before {
      display: none;
    }
  }

  .bottom-side {
    width: 100%;
    display: flex;
    flex-direction: column;


    .info {
      color: rgba(255, 255, 255, 0.30);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-align: center;

      a {
        color: #0A34DD;
      }
    }

    .info-1 {
      margin-bottom: 8px;
    }

    .info-2 {
      margin-bottom: 8px;
    }

    .info.show-more {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
      color: #fff;

      .arrow {
        width: 17px;
        height: 17px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          width: 100%;
          height: 100%;
          display: block;
        }

      }
    }
  }

  @media (orientation: landscape) {
    .top-side {
      flex-direction: row;
      align-items: stretch;
    }

    .logo {
      width: 173px;
      flex: 0 0 173px;
      height: unset;

      &>img {
        width: 174px;
        height: auto;
      }

      .close {
        transform: rotate(-90deg);
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 8px;

    .button {
      cursor: pointer;
      display: flex;
      padding: 8px 12px;

      justify-content: center;
      align-items: center;

      border-radius: 4px;
      background: #ED003F;

      color: #FFF;

      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 16px;
      text-transform: uppercase;
    }

    .button-small {
      cursor: pointer;
      display: flex;
      padding: 8px 12px;

      justify-content: center;
      align-items: center;
      gap: 8px;
      color: #FFF;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px;
      opacity: 0.5;
      text-transform: uppercase;
    }
  }

  &.desktop {
    border-radius: 16px;
    background: radial-gradient(127.68% 78.07% at 50.13% 32.42%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 48.76%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(180deg, #2C054C 67.07%, rgba(44, 5, 76, 0.95) 88.47%, rgba(44, 5, 76, 0.90) 100%);
    box-shadow: 25.55px 0px 25.55px 0px rgba(0, 0, 0, 0.75);
    top: calc(100dvh / 2);
    left: calc(100dvw / 2);
    margin-top: -300px;
    margin-left: -365px;
    width: 730px;
    height: 460px;
    border-radius: 16px;
    padding: 24px;

    .logo {
      .close {
        color: #fff;
        transform: rotate(0);
      }
    }
  }

  &.desktop.warning {
    height: 560px;
  }
}