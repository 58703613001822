.tournaments-missions-dialog-rules {
  box-sizing: border-box;
  z-index: 10000 !important;

  * {
    box-sizing: border-box;
  }

  .MuiBackdrop-root {
    backdrop-filter: blur(5px);
    background-color: #ffffff10;
  }

  .MuiPaper-root {
    margin: 10px;
    background: linear-gradient(to bottom, #213348, #1F3044 65%, #142232);
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    border: 2px solid rgba(136, 162, 252, .2);
    color: #B5BFD0;
    min-width: 300px;
    min-height: 300px;
    padding: 10px 15px;
    overflow: visible;

    .rules-header {
      height: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: -10px;

      .rules-mission {
        min-width: 110px;
        max-width: 110px;
        height: 110px;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        .img {
          min-width: 110px;
          max-width: 110px;
          height: 87px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            height: 100px;
            width: 100px;
            //object-fit: contain;
          }

          .txt {
            position: absolute;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .subtitle {
              font-size: 11px;
              line-height: 11px;
              color: #DFE3E7;
              font-weight: bold;
            }

            .title {
              font-size: 21px;
              line-height: 21px;
              color: #DFE3E7;
              font-weight: bold;
              text-shadow: 1px 2px rgb(0, 0, 0);
            }
          }
        }

        .timer {
          position: absolute;
          bottom: 15px;
          left: 0;
          color: #FF5B00;
          font-size: 12px;
          font-weight: bold;
          background: linear-gradient(to right, #13181E00, rgba(19, 24, 30, .88) 20%, rgba(19, 24, 30, .88) 80%, #13181E00);
          width: 110px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 19px;
          white-space: nowrap;

          .muted {
            opacity: .55;
          }

          .days {
            color: #FF975D;
          }
        }

        .progress-wrapper {
          position: absolute;
          bottom: 5px;
          max-width: 100px;
          min-width: 100px;
          height: 5px;
          background: rgba(255, 255, 255, .07);
          border-radius: 3px;
          margin-bottom: 2px;

          .progress-br {
            height: 5px;
            background: linear-gradient(to right, #F98D00, #FFCA1E);
            border-radius: 3px;
          }
        }
      }

      .rules-close {
        background: linear-gradient(to bottom, #3B526E, #020E1C);
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 38px;
        max-width: 38px;
        height: 38px;
        cursor: pointer;

        .rules-close-wrapper {
          color: #F72226;
          background: #213246;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 34px;
          height: 34px;
          cursor: pointer;
        }
      }
    }

    .rules-title {
      margin-top: 65px;
      color: #DFE3E7;
      font-size: 16px;
      font-weight: 600;
      padding-left: 0px;
    }

    .rules-subtitle {
      margin-top: 15px;
      color: #DFE3E7;
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 5px;
      padding: 0 0 6px;
      text-transform: uppercase;
    }

    .rules-content {
      color: #8D92A0;
      font-size: 12px;
      font-weight: normal;
      padding: 0 0 6px;
    }

    .rules-icons {
      text-align: center;
      height: 56px;

      img {
        display: block;
        width: 100%;
        max-height: 56px;
        object-fit: contain;
      }
    }

    .rules-header-line {
      margin-top: 24px;
      font-size: 10px;
      color: #fff;
      font-weight: normal;
      opacity: .59;
    }

    .rules-recommended-line {

      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 15px;

      .recommended {
        aspect-ratio: 103/187;
        background: linear-gradient(to bottom, rgba(0, 0, 0, .0), rgba(0, 0, 0, .52) 75%, rgba(0, 0, 0, .57));
        border-radius: 11px;
        margin: 0 5px;
        cursor: pointer;

        &:first-child {
          margin-left: 0px;
        }

        &:last-child {
          margin-right: 0px;
        }

        .img {
          position: relative;
          top: -8px;
          margin: -2px;


          transition: transform .25s ease;


          &:hover {
            position: relative;
            transform-origin: center;
            transform: translateZ(0) scale(1.05);
            z-index: 1;
          }

          img {
            width: 100%;
          }

          .free-bet-sticker {
            position: absolute;
            top: 10px;
            left: 0px;
            right: 0px;
            height: 17px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;
            font-size: 10px;
            font-weight: bold;
            border: 1px solid #FFC6C6;
            background: linear-gradient(to right, #FF4E00, #CE0031 80%, #80001F);
            border-radius: 3px;
            padding-bottom: 2px;
          }
        }

        .rank {
          display: flex;
          align-items: center;
          justify-content: center;
          position: relative;
          top: -3px;

          img {
            display: block;
            margin: 0 1px;
            height: 20px;
            width: auto;
          }
        }
      }
    }

    .rules-footer {
      padding: 30px 0 20px;
      color: #8D92A0;
      font-size: 15px;
      font-weight: 600;
      text-decoration: underline;
      text-transform: uppercase;
      text-align: center;
      cursor: pointer;
    }
  }

  @media screen and (orientation: landscape) {
    .MuiPaper-root {
      margin-top: 50px;
    }
  }
}