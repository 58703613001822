.live {
  .table {
    border: 1px solid var(--ns-table-border-color);
    border-bottom-width: 0;
    margin-bottom: 10px;

    .table-header {
      height: 2.125em;
      padding: 5px;
      padding-left: 10px;
      border-bottom: 1px solid var(--ns-table-border-color);
      color: var(--ns-table-header-color);
      overflow: hidden;

      .table-title {
        font-weight: 300;
        font-size: 1em;
        text-align: left;
        display: inline-block;
      }

      .table-favs {
        position: relative;
        top: -2px;
        padding-left: 5px;
        color: var(--ns-table-header-favs-color);
        font-size: 0.8em;
        cursor: pointer;
        display: inline-block;

        svg {
          fill: currentColor;
          width: auto;
          height: 13px;
          vertical-align: middle;
        }

        &.active {
          color: var(--ns-table-row-favs-active-color);
        }
      }

      .table-filter {
        display: none;
        float: right;
        font-size: 0.75em;

        .table-filter-button {
          display: block;
          float: left;
          height: 24px;
          line-height: 24px;
          max-width: 72px;
          padding: 0 10px;
          border-right: 1px solid var(--ns-table-filter-button-border-color);
          background-color: var(--ns-table-filter-button-bg);
          color: var(--ns-table-filter-button-color);
          text-align: center;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.active,
          &:hover {
            background-color: var(--ns-table-filter-button-active-bg);
            color: var(--ns-table-filter-button-active-color);
          }
        }

        @media screen and (min-width: 768px) {
          .table-filter-button {
            max-width: 108px;
          }
        }
      }

      .table-count {
        display: block;
        float: right;
        font-size: 0.75em;
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        background-color: var(--ns-table-header-count-bg);
        text-align: center;
      }

      @media screen and (min-width: 768px) {
        .table-filter {
          display: block;
        }

        .table-count {
          display: none;
        }
      }
    }

    // table-header
  }

  .table-group {
    position: relative;
    height: 26px;
    line-height: 26px;
    padding: 0 50px 0 36px;
    color: var(--ns-table-group-color);
    background-color: var(--ns-table-group-bg-color);
    background-image: var(--ns-table-group-bg-image);
    border-bottom: 1px solid var(--ns-table-border-color);
    font-size: 0.875em;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .flag {
      position: absolute;
      top: -4px;
      left: 10px;
      transform: scale(0.5);
      transform-origin: 0;
    }

    .icon {
      width: 13px;
      position: absolute;
      top: 1px;
      left: 12px;

      svg {
        fill: currentColor;
        width: 13px;
        height: 13px;
      }
    }

    .table-group-favorite {
      display: inline-grid;
      padding: 0 0.5em;
      color: var(--ns-table-group-favorite-color);
      font-size: 0.75em;
      cursor: pointer;

      svg {
        fill: currentColor;
        width: auto;
        height: 12px;
        vertical-align: middle;
        position: relative;
        top: 0px;
      }

      &.active {
        color: var(--ns-table-row-favs-active-color);
      }
    }

    .table-group-collapse-arrow {
      position: absolute;
      right: 0;
      top: 0;
      display: block;
      padding: 0 0.5em;
      color: var(--ns-table-group-collapse-arrow-color);
      font-size: 0.75em;

      svg {
        width: auto;
        height: 16px;
        vertical-align: middle;
      }
    }
  }

  .table-group-body.flx {
    display: flex;
    width: 100%;

    .fst {
      flex: 1 1 auto;
      min-width: 0;
      width: 100%;
    }

    .snd {
      min-width: 50px;
      max-width: 50px;
    }
  }

  .table-row {
    height: 65px;
    background-color: var(--ns-table-row-bg-color);
    background-image: var(--ns-table-row-bg-image);
    border-bottom: 1px solid var(--ns-table-border-color);
    margin-bottom: 0;
    overflow: hidden;

    .table-cell {
      position: relative;
      height: 65px;
      float: left;
    }

    .match-desc {
      width: 85%;
    }

    .match-bets {
      display: none;
    }

    &.mobile {
      background-color: #202020;
      background-image: none;
      display: block;
    }

    &.mobile.fst {
      border-bottom: 0;
    }

    &.mobile.scdd {
      border-bottom: 1px solid #707070;
    }

    .match-bets.mobile {
      display: block;
      width: 100%;
    }

    .match-more {
      position: relative;
      width: 100%;
      height: 100%;
      border-left: 1px solid var(--ns-table-border-color);
      text-align: center;
    }

    &.more {
      width: 100%;
      height: 100%;
      border-bottom: 1px solid #707070;

      .match-more {
        background-color: #202020;
        background-image: none;
        border-left: 0;
      }
    }

    @media screen and (min-width: 400px) {
      .match-desc {
        width: 100%;
      }
    }

    @media screen and (min-width: 768px) {
      &.mobile {
        display: none;
      }

      .match-desc {
        width: 47%;
      }

      .match-bets {
        display: block;
        width: 47%;
      }

      .match-bets.mobile {
        display: none;
      }

      .match-more {
        width: 6%;
      }
    }

    .table-row-header {
      height: 23px;
      padding: 5px 35px 5px 10px;
      border-bottom: 1px solid var(--ns-table-border-color);
      line-height: 1;
      color: var(--ns-table-row-header-color);
      font-size: 0.75em;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .match-options {
        display: none;
        position: absolute;
        right: 0.25em;
        top: 0;
        font-size: 1.6em;

        svg {
          width: auto;
          height: 16px;
          vertical-align: middle;
        }
      }

      @media screen and (min-width: 768px) {
        .match-options {
          display: block;
        }
      }
    }

    &.mobile {
      .table-row-header {
        border-bottom: 0;
      }
    }

    .match-text {
      position: relative;
      display: block;
      line-height: 1;
      text-decoration: none;
      cursor: pointer;
      color: var(--ns-table-row-match-text-color);
    }

    .match-text-time {
      padding-left: 50px;
      padding-right: 10px;
    }

    .match-time {
      display: block;
      position: absolute;
      left: 0;
      top: 5px;
      width: 50px;
      height: 31px;
      font-size: 13px;
      font-weight: 600;
      line-height: 1;
      text-align: center;
      color: var(--ns-table-row-match-time-color);
      border-right: 1px solid var(--ns-table-row-match-time-border-color);

      .match-time-current {
        padding-bottom: 5px;
      }

      .match-time-current.fst {
        color: #969696;
      }
    }

    &.mobile {
      .match-time {
        border-right: 0;
      }
    }

    .match-title {
      width: 100%;
      padding: 5px 10px;
      font-size: 13px;
      font-weight: bold;
      color: var(--ns-table-row-match-title-color);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      .match-title-text {
        padding: 0 3px 1px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .match-result {
      padding: 5px 10px;
      padding-top: 0;
      color: var(--ns-table-row-match-result-color);
      font-weight: 600;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      span {
        padding-right: 5px;
      }

      span:nth-last-child(2) {
        color: var(--ns-table-row-match-result-second-child-color);
      }

      span:last-child {
        color: var(--ns-table-row-match-result-last-child-color);
      }
    }

    .match-bets-group {
      border-left: 1px solid var(--ns-table-border-color);
      color: var(--ns-table-row-match-bets-group-color);
    }

    .match-bets.mobile .match-bets-group {
      border: 0;
    }

    .match-bets-items {
      overflow: hidden;

      .table-buttons {
        padding: 3px 5px 0;
        padding-left: 0;
      }
    }

    .match-bets.mobile {
      .match-bets-items {
        .table-buttons {
          padding: 3px 5px 0 5px;
        }
      }
    }

    .match-bets-empty {
      height: 42px;
      padding: 0 10px;
      font-size: 0.75em;
      color: var(--ns-table-row-match-bets-empty-color);

      .middle {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
      }

      .middle.pointer {
        cursor: pointer;

        .wh {
          color: var(--ns-table-row-match-bets-empty-link-color);
        }
      }
    }

    .table-row-favs {
      display: block;
      position: absolute;
      top: 15px;
      width: 100%;
      color: var(--ns-table-row-favs-color);
      text-decoration: none;
      cursor: pointer;

      svg {
        width: auto;
        height: 16px;
        vertical-align: middle;
      }

      &.active {
        color: var(--ns-table-row-favs-active-color);
      }
    }

    .match-more-length {
      line-height: 20px;
      display: block;
      position: absolute;
      width: 100%;
      bottom: 10px;
      color: var(--ns-match-more-length);
      font-size: 0.75em;
      text-decoration: none;
      cursor: pointer;
    }
  }

  // table

  .match-results {
    position: sticky;
    top: 0px;
    z-index: 1;
    //position: relative;
    //height: 130px;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid var(--ns-match-results-border-color);
    font-size: 0.875em;

    .match-stats {
      width: 100%;
      /*height: 72px;*/
      overflow: hidden;

      .match-tournament {
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        font-weight: 400;
        color: var(--ns-match-results-tournament-color);
        display: flex;
        align-items: center;
        width: 100%;

        .match-tournament-name {
          float: left;
          height: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: var(--ns-match-results-tournament-name-color);
          min-width: 0;
          flex: 1 1 auto;

          .match-time {
            float: left;
            padding-right: 10px;
            color: var(--ns-match-results-tournament-time-color);
            font-weight: 700;
          }
        }
      }

      .match-team {
        height: 24px;
        line-height: 24px;
        padding: 0 10px;
        font-size: 1em;
        font-weight: 400;
      }

      .match-team-row {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .match-team-name {
        color: var(--ns-match-results-team-name-color);
        position: relative;
        min-width: 0;
        flex: 1 1 auto;

        >span {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        .serving-dot {
          position: absolute;
          top: 7px;
          left: -10px;
          display: flex;
          margin: 0px 0px 0px 0px;
          width: 11px;
          height: 11px;
        }
      }

      .match-team-col {
        float: left;
      }

      .match-team-score {
        width: 32px;
        min-width: 32px;
        color: var(--ns-match-results-team-score-color);
        text-align: center;
        font-weight: 600;

        svg {
          fill: #fff;
          width: 12px;
          height: 12px;
        }
      }

      .match-team-score.winning {
        color: #67fe99;
      }

      .match-team-score.loosing {
        color: #f2a6a6;
      }

      .match-team-stat {
        display: block;
        width: 22px;
        min-width: 22px;
        text-align: center;
        font-weight: 600;
        color: rgba(255, 255, 255, 0.6);

        svg {
          fill: #fff;
          width: 12px;
          height: 12px;
        }
      }

      .match-team-stat.active {
        color: #06d2bd;
      }

      .d-top {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        text-align: center;
      }

      .d-bottom {
        display: none;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      @media screen and (min-width: 768px) {
        .d-top {
          display: none;
        }

        .d-bottom {
          display: block;
        }
      }
    }
  }
}

// live