.menu_tickets_status {
  width: 100%;
  box-sizing: border-box;
  height: 46px;
  border-radius: 3px;
  cursor: pointer;

  background: #f5f8fa;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  margin: 10px 0;

  .startIcon {}

  .tickets {
    font-size: 26px;
    color: #030004;
  }

  .text {
    font-size: 12px;
    flex: 1 1 auto;
    text-align: left;
    color: #7D848B;
  }

  .endIcon {}
}