.lotto-draws-selector {
  display: flex;
  margin-top: 6px;
  justify-content: space-around;

  .lsd-button,
  .lds-event {
    color: #192640;
    cursor: pointer;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: bold;
    margin-right: 2px;
    border-radius: 7px;
    margin-bottom: 5px;
    text-transform: uppercase;
    background: #DFE7EF;
    min-width: 60px;
    max-width: 60px;
    text-align: center;
    outline: none;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }

  .lsd-cn {
    cursor: default;
  }

  .lsd-button {
    color: #FFF;
    background: linear-gradient(to right, #F8991C, #FF196E);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.31);
    outline: none;
  }

  .lsd-button:active {
    box-shadow: none;
    outline: none;
  }

  .lsd-button.disabled {
    pointer-events: none;
    opacity: .7;
  }

  .lsd-button.no-r-m {
    margin-right: 0;
  }

  .lds-event.selected {
    color: #fff;
    //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.31);
    background: #06D2BD;
  }

  .lsd-spread {
    min-width: 60px;
    max-width: 60px;
    height: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: normal;
    text-align: center;
    color: #006D0E;
  }

  .lsd-content {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
  }

  .lsd-content.lsd-empty {
    padding: 0;
  }

  .lds-two {
    position: relative;

    .lds-event {
      position: relative;
      margin-left: 5px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.31);
    }

    .abs-1 {
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 0;
    }


  }

  .lds-three {
    position: relative;

    .lds-event {
      position: relative;
      margin-left: 10px;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.31);
    }

    .abs-1 {
      position: absolute;
      left: 0;
      top: 0;
      margin-left: 0;
    }

    .abs-2 {
      position: absolute;
      left: 5px;
      top: 0;
      margin-left: 0;
    }


  }

  .lds-event.lds-lock {
    color: #ccc;

    svg {
      height: 18px;
      width: auto;
    }
  }
}