.deposit-ticket-main-view-dialog {
  .MuiPaper-root {
    border-radius: 10px;
    background-color: transparent;
    max-height: calc(100% - 40px);
    margin: 6px;

    .deposit-ticket-main-view {
      background: #fff;
      padding-top: 0 !important;

      .title-bar {
        position: static !important;
      }
    }
  }
}