/* .card-columns {
    @include media-breakpoint-only(lg) {
      column-count: 4;
    }
    @include media-breakpoint-only(xl) {
      column-count: 5;
    }
} */

.OverflowHidden{
  overflow: hidden !important;
  -ms-scroll-limit: 0 0 0 0; /* ie */
}

#GamesList
{
  padding-bottom: 60px;
}

.GamesCards
{
  display: grid;
  padding-left: 2rem;
  padding-right: 2rem;
  grid-template-columns: repeat(6, 3fr);
  grid-template-rows: repeat(auto-fill, 1fr);
  grid-gap: 1.2rem;
  grid-row-gap: 0.9rem;
  grid-auto-rows: minmax(10.3rem, auto);
}

.GamesCards .DinamicImageContainer {
  display: block;
}

.fadeGameCard-enter {
  opacity: 0;
}

.fadeGameCard-enter-active {
  opacity: 1;
  transition: .4s ease-in all;
}

.fadeGameCard-leave {
  opacity: 1;
}

.fadeGameCard-leave-active {
  opacity: 0;
  transition: .4s ease-out all;
}

@media only screen and (max-width: 780px) {
  .GamesCards {
    grid-template-columns: repeat(2, 3fr);
  }
}

@media only screen and (min-width: 780px) {
  .GamesCards {
    grid-template-columns: repeat(3, 3fr);
  }
}

@media only screen and (min-width: 1000px) {
  .GamesCards {
    grid-template-columns: repeat(4, 3fr);
  }
}

@media only screen and (min-width: 1200px) {
  .GamesCards {
    grid-template-columns: repeat(5, 3fr);
  }
}

@media only screen and (min-width: 1400px) {
  .GamesCards {
    grid-template-columns: repeat(6, 3fr);
  }
}

/** Grid row height **/
@media only screen and (max-width: 780px) {
  .GamesCards {
    grid-auto-rows: minmax(14.7rem, auto);
  }
}

@media only screen and (min-width: 800px) {
  .GamesCards {
    grid-auto-rows: minmax(10.5rem, auto);
  }
}

@media only screen and (min-width: 900px) {
  .GamesCards {
    grid-auto-rows: minmax(11.8rem, auto);
  }
}

@media only screen and (min-width: 950px) {
  .GamesCards {
    grid-auto-rows: minmax(12.4rem, auto);
  }
}

@media only screen and (min-width: 1000px) {
  .GamesCards {
    grid-auto-rows: minmax(9.8rem, auto);
  }
}

@media only screen and (min-width: 1050px) {
  .GamesCards {
    grid-auto-rows: minmax(10rem, auto);
  }
}

@media only screen and (min-width: 1100px) {
  .GamesCards {
    grid-auto-rows: minmax(10.6rem, auto);
  }
}

@media only screen and (min-width: 1200px) {
  .GamesCards {
    grid-auto-rows: minmax(9.2rem, auto);
  }
}

@media only screen and (min-width: 1250px) {
  .GamesCards {
    grid-auto-rows: minmax(9.6rem, auto);
  }
}

@media only screen and (min-width: 1300px) {
  .GamesCards {
    grid-auto-rows: minmax(10.1rem, auto);
  }
}

@media only screen and (min-width: 1400px) {
  .GamesCards {
    grid-auto-rows: minmax(8.8rem, auto);
  }
}

@media only screen and (min-width: 1450px) {
  .GamesCards {
    grid-auto-rows: minmax(9rem, auto);
  }
}

@media only screen and (min-width: 1500px) {
  .GamesCards {
    grid-auto-rows: minmax(9.5rem, auto);
  }
}

@media only screen and (min-width: 1600px) {
  .GamesCards {
    grid-auto-rows: minmax(10.3rem, auto);
  }
}

@media only screen and (min-width: 1650px) {
  .GamesCards {
    grid-auto-rows: minmax(10.6rem, auto);
  }
}

@media only screen and (min-width: 1800px) {
  .GamesCards {
    grid-auto-rows: minmax(12.1rem, auto);
  }
}
