.bonus-popup-simple-timer {
  display: flex;
  align-items: center;
  border-radius: 3px;
  height: 28px;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, .53);

  .days {
    display: block;
    background: #ACBFD2;
    font-size: 13px;
    font-weight: normal;
    color: #fff;
    white-space: nowrap;
    padding: 0 8px 0 10px;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    height: 28px;
    display: flex;
    align-items: center;
  }

  .time {
    display: block;
    background: #FFC400;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    white-space: nowrap;
    padding: 0 10px 0 10px;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    height: 28px;
    display: flex;
    align-items: center;
  }

  .time.no-days {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
  }
}