.component-momentum {
  margin: 10px auto;
  padding-bottom: 5px;
  max-width: 1528px;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .cgl-header {
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      color: #353540;
      font-size: 18px;
      font-weight: 600;
      text-align: left;
    }

    .link {
      color: #353540;
      font-size: 12px;
      font-weight: 600;
      text-align: left;
      cursor: pointer;
    }
  }

  .cgl-body {
    max-width: 560px;
    margin: 8px 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    height: 173px;

    padding: 12px 16px;

    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    border-radius: 12px;
    background: url("./assets/stars.png") no-repeat left bottom, radial-gradient(58.03% 111.67% at 50.13% 50%, #541782 0%, rgba(8, 19, 66, 0.46) 48.76%, rgba(0, 0, 0, 0.00) 100%), #2C054C;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);

    position: relative;

    .header {
      position: absolute;
      top: 12px;
      left: 16px;
      right: 16px;

      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .logo {
        width: 71px;
        height: 41px;

        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
        }
      }

      .timer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .title,
        .counter {
          color: #FFBF1A;
          font-size: 14px;
          font-style: italic;
          font-weight: 600;
          line-height: 16px;
          letter-spacing: 0.14px;
        }

        &.warning .title,
        &.warning .counter {
          color: #ED003F;
        }
      }

    }

    .center {
      width: 100%;
      height: 90px;
      display: flex;
      padding: 38px 6px 2px 0px;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      align-self: stretch;
      overflow: visible;
      position: relative;

      .value {
        display: flex;
        min-height: 45px;
        height: 45px;
        padding: 0 32px 5px 32px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 26.5px;
        border-top: 1px solid #1E0A44;
        gap: 5px;
        position: relative;

        background: linear-gradient(0deg, rgba(31, 5, 51, 0.00) 0%, #041132 100%);


        .stars {
          position: absolute;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          gap: 0;
          top: -12px;

          .star {
            width: 21px;
            height: 20px;
            margin: 0 0 0 -5px;

            img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }

          .star:first-child {
            margin-left: 0;
          }
        }

        .text {
          padding-top: 24px;
          color: rgba(255, 255, 255, 0.90);
          text-shadow: 0px 3.629px 10.614px rgba(255, 255, 255, 0.20);
          font-size: 26px;
          font-style: italic;
          font-weight: 600;
          line-height: 90%;
          letter-spacing: 0.52px;
          white-space: nowrap;
          width: 85px;
          text-align: center;
          overflow: visible;

          display: flex;
          align-items: flex-end;
          justify-content: center;
          gap: 6px;

          .smaller {
            font-size: 22px;
            text-transform: lowercase;
          }
        }
      }

      .progress-wrapper {
        margin-top: 14px;
        width: 100%;
        height: 24px;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .progress {
          width: 100%;
          height: 10px;
          border-radius: 50px;
          border: 1px solid #6916A8;
          padding: 2px;

          background: #2C024D;

          box-shadow: 0px 4px 4.3px 0px rgba(0, 0, 0, 0.56) inset;
          position: relative;

          .progress-bar {
            height: 100%;
            border-radius: 50px;
            background: linear-gradient(92deg, rgba(255, 255, 255, 0.00) 92.4%, #FFF 96.41%, rgba(255, 255, 255, 0.00) 100.42%), linear-gradient(198deg, #FFCC3F 27.22%, #FD692A 93.1%);
            box-shadow: 0.481px 8px 0px 0px rgba(242, 213, 88, 0.44) inset;
            position: relative;
            transition: width 200ms ease-in-out;
          }

          @keyframes my-bg-animation-portrait {
            100% {
              background-position: 1590px 0;
            }
          }

          .progress-bar-shine {
            height: 4px;
            border-radius: 50px;
            position: absolute;
            top: 3px;
            left: 0;
            overflow: hidden;
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 1149px, rgba(255, 255, 255, .5) 1159px, rgba(255, 255, 255, 0) 1169px, transparent);
            animation: my-bg-animation-portrait 10s infinite linear;
            background-repeat: repeat;
            background-position: 0 0;
            background-size: 1590px 6px;
          }
        }

        .end-star {
          position: absolute;
          top: -6px;
          right: -4px;
          width: 18px;
          height: 18px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            width: 100%;
            height: 100%;
            display: block;
          }

          &.op img {
            opacity: 0;
          }
        }
      }
    }

    .footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 24px;
      width: 100%;

      .text {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;

        color: #FFF;

        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 13px;
        letter-spacing: 0.12px;
        opacity: .6;
        text-align: left;
      }

      .cta_text {
        display: flex;
        height: 32px;
        padding: 0 10px 0 16px;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background: rgba(255, 255, 255, 0.10);
        cursor: pointer;
        white-space: nowrap;

        color: #FFF;
        font-size: 12px;
        font-style: italic;
        font-weight: 600;
        line-height: 14px;

        gap: 4px;

        img {
          width: 12px;
          height: 12px;
          display: block;
        }
      }
    }
  }

  @media (min-width: 845px) {
    .cgl-body {
      background: url("./assets/stars.png") no-repeat left bottom, radial-gradient(122.43% 209.61% at 49.87% 10.42%, #6E1EAB 0%, rgba(8, 19, 66, 0.46) 45%, rgba(0, 0, 0, 0.00) 100%), #2C054C;

      height: 260px;
      padding: 24px;

      .header {
        top: 24px;
        left: 24px;
        right: 24px;

        .logo {
          margin-top: 10px;
          width: 118px;
          height: 66px;
        }

        .timer {
          padding-top: 12px;

          .title,
          .counter {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }

      .center {
        padding-top: 24px;
        height: auto;

        .value {
          padding: 0px 42px 7px 42px;
          min-height: 61.251px;
          height: 61.251px;
          border-radius: 34.682px;

          display: flex;
          justify-content: center;

          .stars {
            top: -16px;

            .star {
              width: 38px;
              height: 36px;
              margin: 0 0 0 -11px;
            }
          }

          .text {
            padding-top: 30px;
            font-size: 36px;
            line-height: 90%;
            letter-spacing: 0.72px;

            .smaller {
              font-size: 28px;
            }
          }
        }

        .progress-wrapper {
          margin-top: 46px;

          .progress {
            height: 12px;
          }

          .end-star {
            top: -6px;
            right: -6px;
            width: 28px;
            height: 28px;
          }
        }
      }

      .footer {
        align-items: flex-end;

        .text {
          font-size: 18px;
          line-height: 24px;
          letter-spacing: 0.18px;
        }

        .cta_text {
          padding: 0 16px 0 24px;
          height: 40px;

          font-size: 18px;
          line-height: 18px;

          img {
            width: 18px;
            height: 18px;
          }
        }
      }
    }
  }
}