.tournaments-missions-dialog-history-leaderboard {
  box-sizing: border-box;
  z-index: 10000 !important;

  * {
    box-sizing: border-box;
  }

  .MuiBackdrop-root {
    backdrop-filter: blur(5px);
    background-color: #ffffff10;
  }

  .MuiPaper-root.MuiDialog-paperWidthXs.MuiDialog-paperScrollBody {
    margin: 10px;
    background: rgb(33, 51, 72);
    border-radius: 15px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
    border: 0px solid rgba(136, 162, 252, .2);
    color: #B5BFD0;
    padding: 0;
    overflow: visible;
    margin-top: calc(10px + var(--notch-top-offset, 0px)) !important;

    @media (max-width: 507.95px) {
      max-width: calc(100% - 20px);
    }

    .header-wrapper {
      border-radius: 15px 15px 0 0;
      background: linear-gradient(to bottom, #28339D, #252C71 20px, #252C71 300px, #252C71);
    }

    .top-header {
      border-radius: 15px 15px 0 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 10px 10px;

      .texts {
        display: flex;
        flex-direction: column;

        .big-title {
          color: #fff;
          font-size: 20px;
          font-weight: 600;

        }

        .small-title {
          color: #fff;
          font-size: 12px;
          font-weight: normal;
          opacity: .6;
        }
      }
    }

    .api-error {
      font-size: 11px;
      font-weight: 600;
      color: #fc5995;
      padding: 0 10px 14px;
      text-align: center;
    }

    .claim-wrapper {
      margin: 0 6px 10px;
      border-radius: 7px;
      border: 1px solid #FF005F;
      background: rgba(0, 0, 0, .35);
      padding: 0 12px 0 14px;
      height: 57px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .txt {
        display: flex;

        color: #FFFFFF;
        font-size: 12px;
        font-weight: regular;

        .w {
          height: 15px;
          width: 15px;
          border-radius: 50%;
          border: 1px solid #fff;
          background: #ff005f;
          margin-right: 5px;

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 9px;
          color: #fff;
          font-weight: 600;
        }
      }

      .tm-btn {
        margin: 0 0 0 10px;
        height: 39px;
        background: linear-gradient(to right, #FFD457 0%, #FF6800 100%);
        padding: 2px;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 110px;

        .inner {
          height: 35px;
          background: linear-gradient(to right, #0A1520 0%, #213348 100%);
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: nowrap;
          position: relative;

          font-size: 13px;
          font-weight: bold;
          color: #fff;
          text-transform: uppercase;
          width: 100%;
        }
      }
    }

    .tm-ranking-cnt {
      padding-top: 10px;
    }

    .tm-ranking {
      border-radius: 0 0 15px 15px;
      background: #213348;

      .empty {
        font-size: 12px;
        text-align: center;
        padding: 30px 50px 50px;
        width: 100%;
        font-weight: normal;
        color: #ffffffaa;
      }

      .tm-row {
        display: flex;
        align-items: center;
        justify-content: stretch;
        /*cursor: pointer;*/

        padding: 0 10px;

        width: 100%;
        height: 48px;
        background: #213348;
        border-bottom: 1px solid #304154;

        &.tm-row-0 {
          background: #4e5c6d;
        }

        &.tm-row-1 {
          background: #404f61;
        }

        &.tm-row-2 {
          background: #2c3d51;
        }

        &>div {
          flex: 1 1 auto;
        }

        .tm-header {
          color: #979DAB !important;
          font-size: 9px !important;
          font-weight: bold !important;
          padding: 0 2px;
        }

        .tm-col.tm-left {
          text-align: left;
        }

        .tm-col.tm-right {
          text-align: right;
        }

        .tm-col.tm-center {
          text-align: center;
        }

        .tm-col1 {
          width: 25px;
          min-width: 25px;
          max-width: 25px;

          &>div {
            font-size: 10px;
            font-weight: 600;
            color: #8D92A0;
            text-align: center;
            border-radius: 50%;
            margin: 0 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            line-height: 1px;
          }

          .tm-gold {
            background: linear-gradient(to right, #FF971D, #FFB400);
            border: 1px solid #FCC735;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-silver {
            background: linear-gradient(to right, #B5C9DC, #9AC7F2);
            border: 1px solid #81A0BE;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-bronze {
            background: linear-gradient(to right, #EB7120, #C65F05);
            border: 1px solid #F6A169;
            width: 17px;
            height: 17px;
            color: #FFF;
          }

          .tm-regular {
            border: 1px solid rgba(255, 255, 255, 0);
            height: 16px;
          }
        }

        .tm-col2 {
          width: 40px;
          min-width: 40px;
          max-width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            display: block;
            width: 27px;
            height: 27px;
            transform: translateZ(0);
          }
        }

        .tm-col3 {
          padding: 0 10px;
          color: #C2C4C8;
          font-size: 12px;
          font-weight: 600;
          text-align: left;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .tm-col4 {
          width: 50px;
          min-width: 50px;
          max-width: 50px;
          color: rgba(255, 255, 255, .26);
          font-size: 10px;
          font-weight: bold;
        }

        .tm-col5 {
          width: 80px;
          min-width: 80px;
          max-width: 80px;
          color: #BEC0C5;
          font-size: 10px;
          font-weight: 600;
        }

        &.tm-show-more {
          display: flex;
          align-items: center;
          justify-content: center;
          height: auto;
          cursor: pointer;

          .tm-col-full {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px 10px;
            color: #17214D;
            font-size: 14px;
            font-weight: 600;
            text-align: center;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;

            img {
              width: auto;
              height: 25px;
              margin-right: 10px;
            }
          }
        }
      }

      .tm-row.tm-header {
        height: 29px;
      }

      .tm-row.tm-header {
        border-radius: 7px 7px 0 0;
      }

      .tm-row:last-child {
        border-radius: 0 0 15px 15px;
      }

      .tm-row.tm-active {
        background: linear-gradient(to right, #FF7700, #FFC400);
        padding: 0 5px 0 10px;
        border-bottom: 1px solid transparent;

        position: sticky;
        bottom: 0;
        top: 0;
        z-index: 1;

        .tm-col1>div {
          color: #fff;
        }

        .tm-col2 {
          width: 35px;
          min-width: 35px;
          max-width: 35px;

          img {
            display: block;
            width: 35px;
            height: 35px;
            transform: translateZ(0);
          }
        }

        .tm-col3 {
          font-size: 14px;
          color: #fff;
        }

        .tm-col4 {
          width: auto;
          min-width: unset;
          max-width: unset;
          font-size: 16px;
          color: #fff;
          font-weight: bold;
          white-space: nowrap;
          padding-right: 5px;
        }

        .tm-col5 {
          width: auto;
          min-width: unset;
          max-width: unset;
          border-radius: 11px;
          background: #fff;
          color: #17214D;
          font-size: 12px;
          text-align: center;
          height: 22px;
          line-height: 22px;
        }

        .tm-edit-nickname {
          white-space: nowrap;
          display: flex;
          align-items: center;
          cursor: pointer;

          .tm-edit-icon {
            border-radius: 50%;
            width: 22px;
            height: 22px;
            display: flex;
            align-items: center;
            justify-content: center;
            display: inline-block;
            margin-left: 5px;

            svg {
              display: block;
              width: 18px;
              height: 18px;
              position: relative;
              top: 1px;
            }
          }
        }
      }
    }

  }

  @media screen and (orientation: landscape) {
    .MuiPaper-root {
      margin-top: 50px;
    }
  }
}