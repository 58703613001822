.component-wheel_status {
  margin: 10px auto;
  padding: 0 10px 10px;
  max-width: 1528px;
  position: relative;

  font-family: "Open Sans", OpenSans;

  .cgl-cta {
    background-image: url("../assets/wheel-cta-active.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    overflow: visible;
    height: 67px;
    width: 100%;
    max-width: 490px;
    border-radius: 7px;
    margin: 25px auto 0;
    cursor: pointer;

    display: flex;
    align-items: center;
    justify-content: space-between;

    &.cgl-cta.disabled {
      background-image: url("../assets/wheel-cta-disabled.png");
    }

    .wheel-logo {
      margin-left: 14px;
      overflow: visible;
      position: relative;
      height: 67px;

      img {
        display: block;
        position: absolute;
        width: 66px;
        height: 80px;
        bottom: 0px;
      }
    }


    .wheel-cant-spin,
    .wheel-spin-now {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 15px;

      .wheel-spin-button-denied,
      .wheel-spin-button {
        font-size: 12px;
        color: #fff;
        font-weight: bold;
        white-space: nowrap;
      }

      .wheel-spin-button-denied {
        border-radius: 3px;
        background: #920000;
        font-size: 13px;
        font-weight: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        padding: 0 13px;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .53);
      }

      .wheel-arrow {
        border-radius: 4px;
        width: 36px;
        height: 36px;
        background: linear-gradient(to right, #FE1C6B, #F8961D);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-left: 10px;
      }

      .wheel-why {
        margin-left: 10px;
      }

    }

    .wheel-spin-next {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-right: 15px;


      .wheel-count-down {
        display: flex;
        align-items: center;
        box-shadow: 1px 2px 4px rgba(0, 0, 0, .53);

        .txt {
          height: 28px;
          background: #2E3D4B;
          border-radius: 3px 0 0 3px;
          font-size: 13px;
          color: #fff;
          font-weight: normal;
          white-space: nowrap;
          padding: 0 7px 0 10px;
          display: flex;
          align-items: center;
        }

        .count {
          height: 28px;
          background: #FFC400;
          border-radius: 0 3px 3px 0;
          font-size: 16px;
          color: #000;
          font-weight: bold;
          white-space: nowrap;
          padding: 0 10px 0 7px;
          display: flex;
          align-items: center;
        }
      }

      .wheel-arrow {
        border-radius: 4px;
        width: 36px;
        height: 36px;
        background: linear-gradient(to right, #888888, #A6A6A6);
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        margin-left: 19px;
      }
    }

  }
}


@media screen and (min-width:1528px) {
  .component-wheel_status {}
}